import { Transition, Variants, motion } from "framer-motion";
import { useContext } from "react";

import { Appear } from "@/components/appear";
import { Button } from "@/components/ui/button";
import { UIContext } from "@/context";

const buttonVariants: Variants = {
  open: { y: 0, opacity: 1 },
  closed: { y: 100, opacity: 0 },
};

const buttonTransition: Transition = {
  type: "spring",
  damping: 20,
  stiffness: 150,
};

interface MoveUpButtonProps {
  isVisible: boolean;
}

export const MoveUpButton: React.FC<MoveUpButtonProps> = ({ isVisible }) => {
  const { bottomNavHeight } = useContext(UIContext);
  const onMoveToTop = () => {
    document
      .getElementById("table-header")
      ?.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <Appear isVisible={isVisible} withBottomNav withOffset from="bottom">
      <motion.div
        className="absolute right-4"
        initial="closed"
        animate="open"
        exit="closed"
        variants={buttonVariants}
        transition={buttonTransition}
        style={{ bottom: bottomNavHeight }}
      >
        <Button
          onClick={onMoveToTop}
          disabled={false}
          size="icon"
          shape="circle"
          variant="inverse"
          className="h-8 w-8 drop-shadow-md"
        >
          <span className="material-symbols-rounded text-xl">arrow_upward</span>
        </Button>
      </motion.div>
    </Appear>
  );
};
