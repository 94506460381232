import React from "react";

import { cn } from "@/lib/utils";

export const PasswordCriteria: React.FC<{
  label: string;
  valid: boolean;
}> = ({ label, valid }) => {
  return (
    <div
      className={cn(
        "flex flex-row items-center space-x-1 text-2xs",
        valid ? "text-green-500" : "text-text-tertiary",
      )}
    >
      <span className="material-symbols-rounded text-2xs">
        {valid ? "check" : "close"}
      </span>
      <span>{label}</span>
    </div>
  );
};
