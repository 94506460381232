import { Button, ImageWithFallback, Spinner } from "@/components";
import { LEGAL_TERMS, LISTING_MATCHING_FEE } from "@/constants";
import { FeeType, useInfoModal } from "@/hooks";
import { AnimatedContent, ExpandableFeeList } from "@/modals/base";
import { ModalInfo } from "@/modals/modalInfo";
import { getIconForDune } from "@/utility";

interface Listing {
  amount: string;
  unitPrice: string;
  floorPrice: string;
  floorPriceDeltaPercentage: string;
}

interface Proceeds {
  potentialProceedsInUSD: string;
  potentialProceedsInDoge: string;
}

interface Fees {
  network: string;
  trading: string;
  tradingDiscountAlpha: string;
  service: string;
  total: string;
}

interface ConfirmViewProps {
  listing: Listing;
  proceeds: Proceeds;
  tick?: string;
  // tokenData: AccountDrc20Data;
  handleList: () => void;
  loading: boolean;
  fees: Fees;
  checkbox?: {
    visible: boolean;
    onClick: () => void;
    value: boolean;
  };
}

export const ConfirmView: React.FC<ConfirmViewProps> = ({
  listing,
  proceeds,
  handleList,
  tick,
  //   checkbox,
  loading,
  fees,
}) => {
  const {
    amount,
    unitPrice,
    // floorPrice,
    // floorPriceDeltaPercentage,
  } = listing;
  const { network, trading, tradingDiscountAlpha, total } = fees;
  const { potentialProceedsInUSD, potentialProceedsInDoge } = proceeds;
  const imageToken = getIconForDune(tick);

  const { info, infoVisible, handleCloseInfo, handleOpenInfoFee } =
    useInfoModal();

  if (!tick) return null;

  return (
    <AnimatedContent id="confirm_order">
      <div className="flex flex-1 flex-col space-y-4">
        <div className="flex flex-col space-y-4 rounded-lg bg-background-secondary p-4">
          {/** Amount */}
          <div className="flex flex-row items-center space-x-4">
            <ImageWithFallback
              image={imageToken}
              className="h-8 w-8 rounded-full"
            />
            <div className="flex flex-1 flex-row space-x-2">
              <div className="flex flex-1 flex-col space-y-1">
                <span className="text-xs uppercase text-text-tertiary">
                  Amount
                </span>
                <div className="flex flex-1 flex-row justify-between">
                  <span className="text-md uppercase text-text-primary">
                    {`${amount} ${tick}`}
                  </span>
                </div>
              </div>
              <div className="flex flex-1 flex-col space-y-1">
                <span className="w-full text-right text-xs uppercase text-text-tertiary">
                  Unit Price
                </span>
                <div className="flex flex-1 flex-row justify-between">
                  <span className="w-full text-right text-sm uppercase leading-tight text-text-tertiary md:text-md">
                    {unitPrice}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/** Fees */}
        <ExpandableFeeList
          fees={[
            {
              label: "Total Fees",
              value: total,
              onClick: () => handleOpenInfoFee(FeeType.TOTAL),
            },
            {
              label: "Network Fee",
              value: network,
              onClick: () => handleOpenInfoFee(FeeType.NETWORK),
            },
            {
              label: `Matching Fee (${LISTING_MATCHING_FEE}%)`,
              value: trading,
              onClick: () => handleOpenInfoFee(FeeType.MATCHING),
            },
            // We don't give a discount for Alpha Phase on Dunes Listing
            // {
            //   label: `Alpha Phase Discount (${ALPHA_DISCOUNT_DUNES}%)`,
            //   value: `- ${tradingDiscountAlpha}`,
            //   onClick: () => handleOpenInfoFee(FeeType.ALPHA_DISCOUNT),
            // },
            {
              label: "Service Fee",
              value: "100% Discount",
              onClick: () => handleOpenInfoFee(FeeType.SERVICE),
            },
          ]}
          potentialProceeds={{
            inDoge: potentialProceedsInDoge,
            inUSD: potentialProceedsInUSD,
          }}
        />
      </div>

      <div className="flex w-full flex-col space-y-2">
        <Button
          size="large"
          variant="inverse"
          onClick={handleList}
          disabled={loading}
        >
          {loading ? <Spinner /> : "List Now"}
        </Button>
        <p className="text-center text-2xs italic text-text-tertiary">
          By clicking List Now, you confirm the accuracy of the input data and
          agree to the
          <span
            onClick={() => window.open(LEGAL_TERMS, "_blank")}
            className="ml-1 cursor-pointer underline"
          >
            Terms of Service
          </span>
          .
        </p>
      </div>

      <ModalInfo
        info={info}
        isVisible={infoVisible}
        onClose={handleCloseInfo}
      />
    </AnimatedContent>
  );
};
