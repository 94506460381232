import React, { useCallback, useState } from "react";

import { cn } from "@/lib/utils";

interface ImageWithFallbackProps {
  image: string | null;
  className?: string;
}

export const ImageWithFallback: React.FC<ImageWithFallbackProps> = ({
  image,
  className,
}) => {
  const [imageError, setImageError] = useState(false);
  const handleError = useCallback(() => {
    setImageError(true);
  }, []);
  const [isLowResImage, setIsLowResImage] = useState(false);
  const onLoad = useCallback(
    (event: React.SyntheticEvent<HTMLImageElement>) => {
      const { naturalWidth, naturalHeight } = event.currentTarget;
      if (naturalWidth < 48 && naturalHeight < 48) {
        setIsLowResImage(true);
      }
    },
    [],
  );
  return (
    <div
      className={cn(
        "h-7 w-7 shrink-0 overflow-clip rounded-full bg-background-tertiary",
        className,
      )}
    >
      {image && !imageError ? (
        <img
          src={image}
          alt="icon"
          className="h-full w-full"
          onError={handleError}
          onLoad={onLoad}
          style={isLowResImage ? { imageRendering: "pixelated" } : undefined}
        />
      ) : (
        <div className="flex h-full w-full items-center justify-center rounded-full bg-background-tertiary">
          <span className="material-symbols-rounded text-sm text-text-tertiary">
            pets
          </span>
        </div>
      )}
    </div>
  );
};
