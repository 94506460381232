import { useCallback, useState } from "react";

import { marketplaceApiV2 } from "@/lib/fetch";
import { SortOrder, TimeFrames } from "@/types";
import {
  ActivityDRC20,
  ActivitySortParam,
  ActivityType,
} from "@/types/activity";
import { handleError } from "@/utility";

type Drc20ActivitiesRequestParams = {
  tick?: string;
  offset: number;
  limit?: number;
  action?: ActivityType;
  sortOrder?: SortOrder;
  sortParam?: ActivitySortParam;
  history?: TimeFrames;
  address?: string;
};

type FetchDrc20ActivitiesHookReturn = {
  drc20Activities: ActivityDRC20[];
  isDrc20ActivitiesLoading: boolean;
  getDrc20Activities: () => Promise<void>;
};

const fetchDrc20Activities = async (params: Drc20ActivitiesRequestParams) => {
  const {
    tick,
    offset,
    limit,
    action,
    sortOrder,
    sortParam,
    history,
    address,
  } = params;
  const { data } = await marketplaceApiV2(false).get("/offer/drc20/activity", {
    params: {
      tick,
      offset,
      limit,
      action,
      sortOrder,
      sortParam,
      history,
      address,
    },
  });

  if (!data?.activities || !data.activities.length) {
    return [];
  }

  return data.activities;
};

const useFetchDrc20Activities = ({
  tick,
  offset,
  limit = 20,
  action,
  sortOrder,
  sortParam,
  history,
  address,
}: Drc20ActivitiesRequestParams): FetchDrc20ActivitiesHookReturn => {
  const [isDrc20ActivitiesLoading, setIsDrc20ActivitiesLoading] =
    useState(false);
  const [drc20Activities, setDrc20Activities] = useState<ActivityDRC20[]>([]);

  const getDrc20Activities = useCallback(async () => {
    try {
      setIsDrc20ActivitiesLoading(true);
      const res = await fetchDrc20Activities({
        tick,
        offset,
        limit,
        action,
        sortOrder,
        sortParam,
        history,
        address,
      });

      setDrc20Activities(res);
    } catch (e: Error | unknown) {
      handleError(e);
      setDrc20Activities([]);
      // we are throwing again to deal with error in UI further up the dependency chain
      throw new Error("Failed to fetch Drc20 activity");
    } finally {
      setIsDrc20ActivitiesLoading(false);
    }
  }, [tick, offset, limit, action, sortOrder, sortParam, history, address]);

  return { drc20Activities, isDrc20ActivitiesLoading, getDrc20Activities };
};

export { useFetchDrc20Activities, fetchDrc20Activities };
