export const shortenAddress = (address?: string, digits = 4): string => {
  if (address) {
    const addressStart = address.substring(0, digits);
    const addressLength = address.length;
    const cut = addressLength - 4;
    const addressEnd = address.substring(addressLength, cut);
    return `${addressStart}...${addressEnd}`;
  }
  return "";
};
