import dogecore from "bitcore-lib-doge";
import { useCallback, useState } from "react";

import {
  fundWallet,
  getTotalTransactionFeeInSats,
} from "@/context/wallet/lib/transaction.ts";
import { useBalance, useTxWallet } from "@/contextHooks";
import { useWalletManagement } from "@/contextHooks/useWalletManagement";
import { PreparedTx, WalletForTx } from "@/types/transaction";
import { handleError } from "@/utility";

import { fetchDoginals } from "./datafetching/useFetchDoginals";
import { useCurrentAccount } from "./useCurrentAccount";
const { Transaction } = dogecore;

export type UnsafeBigUtxo = {
  txId: string;
  outputIndex: number;
  satoshis: number;
  scriptPk: string;
  inscriptions: {
    inscriptionId: string;
    offset: number;
  }[];
};

const updateWallet = (wallet: WalletForTx, tx: any) => {
  // This filters the utxos of the wallet, so that the utxos used in the tx are removed
  wallet.utxos = wallet.utxos.filter((utxo) => {
    for (const input of tx.inputs) {
      if (
        input.prevTxId.toString("hex") == utxo.txid &&
        input.outputIndex == utxo.vout
      ) {
        return false;
      }
    }
    return true;
  });

  // This adds the utxos of the tx to the wallet
  tx.outputs.forEach((output: any, vout: number) => {
    if (output.script.toAddress().toString() == wallet.address) {
      wallet.utxos.push({
        txid: tx.hash,
        vout,
        script: output.script.toHex(),
        satoshis: output.satoshis,
      });
    }
  });
};

export const useUnblockUtxo = () => {
  const walletManagementContext = useWalletManagement();
  const { showAccountPrivateKey, pw } = walletManagementContext || {};
  const { address, publicKey } = useCurrentAccount();
  const { safeUtxos } = useBalance();
  const [unsafeBigUtxos, setUnsafeBigUtxos] = useState<UnsafeBigUtxo[]>([]);

  const { accountData } = useTxWallet();

  // this fetched all unsafe utxos with > 100_000 and returns them
  const fetchUnsafeBigUtxos = useCallback(async () => {
    if (!address || accountData.accountDrc20Data?.utxos?.length == 0) return [];

    // unsafe big utxos can be either drc20...
    // const drc20sData = await fetchDrc20s(address, {
    //   show_all: true,
    //   show_utxos: true,
    //   value_filter: 100000,
    // });
    // ...or doginals
    const doginalsData = await fetchDoginals(
      address,
      {
        show_all: true,
        show_utxos: true,
        value_filter: 100000,
      },
      true,
    );

    const uniqueUTXOs: UnsafeBigUtxo[] = [];

    // we loop through drc20s and doginals and create a unique list of utxos in the format we want
    accountData.accountDrc20Data?.utxos?.forEach((utxo) => {
      const existingUTXO = uniqueUTXOs.find(
        (u) => u.txId === utxo.txid && u.outputIndex === utxo.vout,
      );
      if (existingUTXO) {
        existingUTXO.inscriptions.push({
          inscriptionId: utxo.inscription_id as string,
          offset: utxo.offset as number,
        });
      } else {
        uniqueUTXOs.push({
          txId: utxo.txid,
          outputIndex: utxo.vout,
          scriptPk: utxo.script,
          satoshis: utxo.shibes,
          inscriptions: [
            {
              inscriptionId: utxo.inscription_id as string,
              offset: utxo.offset as number,
            },
          ],
        });
      }
    });

    doginalsData.inscriptions.forEach((inscription) => {
      const utxo = inscription.utxo;
      const existingUTXO = uniqueUTXOs.find(
        (u) => u.txId === utxo.txid && u.outputIndex === utxo.vout,
      );
      if (existingUTXO) {
        existingUTXO.inscriptions.push({
          inscriptionId: inscription.inscription_id,
          offset: inscription.offset,
        });
      } else {
        uniqueUTXOs.push({
          txId: utxo.txid,
          outputIndex: utxo.vout,
          scriptPk: utxo.script,
          satoshis: utxo.shibes,
          inscriptions: [
            {
              inscriptionId: inscription.inscription_id,
              offset: inscription.offset,
            },
          ],
        });
      }
    });
    return uniqueUTXOs;
  }, [accountData.accountDrc20Data?.utxos, address]);

  // this function builds a transaction that removes all inscriptions from one utxo
  const unblockUtxo = useCallback(
    async ({
      utxo,
      feeRate,
      prev_txs,
    }: {
      utxo: UnsafeBigUtxo;
      feeRate: number;
      prev_txs: PreparedTx[];
    }) => {
      if (utxo.inscriptions.length === 0)
        throw new Error("No inscriptions to remove");
      if (!address) throw new Error("No address found");
      if (!pw) throw new Error("No password found");

      const privKey = await showAccountPrivateKey?.(address, pw);
      if (!privKey) throw new Error("No private key found");

      if (!publicKey) throw new Error("No publicKey found");

      // assemble local wallet for funding
      const wallet: WalletForTx = {
        broadcast(): Promise<void> {
          return Promise.resolve(undefined);
        },
        broadcastIsError: false,
        broadcastIsLoading: false,
        broadcastIsSuccess: false,
        isLoadingDogeBalance: false,
        isWaitingForConfirmations: false,
        resetBroadcastState(): void {},
        address: address,
        utxos: safeUtxos.map((utxo) => ({
          ...utxo,
          satoshis: utxo.shibes,
        })),
        privKey,
        pubKey: publicKey,
      };

      // we go through the inscriptions and create a output for each one
      const receivers: { address: string; amount: number; inscr: string }[] =
        [];

      const TARGET_INSCRIPTION_VALUE = 100000;
      let currentSatIndex = 0;

      // we sort the inscriptions by their offset. Lowest first
      const inscriptions = utxo.inscriptions.sort(
        (a, b) => a.offset - b.offset,
      );

      for (const [inscriptionIndex, inscription] of inscriptions.entries()) {
        // Calculate the gap value. This is the difference between the current sat index and the inscription offset
        const gapValue = inscription.offset - currentSatIndex;

        // If there's a gap and its larger than the min utxo value, create a gap-filling output
        if (gapValue > 0 && gapValue >= 546) {
          receivers.push({
            address: address,
            amount: gapValue,
            inscr: inscription.inscriptionId,
          });
          currentSatIndex += gapValue;
        }

        // now we check how far the next inscription or the output end is from the currentSatIndex
        const gapToNextInscriptionOrEnd =
          (inscriptions[inscriptionIndex + 1]?.offset || utxo.satoshis) -
          currentSatIndex;
        if (inscriptions[inscriptionIndex + 1]?.offset > utxo.satoshis) {
          throw new Error("Inscription offset is larger than utxo value");
        }

        // Add the inscription output, if we have enough room for it
        if (gapToNextInscriptionOrEnd >= TARGET_INSCRIPTION_VALUE) {
          receivers.push({
            address: address,
            amount: TARGET_INSCRIPTION_VALUE,
            inscr: inscription.inscriptionId,
          });
          currentSatIndex += TARGET_INSCRIPTION_VALUE;
        } else {
          // If not enough value for an inscription we throw an error for now
          throw new Error("Not enough value left");
        }

        // the potentially remaining value is handled by tx.change
      }

      for (const prev_tx of prev_txs) {
        updateWallet(wallet, prev_tx.tx);
      }

      // build transaction
      const tx = new Transaction();
      const utxoFormattedForTx = {
        txid: utxo.txId,
        vout: utxo.outputIndex,
        satoshis: utxo.satoshis,
        script: utxo.scriptPk,
      };
      tx.from(utxoFormattedForTx);
      receivers.forEach((v) => {
        tx.to(v.address, v.amount);
      });

      // fund the tx - important because drc20 should not be spent as fee
      fundWallet(wallet, tx, feeRate, false);

      return {
        tx,
        txFeeInSats: getTotalTransactionFeeInSats(tx, feeRate),
      };
    },
    [address, publicKey, pw, safeUtxos, showAccountPrivateKey],
  );

  const getBigUnsafeUtxos = useCallback(async () => {
    if (!address) {
      setUnsafeBigUtxos([]);
      return;
    }
    let unblockableUtxos: UnsafeBigUtxo[] = [];
    try {
      unblockableUtxos = await fetchUnsafeBigUtxos();
    } catch (e: Error | unknown) {
      handleError(e);
    }

    setUnsafeBigUtxos(unblockableUtxos);
  }, [address, fetchUnsafeBigUtxos]);

  return {
    getBigUnsafeUtxos,
    unsafeBigUtxos,
    unblockUtxo,
  };
};
