import { AnimatePresence, motion } from "framer-motion";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  AppScreen,
  Button,
  ImageWithFallback,
  Skeleton,
  useToast,
} from "@/components";
import { WONKY_ORD_URL } from "@/constants";
import { useCurrency, useDogePrice, useTxWallet } from "@/contextHooks";
import { useCollectiblesDetails } from "@/contextHooks/useCollectiblesDetails.tsx";
import { cn } from "@/lib/utils";
import { ModalBuyCollectible, ModalInstall } from "@/modals";
import { ModalType } from "@/routes/Collection/types.ts";
import { CollectionListingProps, DoginalListing } from "@/types";
import {
  createExtendedCollectibleListings,
  getFormattedValue,
} from "@/utility";
import { useCopyToClipboard } from "@uidotdev/usehooks";
import { fetchDoginalToken } from "@/context/helpers/fetchDoginalTokens";
import { useCurrentAccount } from "@/hooks";
import { useOnboardingModalContext } from "@/contextHooks/useOnboardingModal.ts";

const TOAST_COPY = {
  title: "Copied",
  description: "Copied doginal link to clipboard",
};

enum ExpandableCards {
  Traits = "Traits",
  About = "About",
  Details = "Details",
}

export const CollectionDetail: React.FC = () => {
  const { id, collectionSymbol } = useParams();
  const { currency } = useCurrency();
  const { dogePrice } = useDogePrice();
  const { toast } = useToast();
  const [, copy] = useCopyToClipboard();
  const { login } = useTxWallet();
  const navigate = useNavigate();

  const { address: currentAccountAddress } = useCurrentAccount();

  const { isInstallModalOpen } = useOnboardingModalContext();

  const { collectibleListings, setCollectionSymbol } = useCollectiblesDetails();

  const { setFilters, floorPrice, reset_for_symbol } = collectibleListings;

  const [expandedCards, setExpandedCars] = useState<ExpandableCards[]>([
    ExpandableCards.Traits,
    ExpandableCards.About,
    ExpandableCards.Details,
  ]);
  const [modalType, setModalType] = useState<ModalType | undefined>();

  const isCardExpanded = (card: ExpandableCards) =>
    expandedCards.includes(card);

  const toggleCard = useCallback((card: ExpandableCards) => {
    setExpandedCars((prev) =>
      prev.includes(card) ? prev.filter((c) => c !== card) : [...prev, card],
    );
  }, []);

  const [collectible, setCollectible] = useState<DoginalListing | undefined>();

  const [isLoadingCollectible, setIsLoadingCollectible] = useState(false);
  const [openModalInstall, setOpenModalInstall] = useState<boolean>(false);

  const extendedCollectibles = useMemo<CollectionListingProps[]>(() => {
    if (!collectible) return [];

    return createExtendedCollectibleListings({
      listings: [collectible],
      selectedListings: [],
      floorPrice,
      onSelectListing: () => {},
      onNavigateToListing: () => {},
      currency,
      dogePrice,
      identifier: "offerId",
      checkIfUserIsSeller: (address) => address === currentAccountAddress,
    });
  }, [collectible, floorPrice, currency, dogePrice, currentAccountAddress]);

  const findDoginalById = useCallback((id?: string) => {
    setIsLoadingCollectible(true);
    // Collectible not found
    if (id) {
      // Fetch data again
      fetchDoginalToken(id).then((doginal) => {
        if (!doginal) return;
        setCollectible(doginal);
        setIsLoadingCollectible(false);
      });
    }
  }, []);

  const {
    imageURI,
    collectionName,
    collectibleLink,
    collectibleName,
    inscriptionNumber,
    price,
    lastSalePrice,
    traits,
    details,
    about,
    onOpenShibescription,
  } = useMemo(() => {
    const onOpenShibescription = () =>
      window.open(
        `${WONKY_ORD_URL}shibescription/${collectible?.inscriptionId}`,
        "_blank",
      );

    const onOpenWallet = () => navigate(`/wallet/${collectible?.owner}`);

    const imageURI = collectible?.imageURI || "";
    const collectionName =
      collectible?.doginalCollection?.name ||
      collectible?.collectionSymbol?.toUpperCase() ||
      "";
    const collectibleLink = `/collectible/${collectionSymbol}/${id}`;
    const collectibleName = collectible?.name || "Name";
    const inscriptionNumber = `#${collectible?.inscriptionNumber || ""}`;

    const { value: price } = getFormattedValue(
      collectible?.price || 0,
      dogePrice,
      currency,
      true,
    );
    const { value: lastSalePrice } = getFormattedValue(
      collectible?.lastSale?.price || 0,
      dogePrice,
      currency,
      true,
    );

    const traits = collectible?.metadata
      ? Object.keys(collectible.metadata).map((traitName) => {
          const { metadata } = collectible;

          // @ts-expect-error internal type is wrong
          const traitValue = metadata[traitName];
          return {
            trait: traitName,
            value: traitValue,
            rarity: "0%", // TODO: calculate rarity
          };
        })
      : [];

    const details = [
      {
        title: "Inscription ID",
        value: collectible?.inscriptionId || "",
        onClick: onOpenShibescription,
      },
      {
        title: "Owned By",
        value: collectible?.owner || "",
        onClick: onOpenWallet,
      },
      { title: "Deployed Time", value: collectible?.createdAt || "" },
    ];
    const about = collectible?.doginalCollection?.description || "";

    return {
      imageURI,
      collectibleLink,
      collectibleName,
      collectionName,
      inscriptionNumber,
      price: collectible?.price ? price : "",
      lastSalePrice: collectible?.lastSale?.price ? lastSalePrice : "",
      traits,
      details,
      about,
      onOpenShibescription,
    };
  }, [collectible, collectionSymbol, id, dogePrice, currency, navigate]);

  const onCopyLink = useCallback(() => {
    const link = `${window.location.origin}/#${collectibleLink}`;

    toast(TOAST_COPY);
    copy(link);
  }, [copy, collectibleLink, toast]);

  const onShareCollectionLink = useCallback(() => {
    const url = `${window.location.origin}/#${collectibleLink}`;
    if (navigator.share) {
      navigator.share({
        title: `${collectibleName}`,
        text: `${collectibleName}: `,
        url,
      });
    } else {
      onCopyLink();
    }
  }, [onCopyLink, collectibleName, collectibleLink]);

  const onRefresh = useCallback(async () => {
    reset_for_symbol(collectionSymbol);
  }, [reset_for_symbol, collectionSymbol]);

  const onBuy = useCallback(async () => {
    if (openModalInstall) return;

    // open the install modal (by setting this internal state variable) if the user clicks on select item and is not
    // on the right device or has not installed the app
    if (isInstallModalOpen) {
      setOpenModalInstall(true);
      return; // do not select the listing or check for login state
    }

    const isLoggedIn = await login();
    if (isLoggedIn) {
      setModalType(ModalType.Buy);
    }
  }, [openModalInstall, isInstallModalOpen, login]);

  // update the internal state of the install modal variable  when the install modal is closed
  useEffect(() => {
    if (!isInstallModalOpen) {
      setOpenModalInstall(false);
    }
  }, [isInstallModalOpen]);

  const onCloseModal = useCallback(() => {
    setModalType(undefined);
  }, []);

  useEffect(() => {
    if (!collectionSymbol || !id) return;
    setFilters((prev) => {
      return {
        ...prev,
        collectionSymbol,
        inscriptionId: id,
      };
    });
  }, [collectionSymbol, id, setFilters]);

  useEffect(() => {
    // Only update state or context inside useEffect or event handlers
    if (collectionSymbol) {
      setCollectionSymbol(collectionSymbol);
    }
  }, [collectionSymbol, setCollectionSymbol]);

  useEffect(() => {
    findDoginalById(id);
  }, [findDoginalById, id]);

  if (isLoadingCollectible)
    return (
      <AppScreen className="flex flex-1 flex-col">
        <div className="flex flex-1 flex-col">
          <div className="p-4">
            <CollectionHeader
              onClickShare={onShareCollectionLink}
              onClickRefresh={onRefresh}
            />
          </div>
          <div className="flex flex-1 flex-col p-4 lg:flex-row lg:space-x-8">
            <Skeleton className="aspect-square w-full rounded-xl lg:w-1/2" />

            <div className="flex flex-col space-y-4 lg:w-1/2 lg:flex-1">
              <Skeleton className="h-32 w-full rounded-xl" />
              <Skeleton className="h-32 w-full rounded-xl" />
              <Skeleton className="h-32 w-full rounded-xl" />
            </div>
          </div>
        </div>
      </AppScreen>
    );

  return (
    <AppScreen className="flex flex-1 flex-col">
      <div className="flex flex-1 flex-col">
        {/* Header Section */}
        <div className="p-4">
          <CollectionHeader
            onClickShare={onShareCollectionLink}
            onClickRefresh={onRefresh}
          />
        </div>

        <div className="flex flex-1 flex-col p-4 lg:flex-row lg:space-x-8">
          <div className="flex flex-col lg:w-1/2 lg:flex-1">
            <div className="flex w-full flex-col overflow-clip rounded-xl bg-background-secondary">
              <div className="relative aspect-square w-full bg-background-tertiary">
                <ImageWithFallback
                  image={imageURI}
                  className="h-full w-full rounded-none"
                />
                {(!!price || !!lastSalePrice) && (
                  <div className="absolute bottom-2 right-2 flex flex-col rounded-full border border-border-tertiary/10 bg-background-primary/50 px-3 py-1 drop-shadow-xl backdrop-blur-xl lg:hidden">
                    <div
                      className="text-right text-sm font-semibold text-text-primary"
                      dangerouslySetInnerHTML={{
                        __html: collectible?.listed
                          ? price
                          : lastSalePrice
                            ? "Last Price: " + lastSalePrice
                            : "",
                      }}
                    />
                  </div>
                )}
              </div>
              <div className="flex flex-col space-y-2 p-4 lg:hidden">
                <div className="flex flex-row items-center justify-between">
                  <span className="max-w-sm truncate text-sm font-semibold text-text-tertiary">
                    {collectionName}
                  </span>
                  <span
                    className="font-regular cursor-pointer text-xs text-text-tertiary hover:text-text-highlight"
                    onClick={onOpenShibescription}
                  >
                    {inscriptionNumber}
                  </span>
                </div>
                <span className="max-w-md  truncate text-lg font-semibold text-text-primary">
                  {collectibleName}
                </span>

                {/*only allow buying if there is an offer*/}
                {!!collectible?.listed && collectible.doginalOffer?.offerId && (
                  <Button
                    onClick={onBuy}
                    className="w-full"
                    variant="inverse"
                    size="large"
                    disabled={!collectible?.listed}
                  >
                    Buy Now
                  </Button>
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-col space-y-4 lg:w-1/2 lg:flex-1">
            {/* Collection Info Section */}
            <div className="hidden flex-col space-y-2 rounded-xl bg-background-secondary p-3 lg:flex">
              <div className="flex flex-row items-center justify-between">
                <span className="max-w-sm truncate text-sm font-semibold text-text-tertiary">
                  {collectionName}
                </span>
                <span className="font-regular text-xs text-text-tertiary">
                  {inscriptionNumber}
                </span>
              </div>

              <div className="flex flex-row items-center justify-between">
                <span className="max-w-xs truncate text-md font-semibold text-text-primary">
                  {collectibleName}
                </span>

                {(!!price || !!lastSalePrice) && (
                  <div
                    className="text-right text-sm font-semibold text-text-highlight"
                    dangerouslySetInnerHTML={{
                      __html: collectible?.listed
                        ? price
                        : lastSalePrice
                          ? "Last Price: " + lastSalePrice
                          : "",
                    }}
                  />
                )}
              </div>

              {!!collectible?.listed && (
                <Button
                  onClick={onBuy}
                  className="w-full"
                  variant="inverse"
                  size="large"
                  disabled={!collectible?.listed}
                >
                  Buy Now
                </Button>
              )}
            </div>

            {/* Traits Section */}
            {!!traits.length && (
              <div className="flex flex-col">
                <ExpandableCard
                  title={ExpandableCards.Traits}
                  onClick={() => toggleCard(ExpandableCards.Traits)}
                  isExpanded={isCardExpanded(ExpandableCards.Traits)}
                >
                  <TraitList traits={traits} />
                </ExpandableCard>
              </div>
            )}

            {/* About Section */}
            <div className="flex flex-col">
              <ExpandableCard
                title={ExpandableCards.About}
                onClick={() => toggleCard(ExpandableCards.About)}
                isExpanded={isCardExpanded(ExpandableCards.About)}
              >
                <div className="flex flex-col rounded-md bg-background-tertiary p-3">
                  <span className="font-regular text-sm text-text-primary">
                    {about}
                  </span>
                </div>
              </ExpandableCard>
            </div>

            {/* Details Section */}
            <div className="flex flex-col">
              <ExpandableCard
                title={ExpandableCards.Details}
                onClick={() => toggleCard(ExpandableCards.Details)}
                isExpanded={isCardExpanded(ExpandableCards.Details)}
              >
                <DetailList details={details} />
              </ExpandableCard>
            </div>
          </div>
        </div>
      </div>

      {modalType === ModalType.Buy && (
        <ModalBuyCollectible
          onClose={onCloseModal}
          offers={extendedCollectibles}
          refetchListings={onRefresh}
          isVisible={modalType === ModalType.Buy}
        />
      )}

      {openModalInstall && (
        <ModalInstall
          isVisible={openModalInstall}
          onClose={() => {
            setOpenModalInstall(false);
            navigate("/");
          }}
        />
      )}
    </AppScreen>
  );
};

interface CollectionHeaderProps {
  onClickRefresh: () => void;
  onClickShare: () => void;
}

const CollectionHeader: React.FC<CollectionHeaderProps> = ({
  onClickRefresh,
  onClickShare,
}) => {
  const navigate = useNavigate();

  return (
    <div className="flex w-full flex-row items-center justify-between">
      <Button
        variant="ghost"
        className="h-8 w-8 text-text-secondary"
        onClick={() => navigate(-1)}
      >
        <span className="material-symbols-rounded text-lg">arrow_back_ios</span>
      </Button>

      <div className="flex flex-row items-center space-x-2">
        <Button
          onClick={onClickRefresh}
          className="h-8 w-8 rounded-full"
          variant="inverse"
        >
          <span className="material-symbols-rounded text-lg">refresh</span>
        </Button>
        <Button
          onClick={onClickShare}
          className="h-8 w-8 rounded-full"
          variant="inverse"
        >
          <span className="material-symbols-rounded text-lg">ios_share</span>
        </Button>
      </div>
    </div>
  );
};

interface ExpandableCard {
  title: string;
  onClick: () => void;
  isExpanded: boolean;
  children: React.ReactNode;
}

const ExpandableCard: React.FC<ExpandableCard> = ({
  title,
  onClick,
  isExpanded,
  children,
}) => {
  return (
    <div className="flex flex-col rounded-xl bg-background-secondary p-3">
      <div
        className="flex flex-row items-center justify-between"
        onClick={onClick}
      >
        <span className="text-sm font-semibold text-text-primary">{title}</span>
        <Button className="h-8 w-8" variant="ghost" asChild>
          <span
            className={cn(
              "material-symbols-rounded text-lg text-text-primary transition-transform duration-200",
              { "rotate-180": isExpanded },
            )}
          >
            expand_circle_down
          </span>
        </Button>
      </div>
      <AnimatePresence>
        {isExpanded && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="mt-4 flex flex-1 flex-col"
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

interface TraitListItemProps {
  trait: string;
  value: string;
  rarity?: string;
  className?: string;
}

const TraitListItem: React.FC<TraitListItemProps> = ({
  trait,
  value,
  className,
}) => {
  return (
    <div
      className={cn(
        "flex flex-row items-center justify-between rounded-md bg-background-tertiary p-3",
        className,
      )}
    >
      <span className="text-sm font-semibold text-text-primary">{trait}</span>
      <div className="flex flex-row items-center space-x-4">
        <span className="text-right text-sm font-semibold text-text-primary">
          {value}
        </span>
        {/* <span className="flex w-14 flex-col items-center justify-center rounded-full bg-background-primary/50 py-1 text-xs font-medium text-text-tertiary">
          {rarity}
        </span> */}
      </div>
    </div>
  );
};

interface DetailListItemProps {
  title: string;
  value: string;
  className?: string;
  onClick?: () => void;
}

const DetailListItem: React.FC<DetailListItemProps> = ({
  title,
  value,
  className,
  onClick,
}) => {
  return (
    <div
      className={cn(
        "flex flex-row items-center justify-between rounded-md bg-background-tertiary p-3",
        className,
        onClick && "cursor-pointer hover:bg-background-quaternary",
      )}
      onClick={onClick}
    >
      <span className="text-sm font-semibold text-text-primary">{title}</span>
      <span className="max-w-[50%] truncate text-sm font-semibold text-text-primary">
        {value}
      </span>
    </div>
  );
};

interface ListProps<T> {
  items: T[];
  ItemComponent: React.ComponentType<T>;
}

const GenericList = <T,>({ items, ItemComponent }: ListProps<T>) => {
  return (
    <div className="flex flex-col space-y-0.5">
      {items.map((item, index) => (
        <ItemComponent
          key={index}
          className={cn(
            "rounded-xs",
            index === 0 && "rounded-t-md",
            index === items.length - 1 && "rounded-b-md",
          )}
          {...item}
        />
      ))}
    </div>
  );
};

// Usage for DetailList
const DetailList: React.FC<{ details: DetailListItemProps[] }> = ({
  details,
}) => {
  return <GenericList items={details} ItemComponent={DetailListItem} />;
};

// Usage for TraitList
const TraitList: React.FC<{ traits: TraitListItemProps[] }> = ({ traits }) => {
  return <GenericList items={traits} ItemComponent={TraitListItem} />;
};
