import React, { useCallback, useMemo } from "react";

import { cn } from "@/lib/utils";
import { getColorForChange, getColorForValue } from "@/utility";

import {
  AnimatedClickable,
  ImageWithFallback,
  PendingIndicator,
  Skeleton,
} from "@/components";
import { BalanceRowDRC20Props } from "./type";
import { useNavigate } from "react-router-dom";
import { ChangeIndicator } from "@/routes/Trading/components/changeIndicator";

export const BalanceRowDRC20: React.FC<BalanceRowDRC20Props> = ({
  imageURI,
  name,
  tick,
  amount,
  value,
  valueChangeAbsolute,
  price,
  priceChange24H,
  priceChange7D,
  volume7D,
  onClick,
  numPendingSending,
  numPendingReceiving,
}) => {
  const navigate = useNavigate();

  // Click on image should link to drc20 overview
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.stopPropagation(); // Prevent the event from bubbling up to the parent
      navigate(`/drc20/${tick.toLowerCase()}`);
    },
    [navigate, tick],
  );

  const { colorPrice, colorVolume7D } = useMemo(() => {
    return {
      colorPriceChange24H: getColorForChange(priceChange24H),
      colorPriceChange7D: getColorForChange(priceChange7D),
      colorPrice: getColorForValue(price),
      colorVolume7D: getColorForValue(volume7D),
    };
  }, [price, priceChange24H, priceChange7D, volume7D]);

  //@see: https://ethesys.slack.com/archives/C06U0KBUPK3/p1729196249848599
  // const formattedAmount = useMemo(() => {
  //   return `${formatLargeNumber(amount)}`;
  // }, [amount]);

  return (
    <AnimatedClickable
      onClick={onClick}
      whileHover={{ scale: 1.0 }}
      className="flex flex-row space-x-2 rounded-lg bg-background-tertiary/50 p-4 hover:bg-background-tertiary"
    >
      <div className="flex w-1/3 flex-row items-center space-x-3">
        <AnimatedClickable
          onClick={handleClick}
          className="flex flex-row overflow-hidden"
          whileHover={{ scale: 1.1 }}
        >
          <ImageWithFallback image={imageURI} className="h-11 w-11" />
        </AnimatedClickable>

        <div className="flex flex-col">
          <span className="text-md font-bold uppercase text-text-primary">
            {name}
          </span>

          <div className="flex flex-row space-x-2">
            <span className="py-0.5 text-sm text-text-tertiary">{amount}</span>
            {numPendingSending !== 0 && (
              <PendingIndicator pendingAmount={numPendingSending} />
            )}
            {numPendingReceiving !== 0 && (
              <PendingIndicator pendingAmount={numPendingReceiving} />
            )}
          </div>
        </div>
      </div>
      <div className="flex w-2/3 flex-row space-x-2">
        <div className="flex flex-1 flex-col justify-center">
          <span
            className={cn(
              "text-right text-sm font-medium uppercase",
              colorPrice,
            )}
            dangerouslySetInnerHTML={{ __html: price || "-" }}
          />
        </div>

        <div className="flex flex-1 flex-col justify-center">
          <ChangeIndicator
            showEmptyIndicator
            change={priceChange24H}
            className="justify-end text-right text-sm font-medium uppercase"
          />
        </div>

        <div className="flex flex-1 flex-col justify-center">
          <ChangeIndicator
            showEmptyIndicator
            change={priceChange7D}
            className="justify-end text-right text-sm font-medium uppercase"
          />
        </div>

        <div className="flex flex-1 flex-col justify-center">
          <span
            className={cn(
              "text-right text-sm font-medium uppercase",
              colorVolume7D,
            )}
            dangerouslySetInnerHTML={{ __html: volume7D || "-" }}
          />
        </div>

        <div className="flex flex-1 flex-col items-end justify-center">
          <span
            className="text-lg font-bold text-text-primary"
            dangerouslySetInnerHTML={{ __html: value }}
          />
          {valueChangeAbsolute !== "0" && (
            <ChangeIndicator
              change={valueChangeAbsolute}
              className="text-right text-sm font-medium uppercase"
            />
          )}
        </div>
      </div>
    </AnimatedClickable>
  );
};

export const BalanceRowSkeleton = () => {
  return (
    <Skeleton className="flex items-center justify-between rounded-lg bg-background-tertiary/50 p-4">
      <div className="flex w-1/3 flex-row items-center space-x-3">
        <Skeleton className="h-11 w-11 rounded-full bg-background-tertiary/70" />
        <div className="flex flex-col space-y-0.5">
          <Skeleton className="h-5 w-10 bg-background-tertiary/70" />
          <Skeleton className="h-5 w-8 bg-background-tertiary/70" />
        </div>
      </div>
      <div className="flex w-2/3 flex-row items-center space-x-2">
        <div className="justify-cente flex flex-1 flex-col items-end">
          <Skeleton className="h-6 w-full max-w-28 flex-shrink bg-background-tertiary/30 font-medium uppercase" />
        </div>
        <div className="justify-cente flex flex-1 flex-col items-end">
          <Skeleton className="h-6 w-full max-w-24 flex-shrink bg-background-tertiary/30 font-medium uppercase" />
        </div>
        <div className="justify-cente flex flex-1 flex-col items-end">
          <Skeleton className="h-6 w-full max-w-24 flex-shrink bg-background-tertiary/30 text-right font-medium uppercase" />
        </div>
        <div className="justify-cente flex flex-1 flex-col items-end">
          <Skeleton className="h-6 w-full max-w-24 flex-shrink bg-background-tertiary/30 font-medium uppercase" />
        </div>
        <div className="justify-cente flex flex-1 flex-col items-end space-y-1">
          <Skeleton className="h-6 w-full max-w-24 flex-shrink bg-background-tertiary/30 font-medium uppercase" />
        </div>
      </div>
    </Skeleton>
  );
};

export const BalanceRowDrc20Loading = () => {
  return Array(3)
    .fill("")
    .map((_, index) => <BalanceRowSkeleton key={index} />);
};
