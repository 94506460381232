import { sdoggsApiV2 } from "@/lib/fetch";
import {
  Currency,
  DunesToken,
  DunesActivityList,
  DunesData,
  DunesList,
  Sorting,
} from "@/types";
import { FetchActivityData } from "@/types/watchlist.ts";
import { getIconForDune, handleError } from "@/utility";
import { DUNES_LISTINGS_PAGINATION_LIMIT } from "@/constants.ts";

const API_ENDPOINT_ACTIVITY = "/dunes/list/activity";
const API_ENDPOINT_LIST = "/dunes/list";

const gainersSort = (a: DunesToken, b: DunesToken) => b.change - a.change;

const createLookupMap = <T extends keyof DunesData>(
  data: DunesList["list"],
  key: T,
) => {
  return data.reduce(
    (acc: any, item: any) => {
      if (typeof item[key] === "number") {
        acc[item.tick] = item[key] as number;
      } else {
        acc[item.tick] = 0;
      }
      return acc;
    },
    {} as Record<string, number>,
  );
};

let dunesOffsetCounter = 0;
export const fetchDunesTokens = async ({
  offset,
  limit,
  // history,
  sortOrder,
  sortParam,
  currency,
  dogePrice,
  filter,
  cachebreaker = false,
}: FetchActivityData): Promise<DunesToken[]> => {
  try {
    const params = {
      offset,
      limit,
      sortOrder,
      sortParam: sortParam === Sorting.gainers ? Sorting.top : sortParam,
      filterByTick: filter,
    };

    const fetchApiData = (apiEndpoint: string) =>
      sdoggsApiV2(cachebreaker).get<DunesActivityList>(apiEndpoint, {
        params: { ...params },
      });

    const fetchApiDataFallback = (
      apiEndpoint: string,
      adjustedOffset: number,
    ) =>
      sdoggsApiV2(cachebreaker).get<DunesList>(apiEndpoint, {
        params: { 
          ...params,
          offset: adjustedOffset,
          sortParam: "createdAt" 
        },
      });

    // First, attempt to fetch from fetchDuneListByActivity
    const response = await fetchApiData(API_ENDPOINT_ACTIVITY);
    let data = response.data?.list || [];

    // Check if fetchDuneListByActivity returned insufficient data
    if (
      !response.data?.list || // No data returned
      (data.length < DUNES_LISTINGS_PAGINATION_LIMIT &&
        limit === DUNES_LISTINGS_PAGINATION_LIMIT) // if it is another call, we don't want to request the fallback
    ) {
      // Calculate the remaining items needed
      const itemsShort = DUNES_LISTINGS_PAGINATION_LIMIT - data.length;

      // Use collectionOffsetCounter to continue where the last fetchListByCollection left off
      const adjustedOffset = dunesOffsetCounter;

      // Fetch additional items from fetchDunesList using the independent offset counter
      const responseFallback = await fetchApiDataFallback(
        API_ENDPOINT_LIST,
        adjustedOffset,
      );

      if (
        responseFallback.data?.list &&
        responseFallback.data?.list.length > 0
      ) {
        const fallbackData = responseFallback.data?.list.slice(0, itemsShort).map((item) => ({
          ...item,
          changePercent: 0,
          changePercent24h: 0,
          changePercent7d: 0,
          changePercent30d: 0,
          volume: 0,
          volume24h: 0,
          volume7d: 0,
          volume30d: 0,
          floorPrice: 0,
          floorPrice24h: 0,
          floorPrice7d: 0,
          floorPrice30d: 0,
          sales: 0,
          sales24h: 0,
          sales7d: 0,
          sales30d: 0,
        }));

        // we cannot slice here as we don't know how many items are duplicates in the data and fallBackData.
        // So if we slice here, there could be too less data in the end.
        data = data.concat(fallbackData);

        // Update the independent offset counter by the number of items retrieved in this call
        dunesOffsetCounter += itemsShort;
      }
    }

    return processDunesTokens({
      data,
      offset,
      limit,
      sortParam,
      currency,
      dogePrice,
    });
  } catch (e: Error | unknown) {
    handleError(e);
    return [];
  }
};

export const fetchMintableDunesTokens = async ({
  offset,
  limit,
  // history,
  sortOrder,
  sortParam,
  currency,
  dogePrice,
  filter,
  cachebreaker = false,
  mintable,
}: FetchActivityData): Promise<DunesToken[]> => {
  try {
    const params = {
      offset,
      limit,
      sortOrder,
      sortParam: sortParam === Sorting.gainers ? Sorting.top : sortParam,
      filterByTick: filter,
      mintable,
    };

    const fetchApiData = (apiEndpoint: string) =>
      sdoggsApiV2(cachebreaker).get<DunesList>(apiEndpoint, {
        params: { ...params },
      });

    const response = await fetchApiData(API_ENDPOINT_LIST);
    const data = response.data?.list || [];

    return processDunesTokens({
      data,
      offset: dunesOffsetCounter,
      limit,
      sortParam,
      currency,
      dogePrice,
    });
  } catch (e: Error | unknown) {
    handleError(e);
    return [];
  }
};

export const processDunesTokens = async ({ 
  data,
  offset,
  limit,
  sortParam,
  currency,
  dogePrice,
}: {
  data: DunesData[];
  offset?: number;
  limit?: number;
  sortParam?: string;
  currency?: Currency;
  dogePrice?: number;
}): Promise<DunesToken[]> => {
  try {
    const changeMaps = {
      "24h": createLookupMap(data, "changePercent24h"),
      "7d": createLookupMap(data, "changePercent7d"),
      "30d": createLookupMap(data, "changePercent30d"),
      all: createLookupMap(data, "changePercent"),
    };

    const volumeMaps = {
      "24h": createLookupMap(data, "volume24h"),
      "7d": createLookupMap(data, "volume7d"),
      "30d": createLookupMap(data, "volume30d"),
      all: createLookupMap(data, "volume"),
    };

    const priceMaps = {
      "24h": createLookupMap(data, "floorPrice24h"),
      "7d": createLookupMap(data, "floorPrice7d"),
      "30d": createLookupMap(data, "floorPrice30d"),
      all: createLookupMap(data, "floorPrice"),
    };

    const salesMap = {
      "24h": createLookupMap(data, "sales24h"),
      "7d": createLookupMap(data, "sales7d"),
      "30d": createLookupMap(data, "sales30d"),
      all: createLookupMap(data, "sales"),
    };

    const mapItem = (item: DunesData, index: number) => {
      const {
        id,
        symbol,
        tick,
        owner,
        sales,
        volume,
        holders,
        maxSupply,
        floorPrice,
        changePercent,
        currentSupply,
        mints,
        mintTerms,
        divisibility,
      } = item;

      const ticker = tick.toUpperCase();
      // floor price is already correctly calculated in shibes with 18decimals
      const fdvInShibs = isNaN(floorPrice) ? 0 : floorPrice * maxSupply || 0;

      return {
        ticker,
        id,
        name: ticker,
        symbol,
        fdv: fdvInShibs,
        maxSupply,
        rank: index + 1 + (offset || 0),
        image: getIconForDune(ticker),
        price: isNaN(floorPrice) ? 0 : floorPrice,
        price24h: priceMaps["24h"][tick],
        price7d: priceMaps["7d"][tick],
        price30d: priceMaps["30d"][tick],
        change: isNaN(changePercent) ? 0 : changePercent,
        change24h: changeMaps["24h"][tick] || 0,
        change7d: changeMaps["7d"][tick] || 0,
        change30d: changeMaps["30d"][tick] || 0,
        volume: isNaN(volume) ? 0 : volume,
        volume24h: volumeMaps["24h"][tick] || 0,
        volume7d: volumeMaps["7d"][tick] || 0,
        volume30d: volumeMaps["30d"][tick] || 0,
        sales: isNaN(sales) ? 0 : sales,
        sales24h: salesMap["24h"][tick] || 0,
        sales7d: salesMap["7d"][tick] || 0,
        sales30d: salesMap["30d"][tick] || 0,
        owner,
        currentDogePrice: dogePrice || 0,
        holders: isNaN(holders) ? 0 : holders,
        currency: currency ?? Currency.USD,
        currentSupply: currentSupply || 0,
        mints: mints || 0,
        mintTerms,
        divisibility: divisibility || 0,
      };
    };

    let listings = data.map(mapItem);

    if (sortParam === Sorting.gainers) {
      listings = listings.sort(gainersSort);
    }

    const uniqueListMap = new Map(listings.map((item: any) => [item.name, item]));
    // due to the fallback handling the list is probably longer than the limit
    return Array.from(uniqueListMap.values()).slice(0, limit);
  } catch (e: Error | unknown) {
    handleError(e);
    return [];
  }
};
