import React from "react";

import { cn } from "@/lib/utils";

import { Checkbox } from "../ui/checkbox";

interface CheckboxFieldProps {
  id: string;
  text: string;
  value: boolean;
  onToggleChange: () => void;
  className?: string;
}

export const CheckboxField: React.FC<CheckboxFieldProps> = ({
  id,
  text,
  value,
  onToggleChange,
  className,
}) => {
  return (
    <div
      className={cn(
        "flex flex-row items-center space-x-3 rounded-md border-0.5 border-border-tertiary bg-background-tertiary px-3 py-3 text-xs text-text-secondary",
        className,
      )}
      onClick={onToggleChange}
    >
      <Checkbox id={id} checked={value} />
      <label htmlFor={id} onClick={onToggleChange}>
        {text}
      </label>
    </div>
  );
};
