import { twMerge } from "tailwind-merge";

import { cn } from "@/lib/utils";
import { TickerElement } from "@/types";

export interface TickerProps {
  tickers: TickerElement[];
  className?: string;
}

export const Ticker = ({ tickers, className }: TickerProps) => {
  return (
    <div className={cn("flex flex-1 flex-row items-center", className)}>
      {tickers.map((ticker, index) => {
        const isLast = index === tickers.length - 1;
        return (
          <div
            key={index}
            className={twMerge(
              "flex flex-row items-center space-x-1 text-xs font-semibold",
            )}
          >
            <span className="text-text-primary">{ticker.label}:</span>
            <span className="text-text-highlight">{ticker.value}</span>
            {!isLast && (
              <>
                <span className="text-text-primary">•</span>
                <span> </span>
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};
