import React from "react";

import { ImageWithFallback } from "@/components";

interface CardHeaderProps {
  image: string | null;
  tick: string;
}

export const CardHeader: React.FC<CardHeaderProps> = ({ image, tick }) => {
  return (
    <div className="flex w-full flex-row items-center space-x-4">
      <ImageWithFallback image={image} className="h-8 w-8 rounded-full" />
      <span className="text-lg font-bold uppercase text-text-primary">
        {tick}
      </span>
    </div>
  );
};
