import { Spinner } from "../spinner";

export const SearchLoadingIndicator: React.FC<{ loading: boolean }> = ({
  loading,
}) => (
  <div className="flex h-5 w-5 flex-col items-center justify-center">
    {loading ? (
      <Spinner size={16} />
    ) : (
      <span className="material-symbols-rounded text-xl text-text-tertiary">
        search
      </span>
    )}
  </div>
);
