import { useContext } from "react";

import { Drc20TokenListContext } from "@/context/Drc20TokenListProvider";

export const useDrc20TokenList = () => {
  const drc20TokenListContext = useContext(Drc20TokenListContext);

  if (!drc20TokenListContext) {
    throw new Error(
      "useDrc20TokenList must be used within a Drc20TokenListProvider",
    );
  }

  return drc20TokenListContext;
};
