import { ONE_DOGE_IN_SHIBES } from "@/constants";
import { NumberFormatType, formatNumber } from "@/lib/numbers";

/**
 * Calculates the amount of tokens equivalent to a given USD value and formats it.
 *
 * @param usdValue - The value in USD.
 * @param floorPrice - The floor price of the token in USD.
 * @param dogePrice - The current price of Doge in USD.
 * @param maximumFractionDigits - The maximum number of fraction digits to display.
 * @returns The formatted amount of tokens.
 */
export const calculateTokenAmountFromUSD = (
  usdValue: number,
  floorPrice: number,
  dogePrice: number,
): string => {
  const tokenAmount =
    usdValue / ((floorPrice / ONE_DOGE_IN_SHIBES) * dogePrice);
  return formatNumber({ value: tokenAmount, type: NumberFormatType.Price });
};
