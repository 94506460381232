import React, { useMemo } from "react";

import { Input } from "@/components";
import { cn } from "@/lib/utils";
import { evaluatePasswordStrength } from "@/utility";

import { PasswordCriteria } from "../passwordCriteria";
import { InputProps } from "../ui/input";

interface InputPasswordProps extends InputProps {
  password: string;
  className?: string;
  withCriteria?: boolean;
}

export const InputPassword: React.FC<InputPasswordProps> = ({
  password,
  className,
  withCriteria,
  placeholder = "Password",
  onChange,
  ...props
}) => {
  const {
    hasNumbers,
    hasUpperCase,
    hasLowerCase,
    hasSpecialChars,
    hasMinimumLength,
  } = useMemo(() => {
    return evaluatePasswordStrength(password).rules;
  }, [password]);

  return (
    <div className="flex flex-col">
      <Input
        type="password"
        placeholder={placeholder}
        className={cn(
          "border-0.5 border-border-secondary bg-background-primary",
          className,
        )}
        value={password}
        onChange={onChange}
        {...props}
      />
      {withCriteria && (
        <div className="flex flex-row space-x-2 pt-2">
          <div className="flex flex-1 flex-col space-y-2">
            <PasswordCriteria
              label="Minimum 8 characters"
              valid={hasMinimumLength}
            />
            <PasswordCriteria
              label="At least one uppercase letter"
              valid={hasUpperCase}
            />
            <PasswordCriteria
              label="At least one lowercase letter"
              valid={hasLowerCase}
            />
          </div>
          <div className="flex flex-1 flex-col space-y-2">
            <PasswordCriteria label="At least one number" valid={hasNumbers} />
            <PasswordCriteria
              label="At least one special character"
              valid={hasSpecialChars}
            />
          </div>
        </div>
      )}
    </div>
  );
};
