import { cn } from "@/lib/utils";
import { useAppHealthStatus } from "@/hooks/useAppHealthStatus.ts";
import React, { useEffect, useRef } from "react";

export interface LiveIndicatorProps {
  className?: string;
  isDesktop?: boolean;
}

export const LiveIndicator = ({
  className,
  isDesktop = false,
}: LiveIndicatorProps) => {
  const { healthStatus, checkHealthStatus } = useAppHealthStatus();

  // Fetch data on mount and set an interval to fetch data every 5 minutes
  const intervalId = useRef<null | NodeJS.Timeout>(null);
  useEffect(() => {
    if (!intervalId.current) {
      checkHealthStatus();
    }

    if (intervalId.current) {
      clearInterval(intervalId.current);
    }

    // Set a new interval
    intervalId.current = setInterval(() => checkHealthStatus(), 30_000);

    // Cleanup on unmount
    return () => clearInterval(intervalId.current as NodeJS.Timeout);
  }, [checkHealthStatus]);

  return !isDesktop ? (
    <div
      className={cn(
        "flex h-3 w-3 flex-col items-center justify-center rounded-full bg-background-secondary",
        className,
      )}
    >
      <div
        className="relative flex h-2 w-2 cursor-pointer"
        onClick={() =>
          window.open("https://thedogelabs.betteruptime.com", "_blank")
        }
      >
        <div className="absolute inline-flex h-full w-full animate-ping rounded-full bg-green-400 opacity-75" />
        {healthStatus ? (
          <div className="relative inline-flex h-2 w-2 rounded-full bg-green-500" />
        ) : (
          <div className="relative inline-flex h-2 w-2 rounded-full bg-red-500" />
        )}
      </div>
    </div>
  ) : (
    <div
      className="flex w-[135px] cursor-pointer flex-row items-center justify-end space-x-2 rounded-full py-1"
      onClick={() =>
        window.open("https://thedogelabs.betteruptime.com", "_blank")
      }
    >
      <div className="relative flex h-2 w-2">
        <div className="absolute inline-flex h-full w-full animate-ping rounded-full bg-green-400 opacity-75" />
        {healthStatus ? (
          <div className="relative inline-flex h-2 w-2 rounded-full bg-green-500" />
        ) : (
          <div className="relative inline-flex h-2 w-2 rounded-full bg-red-500" />
        )}
      </div>
      <span className="text-xs font-semibold text-text-primary">
        {healthStatus ? "Live" : "Off"}
      </span>
    </div>
  );
};
