import React from "react";

import { Button, CheckboxField, Spinner } from "@/components";
import { LEGAL_TERMS } from "@/constants";

import { AnimatedContent } from "../../base";

interface ConfirmViewProps {
  recipient: string;
  amount: string;
  currency: string;
  fee: string;
  serviceFee: string;
  handleContinue: () => Promise<void>;
  isSendLoading: boolean;
  checkbox?: {
    visible: boolean;
    onClick: () => void;
    value: boolean;
  };
}

export const ConfirmView: React.FC<ConfirmViewProps> = ({
  handleContinue,
  recipient,
  amount,
  currency,
  fee,
  serviceFee,
  isSendLoading,
  checkbox,
}: ConfirmViewProps) => (
  <AnimatedContent id="confirm">
    <div className="flex flex-1 flex-col space-y-2">
      <div className="flex flex-1 flex-col justify-center space-y-1 rounded-md bg-background-secondary px-4 py-2">
        <span className="text-sm font-bold text-text-tertiary">Recipient</span>
        <span className="text-sm">{recipient}</span>
      </div>

      <div className="flex flex-1 flex-col justify-center space-y-1 rounded-md bg-background-secondary px-4 py-2">
        <span className="text-sm font-bold text-text-tertiary">Amount</span>
        <span className="text-sm text-text-secondary">
          {amount}
          {currency.toUpperCase()}
        </span>
      </div>

      <div className="flex flex-col space-y-1">
        <div className="flex flex-1 flex-row space-x-2">
          <div className="flex flex-1 flex-col justify-center space-y-1 rounded-md bg-background-secondary px-4 py-2">
            <span className="text-sm font-bold text-text-tertiary">
              Service Fee
            </span>
            <span
              className="text-sm text-text-secondary"
              dangerouslySetInnerHTML={{ __html: serviceFee }}
            />
          </div>
          <div className="flex flex-1 flex-col justify-center space-y-1 rounded-md bg-background-secondary px-4 py-2">
            <span className="text-sm font-bold text-text-tertiary">Fee</span>
            <span
              className="text-sm text-text-secondary"
              dangerouslySetInnerHTML={{ __html: fee }}
            />
          </div>
        </div>
        <span className="text-justify text-2xs text-text-tertiary">
          The Service Fee covers the cost of creating the transfer inscription
          required by the DRC-20 protocol.
        </span>
      </div>
    </div>

    {checkbox?.visible && (
      <CheckboxField
        onToggleChange={checkbox?.onClick}
        value={checkbox?.value}
        id="confirm"
        className="bg-background-secondary"
        text={`I agree to cancel my current transfer inscriptions to access the full ${currency.toUpperCase()} amount needed.`}
      />
    )}

    <div className="flex flex-col space-y-2">
      <Button
        size="large"
        variant="inverse"
        onClick={handleContinue}
        // disabled={checkbox ? !checkbox.value || isSendLoading : isSendLoading}
        disabled={
          checkbox?.visible ? !checkbox.value || isSendLoading : isSendLoading
        }
      >
        {isSendLoading ? (
          <Spinner size={20} />
        ) : (
          `Send ${currency.toUpperCase()}`
        )}
      </Button>

      <p className="text-center text-2xs italic text-text-tertiary">
        By clicking “Send”, you confirm the accuracy of the input data and agree
        to the
        <span
          onClick={() => window.open(LEGAL_TERMS, "_blank")}
          className="ml-1 cursor-pointer underline"
        >
          Terms of Service
        </span>
        .
      </p>
    </div>
  </AnimatedContent>
);
