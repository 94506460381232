import { useCallback, useState } from "react";

import { APP_VERSION } from "@/constants";

type AllowedTypes = any;

type SetValue<T extends AllowedTypes> = (
  value: T | ((prevValue: T) => T),
) => void;

const getKey = (name: string, makeVersionDependend: boolean) =>
  `dogemp:${makeVersionDependend ? `version-${APP_VERSION}` : ""}:${name}`;

export const useLocalStorage = <T extends AllowedTypes>(
  name: string,
  initialValue: T,
  makeVersionDependend: boolean = true,
): {
  value: T;
  updateValue: SetValue<T>;
  deleteEntry: (keyName?: string) => void;
  purge: () => void;
  getValuesByVersion: (version: string) => { key: string; value: any }[];
} => {
  const key = getKey(name, makeVersionDependend);

  const storedValue = localStorage.getItem(key);
  const initial: T = storedValue ? JSON.parse(storedValue) : initialValue;

  const [value, setValue] = useState<T>(initial);

  const updateValue: SetValue<T> = useCallback(
    (newValue) => {
      const valueToStore =
        newValue instanceof Function ? newValue(value as T) : newValue;
      setValue(valueToStore);
      if (valueToStore) {
        localStorage.setItem(key, JSON.stringify(valueToStore));
      } else {
        localStorage.removeItem(key);
      }
    },
    [key, value],
  );

  const deleteEntry = useCallback(
    (keyName?: string) => {
      const key = getKey(keyName || name, makeVersionDependend);
      localStorage.removeItem(key);
    },
    [makeVersionDependend, name],
  );

  const purge = useCallback(() => {
    localStorage.clear();
  }, []);

  const getValuesByVersion = useCallback(
    (version: string): { key: string; value: any }[] => {
      const keys = Object.keys(localStorage);
      const versionKeys = keys.filter((key) =>
        key.includes(`version-${version}`),
      );
      return versionKeys.map((key) => {
        const value = localStorage.getItem(key);
        return { key, value: value ? JSON.parse(value) : null };
      });
    },
    [],
  );

  return { value, updateValue, deleteEntry, purge, getValuesByVersion };
};
