import { useContext } from "react";

import { DunesTokenListContext } from "@/context/DunesTokenListProvider";

export const useDunesTokenList = () => {
  const dunesTokenListContext = useContext(DunesTokenListContext);

  if (!dunesTokenListContext) {
    throw new Error(
      "useDunesTokenList must be used within a DunesTokenListProvider",
    );
  }

  return dunesTokenListContext;
};
