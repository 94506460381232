import React from "react";

import { Button } from "@/components";

import { AnimatedContent } from "../../base";
import { OnboardingVariant } from "../constants";

interface CompletionViewProps {
  onClose: () => void;
  variant?: OnboardingVariant;
}

export const CompletionView: React.FC<CompletionViewProps> = ({
  onClose,
  variant,
}) => (
  <AnimatedContent id="onboarding_create_3">
    <div className="flex flex-1 flex-col items-center justify-center space-y-10">
      <span className="relative flex h-16 w-16">
        <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-green-600 opacity-25"></span>
        <span className="relative inline-flex h-full w-full items-center justify-center rounded-full bg-green-600">
          <span className="material-symbols-rounded text-4xl text-text-primary">
            verified
          </span>
        </span>
      </span>

      <div className="flex flex-col justify-center space-y-4">
        <h2 className="text-center text-xl font-medium">You're all set!</h2>
        <p className="text-center">
          {variant === OnboardingVariant.Create
            ? "Your new wallet has been created successfully."
            : "Your wallet has been imported successfully."}
        </p>

        <div className="flex flex-1 flex-col justify-center space-y-4"></div>
      </div>
    </div>
    <Button size="large" variant="inverse" onClick={onClose}>
      <span>Let's Go!</span>
    </Button>
  </AnimatedContent>
);
