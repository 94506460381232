/**
 * Calculates the floor difference percentage between unit price and floor price.
 *
 * @param unitPrice - The unit price.
 * @param floorPrice - The floor price.
 * @returns The floor difference percentage, or -1 if any of the prices are 0.
 */
export function calculateFloorDifference(
  unitPrice: number,
  floorPrice: number,
): number {
  if (unitPrice === 0 || floorPrice === 0) {
    return -1;
  }

  return ((unitPrice - floorPrice) / floorPrice) * 100;
}
