import { useCallback, useState } from "react";

import { REORG_SAFE_MIN_CONFIRMATIONS } from "@/constants";
import { fetchDrc20Listings } from "@/context/helpers/fetchDrc20Listings";
import {
  Drc20Listing,
  ListingStatus,
  SortOrder,
  SortParamListingsDRC20,
} from "@/types";
import { getTxConfirmations, getTxFromInscriptionId } from "@/utility";

/*
Unlike useFetchDrc20Listings hook, this hook fetches listings for a specific address.
- It fetches both listed AND PENDING listings, which the other hook does not.
- It also double checks the status of the listed ones.
- It paginates automatically, to make sure, it gets ALL listings.
*/

type UseFetchDrc20ListingsForAddressReturn = {
  listings: Drc20Listing[];
  loading: boolean;
  error?: Error | unknown;
  fetchListings: (token?: string, cacheBreaker?: boolean) => Promise<void>;
};

const useFetchDrc20ListingsForAddress = ({
  address,
}: {
  address?: string;
}): UseFetchDrc20ListingsForAddressReturn => {
  const [listings, setListings] = useState<Drc20Listing[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | unknown>();

  const fetchListings = useCallback(
    async (token?: string, cacheBreaker: boolean = false) => {
      if (!token || !address) {
        return;
      }

      setLoading(true);
      const limit = 20; // Number of listings to fetch per request
      let offset = 0;
      let allListings: Drc20Listing[] = [];
      let hasMoreData = true;

      try {
        while (hasMoreData) {
          const [listed, pending] = await Promise.all([
            fetchDrc20Listings(
              {
                tick: token,
                seller: address,
                offset: offset,
                limit: limit,
                sortParam: SortParamListingsDRC20.CreatedAt,
                sortOrder: SortOrder.Descending,
              },
              cacheBreaker,
            ),
            fetchDrc20Listings(
              {
                tick: token,
                seller: address,
                offset: offset,
                limit: limit,
                status: ListingStatus.Pending,
                sortParam: SortParamListingsDRC20.CreatedAt,
                sortOrder: SortOrder.Descending,
              },
              cacheBreaker,
            ),
          ]);

          const total = pending.total + listed.total;
          const offers = [...pending.offers, ...listed.offers];

          const result = { offers, total };

          console.log(
            "useFetchDrc20ListingsForAddress- Fetched listings",
            result.total,
            "offset",
            offset,
          );
          allListings = [...allListings, ...result.offers];

          // If no more listings are returned, stop the loop
          if (result.total < offset + limit) {
            hasMoreData = false;
          } else {
            offset += limit;
            console.log("useFetchDrc20ListingsForAddress - Offset", offset);
          }
        }

        // Double-check the status of the listed ones
        const safeResult = await Promise.all(
          allListings.map(async (r) => {
            if (r.status === ListingStatus.Listed) {
              const confirmations = await getTxConfirmations(
                getTxFromInscriptionId(r.inscriptionId),
              );
              const doubleCheckedStatus =
                confirmations >= REORG_SAFE_MIN_CONFIRMATIONS
                  ? ListingStatus.Listed
                  : ListingStatus.Pending;

              return {
                ...r,
                status: doubleCheckedStatus,
              };
            }
            return r;
          }),
        );

        setListings(safeResult);
      } catch (error: Error | unknown) {
        setError(error);
        setListings([]);
        console.error("Error fetching listings", error);
      } finally {
        setLoading(false);
      }
    },
    [address],
  );

  return { listings, loading, error, fetchListings };
};

export { useFetchDrc20ListingsForAddress };
export type { UseFetchDrc20ListingsForAddressReturn };
