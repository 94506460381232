import { useCallback, useState } from "react";

import { useDogePrice } from "@/contextHooks";
import { marketplaceApiV2 } from "@/lib/fetch";
import {
  AccountDrc20Data,
  AllDrc20Data,
  Drc20TokenDataResponse,
  UseSingleDrc20HookReturn,
} from "@/types/inscription.ts";
import {
  calculateTokenValueInDoge,
  calculateTokenValueInUSD,
  handleError,
} from "@/utility";

import { fetchDrc20s, useCurrentAccount } from "..";
import { Drc20List } from "@/types";

// hook to fetch Drc20 token data, tickdata, dogeprice
// if there is an address and this address has the token, fetch address related data and merge them all in one

export const useFetchDrc20 = (): UseSingleDrc20HookReturn => {
  const { dogePrice } = useDogePrice();
  const { address } = useCurrentAccount();
  const [loading, setLoading] = useState(false);
  const [loadingAccountData, setLoadingAccountData] = useState(false);
  const [accountData, setAccountData] = useState<
    AccountDrc20Data | undefined
  >();
  const [tokenData, setTokenData] = useState<AllDrc20Data | undefined>();

  const getDrc20Data = useCallback(
    async (tick?: string) => {
      if (!tick) {
        setTokenData(undefined);
        return;
      }

      if (!dogePrice) {
        return;
      }

      try {
        setLoading(true);

        //fetch additional data about the token e.g. limitPerMint, mintingTransactions, deployer, deploymentShibescription,ranking etc.
        // @todo: The featured endpoint is not the right one to fetch the data, we need to change it to the correct one
        const [rankingDataRes, activityData] = await Promise.all([
          marketplaceApiV2(false).get<Drc20TokenDataResponse>(
            `drc20/data/${tick}`,
          ),
          marketplaceApiV2(false).get<Drc20List>("/drc20/list/activity", {
            params: { filterByTick: tick, offset: 0, limit: 1, action: "sale" },
          }),
        ]);

        const activityItem = activityData.data.list[0];

        const rankingData: Drc20TokenDataResponse = rankingDataRes.data;
        const {
          limitPerMint = 0,
          currentSupply = 0,
          maxSupply = 0,
          timestamp = new Date().toISOString().split("T")[0],
          deployer,
          inscriptionId,
          inscriptionNumber,
          rank = 0,
          trustLevel = 1,
          mintingTransactions = 0,
        } = rankingData;

        setTokenData({
          ...activityItem,
          limitPerMint,
          currentSupply,
          maxSupply,
          mintingTransactions,
          timestamp: new Date(timestamp).toISOString().split("T")[0],
          deployer,
          inscriptionId,
          inscriptionNumber,
          trustLevel,
          rank,
        });
      } catch (e: Error | unknown) {
        handleError(e);
        // we are throwing again to deal with error in UI further up the dependency chain
        throw new Error("Failed to fetch Drc20 details");
      } finally {
        setLoading(false);
      }
    },
    [dogePrice],
  );

  const getDrc20AccountData = useCallback(
    async (tick?: string, loadUtxos?: boolean, cachebreaker?: boolean) => {
      if (!tick) {
        setTokenData(undefined);
        setAccountData(undefined);
        return;
      }

      if (!address || !tokenData || !dogePrice) return;

      try {
        setLoadingAccountData(true);

        console.log("getDrc20Data - fetch address related token data", address);

        const show_utxos = loadUtxos === true;
        const cacheBreaker = cachebreaker === true;
        const data = await fetchDrc20s(
          address,
          { tick, show_utxos },
          cacheBreaker,
        );
        const userTokenData = data.drc20[0];

        // if there is user data, fetch it
        if (userTokenData) {
          const { available, transferable } = userTokenData;
          const amount = Number(available) + Number(transferable);

          const floorPrice = tokenData.floorPrice;
          const walletTokenValueInDoge = calculateTokenValueInDoge(
            amount,
            floorPrice,
          );
          const walletTokenValueInUSD = calculateTokenValueInUSD(
            amount,
            floorPrice,
            dogePrice,
          );

          setAccountData({
            ...userTokenData,
            availableBalance: `${amount} ${tick.toUpperCase()}`,
            walletTokenValueInDoge,
            walletTokenValueInUSD,
            address,
          });
        }
      } catch (e: Error | unknown) {
        handleError(e);
        // we are throwing again to deal with error in UI further up the dependency chain
        throw new Error("Failed to fetch Drc20 Account Data");
      } finally {
        setLoadingAccountData(false);
      }
    },
    [address, dogePrice, tokenData],
  );

  return {
    getDrc20Data,
    getDrc20AccountData,
    accountDrc20Data: accountData,
    tokenData,
    loading: loading || loadingAccountData,
  };
};
