import { marketplaceApiV2 } from "@/lib/fetch";
import { Drc20Listing, FilterListingsDRC20, SortListingsDRC20 } from "@/types";

export const fetchDrc20Listings = async (
  fetchParams: SortListingsDRC20 & FilterListingsDRC20,
  cachebreaker: boolean = false,
): Promise<{
  offers: Drc20Listing[];
  total: number;
}> => {
  const {
    tick,
    seller,
    offset,
    limit,
    sortOrder,
    sortParam,
    status,
    amount,
    inscriptionId,
    priceMin,
    priceMax,
    floorDiffMinPercent,
    floorDiffMaxPercent,
  } = fetchParams;

  const { data } = await marketplaceApiV2(cachebreaker).get(
    "/offer/drc20/list",
    {
      params: {
        tick,
        seller,
        offset,
        limit,
        sortOrder,
        sortParam,
        status,
        amount,
        inscriptionId,
        priceMin,
        priceMax,
        floorDiffMinPercent,
        floorDiffMaxPercent,
      },
    },
  );

  return data;
};
