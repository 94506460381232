import { shortenAddress } from "@/lib/address";

interface SuggestionProps {
  label: string;
  value: string;
  onSelect: () => void;
}

export const SuggestionAddress: React.FC<SuggestionProps> = ({
  label,
  value,
  onSelect,
}) => {
  return (
    <div
      className="flex cursor-pointer flex-row items-center space-x-2 rounded-lg bg-background-secondary px-3 py-4 text-sm hover:bg-background-tertiary"
      onClick={onSelect}
    >
      <div className="flex flex-1 flex-col">
        <span className="text-text-tertiary">{label}</span>
        <span>{shortenAddress(value)}</span>
      </div>
      <span className="material-symbols-rounded text-xl text-text-tertiary">
        chevron_right
      </span>
    </div>
  );
};
