import React from "react";

import { Button } from "@/components";

import { AnimatedContent } from "../../base";

interface StartingViewProps {
  onStartCreate: () => void;
  onStartImport: () => void;
}

export const StartingView: React.FC<StartingViewProps> = ({
  onStartCreate,
  onStartImport,
}) => (
  <AnimatedContent id="onboarding_start">
    <div className="flex flex-1 flex-col items-center justify-center space-y-12">
      <span className="relative flex h-16 w-16">
        <span className="relative inline-flex h-full w-full items-center justify-center rounded-full bg-background-tertiary">
          <span className="material-symbols-rounded text-4xl text-text-primary">
            account_circle
          </span>
        </span>
      </span>
      <div className="flex flex-col justify-center space-y-4">
        <h2 className="text-center text-xl font-medium">No Wallet Detected</h2>
        <p className="text-center">
          To get started, create a new wallet or import an existing one.
        </p>
      </div>
    </div>

    <div className="flex flex-col space-y-2">
      <Button size="large" variant="inverse" onClick={onStartCreate}>
        <span>Create New Wallet</span>
      </Button>
      <Button size="large" variant="default" onClick={onStartImport}>
        <span>Import Existing Wallet</span>
      </Button>
    </div>
  </AnimatedContent>
);
