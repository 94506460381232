import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  ButtonBar,
  TableActivity,
  TableContentActivityCollection,
} from "@/components";
import { PAGINATION_LIMIT } from "@/constants";
import { useCurrency, useDogePrice } from "@/contextHooks";
import { useCollectiblesDetails } from "@/contextHooks/useCollectiblesDetails.tsx";
import { useInfiniteScroll } from "@/hooks";
import {
  ModalActivityFilter,
  ModalActivitySearch,
  ModalActivitySort,
} from "@/modals";
import {
  ActivityFilters,
  ActivitySort,
  ActivityType,
  CollectionActivity,
  Currency,
} from "@/types";
import { getFormattedValue } from "@/utility";

import { ModalType } from "../types";
import {
  FILTER_DEFAULT,
  SORT_DEFAULT,
} from "@/hooks/datafetching/useFetchCollectionActivities";

interface TableCollectionActivityProps {
  collectionSymbol: string | undefined;
}

export const TabCollectionActivity: React.FC<TableCollectionActivityProps> = ({
  collectionSymbol,
}) => {
  const navigate = useNavigate();
  const { currency } = useCurrency();
  const { dogePrice, loading: isLoadingDogePrice } = useDogePrice();

  /**
   * Local State
   */
  const [modalType, setModalType] = useState<ModalType | undefined>();

  const { collectibleActivities, setCollectionSymbol } =
    useCollectiblesDetails();

  const {
    isLoading,
    hasMoreData,
    isLoadingMoreData,
    setFilters,
    setSort,
    sort,
    filters,
    reset,
    offset,
    setOffset,
    data,
  } = collectibleActivities;

  const search = useMemo(() => {
    return filters.address || "";
  }, [filters.address]);

  const { hasActiveFilters, hasActiveSorting, hasActiveSearch } =
    useMemo(() => {
      const { address, ...otherFilters } = filters;
      return {
        hasActiveFilters: !Object.entries(otherFilters).every(
          // @ts-expect-error internal type is wrong
          ([key, value]) => FILTER_DEFAULT[key] === value,
        ),
        hasActiveSorting: !Object.entries(sort).every(
          // @ts-expect-error internal type is wrong
          ([key, value]) => SORT_DEFAULT[key] === value,
        ),
        hasActiveSearch: !!address,
      };
    }, [filters, sort]);

  // Format token activities
  const dataCollectionActivity = useMemo<CollectionActivity[]>(() => {
    if (!data || isLoadingDogePrice) return [];

    return data.map((activity) => {
      const {
        collectionSymbol,
        inscriptionNumber,
        doginalName,
        imageURI,
        type,
        price,
        from,
        to,
        createdAt,
        inscriptionId,
        floorDifference,
        floorPrice,
      } = activity;

      const { valueInDoge: priceDoge, valueInUSD: priceUSD } =
        getFormattedValue(price, dogePrice, currency);

      return {
        doginalName,
        imageURI,
        collectionSymbol,
        inscriptionNumber,
        type: type as ActivityType,
        price,
        priceForCurrency: currency === Currency.DOGE ? priceDoge : priceUSD,
        priceDoge,
        priceUSD,
        from,
        to,
        createdAt,
        inscriptionId,
        floorDifference,
        floorPrice,
        onNavigateToListing: () =>
          navigate(
            `/collectible/${collectionSymbol.toLowerCase()}/${inscriptionId}`,
          ),
      };
    });
  }, [data, isLoadingDogePrice, dogePrice, currency, navigate]);

  /**
   * Button Bar: Modals
   */

  const onOpenModalFilter = useCallback(() => {
    setModalType(ModalType.Filter);
  }, []);

  const onOpenModalSort = useCallback(() => {
    setModalType(ModalType.Sort);
  }, []);

  const onOpenModalSearch = useCallback(() => {
    setModalType(ModalType.Search);
  }, []);

  const onCloseModal = useCallback(() => {
    setModalType(undefined);
  }, []);

  /**
   * Button Bar: Other Actions
   */

  const onRefresh = useCallback(async () => {
    if (!collectionSymbol) return;
    reset(collectionSymbol);
  }, [collectionSymbol, reset]);

  /**
   * Apply Filters/Sort/Search
   */

  const onApplyFilter = useCallback(
    (type?: ActivityType) => {
      // we want to reset the offset when we apply a filter
      setFilters((prev: ActivityFilters) => ({ ...prev, type, offset: 0 }));
      onCloseModal();
    },
    [onCloseModal, setFilters],
  );

  const onApplySort = useCallback(
    (sort: ActivitySort | undefined) => {
      setSort(sort);
      onCloseModal();
    },
    [onCloseModal, setSort],
  );

  const onApplySearch = useCallback(
    (search: string) => {
      // we want to reset the offset when we apply a filter
      setFilters((prev: ActivityFilters) => ({
        ...prev,
        address: search?.length ? search : undefined,
        offset: 0,
      }));
      onCloseModal();
    },
    [onCloseModal, setFilters],
  );

  /**
   * Data Transformations
   */

  const buttonBarConfig = useMemo(() => {
    return [
      {
        icon: "filter_list",
        onClick: onOpenModalFilter,
        isActive: hasActiveFilters,
      },

      {
        icon: "swap_vert",
        onClick: onOpenModalSort,
        isActive: hasActiveSorting,
      },
      { icon: "search", onClick: onOpenModalSearch, isActive: hasActiveSearch },
      { icon: "refresh", onClick: onRefresh },
    ];
  }, [
    onOpenModalFilter,
    onOpenModalSearch,
    onOpenModalSort,
    onRefresh,
    hasActiveFilters,
    hasActiveSorting,
    hasActiveSearch,
  ]);

  /**
   * Table Pagination
   */

  // For Infinite Scroll
  const bottomTableRef = useInfiniteScroll({
    isLoadingData: isLoading,
    hasMoreData: hasMoreData,
    isLoadingMoreData: isLoadingMoreData,
    setOffset,
    offset,
    paginationLimit: PAGINATION_LIMIT,
  });

  /**
   * Effects
   */

  useEffect(() => {
    setCollectionSymbol(collectionSymbol);
  }, [collectionSymbol, setCollectionSymbol]);

  return (
    <>
      <div className="flex flex-1 flex-col">
        {/** Actions */}
        <div className="w-full px-4 md:px-0">
          <ButtonBar buttons={buttonBarConfig} />
        </div>

        {/** Activity */}
        <div className="mt-4 pb-16 md:mt-8">
          <TableActivity
            data={dataCollectionActivity}
            columns={TableContentActivityCollection}
            loading={isLoading}
          />
          <div ref={bottomTableRef} />
        </div>
      </div>

      {/** Modals */}

      <ModalActivitySearch
        title="Search"
        search={search}
        onClose={onCloseModal}
        onApply={onApplySearch}
        isVisible={modalType === ModalType.Search}
      />

      <ModalActivityFilter
        title="Filter"
        type={filters.type}
        onClose={onCloseModal}
        onApply={({ type }) => onApplyFilter(type)}
        isVisible={modalType === ModalType.Filter}
      />

      <ModalActivitySort
        title="Sort"
        sort={sort}
        onClose={onCloseModal}
        onApply={onApplySort}
        isVisible={modalType === ModalType.Sort}
      />
    </>
  );
};
