import React, { useCallback, useEffect, useMemo, useState } from "react";

import { AppScreen, ScrollableTabs, TabItem, ToggleSwitch } from "@/components";
import { useAppContentSettings } from "@/contextHooks";
import { Content, Tab } from "@/types";

import { TradingCollectibles, TradingDRC20, TradingDunes } from "./tabs";
import { useParams } from "react-router-dom";
import { OnboardingModalContextProvider } from "@/context";
import { useOperatingSystem } from "@/hooks";

const TABS = [
  { value: Tab.Top, label: "Top Gainers" },
  { value: Tab.Trending, label: "Trending" },
  { value: Tab.Recent, label: "Recent Sales" },
  { value: Tab.Watchlist, label: "Watchlist" },
];

export const Trading: React.FC = () => {
  const { content: paramContent } = useParams();
  const { isMobile, isTablet } = useOperatingSystem();
  const [enabledTabItems, setEnabledTabItems] = useState<TabItem[]>([]);
  const {
    content,
    icon,
    changeContent,
    setContent,
    tabExplore,
    setTabExplore,
  } = useAppContentSettings();

  useEffect(() => {
    if (paramContent) {
      setContent(paramContent as Content);
    }
  }, [paramContent, setContent]);

  const contentChecks = useMemo(() => {
    return {
      isContentDRC20: content === Content.DRC20,
      isContentCollectibles: content === Content.COLLECTIBLES,
      isContentDunes: content === Content.DUNES,
    };
  }, [content]);

  const tabItems = useMemo<TabItem[]>(() => {
    return TABS.map((tab) => ({
      label: tab.label,
      value: tab.value,
      isActive: tabExplore === tab.value,
      onSelect: (value: Tab) => setTabExplore(value),
    }));
  }, [tabExplore, setTabExplore]);

  const changeToNextTabIfCurrentTabContentIsEmpty = useCallback(
    (currentTab: Tab) => {
      switch (currentTab) {
        case Tab.Top:
          setTabExplore(Tab.Trending);
          break;
        case Tab.Trending:
          setTabExplore(Tab.Recent);
          break;
        case Tab.Recent:
          setTabExplore(Tab.Watchlist);
          break;
        case Tab.Watchlist:
          setTabExplore(Tab.Top);
          break;
      }
    },
    [setTabExplore],
  );

  useEffect(() => {
    if (
      // One is needed here, because WATCHLIST Tab is always enabled
      enabledTabItems.length > 1 &&
      !enabledTabItems.some((tabItem) => tabItem.value === tabExplore)
    ) {
      changeToNextTabIfCurrentTabContentIsEmpty(tabExplore);
    } else {
      setTabExplore(tabExplore);
    }
  }, [
    changeToNextTabIfCurrentTabContentIsEmpty,
    enabledTabItems,
    setTabExplore,
    tabExplore,
  ]);

  return (
    <OnboardingModalContextProvider>
      <AppScreen withBottomNav>
        <div className="flex w-full flex-1 flex-col">
          <div className="flex h-12 w-full flex-row items-center space-x-2 pr-4 md:h-auto">
            {enabledTabItems && enabledTabItems.length > 0 && (
              <ScrollableTabs
                tabs={enabledTabItems}
                className="mr-4 overflow-hidden pl-4 lg:py-2"
              />
            )}
            <ToggleSwitch
              value={content}
              icon={icon}
              onChange={changeContent}
              className={isMobile || isTablet ? "" : "lg:hidden"}
            />
          </div>
          {contentChecks.isContentDRC20 && (
            <TradingDRC20
              selectedTab={tabExplore}
              setEnabledTabItems={setEnabledTabItems}
              availableTabItems={tabItems}
            />
          )}
          {contentChecks.isContentCollectibles && (
            <TradingCollectibles
              selectedTab={tabExplore}
              setEnabledTabItems={setEnabledTabItems}
              availableTabItems={tabItems}
            />
          )}
          {contentChecks.isContentDunes && (
            <TradingDunes
              selectedTab={tabExplore}
              setEnabledTabItems={setEnabledTabItems}
              availableTabItems={tabItems}
            />
          )}
        </div>
      </AppScreen>
    </OnboardingModalContextProvider>
  );
};
