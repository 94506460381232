import React from "react";
import {
  Route,
  RouterProvider,
  createHashRouter,
  createRoutesFromElements,
} from "react-router-dom";

import {
  Account,
  AccountCollectionDetail,
  AccountTokenDetail,
  AccountDuneDetail,
  Collection,
  CollectionDetail,
  Dune,
  InscriptionService,
  LayoutApp,
  NoResults,
  PageError,
  PageNotFound,
  Token,
  Trading,
  WalletOverview,
} from "./routes";
import { OnboardingModalsContextWrapper } from "@/components/onboardingModalsContextWrapper";
import { OnboardingModalContextProvider } from "@/context";

const router = createHashRouter(
  createRoutesFromElements(
    <Route path="/" element={<LayoutApp />} errorElement={<PageError />}>
      {/* Make Trading the index route and accessible via /trading */}
      <Route index element={<Trading />} />
      <Route path="trading" element={<Trading />} />
      <Route path="trading/:content" element={<Trading />} />

      {/* Other Tabs */}
      {/*<Route path="chat" element={<Chat />} />*/}

      <Route path="account" element={<OnboardingModalsContextWrapper />}>
        <Route index element={<Account />} />
        <Route path="drc20/:token" element={<AccountTokenDetail />} />
        <Route
          path="collectible/:collectionSymbol"
          element={<AccountCollectionDetail />}
        />
        <Route path="dune/:token" element={<AccountDuneDetail />} />
      </Route>

      <Route
        path="drc20/:token"
        element={
          <OnboardingModalContextProvider>
            <Token />
          </OnboardingModalContextProvider>
        }
      />
      <Route
        path="dune/:token"
        element={
          <OnboardingModalContextProvider>
            <Dune />{" "}
          </OnboardingModalContextProvider>
        }
      />
      <Route
        path="collectible/:collectionSymbol"
        element={
          <OnboardingModalContextProvider>
            <Collection />
          </OnboardingModalContextProvider>
        }
      />
      <Route
        path="collectible/:collectionSymbol/:id"
        element={
          <OnboardingModalContextProvider>
            <CollectionDetail />
          </OnboardingModalContextProvider>
        }
      />
      <Route path="collectible/:collectionSymbol" element={<Collection />} />

      <Route path="wallet" element={<OnboardingModalsContextWrapper />}>
        <Route path=":walletAddress" element={<WalletOverview />} />
      </Route>

      <Route path="service">
        <Route path="inscribe" element={<InscriptionService />} />
      </Route>

      <Route path="search/noresults" element={<NoResults />} />

      {/* Fallback for any unmatched routes */}
      <Route path="*" element={<PageNotFound />} />
    </Route>,
  ),
);

export const AppRouter: React.FC = () => {
  return <RouterProvider router={router} />;
};
