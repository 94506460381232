import { motion } from "framer-motion";

import { ImageWithFallback } from "@/components/imageWithFallback";
import { Spinner } from "@/components/spinner";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { shortenAddress } from "@/lib/address";
import { NumberFormatType, formatNumber } from "@/lib/numbers";
import { cn } from "@/lib/utils";
import { ListingStatus, TokenListingProps } from "@/types";
import {
  getFormattedValue,
  getIconForTick,
  getTimeDifference,
} from "@/utility";
import { ColumnDef } from "@tanstack/react-table";

import {
  ChangeCell,
  ChangeCellType,
  HeaderCell,
  NumberCell,
  TextCell,
} from "../components";
import { handleSelectRow } from "./handleSelectRow";
import { Link } from "react-router-dom";

export const TableContentListingsDRC20: ColumnDef<TokenListingProps>[] = [
  {
    id: "select",
    header: () => {
      return <HeaderCell title="" aligned="left" className="mx-2 w-4" />;
    },
    cell: ({ row }) => {
      const { isSelected, userIsSeller } = row.original;
      const onClick = userIsSeller
        ? undefined
        : () => handleSelectRow(row, !isSelected);
      const classNameSelection = isSelected
        ? "bg-primary-500 text-white"
        : "text-text-tertiary";
      return (
        <div
          className={cn(
            "text-text-xs mx-2 h-4 w-4 overflow-hidden rounded-sm",
            classNameSelection,
          )}
          onClick={onClick}
        >
          <Checkbox
            checked={isSelected}
            className={cn(
              "rounded-none",
              isSelected && "border-transparent",
              userIsSeller && "border-none",
            )}
          />
        </div>
      );
    },
  },
  {
    accessorKey: "item",
    header: () => {
      return <HeaderCell title="Item" aligned="left" className="w-28" />;
    },
    cell: ({ row }) => {
      const {
        tick,
        status,
        onBuy,
        userIsSeller,
        isLoading,
        isSelected,
        inscriptionNumber,
      } = row.original;
      const image = getIconForTick(tick);
      const onClick = userIsSeller
        ? undefined
        : () => handleSelectRow(row, !isSelected);
      const onClickBuy = userIsSeller ? undefined : onBuy;
      return (
        <motion.div
          className="flex w-28 flex-row items-center space-x-2 lg:space-x-4"
          whileHover={{ scale: 1.05 }}
          transition={{ duration: 0.1 }}
        >
          <div
            className="relative h-7 w-7 overflow-visible lg:h-10 lg:w-10"
            onClick={onClickBuy}
          >
            <ImageWithFallback
              image={image}
              className="h-7 w-7 lg:h-10 lg:w-10"
            />
            {status == ListingStatus.Pending ? (
              <div className="absolute -bottom-0.5 -right-0.5 h-3 w-3 rounded-full bg-background-secondary lg:-bottom-1 lg:-right-1 lg:h-4 lg:w-4 xl:bg-background-primary">
                <Spinner size={12} />
              </div>
            ) : (
              <Button
                size="icon"
                shape="circle"
                disabled={isLoading}
                className="absolute -bottom-2 -right-2 h-6 w-6 items-center justify-center border-none bg-background-secondary disabled:opacity-100 lg:-bottom-3 lg:-right-3 lg:h-7 lg:w-7 xl:bg-background-primary"
              >
                {isLoading ? (
                  <Spinner size={12} />
                ) : userIsSeller ? (
                  <span className="material-symbols-rounded text-xs text-text-primary">
                    person
                  </span>
                ) : (
                  <span className="material-symbols-rounded text-xs text-text-primary">
                    shopping_cart
                  </span>
                )}
              </Button>
            )}
          </div>

          <div
            className="flex flex-col -space-y-1 lg:space-y-1"
            onClick={onClick}
          >
            <span className="text-sm font-medium uppercase text-text-primary lg:text-md">
              {tick}
            </span>
            <span className="text-2xs text-text-tertiary lg:text-xs">
              {inscriptionNumber}
            </span>
          </div>
        </motion.div>
      );
    },
  },
  {
    accessorKey: "amount",
    header: ({ column }) => {
      return (
        <HeaderCell
          title="Amount"
          aligned="right"
          column={column}
          withSorting
          className="w-24"
        />
      );
    },
    cell: ({ row }) => {
      const { amount, isSelected } = row.original;
      const onClick = () => handleSelectRow(row, !isSelected);
      const formattedAmount = formatNumber({
        value: amount,
        type: NumberFormatType.Large_Number,
      });
      return (
        <NumberCell
          value={formattedAmount}
          onClick={onClick}
          className="w-24"
        />
      );
    },
  },
  {
    accessorKey: "unitPrice",
    header: ({ column }) => {
      return (
        <HeaderCell
          title="Unit Price"
          aligned="right"
          column={column}
          withSorting
          className="w-24"
        />
      );
    },
    cell: ({ row }) => {
      const { unitPrice, isSelected, currency, currentDogePrice } =
        row.original;
      const onClick = () => handleSelectRow(row, !isSelected);

      const { value: unitPriceForCurrency } = getFormattedValue(
        unitPrice,
        currentDogePrice,
        currency,
        true,
      );
      return (
        <NumberCell
          value={unitPriceForCurrency}
          onClick={onClick}
          className="w-24"
        />
      );
    },
  },
  {
    accessorKey: "totalPrice",
    header: ({ column }) => {
      return (
        <HeaderCell
          title="Total Price"
          aligned="right"
          column={column}
          withSorting
          className="w-24"
        />
      );
    },
    cell: ({ row }) => {
      const { totalPrice, isSelected, currency, currentDogePrice } =
        row.original;
      const onClick = () => handleSelectRow(row, !isSelected);

      const { value: totalPriceForCurrency } = getFormattedValue(
        totalPrice,
        currentDogePrice,
        currency,
        true,
      );
      return (
        <NumberCell
          value={totalPriceForCurrency}
          onClick={onClick}
          className="w-24"
        />
      );
    },
  },
  {
    accessorKey: "floorDifferencePercentage",
    header: ({ column }) => {
      return (
        <HeaderCell
          title="Floor Diff."
          aligned="right"
          column={column}
          withSorting
          className="w-24"
        />
      );
    },
    cell: ({ row }) => {
      const { floorDifferencePercentage, isSelected } = row.original;
      const onClick = () => handleSelectRow(row, !isSelected);
      return (
        <ChangeCell
          change={floorDifferencePercentage}
          type={ChangeCellType.FloorDifference}
          onClick={onClick}
          className="w-24"
        />
      );
    },
  },
  {
    accessorKey: "createdAt",
    header: ({ column }) => {
      return (
        <HeaderCell
          title="Listed"
          aligned="right"
          column={column}
          withSorting
          className="w-24"
        />
      );
    },
    cell: ({ row }) => {
      const { createdAt, isSelected } = row.original;
      const onClick = () => handleSelectRow(row, !isSelected);

      return (
        <TextCell
          value={getTimeDifference(createdAt)}
          onClick={onClick}
          className="w-24"
        />
      );
    },
  },
  {
    accessorKey: "address",
    header: () => {
      return <HeaderCell title="Seller" aligned="left" className="w-24" />;
    },
    cell: ({ row }) => {
      const { address, isSelected } = row.original;
      const onClick = () => handleSelectRow(row, !isSelected);
      return (
        <TextCell
          value={shortenAddress(address)}
          onClick={onClick}
          className="w-24 text-left"
        />
      );
    },
  },
  {
    accessorKey: "actions",
    header: () => <HeaderCell title="" aligned="right" className="mr-4 w-16" />,
    cell: ({ row }) => {
      const { onBuy, isLoading, status, tick, userIsSeller } = row.original;
      return (
        <div className="mr-4 flex w-16 flex-row justify-end">
          {userIsSeller ? (
            <Link to={`/account/drc20/${tick}`}>
              <Button
                size="small"
                variant="inverse"
                shape="circle"
                className="h-7 w-14 bg-text-highlight text-text-primary"
              >
                View
              </Button>
            </Link>
          ) : (
            <Button
              size="small"
              variant="inverse"
              shape="circle"
              className="h-7 w-14"
              onClick={onBuy}
              disabled={isLoading || status === ListingStatus.Pending}
            >
              {isLoading ? <Spinner size={16} /> : "Buy"}
            </Button>
          )}
        </div>
      );
    },
  },
];
