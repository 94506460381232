export const SearchCloseButton: React.FC<{
  onClick: () => void;
  isVisible: boolean;
}> = ({ onClick, isVisible }) => {
  return isVisible ? (
    <button
      className="flex h-6 w-6 items-center justify-center rounded-full"
      onClick={onClick}
    >
      <span className="material-symbols-rounded text-md text-text-tertiary">
        close
      </span>
    </button>
  ) : (
    <div className="w-6" />
  );
};
