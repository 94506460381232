import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { Button, Input, useToast } from "@/components";
import { useWalletManagement } from "@/contextHooks";
import { handleError } from "@/utility";
import { useCopyToClipboard } from "@uidotdev/usehooks";

import { Sheet, SheetProps } from "../base";
import { ModalSecret } from "../modalSecret";

interface ModalAccountEditProps extends SheetProps {
  walletAddress?: string;
}

export const ModalAccountEdit: React.FC<ModalAccountEditProps> = ({
  isVisible = false,
  walletAddress,
  onClose,
}) => {
  const accountNameInputRef = useRef<HTMLInputElement>(null);
  const { toast } = useToast();
  const [, copy] = useCopyToClipboard();

  const { wallet, changeAccountName, toggleAccountVisibility } =
    useWalletManagement() || {};

  /**
   * Local State
   */

  // State for showing the Modal
  const [modalSecretVisible, setModalSecretVisible] = useState(false);

  // State for managing the account name and its editable status
  const [accountName, setAccountName] = useState("");
  const [accountNameDisabled, setAccountNameDisabled] = useState(true);

  // Memoized value to find the specific account by wallet address
  const account = useMemo(() => {
    return (
      wallet?.walletData?.accounts?.find(
        (acc) => acc.address === walletAddress,
      ) || null
    );
  }, [wallet, walletAddress]);

  // Effect to synchronize the account name from the current account state
  useEffect(() => {
    // Update account name from the account details, defaulting to an empty string if not available
    setAccountName(account?.name || "");
  }, [account]);

  /**
   * Functionality
   */

  // useCallback to handle the logic to change the account name based on user inputs
  const handleChangeAccountName = useCallback(async () => {
    try {
      // Check if walletAddress or accountName is missing, and if so, abort the function
      if (!walletAddress || !accountName) return;

      // Optionally call the changeAccountName function if it exists, with current wallet address and account name
      await changeAccountName?.(walletAddress, accountName);

      setAccountNameDisabled(true); // Disable the account name input after changing the name

      // Display a success message using a toast notification
      toast({
        title: "Success",
        description: "Account name changed",
      });
    } catch (e: Error | unknown) {
      const message = handleError(e);
      if (accountNameInputRef.current) {
        accountNameInputRef.current.focus();
      }

      // On error, display an error message using a toast notification
      toast({
        title: "Error",
        description: message ?? "Failed to change account name",
        variant: "error",
      });
    }
  }, [changeAccountName, walletAddress, accountName, toast]);

  // useCallback to handle enabling/disabling the account name input and managing focus
  const handleEditAccountName = useCallback(() => {
    // Check if the account name input is currently disabled
    if (accountNameDisabled) {
      // Enable the input
      setAccountNameDisabled(false);

      // Focus the input field immediately after enabling it
      if (accountNameInputRef.current) {
        accountNameInputRef.current.focus();
      }
    } else {
      handleChangeAccountName();
    }
  }, [accountNameDisabled, handleChangeAccountName, accountNameInputRef]);

  // useCallback to update the accountName state based on the value from an input field
  const handleSetNewAccountName = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      // Directly set the accountName state to the current value of the input field
      setAccountName(event.target.value);
    },
    [], // Empty dependency array means this callback never needs to be recreated
  );

  // useCallback to handle 'Enter' key press in account name input field
  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      // Listen for the 'Enter' key
      if (event.key === "Enter") {
        event.preventDefault(); // Prevent the default action of the Enter key
        handleChangeAccountName(); // Trigger account name change when Enter is pressed
      }
    },
    [handleChangeAccountName], // Depend on the handleChangeAccountName for re-creation
  );

  const handleShowPrivateKey = useCallback(() => {
    setModalSecretVisible((prev) => !prev);
  }, []);

  // Removes an account from visibility
  const handleRemoveAccount = useCallback(() => {
    // Do nothing if walletAddress is not defined
    if (!walletAddress) return;
    // Call the function to make the account invisible
    toggleAccountVisibility?.(walletAddress, true);
    // Close any open modal or component using this callback
    onClose?.();
  }, [toggleAccountVisibility, walletAddress, onClose]);

  // Copies the current wallet address to the clipboard
  const handleCopyWalletAddress = useCallback(() => {
    // Use a copy utility function to copy the wallet address, falling back to an empty string if undefined
    copy(walletAddress || "");
    // Display a toast notification confirming the address was copied
    toast({
      title: "Copied",
      description: "Wallet address copied to clipboard",
    });
  }, [walletAddress, toast, copy]);

  // Handles the modal close action
  const handleClose = useCallback(() => {
    onClose?.();
  }, [onClose]);

  return (
    <>
      <Sheet
        title={account?.name || "Account"}
        isVisible={isVisible}
        onClose={handleClose}
        classNameContent="aspect-3/4 flex flex-col flex-1 max-h-[800px] sm:h-[90vh] sm:aspect-none"
      >
        <div className="flex flex-1 flex-col px-4 pb-4">
          <div className="flex flex-1 flex-col space-y-4">
            <div className="flex flex-col space-y-2">
              <span className="ml-1 text-sm text-text-tertiary">
                Account Name
              </span>
              <div className="flex flex-1 flex-row items-center space-x-1">
                <Input
                  ref={accountNameInputRef}
                  disabled={accountNameDisabled}
                  onKeyDown={handleKeyDown}
                  onChange={handleSetNewAccountName}
                  placeholder={account?.name || "Account Name"}
                  className="h-12 border-0.5 border-border-tertiary bg-background-secondary disabled:border-transparent disabled:bg-background-secondary disabled:text-text-primary disabled:opacity-100"
                  value={accountName}
                />
                <div>
                  <Button
                    variant="ghost"
                    size="icon"
                    shape="circle"
                    onClick={handleEditAccountName}
                  >
                    <span className="material-symbols-rounded text-lg">
                      {accountNameDisabled ? "edit" : "save"}
                    </span>
                  </Button>
                </div>
              </div>
            </div>
            <div className="flex flex-col space-y-2">
              <span className="ml-1 text-sm text-text-tertiary">
                Wallet Address
              </span>
              <div className="flex flex-1 flex-row items-center space-x-1">
                <Input
                  disabled
                  value={walletAddress}
                  placeholder="Wallet Address"
                  className="h-12 border-0.5 border-border-tertiary bg-background-secondary disabled:border-transparent disabled:bg-background-secondary disabled:text-text-primary disabled:opacity-100"
                />
                <Button
                  variant="ghost"
                  size="icon"
                  shape="circle"
                  onClick={handleCopyWalletAddress}
                >
                  <span className="material-symbols-rounded text-lg">
                    content_copy
                  </span>
                </Button>
              </div>
            </div>
            <div className="flex flex-col space-y-2">
              <span className="ml-1 text-sm text-text-tertiary">
                Private Key
              </span>

              <div className="flex flex-col space-y-1">
                <div
                  className="flex flex-1 cursor-pointer flex-row items-center space-x-1"
                  onClick={handleShowPrivateKey}
                >
                  <Input
                    disabled
                    placeholder="••••••••••"
                    type="password"
                    className="h-12 border-0.5 border-border-tertiary bg-background-secondary disabled:border-transparent disabled:bg-background-secondary disabled:text-text-primary disabled:opacity-100"
                  />
                  <Button variant="ghost" size="icon" shape="circle" asChild>
                    <span className="material-symbols-rounded text-lg">
                      lock
                    </span>
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <Button
            variant="destructive"
            size="large"
            shape="default"
            onClick={handleRemoveAccount}
          >
            Remove Account
          </Button>
        </div>
      </Sheet>
      <ModalSecret
        variant="privateKey"
        walletAddress={walletAddress}
        isVisible={modalSecretVisible}
        onClose={handleShowPrivateKey}
      />
    </>
  );
};
