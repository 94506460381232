import { useContext, useEffect, useRef } from "react";

import { UIContext } from "@/context";

export const useAppHeaderHeight = () => {
  const headerRef = useRef<HTMLDivElement>(null);
  const { setAppHeaderHeight, appHeaderHeight } = useContext(UIContext);

  useEffect(() => {
    const updateHeaderHeight = () => {
      if (headerRef.current) {
        setAppHeaderHeight(headerRef.current.offsetHeight);
      }
    };

    // Initial update
    updateHeaderHeight();

    // Add resize event listener
    window.addEventListener("resize", updateHeaderHeight);

    // Cleanup
    return () => {
      window.removeEventListener("resize", updateHeaderHeight);
    };
  }, [setAppHeaderHeight]);

  return { headerRef, appHeaderHeight };
};
