import { DunesSearchSuggestion } from "@/types";

import { ImageWithFallback } from "@/components";

interface DuneSuggestionProps extends DunesSearchSuggestion {
  onSelect: () => void;
}

export const SuggestionDune: React.FC<DuneSuggestionProps> = ({
  tick,
  image,
  volume,
  floor,
  onSelect,
}) => {
  return (
    <div
      className="flex cursor-pointer flex-row items-center space-x-3 rounded-lg bg-background-secondary p-3 text-sm hover:bg-background-tertiary"
      onClick={onSelect}
    >
      <ImageWithFallback image={image} className="h-9 w-9" />

      <div className="flex flex-1 flex-col space-y-0">
        <span className="truncate font-bold uppercase text-text-primary">
          {tick}
        </span>
        <div className="flex flex-row space-x-2 text-xs text-text-tertiary">
          <span>{`Volume: ${volume}`}</span>
          <span>•</span>
          <span>
            {"Floor: "}
            <span dangerouslySetInnerHTML={{ __html: floor }} />
          </span>
        </div>
      </div>

      <span className="material-symbols-rounded w-4 text-xl text-text-tertiary">
        chevron_right
      </span>
    </div>
  );
};
