import { AnimatePresence } from "framer-motion";
import React, { useCallback, useState } from "react";

import { Button, CheckboxField, Input, useToast } from "@/components";
import { useLoginPrompt, useWalletManagement } from "@/contextHooks";
import { handleError, obfuscateString } from "@/utility";
import { useCopyToClipboard, useToggle } from "@uidotdev/usehooks";

import { AnimatedContent, Sheet, SheetProps } from "../base";

interface WarningItem {
  icon: string;
  text: string;
}

const WARNING_PHRASE: WarningItem[] = [
  {
    icon: "key",
    text: "Your secret recovery phrase is the only way to recover your wallet.",
  },
  {
    icon: "visibility_lock",
    text: "Do not let anyone see your secret recovery phrase.",
  },
  {
    icon: "block",
    text: "Never share your secret recovery phrase with anyone.",
  },
];

const WARNING_KEY: WarningItem[] = [
  {
    icon: "key",
    text: "Your private key is the only way to recover your wallet.",
  },
  {
    icon: "visibility_lock",
    text: "Do not let anyone see your private key.",
  },
  {
    icon: "block",
    text: "Never share your private key with anyone.",
  },
];

interface ModalSecretProps extends SheetProps {
  variant: "recoveryPhrase" | "privateKey";
  walletAddress?: string;
}

export const ModalSecret: React.FC<ModalSecretProps> = ({
  isVisible = false,
  walletAddress,
  variant,
  onClose,
}) => {
  const { toast } = useToast();
  const [, copy] = useCopyToClipboard();
  const { pw, showMnemonicPhrase, showAccountPrivateKey } =
    useWalletManagement() || {};
  const { openLoginPrompt } = useLoginPrompt();

  const [isObfuscated, setIsObfuscated] = useState(true);
  const [isSecretVisible, setIsSecretVisible] = useState(false);
  const [acknowledgeWarning, toggleAcknowledgeWarning] = useToggle(false);
  const [secret, setSecret] = useState<string | undefined>(undefined);

  const showSecret = useCallback(async () => {
    if (variant === "recoveryPhrase") {
      await openLoginPrompt(true);
      const mnemonic = await showMnemonicPhrase?.();
      setSecret(mnemonic);
    }

    if (variant === "privateKey") {
      try {
        if (!walletAddress) {
          throw new Error("Missing wallet address.");
        }

        await openLoginPrompt(true);

        const decryptedPrivKey = await showAccountPrivateKey?.(
          walletAddress,
          pw as string,
        );

        // Handle decryption failure
        if (!decryptedPrivKey) {
          throw new Error("Failed to decrypt private key");
        }

        setSecret(decryptedPrivKey);
      } catch (e: Error | unknown) {
        const message = handleError(e);
        toast({
          title: "Error",
          description: message,
        });
      }
    }

    setIsSecretVisible(true);
  }, [
    pw,
    openLoginPrompt,
    showMnemonicPhrase,
    toast,
    showAccountPrivateKey,
    walletAddress,
    variant,
  ]);

  // Obfuscate the recovery phrase
  const resetAndClose = useCallback(() => {
    setIsSecretVisible(false);
    toggleAcknowledgeWarning(false);
    setIsObfuscated(true);
    setSecret(undefined);
    onClose?.();
  }, [toggleAcknowledgeWarning, onClose]);

  // Copy the secret to the clipboard
  const handleCopySecret = useCallback(() => {
    copy(secret!);

    if (variant === "recoveryPhrase") {
      toast({
        title: "Recovery Phrase Copied",
        description: "Your recovery phrase has been copied to your clipboard.",
      });
    }

    if (variant === "privateKey") {
      toast({
        title: "Private Key Copied",
        description: "Your private key has been copied to your clipboard.",
      });
    }
  }, [copy, toast, secret, variant]);

  return (
    <Sheet
      isVisible={isVisible}
      onClose={resetAndClose}
      title={variant === "recoveryPhrase" ? "Recovery Phrase" : "Private Key"}
      classNameContent="aspect-3/4 flex flex-col max-h-[800px] sm:h-[90vh] sm:aspect-none"
    >
      <div className="relative flex flex-1 flex-col">
        <AnimatePresence>
          {!isSecretVisible && (
            <AnimatedContent id="recovery-phrase-warning">
              <div className="flex flex-1 flex-col items-center space-y-10">
                <span className="relative flex h-16 w-16">
                  <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-red-500 opacity-25" />
                  <span className="relative inline-flex h-full w-full items-center justify-center rounded-full bg-red-500">
                    <span className="material-symbols-rounded text-4xl text-text-primary">
                      exclamation
                    </span>
                  </span>
                </span>

                <div className="flex w-full flex-1 flex-col space-y-4">
                  {(variant === "recoveryPhrase"
                    ? WARNING_PHRASE
                    : WARNING_KEY
                  ).map((warning, index) => (
                    <div
                      key={index}
                      className="flex flex-row items-center space-x-4 text-sm text-white"
                    >
                      <div className="flex h-10 w-10 flex-col items-center justify-center rounded-full bg-red-500/20">
                        <span className="material-symbols-rounded text-2xl text-red-600">
                          {warning.icon}
                        </span>
                      </div>
                      <span className="flex flex-1">{warning.text}</span>
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex flex-col space-y-4">
                <CheckboxField
                  id="acknowledgeWarning"
                  text={
                    variant === "recoveryPhrase"
                      ? "I will not share my recovery phrase with anyone."
                      : "I will not share my private key with anyone."
                  }
                  value={acknowledgeWarning}
                  onToggleChange={toggleAcknowledgeWarning}
                />
                <Button
                  variant="default"
                  size="large"
                  onClick={showSecret}
                  disabled={!acknowledgeWarning || !pw}
                >
                  Continue
                </Button>
              </div>
            </AnimatedContent>
          )}

          {isSecretVisible && (
            <AnimatedContent id="recovery-phrase">
              <div className="flex flex-1 flex-col justify-center space-y-1 rounded-2xl bg-background-secondary p-4 text-text-primary">
                {variant === "recoveryPhrase" ? (
                  <div className="grid grid-cols-3 gap-1">
                    {secret?.split(" ").map((word, index) => (
                      <div
                        key={index}
                        className="flex flex-row space-x-2 rounded-lg border-0.5 border-border-secondary bg-background-primary px-4 py-2 text-xs text-white"
                      >
                        <span>{index + 1}.</span>
                        <span>
                          {isObfuscated ? obfuscateString(word) : word}
                        </span>
                      </div>
                    ))}
                  </div>
                ) : (
                  <Input
                    disabled
                    value={
                      isObfuscated ? obfuscateString(secret || "") : secret
                    }
                    className="h-12 border-0.5 border-border-tertiary bg-background-primary disabled:border-transparent disabled:bg-background-primary disabled:text-text-primary disabled:opacity-100"
                  />
                )}
                <div className="flex flex-row space-x-4 pt-4">
                  <Button
                    size="small"
                    variant="inverse"
                    onClick={handleCopySecret}
                    disabled={!secret}
                    className="w-full"
                  >
                    <span>Copy</span>
                  </Button>
                  <Button
                    size="small"
                    variant="inverse"
                    onClick={() => setIsObfuscated(!isObfuscated)}
                    disabled={!secret}
                    className="w-full"
                  >
                    <span>{isObfuscated ? "Show" : "Hide"}</span>
                  </Button>
                </div>
              </div>
              <div className="flex flex-col space-y-4 bg-background-primary pt-4">
                <Button variant="default" size="large" onClick={resetAndClose}>
                  Done
                </Button>
              </div>
            </AnimatedContent>
          )}
        </AnimatePresence>
      </div>
    </Sheet>
  );
};
