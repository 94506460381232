import { CollectionListingProps, TokenListingProps } from "@/types";
import { handleError } from "@/utility";
import { Row } from "@tanstack/react-table";

export const handleSelectRow = (
  row: Row<TokenListingProps> | Row<CollectionListingProps>,
  value: boolean | string,
) => {
  try {
    const { onAction } = row.original;
    row.toggleSelected(!!value);
    onAction();
  } catch (e: Error | unknown) {
    handleError(e);
  }
};
