export function getColorForFloorDifference(floorDifference: number): string {
  if (floorDifference < 0) {
    return "text-text-disabled";
  } else if (floorDifference === 0) {
    return "text-text-primary";
  } else if (floorDifference < 10) {
    return "text-green-500";
  } else if (floorDifference < 100) {
    return "text-primary-500";
  } else {
    return "text-red-500";
  }
}
