import { AnimatePresence } from "framer-motion";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import {
  AVERAGE_NETWORK_FEE_RATE,
  NetworkFeeType,
  ONE_DOGE_IN_SHIBES,
} from "@/constants";
import { buildSendDogeTxs } from "@/context/wallet/helpers/sendDoge.ts";
import { useTxWallet } from "@/contextHooks";
import { useNetworkFees } from "@/hooks";
import { shortenAddress } from "@/lib/address";
import {
  NumberFormatType,
  dogeToSatoshi,
  formatNumber,
  satoshisToDoge,
} from "@/lib/numbers";
import { Currency } from "@/types";
import {
  filterInvalidCharacters,
  handleError,
  isValidDogecoinAddress,
} from "@/utility";

import {
  ErrorView,
  ModalNavigation,
  Sheet,
  SheetProps,
  SuccessView,
} from "../base";
import { SendTransactionStep } from "./constants";
import {
  AmountView,
  ConfirmView,
  FeeView,
  NetworkFeeCardProps,
  RecipientView,
} from "./views";

/* TODO:
  - fix the issue that user can stay stuck in error state
  */

interface ModalSendDogeProps extends SheetProps {}

interface StepDetails {
  title: string;
  iconBack?: "arrow_back_ios" | "close";
}

const FLOW = [
  SendTransactionStep.RECIPIENT,
  SendTransactionStep.AMOUNT,
  SendTransactionStep.FEE,
  SendTransactionStep.CONFIRM,
];

export const ModalSendDoge: React.FC<ModalSendDogeProps> = ({
  onClose,
  ...props
}) => {
  const networkFees = useNetworkFees();
  const { sendDoge, txWallet, dogePrice, availableBalance } = useTxWallet();
  const [networkFeeRate, setNetworkFeeRate] = useState<number>(
    AVERAGE_NETWORK_FEE_RATE,
  );

  const [transactionStep, setTransactionStep] = useState<SendTransactionStep>(
    FLOW[0],
  );

  const flowIndex = useMemo(() => {
    return FLOW.indexOf(transactionStep);
  }, [transactionStep]);

  const handleNext = useCallback(async () => {
    if (flowIndex < FLOW.length - 1) {
      setTransactionStep(FLOW[flowIndex + 1]);
    }
  }, [flowIndex]);

  const handleBack = useCallback(() => {
    if (flowIndex > 0) {
      setTransactionStep(FLOW[flowIndex - 1]);
    } else {
      setRecipient("");
      setAmount("0");
      onClose?.();
    }
  }, [flowIndex, onClose]);

  const { title, iconBack = "arrow_back_ios" } = useMemo<StepDetails>(() => {
    switch (transactionStep) {
      case SendTransactionStep.RECIPIENT:
        return {
          title: "Recipient",
          iconBack: "close",
        };
      case SendTransactionStep.AMOUNT:
        return {
          title: "Amount",
        };
      case SendTransactionStep.FEE:
        return {
          title: "Fee",
        };
      case SendTransactionStep.CONFIRM:
        return {
          title: "Confirm",
        };
      default:
        return { title: "" };
    }
  }, [transactionStep]);

  const [error, setError] = useState({
    title: "Error",
    description: "An error occurred while processing your request.",
  });

  const handleSetError = useCallback(
    (title: string, description: string) => {
      setError({ title, description });
    },
    [setError],
  );

  // Step 1: Recipient

  const [recipient, setRecipient] = useState<string>("");

  const recipientError = useMemo(() => {
    // Return undefined immediately if recipient is an empty string
    if (recipient === "") {
      return undefined;
    }
    // Check if the recipient's address is valid, return undefined if valid, otherwise return error message
    return isValidDogecoinAddress(recipient) ? undefined : "Invalid address";
  }, [recipient]);

  const handleRecipientChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setRecipient(filterInvalidCharacters(e.target.value));
    },
    [setRecipient],
  );

  const handlePasteAddress = useCallback(() => {
    navigator.clipboard.readText().then((text) => {
      setRecipient(filterInvalidCharacters(text));
    });
  }, [setRecipient]);

  const handleClearAddress = useCallback(() => {
    setRecipient("");
  }, []);

  // Step 2: Amount

  const [amount, setAmount] = useState<string>("");
  const [currency, setCurrency] = useState<Currency>(Currency.DOGE);
  const [amountError, setAmountError] = useState<string | undefined>();

  const [networkFeeItems, setNetworkFeeItems] = useState<NetworkFeeCardProps[]>(
    [],
  );

  const amountDetail = useMemo(() => {
    // Check if the amount is empty and return a formatted zero for the current currency
    if (amount === "") return `${currency}0`;

    // Parse the amount as a float
    const numericAmount = parseFloat(amount);
    let formattedAmount;

    if (currency === Currency.DOGE) {
      // If currency is Dogecoin, convert the amount from Dogecoin to USD
      formattedAmount = formatNumber({
        value: numericAmount * dogePrice,
        type: NumberFormatType.Price,
      });
      return `${Currency.USD}${formattedAmount}`;
    } else {
      // Otherwise, convert the amount from USD to Dogecoin
      formattedAmount = formatNumber({
        value: numericAmount / dogePrice,
        type: NumberFormatType.Price,
      });
      return `${Currency.DOGE}${formattedAmount}`;
    }
  }, [currency, amount, dogePrice]);

  const recipientShort = useMemo(() => {
    const shortenedAddress = shortenAddress(recipient);
    if (!shortenedAddress) return;
    return shortenedAddress;
  }, [recipient]);

  const handleAmountChange = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = e.target.value;
      const cleanInput = inputValue.replace(/[^0-9.]/g, "");

      // Validate against non-numeric characters but allow empty string and dots for decimal input
      const validInput =
        cleanInput === "" || /^0$|^[1-9]\d*(\.\d*)?$|^0\.\d*$/.test(cleanInput);

      if (validInput) {
        setAmount(cleanInput);
      } else if (!inputValue) {
        setAmount("0"); // Reset to '0' if the input is cleared
      }
    },
    [],
  );

  const handleSwitchCurrency = useCallback(() => {
    setCurrency((prev) =>
      prev === Currency.DOGE ? Currency.USD : Currency.DOGE,
    );
  }, []);

  const handleSetMaxAmount = useCallback(async () => {
    const maxSendableAmount = await sendDoge.calculateMaxSendableDoge({
      receiver: recipient,
      amount: Number(amount) * ONE_DOGE_IN_SHIBES,
      currency,
      feeRate: AVERAGE_NETWORK_FEE_RATE,
    });

    if (!maxSendableAmount) return;

    setAmount(maxSendableAmount);
  }, [amount, currency, recipient, sendDoge]);

  // Step 3: Fees

  const [networkFee, setNetworkFee] = useState<NetworkFeeType>(
    NetworkFeeType.Average,
  );

  const [txFeeInSats, setTxFeeInSats] = useState<number | undefined>();

  useEffect(() => {
    // @todo: Refactor this to use calculateNetworkFees from the wallet context
    const getNetworkFeesWithTotal = async () => {
      // loop through network fees and get the total fee for each option
      const promises = networkFees?.map(
        async (fee): Promise<NetworkFeeCardProps> => {
          let tx;
          try {
            tx = await buildSendDogeTxs({
              txWallet,
              receiverAddress: recipient,
              amt: dogeToSatoshi(amount),
              feePerVByte: fee.feeVb,
              estimateFeesOnly: true,
            });
          } catch (e: Error | unknown) {
            handleError(e);
          }

          return {
            label: fee.speed,
            detail: fee.duration,
            feeRatePerVb: fee.feeVb,
            isToggled: fee.value === networkFee,
            handleToggle: () => setNetworkFee(fee.value),
            totalFee: tx?.txFeeInSats ?? 0,
          };
        },
      );

      const updatedNetworkFeeItems = await Promise.all(
        promises as Promise<NetworkFeeCardProps>[],
      );
      setNetworkFeeItems(updatedNetworkFeeItems as NetworkFeeCardProps[]);
    };

    // Call fetchData when networkFees or networkFee change
    if (
      transactionStep === SendTransactionStep.FEE &&
      txWallet &&
      recipient &&
      amount
    ) {
      getNetworkFeesWithTotal().then();
    }
  }, [
    networkFees,
    networkFee,
    setNetworkFee,
    transactionStep,
    txWallet,
    recipient,
    amount,
  ]);

  const handleConfirmFee = useCallback(async () => {
    const feeItem = networkFeeItems?.find((feeItem) => feeItem.isToggled);

    const fee = feeItem?.feeRatePerVb ?? AVERAGE_NETWORK_FEE_RATE;
    setNetworkFeeRate(fee);
    setTxFeeInSats(feeItem?.totalFee);

    handleNext();
  }, [handleNext, networkFeeItems]);

  // Step 4: Confirm
  const [txHash, setTxHash] = useState<string | null | undefined>();

  const handleSend = useCallback(async () => {
    if (!recipient || !amount || !networkFeeRate) return;
    await sendDoge.execute({
      receiver: recipient,
      amount: dogeToSatoshi(amount),
      feeRate: networkFeeRate,
    });
  }, [sendDoge, recipient, amount, networkFeeRate]);

  useEffect(() => {
    if (sendDoge.isSuccess && sendDoge.txHash) {
      setTxHash(sendDoge.txHash);
      setTransactionStep(SendTransactionStep.SUCCESS);
    }
  }, [sendDoge.isSuccess, sendDoge.txHash]);

  useEffect(() => {
    if (sendDoge.isError) {
      setTransactionStep(SendTransactionStep.ERROR);
      handleSetError("Error", "An error occurred while sending.");
    }
  }, [handleSetError, sendDoge.isError]);

  const feeText = useMemo(() => {
    // Get the items which is toggled
    const selectedFee = networkFeeItems?.find((item) => item.isToggled);
    if (!selectedFee) return "N/A";

    const { label, detail } = selectedFee;
    const totalFee = txFeeInSats ?? 0;
    return `${label} (${detail}) - ${Currency.DOGE}${formatNumber({ value: satoshisToDoge(totalFee), type: NumberFormatType.Price })}`;
  }, [networkFeeItems, txFeeInSats]);

  // Step 5: Success
  const reset = useCallback(() => {
    setRecipient("");
    setAmount("0");
    setCurrency(Currency.DOGE);
    setTransactionStep(SendTransactionStep.RECIPIENT);
    setAmountError(undefined);
    setTxFeeInSats(undefined);
  }, []);

  const handleClose = useCallback(() => {
    reset();
    onClose?.();
  }, [onClose, reset]);

  const handleOpenTransaction = useCallback(() => {
    window.open(`https://dogechain.info/tx/${txHash}`, "_blank");
  }, [txHash]);

  useEffect(
    () => () => {
      reset();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <Sheet
      withHeader={false}
      classNameContent="flex flex-col aspect-square text-text-primary sm:aspect-auto sm:h-[80vh] max-h-[640px]"
      {...props}
    >
      <ModalNavigation
        title={title}
        subtitle={recipientShort}
        iconBack={iconBack}
        onBack={handleBack}
      />

      <AnimatePresence>
        <div className="relative flex flex-1 flex-col">
          {transactionStep === SendTransactionStep.RECIPIENT && (
            <RecipientView
              handleValueChange={handleRecipientChange}
              handleContinue={handleNext}
              handlePasteAddress={handlePasteAddress}
              handleClearAddress={handleClearAddress}
              value={recipient}
              errorMessage={recipientError}
            />
          )}

          {transactionStep === SendTransactionStep.AMOUNT && (
            <AmountView
              handleValueChange={handleAmountChange}
              handleSwitchCurrency={handleSwitchCurrency}
              handleSetMax={handleSetMaxAmount}
              handleContinue={handleNext}
              value={amount}
              currency={currency}
              detail={amountDetail}
              balance={availableBalance}
              hasBalance={parseFloat(availableBalance ?? "0") > 0}
              errorMessage={amountError}
            />
          )}

          {transactionStep === SendTransactionStep.FEE && (
            <FeeView
              items={networkFeeItems}
              handleContinue={handleConfirmFee}
            />
          )}

          {transactionStep === SendTransactionStep.CONFIRM && (
            <ConfirmView
              handleContinue={handleSend}
              isSendLoading={sendDoge.isLoading}
              recipient={recipient}
              amount={amount}
              currency={currency}
              fee={feeText}
            />
          )}

          {transactionStep === SendTransactionStep.SUCCESS && (
            <SuccessView
              handleContinue={handleClose}
              handleOpenTransactions={handleOpenTransaction}
            />
          )}

          {transactionStep === SendTransactionStep.ERROR && (
            <ErrorView
              handleContinue={handleClose}
              title={error.title}
              description={error.description}
            />
          )}
        </div>
      </AnimatePresence>
    </Sheet>
  );
};
