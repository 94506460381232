import { motion } from "framer-motion";
import React from "react";

import { Button, Spinner } from "@/components";

import { cardTransition, cardVariants } from "./motion";
import { Modal, ModalProps } from "./variantModal";

interface AlertActionProps {
  label: string;
  variant: "default" | "inverse" | "ghost" | "destructive";
  disabled?: boolean;
  loading?: boolean;
  onClick: () => void;
}

interface AlertProps extends ModalProps {
  title: string;
  message?: string;
  onClose: () => void;
  actionPrimary?: AlertActionProps;
  actionSecondary?: AlertActionProps;
  children?: React.ReactElement | React.ReactElement[];
}

export const Alert: React.FC<AlertProps> = ({
  onClose,
  title,
  message,
  actionPrimary,
  actionSecondary,
  children,
  ...props
}) => {
  return (
    <Modal
      opacity={50}
      backdropBlur={false}
      classNameContent="relative justify-center p-8"
      {...props}
    >
      <motion.div
        key="alert"
        initial="closed"
        animate="open"
        exit="closed"
        variants={cardVariants}
        transition={cardTransition}
        className="relative mx-auto flex max-w-2xl flex-col rounded-2xl bg-background-secondary p-4"
      >
        <div className="flex flex-1 flex-col space-y-2 text-center text-text-primary">
          <p className="text-lg font-bold">{title}</p>
          {message && <p className="text-xs">{message}</p>}
        </div>

        <div className="flex flex-col space-y-4 py-8">{children}</div>

        {(actionPrimary || actionSecondary) && (
          <div className="flex flex-row space-x-4">
            {actionSecondary && (
              <Button
                size="default"
                variant={actionSecondary.variant}
                onClick={actionSecondary.onClick}
                disabled={actionSecondary.disabled || actionSecondary.loading}
                className="w-full"
              >
                {actionSecondary.loading ? (
                  <Spinner size={20} />
                ) : (
                  actionSecondary.label
                )}
              </Button>
            )}

            {actionPrimary && (
              <Button
                size="default"
                variant={actionPrimary.variant}
                onClick={actionPrimary.onClick}
                disabled={actionPrimary.disabled || actionPrimary.loading}
                className="w-full"
              >
                {actionPrimary.loading ? (
                  <Spinner size={20} />
                ) : (
                  actionPrimary.label
                )}
              </Button>
            )}
          </div>
        )}

        <button
          onClick={onClose}
          className="absolute -right-1 -top-1 flex h-6 w-6 items-center justify-center rounded-full border-0.5 border-background-primary bg-background-secondary"
        >
          <span className="material-symbols-rounded text-md text-text-primary">
            close
          </span>
        </button>
      </motion.div>
    </Modal>
  );
};
