import { useCallback, useEffect, useRef, useState } from "react";

interface UseEndlessScrollResult {
  showButton: boolean;
  targetRowRef: React.MutableRefObject<HTMLTableRowElement | null>;
}

export const useEndlessScroll = (data: unknown): UseEndlessScrollResult => {
  const [showButton, setShowButton] = useState<boolean>(false);
  const observerRef = useRef<IntersectionObserver | null>(null);
  const targetRowRef = useRef<HTMLTableRowElement | null>(null);

  const observerCallback: IntersectionObserverCallback = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting || entry.boundingClientRect.top < 0) {
          setShowButton(true); // Show button when row 50 is visible or scrolled past
        } else {
          setShowButton(false); // Hide button if row 50 is not visible
        }
      });
    },
    [],
  );

  useEffect(() => {
    if (observerRef.current) {
      observerRef.current.disconnect(); // Disconnect previous observer
    }

    const observer = new IntersectionObserver(observerCallback, {
      root: null,
      rootMargin: "0px",
      threshold: 0.1, // Trigger callback when 10% of the target is visible
    });

    observerRef.current = observer;

    if (targetRowRef.current) {
      observer.observe(targetRowRef.current);
    }

    // Cleanup observer on unmount
    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };

    // The data dependency is intentional in order to properly to toggle the showButton state
  }, [data, observerCallback]);

  return {
    showButton,
    targetRowRef,
  };
};
