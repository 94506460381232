import { DuneListing, DunesUtxo } from "@/types";

const getFilteredDuneUtxos = (listings: DuneListing[] | undefined, utxos: DunesUtxo[]) => {
    const listedUtxos = listings?.map((listing) => ({
      hash: listing.txHash,
      vout: listing.txIndex
    }));
  
    return utxos.filter((utxo) => {
      return !listedUtxos?.some((listedUtxo) => 
        listedUtxo.hash === utxo.txid && Number(listedUtxo.vout) === utxo.vout
      );
    });
};

export { getFilteredDuneUtxos };
