import { useContext } from "react";
import { CollectiblePendingItemsContext } from "@/context";

export const useCollectiblePendingItems = () => {
  const context = useContext(CollectiblePendingItemsContext);
  if (!context) {
    throw new Error(
      "useCollectiblePendingItems must be used within a CollectiblePendingItemsProvider",
    );
  }
  return context;
};
