import {
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import {
  DUNES_LISTINGS_PAGINATION_LIMIT,
  FEATURE_ACTIVATION_DUNES,
  ONE_DOGE_IN_SHIBES,
  PAGINATION_LIMIT,
} from "@/constants";
import { useCurrency, useDogePrice, useRefreshKey } from "@/contextHooks";
import { sdoggsApiV2 } from "@/lib/fetch";
import { NumberFormatType, formatNumber, formatPrice } from "@/lib/numbers";
import * as Sentry from "@sentry/react";
import {
  Currency,
  DunesSale,
  DunesToken,
  DunesData,
  Sorting,
  TimeFrames,
} from "@/types";
import { WatchlistItem } from "@/types/watchlist.ts";
import {
  calculateTokenValueInDoge,
  calculateTokenValueInUSD,
  ensureUniqueTableData,
  handleError,
  mapHoursToRange,
} from "@/utility";

import {
  DunesWatchlistContext,
  WatchlistProvider,
} from "./DunesWatchListContext.ts";
import { fetchDunesTokens, fetchMintableDunesTokens } from "./helpers/fetchDunesTokens.ts";

enum MoverKey {
  ChangePercent24h = "changePercent24h",
  ChangePercent7d = "changePercent7d",
  ChangePercent30d = "changePercent30d",
  ChangePercent = "changePercent",
}

type DunesDataWithMoverKey = DunesData & { moverKey: MoverKey };

type DunesSalesList = {
  activities: Array<DunesSale>;
  total: number;
};

type DunesList = {
  list: Array<DunesData>;
  total: number;
  DOGEprice: number;
};

export type TopBaseDunes = {
  title: string;
  value: string;
};

export type TopMover = TopBaseDunes & {
  id: string;
  percentage: string;
  percentageTimeframe: string;
  priceInUSD: string;
  priceInDOGE: string;
};

export type TopSales = TopBaseDunes & {
  id: string;
  sales: string;
  timeframe?: string;
  priceInUSD: string;
  priceInDOGE: string;
};

export type RecentSales = TopBaseDunes & {
  id: string;
  sales: string;
  timeframe?: string;
  priceInUSD: string;
  priceInDOGE: string;
  createdAt: string;
  inscriptionId: string;
};

/*
mintableTableOffset,
    isLoadingMintableTableData,
    isLoadingMoreMintableTableData,
*/
interface DunesTokenListContextType {
  // table data
  isLoadingTableData: boolean;
  isLoadingMintableTableData: boolean;
  isLoadingMoreTableData: boolean;
  isLoadingMoreMintableTableData: boolean;
  hasMoreTableData: boolean;
  hasMoreMintableTableData: boolean;
  tableData: DunesToken[];
  mintableTableData: DunesToken[];
  tableOffset: number;
  mintableTableOffset: number;
  debouncedSetTableOffset: (offset: number) => void;
  debouncedSetMintableTableOffset: (offset: number) => void;
  loadTableData: (params?: {
    history: TimeFrames;
    sortParam: Sorting;
    currency: Currency;
    limit: number;
    mintable?: boolean;
  }) => void;

  // top movers and recent sales
  isMoversLoading: boolean;
  topMovers: TopMover[];
  topSales: TopSales[];
  recentSales: RecentSales[];
}

type DunesTokenListProviderProps = {
  children: ReactNode;
};

const DunesTokenListContext = createContext<DunesTokenListContextType>({
  // table data
  isLoadingTableData: false,
  isLoadingMintableTableData: false,
  isLoadingMoreTableData: false,
  isLoadingMoreMintableTableData: false,
  hasMoreTableData: true,
  hasMoreMintableTableData: true,
  tableData: [],
  mintableTableData: [],
  tableOffset: 0,
  mintableTableOffset: 0,
  debouncedSetTableOffset: () => {},
  debouncedSetMintableTableOffset: () => {},
  loadTableData: () => {},
  // loadMintableTableData: () => {},

  // top movers and recent sales
  isMoversLoading: false,
  topMovers: [],
  topSales: [],
  recentSales: [],
});

const processTrendingList = <T extends keyof DunesData>(
  list: DunesData[],
  sortParam: T,
  dogePrice: number,
  currency: Currency,
) => {
  // Ensure the sortParam is a key of DunesData that maps to a number
  if (typeof list[0][sortParam] !== "number") {
    throw new Error(
      `${String(sortParam)} must be a key of DunesData with a numeric value`,
    );
  }

  const sortedList = list.sort(
    (a, b) => ((b[sortParam] as number) || 0) - ((a[sortParam] as number) || 0),
  );

  return sortedList.map((item) => {
    const { tick: id, floorPrice, sales7d, sales30d, sales24h } = item;

    const formattedId = id.toUpperCase();
    const priceInUSD = calculateTokenValueInUSD(1, floorPrice || 0, dogePrice);
    const priceInDOGE = calculateTokenValueInDoge(1, floorPrice || 0);

    const sales24hFormatted = formatNumber({
      value: sales24h,
      type: NumberFormatType.Large_Number,
    });

    const sales7dFormatted = formatNumber({
      value: sales7d,
      type: NumberFormatType.Large_Number,
    });

    const sales30dFormatted = formatNumber({
      value: sales30d,
      type: NumberFormatType.Large_Number,
    });

    const salesFormatted =
      sales24h > 0
        ? sales24hFormatted
        : sales7d > 0
          ? sales7dFormatted
          : sales30dFormatted;
    const sales = sales24h > 0 ? sales24h : sales7d > 0 ? sales7d : sales30d;
    const timeframe = sales24h > 0 ? "24h" : sales7d > 0 ? "7D" : "30D";

    return {
      sales: `${salesFormatted} SALE${sales === 1 ? "" : "S"}`,
      numSales: sales,
      id: formattedId,
      title: formattedId,
      timeframe,
      value:
        currency === Currency.DOGE
          ? `${Currency.DOGE}${priceInDOGE}`
          : `${Currency.USD}${priceInUSD}`,
      priceInUSD: `${Currency.USD}${priceInUSD}`,
      priceInDOGE: `${Currency.DOGE}${priceInDOGE}`,
    };
  });
};

const processMoversList = (
  list: DunesDataWithMoverKey[],
  dogePrice: number,
  currency: Currency,
) => {
  return list.map((item) => {
    const { tick: id, floorPrice, moverKey } = item;

    const formattedId = id.toUpperCase();
    const priceInUSD = calculateTokenValueInUSD(1, floorPrice || 0, dogePrice);
    const priceInDOGE = calculateTokenValueInDoge(1, floorPrice || 0);
    const changePercentFormatted = formatNumber({
      value: item[moverKey] || 0,
      type: NumberFormatType.Percentage,
    });
    const percentageTimeframe =
      moverKey === MoverKey.ChangePercent24h
        ? ""
        : moverKey === MoverKey.ChangePercent7d
          ? "7D"
          : moverKey === MoverKey.ChangePercent30d
            ? "30D"
            : "ALL";

    return {
      id: formattedId,
      title: formattedId,
      percentage: changePercentFormatted,
      percentageTimeframe,
      priceInUSD: `${Currency.USD}${priceInUSD}`,
      priceInDOGE: `${Currency.DOGE}${priceInDOGE}`,
      value:
        currency === Currency.DOGE
          ? `${Currency.DOGE}${priceInDOGE}`
          : `${Currency.USD}${priceInUSD}`,
    };
  });
};

const mapTokenToTopMover = (
  data: DunesToken,
  dogePrice: number,
  currency?: Currency,
): WatchlistItem => {
  const { id, change, price, sales, currentSupply, rank } = data;

  const formattedId = id.toUpperCase();
  // To format the price with small number is needed to calculate the mcaps correctly, as formatting
  // with price gives a string with html in it.
  const priceInUSD = parseFloat(
    calculateTokenValueInUSD(
      1,
      price,
      dogePrice,
      NumberFormatType.Small_Number,
    ),
  );
  const priceInUSDFormatted = formatPrice(priceInUSD);

  const priceInDOGE = parseFloat(
    calculateTokenValueInDoge(1, price, NumberFormatType.Small_Number),
  );
  const priceInDOGEFormatted = formatPrice(priceInDOGE);

  const percentage = formatNumber({
    value: change,
    type: NumberFormatType.Percentage,
  });

  const totalSales = formatNumber({
    value: sales,
    type: NumberFormatType.Large_Number,
  });

  const currentSupplyNumber =
    typeof currentSupply === "number"
      ? currentSupply
      : Number(currentSupply) / ONE_DOGE_IN_SHIBES;

  const mcapInUSD =
    currentSupplyNumber && currentSupplyNumber > 0
      ? `$${formatPrice(currentSupplyNumber * priceInUSD)}`
      : "";

  const mcapInDogeUnformatted =
    currentSupply && currentSupply > 0
      ? currentSupply * (price / ONE_DOGE_IN_SHIBES)
      : 0;

  return {
    mcapInUSD,
    mcapInDogeUnformatted,
    rank,
    sales: totalSales,
    id: id,
    title: formattedId,
    percentage: percentage,
    priceInUSD: `${Currency.USD}${priceInUSDFormatted}`,
    priceInDOGE: `${Currency.DOGE}${priceInDOGEFormatted}`,
    value:
      currency === Currency.DOGE
        ? `${Currency.DOGE}${priceInDOGE}`
        : `${Currency.USD}${priceInUSD}`,
  };
};

const processSalesList = (
  list: DunesSale[],
  dogePrice: number,
  currency: Currency,
) => {
  // If the list is empty, return an empty array
  if (!list.length) return [];

  // Sort by the most recent sale via createdAt (string date)
  const sortedList = list.sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
  );

  return sortedList.map((item) => {
    const { tick: id, price, amount, createdAt, inscriptionId } = item;

    const formattedId = id.toUpperCase();
    const formattedValueInDOGE = calculateTokenValueInDoge(amount, price);
    const formattedValueInUSD = calculateTokenValueInUSD(
      amount,
      price,
      dogePrice,
    );

    const formattedAmount = formatNumber({
      value: amount,
      type:
        amount >= 1 ? NumberFormatType.Large_Number : NumberFormatType.Default,
    });

    return {
      createdAt,
      inscriptionId,
      id: formattedId,
      title: formattedId,
      sales: `Amount: ${formattedAmount}`,
      timeframe: `${mapHoursToRange(createdAt)}`,
      value:
        currency === Currency.DOGE
          ? `${Currency.DOGE}${formattedValueInDOGE}`
          : `${Currency.USD}${formattedValueInUSD}`,
      priceInUSD: `${Currency.USD}${formattedValueInUSD}`,
      priceInDOGE: `${Currency.DOGE}${formattedValueInDOGE}`,
    };
  });
};

const DunesTokenListProvider = ({ children }: DunesTokenListProviderProps) => {
  const { currency } = useCurrency();
  const { dogePrice } = useDogePrice();
  const hasMoversLoadedInitially = useRef(false);

  const [isLoadingTableData, setIsLoadingTableData] = useState<boolean>(false);
  const [isLoadingMintableTableData, setIsLoadingMintableTableData] = useState<boolean>(false);
  const [isLoadingMoreTableData, setIsLoadingMoreTableData] =
    useState<boolean>(false);
  const [isLoadingMoreMintableTableData, setIsLoadingMoreMintableTableData] =
    useState<boolean>(false);
  const [tableData, setTableData] = useState<DunesToken[]>([]);
  const [mintableTableData, setMintableTableData] = useState<DunesToken[]>([]);
  const [tableOffset, setTableOffset] = useState<number>(0);
  const [mintableTableOffset, setMintableTableOffset] = useState<number>(0);
  const [hasMoreTableData, setHasMoreTableData] = useState<boolean>(true);
  const [hasMoreMintableTableData, setHasMoreMintableTableData] = useState<boolean>(true);

  const [isMoversLoading, setIsMoversLoading] = useState<boolean>(false);
  // this is the state for the data we fetch, this data gets mapped/processed into topSales, topMovers, recentSales
  const [topMoversList, setTopMoversList] = useState<DunesDataWithMoverKey[]>(
    [],
  );
  const [recentSalesList, setRecentSalesList] = useState<DunesSale[]>([]);
  const [trendingList, setTrendingList] = useState<DunesData[]>([]);
  const [topSales, setTopSales] = useState<TopSales[]>([]); // The highest 7 day number of sales, in descending order
  const [topMovers, setTopMovers] = useState<TopMover[]>([]); // The highest gain from the past 24 hours, in descending order
  const [recentSales, setRecentSales] = useState<RecentSales[]>([]); // The most recent dunes sale on the platform, in chronological order (most recent on the far left).

  const { refreshKey } = useRefreshKey();

  const loadTableData = useCallback(
    async (params = {}) => {
      const { ...fetchParams } = {
        // default params
        history: TimeFrames["7d"],
        sortParam: Sorting.volume30d,
        currency: Currency.USD,
        limit: DUNES_LISTINGS_PAGINATION_LIMIT,
        mintable: false,
        // passed params
        ...params,
      };

      if (!hasMoreTableData || FEATURE_ACTIVATION_DUNES !== "true") {
        return;
      }

      try {
        setIsLoadingMoreTableData(true);
        const newData = await fetchDunesTokens({
          offset: tableOffset,
          ...fetchParams,
        });

        setHasMoreTableData(newData.length === DUNES_LISTINGS_PAGINATION_LIMIT);
        setTableData(ensureUniqueTableData(newData, "ticker"));
      } catch (e: Error | unknown) {
        handleError(e);
      } finally {
        setIsLoadingMoreTableData(false);
        setIsLoadingTableData(false);
      }
    },
    [hasMoreTableData, tableOffset],
  );

  const loadMintableTableData = useCallback(
    async (params = {}) => {
      const { ...fetchParams } = {
        // default params
        history: TimeFrames["7d"],
        sortParam: 'volume',
        currency: Currency.USD,
        limit: DUNES_LISTINGS_PAGINATION_LIMIT,
        mintable: true,
        // passed params
        ...params,
      };

      if (!hasMoreMintableTableData || FEATURE_ACTIVATION_DUNES !== "true") {
        return;
      }

      try {
        setIsLoadingMoreMintableTableData(true);
        const newData = await fetchMintableDunesTokens({
          offset: mintableTableOffset,
          ...fetchParams,
        });

        setHasMoreMintableTableData(newData.length === DUNES_LISTINGS_PAGINATION_LIMIT);
        setMintableTableData(ensureUniqueTableData(newData, "ticker"));
      } catch (e: Error | unknown) {
        handleError(e);
      } finally {
        setIsLoadingMoreMintableTableData(false);
        setIsLoadingMintableTableData(false);
      }
    },
    [hasMoreMintableTableData, mintableTableOffset],
  );

  // todo: move to a hook
  const fetchActivityData = useCallback(
    async (
      maxRetries = 3,
      cachebreaker: boolean = false,
      sortParam: Sorting,
      offset: number,
      limit: number,
    ) => {
      if (FEATURE_ACTIVATION_DUNES !== "true") {
        return [];
      }

      let attempt = 0;
      while (attempt < maxRetries) {
        try {
          const response = await sdoggsApiV2(cachebreaker).get<DunesList>(
            "/dunes/list/activity",
            {
              params: {
                offset,
                limit,
                sortOrder: "desc",
                sortParam,
              },
            },
          );

          if (response.data?.list && response.data?.list.length >= 1) {
            return response.data.list.map((dune) => {
              return {
                ...dune,
                holders:
                  typeof dune.holders === "number"
                    ? dune.holders
                    : (dune.holders as any).length,
              };
            });
          }

          const responseFallback = await sdoggsApiV2(
            cachebreaker,
          ).get<DunesList>("/dunes/list", {
            params: {
              offset,
              limit,
              sortOrder: "desc",
              sortParam: "createdAt",
            },
          });

          if (
            responseFallback.data?.list &&
            responseFallback.data?.list.length > 0
          ) {
            const enhancedFallbackResponse = responseFallback.data?.list.map(
              (dune) => ({
                ...dune,
                holders:
                  typeof dune.holders === "number"
                    ? dune.holders
                    : (dune.holders as any).length,
                sales7d: 0,
                changePercent24h: 0,
                changePercent7d: 0,
                changePercent30d: 0,
              }),
            );

            return [
              ...(response.data?.list || []),
              ...enhancedFallbackResponse,
            ];
          }

          return [];
        } catch (e) {
          attempt++;
          if (attempt >= maxRetries) {
            Sentry.captureException(e);
            return [];
          }
        }
      }
      return [];
    },
    [],
  );

  // todo: once https://app.clickup.com/t/86b1de22h is implemented, this whole function can be replaced by one api call
  const getTopChangePercentItems = useCallback(
    async (onlyPositive?: boolean) => {
      // we want to display 20 items
      const targetAmount = 20;
      const limit = 25; // this is a good number to fetch at once
      let offset = 0;
      const seenTicks = new Set<string>();
      const top24h: DunesDataWithMoverKey[] = [];
      const top7d: DunesDataWithMoverKey[] = [];
      const top30d: DunesDataWithMoverKey[] = [];
      const topOverall: DunesDataWithMoverKey[] = [];

      while (
        top24h.length + top7d.length + top30d.length + topOverall.length <
        targetAmount
      ) {
        if (offset == limit * 3) {
          break;
        }

        const data = await fetchActivityData(
          3,
          false,
          Sorting.top,
          offset,
          limit,
        );
        if (data.length === 0) {
          break; // Break if no data is fetched
        }

        for (const item of data) {
          if (!seenTicks.has(item.tick)) {
            const hasPositiveChange = (changePercent: number | undefined) =>
              changePercent !== undefined &&
              (onlyPositive ? changePercent > 0 : true);

            if (hasPositiveChange(item.changePercent24h)) {
              top24h.push({ ...item, moverKey: MoverKey.ChangePercent24h });
            } else if (hasPositiveChange(item.changePercent7d)) {
              top7d.push({ ...item, moverKey: MoverKey.ChangePercent7d });
            } else if (hasPositiveChange(item.changePercent30d)) {
              top30d.push({ ...item, moverKey: MoverKey.ChangePercent30d });
            } else if (hasPositiveChange(item.changePercent)) {
              topOverall.push({ ...item, moverKey: MoverKey.ChangePercent });
            }
            seenTicks.add(item.tick);
          }
        }

        if (
          top24h.length + top7d.length + top30d.length + topOverall.length >=
          targetAmount
        ) {
          console.log("getTopChangePercentItems -> top24h", top24h.length);
          console.log("getTopChangePercentItems -> top7d", top7d.length);
          console.log("getTopChangePercentItems -> top30d", top30d.length);
          console.log(
            "getTopChangePercentItems -> topOverall",
            topOverall.length,
          );
          break;
        }

        if (data.length < limit) {
          break;
        }

        offset += limit;
      }

      // Sorting logic
      const sortedTop24h = [...top24h].sort(
        (a, b) => (b.changePercent24h || 0) - (a.changePercent24h || 0),
      );
      const sortedTop7d = [...top7d].sort(
        (a, b) => (b.changePercent7d || 0) - (a.changePercent7d || 0),
      );
      const sortedTop30d = [...top30d].sort(
        (a, b) => (b.changePercent30d || 0) - (a.changePercent30d || 0),
      );
      const sortedTopOverall = [...topOverall].sort(
        (a, b) => (b.changePercent || 0) - (a.changePercent || 0),
      );

      const combinedSorted = [
        ...sortedTop24h,
        ...sortedTop7d,
        ...sortedTop30d,
        ...sortedTopOverall,
      ];

      return [...combinedSorted].slice(0, limit);
    },
    [fetchActivityData],
  );

  // todo: move to a hook
  const fetchSalesData = useCallback(
    async (history: string, cachebreaker: boolean = false) => {
      if (FEATURE_ACTIVATION_DUNES !== "true") {
        return [];
      }

      try {
        const response = await sdoggsApiV2(cachebreaker).get<DunesSalesList>(
          "offer/dunes/activity",
          {
            params: {
              offset: 0,
              limit: PAGINATION_LIMIT,
              history,
              sortOrder: "desc",
              sortParam: "recent",
              action: "sale",
            },
          },
        );
        return response.data.activities || [];
      } catch (e) {
        Sentry.captureException(e);
        return [];
      }
    },
    [],
  );

  const fetchPerformersData = useCallback(
    async (cachebreaker: boolean = false) => {
      try {
        setIsMoversLoading(true);
        const [activitiesTop, activitiesSales7d, recentSales] =
          await Promise.all([
            getTopChangePercentItems(true),
            fetchActivityData(
              3,
              cachebreaker,
              Sorting.sales7d,
              0,
              PAGINATION_LIMIT,
            ),
            fetchSalesData("30d", false),
          ]);
        setTopMoversList(activitiesTop);
        // filter to only show items with at least one sale in the last 7 days or 24 hours
        // https://github.com/the-doge-labs/marketplace-pwa/issues/376
        setTrendingList(
          activitiesSales7d.filter(
            (item) => Number(item.sales7d) > 0 || Number(item.sales24h) > 0,
          ),
        );
        setRecentSalesList(recentSales);
      } catch (e: Error | unknown) {
        handleError(e);
      } finally {
        setIsMoversLoading(false);
      }
    },
    [fetchSalesData, fetchActivityData, getTopChangePercentItems],
  );

  // fetch data initially or whenever offset changes -> endless scroll
  useEffect(() => {
    const fetchData = async () => {
      await loadTableData({ cachebreaker: false });
      await loadMintableTableData({ cachebreaker: false });
    };

    fetchData();
  }, [tableOffset, loadTableData, loadMintableTableData]);

  // fetch movers data initially -> should we refetch on an interval?
  useEffect(() => {
    const fetchData = async () => {
      if (!hasMoversLoadedInitially.current) {
        await fetchPerformersData(false);
        hasMoversLoadedInitially.current = true;
      }
    };

    fetchData();
  }, [fetchPerformersData]);

  // remap movers data whenever the listActivities, listRecentSales, dogePrice or currency change
  useEffect(() => {
    if (topMoversList.length > 0) {
      const mappedTwentyFourHours = processMoversList(
        topMoversList,
        dogePrice,
        currency,
      );
      setTopMovers(mappedTwentyFourHours);
    }

    if (trendingList.length > 0) {
      const mappedSevenDays = processTrendingList(
        trendingList,
        "sales7d",
        dogePrice,
        currency,
      );
      setTopSales(mappedSevenDays);
    }

    if (recentSalesList.length > 0) {
      const mappedRecentSales = processSalesList(
        recentSalesList,
        dogePrice,
        currency,
      );

      setRecentSales(mappedRecentSales);
    }
  }, [recentSalesList, topMoversList, trendingList, currency, dogePrice]);

  useEffect(() => {
    if (refreshKey > 0) {
      setTableData([]);
      setMintableTableData([]);
      setTableOffset(0);
      loadTableData({ offset: 0, cachebreaker: false });
      loadMintableTableData({ offset: 0, cachebreaker: false });
      // also loads sales and activity data
      fetchPerformersData(true);
    }
  }, [refreshKey]);

  return (
    <DunesTokenListContext.Provider
      value={{
        isLoadingTableData,
        isLoadingMintableTableData,
        isLoadingMoreTableData: !isLoadingTableData && isLoadingMoreTableData,
        isLoadingMoreMintableTableData: !isLoadingMintableTableData && isLoadingMoreMintableTableData,
        hasMoreTableData,
        hasMoreMintableTableData,
        tableData,
        mintableTableData,
        tableOffset,
        mintableTableOffset,
        debouncedSetTableOffset: setTableOffset,
        debouncedSetMintableTableOffset: setMintableTableOffset,
        loadTableData,
        // loadMintableTableData,
        isMoversLoading,
        topMovers,
        topSales,
        recentSales,
      }}
    >
      <WatchlistProvider
        watchlistName="watchlist-dunes"
        fetchTokens={fetchDunesTokens}
        mapTokenToItem={mapTokenToTopMover}
        watchlistKey="id"
        sortParam="change"
        Context={DunesWatchlistContext}
      >
        {children}
      </WatchlistProvider>
    </DunesTokenListContext.Provider>
  );
};

export { DunesTokenListProvider, DunesTokenListContext };
