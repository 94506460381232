import { calculateMarketCap } from "@/hooks";
import { NumberFormatType, formatNumber } from "@/lib/numbers";
import { CollectionData, Currency } from "@/types";
import { formatChangeValue, getFormattedValue } from "@/utility";

import { CollectionDetailProps } from "./types";

export const getFormattedCollectionDetails = (
  tokenData: CollectionData,
  dogePrice: number,
  currency: Currency,
): CollectionDetailProps[] => {
  const {
    floorPrice,
    supply,
    holders,
    change24h,
    change7d,
    change30d,
    volume7d,
    volume30d,
    totalVolume,
    sales7d,
    sales,
    minInscriptionNumber,
    maxInscriptionNumber,
  } = tokenData;

  const marketCap = calculateMarketCap(tokenData);

  const formattedChange24h = formatChangeValue(change24h ?? 0);
  const formattedChange7d = formatChangeValue(change7d ?? 0);
  const formattedChange30d = formatChangeValue(change30d ?? 0);

  return [
    {
      label: "Floor Price",
      value: getFormattedValue(floorPrice, dogePrice, currency, true).value,
    },
    {
      label: "Total Supply",
      value: formatNumber({
        value: supply,
        type: NumberFormatType.Large_Number,
      }),
    },
    {
      label: "Owners",
      value: formatNumber({
        value: holders,
        type: NumberFormatType.Large_Number,
      }),
    },
    {
      label: "24h",
      value: formattedChange24h.value,
      color: formattedChange24h.color,
    },
    {
      label: "7d",
      value: formattedChange7d.value,
      color: formattedChange7d.color,
    },
    {
      label: "30d",
      value: formattedChange30d.value,
      color: formattedChange30d.color,
    },
    {
      label: "Vol (7d)",
      value: getFormattedValue(volume7d ?? 0, dogePrice, currency, false).value,
    },
    {
      label: "Vol (30d)",
      value: getFormattedValue(volume30d ?? 0, dogePrice, currency, false)
        .value,
    },
    {
      label: "Vol (All)",
      value: getFormattedValue(totalVolume ?? 0, dogePrice, currency, false)
        .value,
    },
    {
      label: "Sales (7d)",
      value: formatNumber({
        value: sales7d ?? 0,
        type: NumberFormatType.Large_Number,
      }),
    },
    {
      label: "Sales (All)",
      value: formatNumber({
        value: sales,
        type: NumberFormatType.Large_Number,
      }),
    },
    {
      label: "Market Cap",
      value: getFormattedValue(marketCap, dogePrice, currency, false).value,
    },
    {
      label: "Range From",
      value: minInscriptionNumber,
    },
    {
      label: "Range To",
      value: maxInscriptionNumber,
    },
  ];
};
