import {
  isDesktop,
  isTablet,
  isMobile,
  isAndroid,
  isIOS,
  osName,
} from "react-device-detect";

export function useOperatingSystem() {
  return {
    isDesktop,
    isTablet,
    isMobile,
    isAndroid,
    isIOS,
    osName,
  };
}
