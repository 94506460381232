import { useLocalStorage } from "@/hooks";
import { ReactNode, createContext, useEffect, useState } from "react";
import { USER_SECURITY_PREFERENCE_KEY } from "@/constants.ts";

interface SecurityPreferenceContextType {
  securityPreference: "pin" | "password" | undefined;
  setSecurityPreference: (value: "pin" | "password" | undefined) => void;
}

type SecurityPreferenceProviderProps = {
  children: ReactNode;
};

export const SecurityPreferenceContext =
  createContext<SecurityPreferenceContextType>({
    securityPreference: undefined,
    setSecurityPreference: () => {},
  });

export const SecurityPreferenceProvider = ({
  children,
}: SecurityPreferenceProviderProps) => {
  const { value: localStorageValue, updateValue: setLocalStorageValue } =
    useLocalStorage<"pin" | "password" | undefined>(
      USER_SECURITY_PREFERENCE_KEY,
      undefined,
      false, // this needs to be set to the same value as the value for USER_WALLET_KEY and HAS_COMPLETED_WALLET_SETUP_KEY
    );

  const [securityPreference, setSecurityPreference] = useState<
    "pin" | "password" | undefined
  >(localStorageValue);

  useEffect(() => {
    if (!localStorageValue) return;
    setSecurityPreference(localStorageValue);
  }, [localStorageValue]);

  return (
    <SecurityPreferenceContext.Provider
      value={{
        securityPreference,
        setSecurityPreference: setLocalStorageValue,
      }}
    >
      {children}
    </SecurityPreferenceContext.Provider>
  );
};
