import { AnimatePresence, motion } from "framer-motion";
import React, { useContext } from "react";

import { UIContext } from "@/context";
import { useLock } from "@/hooks";
import { cn } from "@/lib/utils";

import { Header } from "./header";
import { backgroundTransition, backgroundVariants } from "./motion";

export interface ModalProps {
  isVisible: boolean;
  onClose?: () => void;
  onCancel?: () => void;
  title?: string;
  classNameContent?: string;
  classNameOverlay?: string;
  children?: React.ReactNode;
  withSafeArea?: boolean;
  withBottomNav?: boolean;
  opacity?: number;
  backdropBlur?: boolean;
}

export const Modal = ({
  isVisible,
  onClose,
  title = "",
  children,
  classNameContent,
  classNameOverlay,
  withSafeArea = true,
  withBottomNav = false,
  opacity = 80,
  backdropBlur = true,
}: ModalProps) => {
  useLock(isVisible);
  const { bottomNavHeight } = useContext(UIContext);

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          key="modal-background"
          initial="closed"
          animate="open"
          exit="closed"
          variants={backgroundVariants}
          transition={backgroundTransition}
          className={cn(
            "max-h-full, fixed inset-0 m-0 flex flex-col overflow-y-auto p-0",
            withSafeArea && "pb-safe-or-4 pt-safe-or-4",
            backdropBlur && "backdrop-blur-sm",
            `bg-black/${opacity}`,
            classNameOverlay,
          )}
          style={{
            marginBottom: withBottomNav ? bottomNavHeight : 0,
            zIndex: 102,
          }}
        >
          {title !== "" && <Header title={title} onClose={onClose} />}
          <div
            className={cn(
              "flex flex-1 flex-col overflow-y-auto pt-4",
              classNameContent,
            )}
          >
            {children}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
