import { AnimatePresence } from "framer-motion";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import {
  AVERAGE_NETWORK_FEE_RATE,
  HIGH_NETWORK_FEE_RATE,
  ONE_DOGE_IN_SHIBES,
} from "@/constants";
import { useCurrency, useDogePrice, useTxWallet } from "@/contextHooks";
import { formatNumber, NumberFormatType } from "@/lib/numbers";
import { Currency, DunesMintingProps } from "@/types";
import { CalculateMintFeesReturn, getFormattedValue } from "@/utility";

import {
  DEFAULT_ERROR,
  ErrorView,
  ModalNavigation,
  Sheet,
  SheetProps,
  SuccessView,
} from "../base";
import { MintTransactionStep } from "./constants";
import { MintView } from "./views";
import { FeeType } from "@/context/wallet/calculateNetworkFees";

interface ModalMintDuneProps extends SheetProps {
  duneMintOptions?: DunesMintingProps;
}

const processMintOptions = ({
  duneMintOptions,
  dogePrice,
  currency,
  txFeeInSats,
  serviceFeeInSats
}: {
  duneMintOptions?: DunesMintingProps;
  dogePrice: number;
  currency: Currency;
  txFeeInSats: number;
  serviceFeeInSats: number;
}) => {
  if (duneMintOptions === undefined) {
    return {
      pay: { value: "N/A", valueInUSD: "N/A" },
      receive: { value: "N/A", valueInUSD: "N/A" },
      tick: undefined,
    };
  }

  const totalValuePay = txFeeInSats + serviceFeeInSats;
  let totalValueReceive = 0;
  let tick = duneMintOptions.tick;

  // Aggregate total values from the offers
  const shibesPerTick = Math.pow(10, duneMintOptions.divisibility);
  for (const { amount, limit, tick: offerTick } of [duneMintOptions]) {
    totalValueReceive += amount * limit / shibesPerTick;
    tick = offerTick; // Assumes all offers have the same tick value
  }

  // Get the formatted total price in both DOGE and USD
  const { valueInDoge, valueInUSD } = getFormattedValue(
    totalValuePay,
    dogePrice,
    currency,
  );

  // Extract numeric values from formatted strings
  const totalPriceValueForCurrency =
    currency === Currency.DOGE
      ? parseFloat(valueInDoge.replace(/[^0-9,.]/g, "").replace(",", ""))
      : parseFloat(valueInUSD.replace(/[^0-9,.]/g, "").replace(",", ""));

  // Calculate and format the average unit price
  let formattedUnitPrice = "N/A";
  if (totalValueReceive > 0) {
    const averageUnitPrice = totalPriceValueForCurrency / totalValueReceive;
    if (!isNaN(averageUnitPrice) && isFinite(averageUnitPrice)) {
      formattedUnitPrice = formatNumber({
        value: averageUnitPrice,
        type: NumberFormatType.Price,
      });
    }
  }

  return {
    pay: {
      value: valueInDoge,
      valueInUSD,
    },
    receive: {
      value: `${formatNumber({ value: totalValueReceive, type: NumberFormatType.Large_Number })} ${duneMintOptions.symbol}`,
      valueInUSD: undefined,
      valueDetails: `at ${currency}${formattedUnitPrice}/${duneMintOptions.symbol}`,
    },
    tick,
  };
};

const calculateFees = ({
  duneMintOptions,
  txFeeInSats,
}: {
  duneMintOptions?: DunesMintingProps;
  txFeeInSats: number;
}): CalculateMintFeesReturn => {
  if (!duneMintOptions)
    return { network: "N/A", service: "N/A", total: "N/A" };

  const serviceFeeInDoge = duneMintOptions.amount * 1; // 1 DOGE service fee per mint
  const networkFeeInDoge = txFeeInSats / ONE_DOGE_IN_SHIBES;

  return {
    network: `${Currency.DOGE} ${formatNumber({ value: networkFeeInDoge, type: NumberFormatType.Price })}`,
    service: `${Currency.DOGE} ${formatNumber({ value: serviceFeeInDoge, type: NumberFormatType.Price })}`,
    total: `${Currency.DOGE} ${formatNumber({ value: serviceFeeInDoge + networkFeeInDoge, type: NumberFormatType.Price })}`,
  };
};

export const ModalMintDune: React.FC<ModalMintDuneProps> = ({
  onClose,
  duneMintOptions,
  isVisible,
  ...props
}) => {
  const { currency } = useCurrency();
  const { dogePrice } = useDogePrice();
  const { mintDune, calculateNetworkFees } = useTxWallet();

  // Local State
  const [localIsVisible, setLocalIsVisible] = useState<boolean>(isVisible);
  const [txFeeInSats, setTxFeeInSats] = useState(0);
  const [feePerVByte, setFeePerVByte] = useState(AVERAGE_NETWORK_FEE_RATE);
  const [error, setError] = useState(DEFAULT_ERROR);
  const [transactionStep, setTransactionStep] = useState(
    MintTransactionStep.MINT,
  );

  useEffect(() => {
    setLocalIsVisible(isVisible);
  }, [isVisible]);

  // Handle buy error
  const handleMintError = useCallback((title: string, description: string) => {
    setError({ title, description });
    setTransactionStep(MintTransactionStep.ERROR);
    setLocalIsVisible(true);
  }, []);

  // Build the transaction
  useEffect(() => {
    if (
      !isVisible ||
      !duneMintOptions ||
      calculateNetworkFees.isLoading ||
      calculateNetworkFees.isError ||
      calculateNetworkFees.isSuccess
    )
      return;

    calculateNetworkFees.execute(FeeType.MINTDUNE, {
      duneId: duneMintOptions.duneId,
      amt: duneMintOptions.amount,
      receiver: duneMintOptions.receiver,
      limit: duneMintOptions.limit,
      feePerVByte: feePerVByte,
      duneUtxos: [],
    });
  }, [duneMintOptions, feePerVByte]);

  // Close the modal
  const handleClose = useCallback(() => {
    if (mintDune.isLoading) return;

    // reset values
    setError(DEFAULT_ERROR);
    setTransactionStep(MintTransactionStep.MINT);
    mintDune.reset();

    setLocalIsVisible(false);
    onClose?.();
  }, [mintDune, onClose]);

  useEffect(() => {
    if (calculateNetworkFees.isError && calculateNetworkFees.error) {
      // @todo: this needs to go into a custom hook for error handling calculateNetworkFees

      //ensure the selected listings are updated after an error
      if (calculateNetworkFees.error instanceof Map) {
        console.log('Error', calculateNetworkFees.error);
      }

      handleMintError(
        "Error",
        calculateNetworkFees.error instanceof Map
          ? Array.from(calculateNetworkFees.error.entries())
              .map(([key, value]) => `${key}: ${value}`)
              .join(", ")
          : (calculateNetworkFees.error as string) ||
              "An error occurred while calculating your fees.",
      );
    }
  }, [
    calculateNetworkFees.error,
    calculateNetworkFees.isError,
    handleMintError,
  ]);

  useEffect(() => {
    if (calculateNetworkFees.isSuccess && calculateNetworkFees.totalFeeInSats) {
      setTxFeeInSats(calculateNetworkFees.totalFeeInSats);
    }
  }, [calculateNetworkFees.isSuccess, calculateNetworkFees.totalFeeInSats]);

  // Handle buy success
  const handleMintSuccess = useCallback(() => {
    setTransactionStep(MintTransactionStep.SUCCESS);
  }, []);

  // Handle buy transaction
  const handleMint = useCallback(async () => {
    if (duneMintOptions) {
      await mintDune.execute({
        ...duneMintOptions,
        feePerVByte,
        amt: duneMintOptions.amount,
      });
    }
  }, [duneMintOptions, feePerVByte, mintDune]);

  // On success, show the success view
  useEffect(() => {
    if (mintDune.isSuccess) {
      handleMintSuccess();
    }
  }, [handleMintSuccess, mintDune.isSuccess]);

  // On error, show the error view
  useEffect(() => {
    if (mintDune.isError && mintDune.error) {
      let message = "An error occurred while processing your request.";

      if (mintDune.error) {
        message = `${message}, ${mintDune.error as string}`;
      }

      handleMintError("Error", message);
    }
  }, [handleMintError, mintDune.isError, mintDune.error]);

  // Displayed fees
  const fees = useMemo(() => {
    return calculateFees({ duneMintOptions, txFeeInSats });
  }, [duneMintOptions, txFeeInSats]);

  // Displayed values
  const { pay, receive, tick } = useMemo(() => {
    const { pay, receive, tick } = processMintOptions({
      duneMintOptions,
      dogePrice,
      currency,
      txFeeInSats,
      serviceFeeInSats: duneMintOptions ? duneMintOptions.amount * ONE_DOGE_IN_SHIBES : 0
    });

    return {
      pay: { value: pay.value, valueInUSD: pay.valueInUSD },
      receive: {
        value: receive.value,
        valueInUSD: receive.valueInUSD,
        valueDetails: receive.valueDetails,
      },
      tick,
    };
  }, [dogePrice, currency, txFeeInSats, duneMintOptions]);

  return (
    <Sheet
      withHeader={false}
      isVisible={localIsVisible}
      classNameContent="flex flex-col text-text-primary h-full md:min-h-2/3 md:max-h-5/6 md:aspect-auto rounded-none pt-safe-offset-4 pb-safe-offset-4"
      onClose={handleClose}
      {...props}
    >
      <ModalNavigation
        title="Review Order"
        iconBack="close"
        onBack={handleClose}
      />
      <AnimatePresence>
        <div className="relative flex flex-1 flex-col">
          {transactionStep === MintTransactionStep.MINT &&
            fees.network &&
            fees.service && (
              <MintView
                pay={pay}
                tick={tick}
                receive={receive}
                handleMint={handleMint}
                setFeePerVByte={setFeePerVByte}
                loading={
                  (mintDune.isLoading as boolean) ||
                  (calculateNetworkFees.isLoading as boolean)
                }
                fees={{
                  network: fees.network,
                  service: fees.service,
                  total: fees.total || "N/A",
                }}
              />
            )}
          {transactionStep === MintTransactionStep.SUCCESS && (
            <SuccessView handleContinue={handleClose} />
          )}
          {transactionStep === MintTransactionStep.ERROR && (
            <ErrorView
              handleContinue={handleClose}
              title={error.title}
              description={error.description}
            />
          )}
        </div>
      </AnimatePresence>
    </Sheet>
  );
};
