// import React from "react";

import { AppNavigation, AppScreen } from "@/components";

export const NoResults = () => {
  return (
    <AppScreen withNavigationHeader>
      <AppNavigation title={" "} onAction={() => {}} iconAction="open_in_new" />
      <div className="flex flex-1 flex-col items-center justify-center space-y-10">
        <span className="relative flex h-16 w-16">
          <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-background-tertiary opacity-25" />
          <span className="relative inline-flex h-full w-full items-center justify-center rounded-full bg-background-tertiary">
            <span className="material-symbols-rounded text-4xl text-text-primary">
              search
            </span>
          </span>
        </span>

        <h2 className="text-center text-lg font-medium text-text-primary">
          No Results Found
        </h2>
      </div>
    </AppScreen>
  );
};
