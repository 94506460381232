import { HTMLMotionProps, motion } from "framer-motion";
import React from "react";

import { cn } from "@/lib/utils";

interface AnimatedClickableProps extends HTMLMotionProps<"div"> {
  children: React.ReactNode;
}

export const AnimatedClickable: React.FC<AnimatedClickableProps> = ({
  children,
  className,
  ...rest
}) => {
  return (
    <motion.div
      transition={{ duration: 0.1 }}
      whileTap={{ scale: 0.98, opacity: 0.5 }}
      whileHover={{ scale: 1.02 }}
      className={cn("cursor-pointer", className)}
      {...rest}
    >
      {children}
    </motion.div>
  );
};
