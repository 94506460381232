import React, { useCallback, useEffect, useState } from "react";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

import {
  ONE_DOGE_IN_SHIBES,
  WONKY_ORD_API,
  FEATURE_ACTIVATION_DUNES,
} from "@/constants";
import { useDebounce } from "@/hooks";
import { marketplaceApiV2, sdoggsApiV2, wonkyOrdApi } from "@/lib/fetch";
import { NumberFormatType, formatNumber } from "@/lib/numbers";
import {
  AddressSearchSuggestions,
  CollectionData,
  Currency,
  DoginalSearchSuggestion,
  Drc20Data,
  Drc20SearchSuggestion,
  DunesData,
  DunesSearchSuggestion,
  GenericSearchSuggestions,
  InscriptionSearchSuggestions,
  SearchSuggestionType,
} from "@/types";
import {
  getIconForDune,
  getIconForTick,
  handleError,
  isValidDogecoinAddress,
} from "@/utility";

interface Drc20Response {
  data: {
    list: Drc20Data[];
  };
}

interface DoginalResponse {
  data: {
    collections: CollectionData[];
  };
}

interface DuneResponse {
  data: {
    list: DunesData[];
  };
}

// Fetch Matches
const fetchDrc20Matches = async (searchTerm: string) => {
  try {
    return marketplaceApiV2(false).get("/drc20/list", {
      params: {
        filterByTick: searchTerm,
        offset: 0,
        limit: 5,
        sortOrder: "desc",
        sortParam: "volume",
      },
    });
  } catch (e: Error | unknown) {
    handleError(e);
    return { data: { list: [] } };
  }
};

const fetchDoginalMatches = async (searchTerm: string) => {
  try {
    return marketplaceApiV2(false).get("/doginals/list", {
      params: {
        filterByName: searchTerm,
        offset: 0,
        limit: 5,
        sortOrder: "desc",
        sortParam: "volume",
      },
    });
  } catch (e: Error | unknown) {
    handleError(e);
    return { data: { collections: [] } };
  }
};

const fetchDuneMatches = async (searchTerm: string) => {
  try {
    if (FEATURE_ACTIVATION_DUNES === "true") {
      return sdoggsApiV2(false).get("/dunes/list", {
        params: {
          filterByTick: searchTerm,
          offset: 0,
          limit: 5,
          sortOrder: "desc",
          sortParam: "volume",
        },
      });
    }

    return { data: { list: [] } };
  } catch (e: Error | unknown) {
    handleError(e);
    return { data: { list: [] } };
  }
};

// Fetch shibescription
const fetchShibescription = async (searchTerm: string) => {
  try {
    const res = await wonkyOrdApi(false).head(`/shibescription/${searchTerm}`);
    return { ok: res.status && res.status === 200 };
  } catch (e: Error | unknown) {
    handleError(e);
    return { ok: false };
  }
};

// Navigate to respective page based on suggestion type
const handleNavigation = (
  suggestionType: SearchSuggestionType,
  value: string,
  navigate: (path: string, options?: any) => void,
) => {
  switch (suggestionType) {
    case SearchSuggestionType.Address:
      navigate(`/wallet/${value}`);
      break;
    case SearchSuggestionType.Inscription:
      window.open(`${WONKY_ORD_API}shibescription/${value}`, "_blank");
      break;
    case SearchSuggestionType.DRC20:
      navigate(`drc20/${value}`);
      break;
    case SearchSuggestionType.Doginal:
      navigate(`collectible/${value}`);
      break;
    case SearchSuggestionType.Dunes:
      navigate(`dune/${value}`);
      break;
    default:
      navigate("search/noresults");
      break;
  }
};

export const useAppSearch = (onSelect: () => void) => {
  const navigate = useNavigate();

  /**
   * Local State
   */

  // Search
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  // Suggestions
  const [addressSuggestion, setAddressSuggestion] = useState<
    AddressSearchSuggestions | undefined
  >();
  const [inscriptionSuggestion, setInscriptionSuggestion] = useState<
    InscriptionSearchSuggestions | undefined
  >();
  const [drc20Suggestions, setDrc20Suggestions] = useState<
    Drc20SearchSuggestion[] | undefined
  >();
  const [doginalSuggestions, setDoginalSuggestions] = useState<
    DoginalSearchSuggestion[] | undefined
  >();
  const [dunesSuggestions, setDunesSuggestions] = useState<
    DunesSearchSuggestion[] | undefined
  >();

  const showResults = useMemo(
    () =>
      addressSuggestion ||
      inscriptionSuggestion ||
      drc20Suggestions?.length ||
      dunesSuggestions?.length ||
      doginalSuggestions?.length,
    [
      addressSuggestion,
      drc20Suggestions,
      doginalSuggestions,
      dunesSuggestions,
      inscriptionSuggestion,
    ],
  );

  /**
   * Functions
   */

  // Search
  const handleSetSearchValue = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchValue(event.target.value);
      event.target.value.trim().length > 0 && setShowSuggestions(true);
    },
    [],
  );

  // Reset Suggestions
  const resetSuggestions = useCallback(() => {
    setAddressSuggestion(undefined);
    setInscriptionSuggestion(undefined);
    setDrc20Suggestions(undefined);
    setDunesSuggestions(undefined);
    setShowSuggestions(false);
  }, []);

  // Clear Search
  const handleClearSearch = useCallback(() => {
    onSelect();
    setSearchValue("");
    resetSuggestions();
  }, [resetSuggestions, setSearchValue, onSelect]);

  // Select Suggestion: Navigate to respective page based on suggestion type
  const onSelectSuggestion = useCallback(
    (suggestion: GenericSearchSuggestions) => {
      const { type, address, inscription, tick, collectionSymbol } = suggestion;
      const valueMap = {
        [SearchSuggestionType.Address]: address,
        [SearchSuggestionType.Inscription]: inscription,
        [SearchSuggestionType.DRC20]: tick,
        [SearchSuggestionType.Dunes]: tick,
        [SearchSuggestionType.Doginal]: collectionSymbol,
      };

      handleNavigation(type, valueMap[type], navigate);
      handleClearSearch();
    },
    [navigate, handleClearSearch],
  );

  // Search: Navigate to respective page based on submitting search value
  const handleOnSubmit = useCallback(async () => {
    const trimmedValue = searchValue.trim();
    const isValidDogeAddress = isValidDogecoinAddress(trimmedValue);
    const couldBeInscription = trimmedValue.slice(-2) === "i0";

    // user hit enter, so no need to show suggestions
    resetSuggestions();

    if (isValidDogeAddress) {
      handleNavigation(SearchSuggestionType.Address, trimmedValue, navigate);
      return;
    }

    if (couldBeInscription) {
      setLoading(true);
      const response = await fetchShibescription(trimmedValue);
      setLoading(false);

      if (response.ok) {
        handleNavigation(
          SearchSuggestionType.Inscription,
          trimmedValue,
          navigate,
        );
      } else {
        navigate("search/noresults");
      }
      return;
    }

    let drc20Response: Drc20Response = { data: { list: [] } };
    let dunesResponse: DuneResponse = { data: { list: [] } };
    let doginalResponse: DoginalResponse = { data: { collections: [] } };
    try {
      setLoading(true);

      const [drc20Fetch, doginalFetch, duneFetch] = await Promise.all([
        fetchDrc20Matches(trimmedValue),
        fetchDoginalMatches(trimmedValue),
        fetchDuneMatches(trimmedValue),
      ]);

      drc20Response = drc20Fetch;
      dunesResponse = duneFetch;
      doginalResponse = doginalFetch;
    } catch (e: Error | unknown) {
      handleError(e);
    } finally {
      setLoading(false);
    }

    // can there be doginal and drc20 with same name?
    const exactMatchDrc20 = drc20Response.data.list.find(
      (x: Drc20Data) => x.tick === trimmedValue,
    );
    const exactMatchDoginal = doginalResponse.data.collections.find(
      (x: CollectionData) => x.name.toLowerCase() === trimmedValue,
    );
    const exactMatchDune = dunesResponse.data.list.find(
      (x: DunesData) => x.tick === trimmedValue,
    );

    if (exactMatchDrc20) {
      handleNavigation(
        SearchSuggestionType.DRC20,
        exactMatchDrc20.tick,
        navigate,
      );
    } else if (exactMatchDoginal) {
      handleNavigation(
        SearchSuggestionType.Doginal,
        exactMatchDoginal.symbol,
        navigate,
      );
    } else if (exactMatchDune) {
      handleNavigation(
        SearchSuggestionType.Dunes,
        exactMatchDune.tick,
        navigate,
      );
    } else {
      navigate("search/noresults");
    }
  }, [navigate, resetSuggestions, searchValue]);

  // Search: Handle Enter Key
  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        handleOnSubmit();
      }
    },
    [handleOnSubmit],
  );

  // Fetch Suggestions on Search Value Change
  const debouncedGetSuggestions = useDebounce(async (searchTerm: string) => {
    const trimmedSearchTerm = searchTerm.trim();

    if (trimmedSearchTerm) {
      const isValidDogeAddress = isValidDogecoinAddress(trimmedSearchTerm);
      const couldBeInscription = trimmedSearchTerm.slice(-2) === "i0";

      // Fetch Address
      if (isValidDogeAddress) {
        setAddressSuggestion({
          type: SearchSuggestionType.Address,
          address: trimmedSearchTerm,
        });
        setInscriptionSuggestion(undefined);
        setDrc20Suggestions(undefined);
        setDoginalSuggestions(undefined);
        setDunesSuggestions(undefined);

        return;
      }

      // Fetch Inscription
      if (couldBeInscription) {
        setLoading(true);
        const response = await fetchShibescription(trimmedSearchTerm);

        if (response.ok) {
          setInscriptionSuggestion({
            type: SearchSuggestionType.Inscription,
            inscription: trimmedSearchTerm,
          });
        } else {
          setInscriptionSuggestion(undefined);
        }

        setLoading(false);
        setAddressSuggestion(undefined);
        setDrc20Suggestions(undefined);
        setDoginalSuggestions(undefined);
        setDunesSuggestions(undefined);

        return;
      }

      // Fetch DRC20 & Doginal & Dune Matches
      let drc20Response: Drc20Response = { data: { list: [] } };
      let doginalResponse: DoginalResponse = { data: { collections: [] } };
      let duneResponse: DuneResponse = { data: { list: [] } };
      try {
        setLoading(true);

        // if > 4 characters, fetch Doginal & Dune Matches but not DRC20
        if (trimmedSearchTerm.length > 4) {
          const [doginalFetch, duneFetch] = await Promise.all([
            fetchDoginalMatches(trimmedSearchTerm),
            fetchDuneMatches(trimmedSearchTerm),
          ]);

          doginalResponse = doginalFetch;
          duneResponse = duneFetch;
        } else {
          const [drc20Fetch, doginalFetch, duneFetch] = await Promise.all([
            fetchDrc20Matches(trimmedSearchTerm),
            fetchDoginalMatches(trimmedSearchTerm),
            fetchDuneMatches(trimmedSearchTerm),
          ]);

          drc20Response = drc20Fetch;
          doginalResponse = doginalFetch;
          duneResponse = duneFetch;
        }
      } catch (e: Error | unknown) {
        handleError(e);
      } finally {
        setLoading(false);
      }

      const suggestionsDrc20 = drc20Response.data.list?.map(
        (drc20: Drc20Data) => {
          const volumeInDoge = formatNumber({
            value: drc20.volume / ONE_DOGE_IN_SHIBES,
            type: NumberFormatType.Large_Number,
          });
          const floorInDoge = formatNumber({
            value: drc20.floorPrice / ONE_DOGE_IN_SHIBES,
            type: NumberFormatType.Price,
          });

          return {
            tick: drc20.tick,
            image: getIconForTick(drc20.tick),
            floor: `${Currency.DOGE}${floorInDoge}`,
            volume: `${Currency.DOGE}${volumeInDoge}`,
            totalVolume: drc20.volume,
            type: SearchSuggestionType.DRC20,
          };
        },
      );

      const suggestionsDoginal = doginalResponse.data.collections?.map(
        (doginal: CollectionData) => {
          const volumeInDoge = formatNumber({
            value: doginal.totalVolume / ONE_DOGE_IN_SHIBES,
            type: NumberFormatType.Large_Number,
          });

          const floorInDoge = formatNumber({
            value: doginal.floorPrice / ONE_DOGE_IN_SHIBES,
            type: NumberFormatType.Price,
          });

          return {
            tick: doginal.name,
            collectionSymbol: doginal.symbol,
            image: doginal.imageURI,
            floor: `${Currency.DOGE}${floorInDoge}`,
            volume: `${Currency.DOGE}${volumeInDoge}`,
            totalVolume: doginal.totalVolume,
            type: SearchSuggestionType.Doginal,
          };
        },
      );

      const suggestionsDunes = duneResponse.data.list?.map(
        (dune: DunesData) => {
          const volumeInDoge = formatNumber({
            value: dune.volume / ONE_DOGE_IN_SHIBES,
            type: NumberFormatType.Large_Number,
          });
          const floorInDoge = formatNumber({
            value: dune.floorPrice / ONE_DOGE_IN_SHIBES,
            type: NumberFormatType.Price,
          });

          return {
            tick: dune.tick,
            image: getIconForDune(dune.tick),
            floor: `${Currency.DOGE}${floorInDoge}`,
            volume: `${Currency.DOGE}${volumeInDoge}`,
            totalVolume: dune.volume,
            type: SearchSuggestionType.Dunes,
          };
        },
      );

      suggestionsDrc20?.length > 0
        ? setDrc20Suggestions(suggestionsDrc20)
        : setDrc20Suggestions(undefined);

      suggestionsDoginal?.length > 0
        ? setDoginalSuggestions(suggestionsDoginal)
        : setDoginalSuggestions(undefined);

      suggestionsDunes?.length > 0
        ? setDunesSuggestions(suggestionsDunes)
        : setDunesSuggestions(undefined);

      setAddressSuggestion(undefined);
      setInscriptionSuggestion(undefined);
    }
  }, 500);

  // Fetch Suggestions on Search Value Change
  useEffect(() => {
    // ensure searchValue is > 1 characters (too avoid unnecessary fetches)
    if (searchValue.length < 2) {
      return;
    }

    debouncedGetSuggestions(searchValue);
  }, [debouncedGetSuggestions, searchValue]);

  // Reset Focus
  useEffect(() => {
    if (!isFocused) {
      resetSuggestions();
    }
  }, [isFocused, resetSuggestions, handleOnSubmit, searchValue]);

  return {
    isFocused,
    searchValue,
    showResults,
    showSuggestions,
    loading,
    addressSuggestion,
    inscriptionSuggestion,
    drc20Suggestions,
    doginalSuggestions,
    dunesSuggestions,
    handleSetSearchValue,
    handleClearSearch,
    onSelectSuggestion,
    handleOnSubmit,
    handleKeyDown,
    setIsFocused,
  };
};
