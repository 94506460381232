export enum InscribeContentType {
  DRC20 = "drc20",
  FILES = "files",
  TEXT = "text",
  DOGEMAP = "dogemap",
  DELEGATE = "delegate",
  DUNES = "dunes",
}

export enum InscribeTab {
  EXPLORE = "explore",
  MINT = "mint",
  ETCH = "etch",
}

