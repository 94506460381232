import { AnimatePresence, Transition, Variants, motion } from "framer-motion";
import React, { useMemo } from "react";
import { useCallback } from "react";

import { useBalance, useCurrency } from "@/contextHooks";
import { useAppHeaderHeight } from "@/hooks";
import { cn } from "@/lib/utils";
import { Currency } from "@/types";
import { useToggle } from "@uidotdev/usehooks";

import { SearchMobile } from "../search";
import { Button } from "../ui/button";
import { Balance } from "./balance";
import { LiveIndicator } from "./liveIndicator";
import { Ticker, TickerProps } from "./ticker";
import { useLocation } from "react-router-dom";
import { CurrencySwitch } from "@/components";

const variants: Variants = {
  open: { opacity: 1 },
  closed: { opacity: 0 },
};

const transition: Transition = {
  duration: 0.2,
  ease: "easeInOut",
};

interface HeaderProps extends TickerProps {
  onRefresh: () => void;
  className?: string;
}

export const Header: React.FC<HeaderProps> = ({
  tickers,
  className,
  onRefresh,
}) => {
  const { headerRef } = useAppHeaderHeight();
  const { currency, setCurrency } = useCurrency();
  // current url:
  const { pathname } = useLocation();
  // if pathname is /wallet/.* set remove the balance header
  const showBalance = !pathname.includes("/wallet/");

  const { balanceInDOGEForHeader, balanceInUSDForHeader } = useBalance();

  // Local state for displaying the search bar
  const [showSearch, toggleSearch] = useToggle(false);

  // Toggle between USD and DOGE
  const toggleCurrency = useCallback(() => {
    setCurrency(currency === Currency.USD ? Currency.DOGE : Currency.USD);
  }, [currency, setCurrency]);

  // Calculate the balance value based on the selected currency
  const value = useMemo(() => {
    if (currency === Currency.USD) {
      return balanceInUSDForHeader;
    }
    return balanceInDOGEForHeader;
  }, [balanceInDOGEForHeader, balanceInUSDForHeader, currency]);

  return (
    <AnimatePresence>
      <motion.div
        ref={headerRef}
        className={cn(
          "fixed top-0 flex w-full flex-col space-y-2 border-b-0.5 border-border-primary bg-background-primary pb-2 pt-safe-or-2",
          className,
        )}
        style={{ zIndex: 101 }}
      >
        <div className="flex flex-1 flex-row items-center px-2">
          <LiveIndicator className="mr-2" />
          <Ticker tickers={tickers} />
          <div className="flex flex-row">
            <Button
              onClick={() => toggleSearch()}
              className="z-10 h-8 w-8 rounded-md"
              variant="ghost"
              size="icon"
            >
              <span className="material-symbols-rounded text-lg text-text-secondary">
                {showSearch ? "search_off" : "search"}
              </span>
            </Button>
            <Button
              onClick={onRefresh}
              className="z-10 h-8 w-8 rounded-md"
              variant="ghost"
              size="icon"
            >
              <span className="material-symbols-rounded text-lg text-text-secondary">
                refresh
              </span>
            </Button>
          </div>
          <CurrencySwitch
            onClick={toggleCurrency}
            value={currency}
            fontSize={"1.25rem"}
          />
          {showBalance && <Balance value={value} />}
        </div>
        {showSearch && (
          <motion.div
            key="search-bar"
            initial="closed"
            animate="open"
            exit="closed"
            variants={variants}
            transition={transition}
          >
            <SearchMobile
              className="mx-4 mb-2 flex-1"
              autoFocus={showSearch}
              onSelect={toggleSearch}
            />
          </motion.div>
        )}
      </motion.div>
    </AnimatePresence>
  );
};
