import React, { useCallback, useEffect, useState } from "react";

import {
  Button,
  Input,
  ListItem,
  PaymentItem,
  Separator,
  Spinner,
} from "@/components";
import {
  AVERAGE_NETWORK_FEE_RATE,
  DOGE_LOGO,
  HIGH_NETWORK_FEE_RATE,
  LEGAL_TERMS,
  LOW_NETWORK_FEE_RATE,
  ONE_DOGE_IN_SHIBES,
} from "@/constants";
import { useInfoModal } from "@/hooks";
import { ModalInfo } from "@/modals/modalInfo";
import { getIconForDune } from "@/utility";

import { AnimatedContent, ExpandableFeeList } from "../../base";
import { NetworkFeeCard } from "@/modals/modalSendDoge/views";

interface Fees {
  network: string;
  service: string;
  total: string;
}

interface Values {
  valueInUSD?: string;
  value: string;
  valueDetails?: string;
}

interface MintViewProps {
  handleMint: () => void;
  setFeePerVByte: (feePerVByte: number) => void;
  loading: boolean;
  tick?: string;
  fees: Fees;
  pay: Values;
  receive: Values;
}

export const MintView: React.FC<MintViewProps> = ({
  fees,
  pay,
  receive,
  tick,
  loading,
  handleMint,
  setFeePerVByte,
}: MintViewProps) => {
  const { info, infoVisible, handleOpenInfo, handleCloseInfo } = useInfoModal();
  const [choosenFee, setChoosenFee] = useState<number>(AVERAGE_NETWORK_FEE_RATE);

  const handleOpenPaymentInfo = useCallback(() => {
    handleOpenInfo("Payment Info", [
      "The amounts displayed here are estimates based on your current inscription selections.",
      "The amount You Pay and You Receive therefore may vary from what is shown, contingent on the remaining availability of these inscriptions, the Dogecoin mempool, status of the network, etc.",
      "By proceeding, you agree to these terms.",
    ]);
  }, [handleOpenInfo]);

  useEffect(() => {
    setFeePerVByte && setFeePerVByte(choosenFee);
  }, [choosenFee, setFeePerVByte]);

  return (
    <AnimatedContent id="mint">
      <div className="flex flex-1 flex-col">
        <div className="relative flex flex-1 flex-col space-y-2">
          <div className="flex flex-col space-y-4 rounded-lg bg-background-secondary p-4">
            <ExpandableFeeList
              fees={[
                {
                  label: "Total Fees",
                  value: fees.total,
                  onClick: handleOpenPaymentInfo,
                },
                {
                  label: "Network Fee",
                  value: fees.network,
                  onClick: () => handleOpenInfo(
                    "Network Fee",
                    "The Network Fee is the cost of processing the transaction on the blockchain.",
                  ),
                },
                {
                  label: `Service Fee`,
                  value: fees.service,
                  onClick: () => handleOpenInfo(
                    "Service Fee",
                    `The Service Fee is a fixed amount of 1 DOGE and part of the transaction.`,
                  ),
                },
              ]}
              expandableHead={{
                header: "You Pay",
                value: `${pay.valueInUSD} ≈ ${pay.value}`,
                withExpandableHeaderImage: true,
                onClick: handleOpenPaymentInfo,
                imageSize: "h-10 w-10",
              }}
            />
            <Separator />
            <div className="p-4">
              <PaymentItem
                image={getIconForDune(tick)}
                label="You Receive"
                value={receive.value}
                valueInUSD={receive.valueInUSD}
                valueDetails={receive.valueDetails}
                onClick={handleOpenPaymentInfo}
              />
            </div>
          </div>
          <div className="py-4 space-y-2">
            <div className="flex flex-row items-center space-x-2 bg-background-secondary p-2 rounded-lg">
              <NetworkFeeCard 
                className="py-2 text-center"
                handleToggle={() => setChoosenFee(LOW_NETWORK_FEE_RATE)}
                isToggled={choosenFee === LOW_NETWORK_FEE_RATE} 
                label="Economy"
                detail={`${(LOW_NETWORK_FEE_RATE * 1000 / ONE_DOGE_IN_SHIBES).toFixed(1)} DOGE/kb`}
                showButton={false}
              />
              <NetworkFeeCard 
                className="py-2 text-center"
                handleToggle={() => setChoosenFee(AVERAGE_NETWORK_FEE_RATE)}
                isToggled={choosenFee === AVERAGE_NETWORK_FEE_RATE} 
                label="Priority"
                detail={`${(AVERAGE_NETWORK_FEE_RATE * 1000 / ONE_DOGE_IN_SHIBES).toFixed(1)} DOGE/kb`}
                showButton={false}
              />
              <NetworkFeeCard 
                className="py-2 text-center"
                handleToggle={() => setChoosenFee(HIGH_NETWORK_FEE_RATE)}
                isToggled={choosenFee !== LOW_NETWORK_FEE_RATE && choosenFee !== AVERAGE_NETWORK_FEE_RATE}
                label="Custom"
                detail={`${(HIGH_NETWORK_FEE_RATE * 1000 / ONE_DOGE_IN_SHIBES).toFixed(1)} DOGE/kb`}
                showButton={false}
              />
            </div>
            {choosenFee !== LOW_NETWORK_FEE_RATE && choosenFee !== AVERAGE_NETWORK_FEE_RATE && (
              <Input type="number" placeholder={(HIGH_NETWORK_FEE_RATE * 1000 / ONE_DOGE_IN_SHIBES).toFixed(1)} value={choosenFee * 1000 / ONE_DOGE_IN_SHIBES} onChange={(event) => setChoosenFee(Number(event.target.value) * ONE_DOGE_IN_SHIBES / 1000)} />
            )}
          </div>
        </div>
        <div className="flex w-full flex-col space-y-2">
          <Button
            size="large"
            variant="inverse"
            onClick={handleMint}
            disabled={loading || !tick || !choosenFee}
          >
            {loading ? <Spinner size={24} /> : "Submit Mint"}
          </Button>
          <p className="text-center text-2xs italic text-text-tertiary">
            By clicking Submit Mint, you confirm the accuracy of the input data and
            agree to the
            <span
              onClick={() => window.open(LEGAL_TERMS, "_blank")}
              className="ml-1 cursor-pointer underline"
            >
              {" "}
              Terms of Service
            </span>
            .
          </p>
        </div>
      </div>

      <ModalInfo
        info={info}
        isVisible={infoVisible}
        onClose={handleCloseInfo}
      />
    </AnimatedContent>
  );
};
