import React from "react";

import { cn } from "@/lib/utils";
import * as TabsPrimitive from "@radix-ui/react-tabs";

import { TabsTrigger as TabsTriggerInner } from "../ui/tabs";

export const TabsTrigger = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ className, ...props }, ref) => {
  return (
    <TabsTriggerInner
      ref={ref}
      className={cn(
        "rounded-full text-sm font-medium",
        "bg-background-secondary text-text-tertiary hover:text-text-primary",
        "data-[state=active]:bg-background-tertiary data-[state=active]:text-text-primary data-[state=active]:shadow-md",
        className,
      )}
      {...props}
    />
  );
});

TabsTrigger.displayName = "TabsTrigger";
