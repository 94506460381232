import React from "react";
import { useNavigate } from "react-router-dom";

import { Button, ImageWithFallback } from "@/components";
import { useNavigationHeaderHeight } from "@/hooks";
import { cn } from "@/lib/utils";
import { getIconForTick } from "@/utility";

interface AppNavigationProps {
  title: string;
  iconAction?: string;
  withAppHeader?: boolean;
  titleIsTick?: boolean;
  onAction?: () => void;
  onBack?: () => void;
  className?: string;
}

export const AppNavigation: React.FC<AppNavigationProps> = ({
  title,
  iconAction,
  // withAppHeader = false,
  titleIsTick = false,
  onAction,
  onBack,
  className,
}) => {
  const navigate = useNavigate();

  const headerRef = useNavigationHeaderHeight();
  // const { appHeaderHeight } = useContext(UIContext);

  const handleGoBack = () => {
    if (onBack) {
      onBack();
    } else {
      navigate(-1);
    }
  };

  return (
    <div
      ref={headerRef}
      className={cn("bg-background-primary", className)}
      style={{
        zIndex: 100,
      }}
    >
      <div className="flex flex-1 flex-row items-center space-x-2 px-4 py-2">
        <Button
          variant="ghost"
          size="icon"
          shape="circle"
          onClick={handleGoBack}
          className="lg:hidden"
        >
          <span className="material-symbols-rounded text-lg">
            arrow_back_ios
          </span>
        </Button>

        <div className="h-10 w-10 max-lg:hidden" />

        {titleIsTick && (
          <div className="flex flex-1 flex-row items-center justify-center space-x-2 lg:space-x-4">
            <ImageWithFallback
              image={getIconForTick(title)}
              className="h-8 w-8 lg:h-10 lg:w-10"
            />
            <span className="text-center text-lg font-bold uppercase text-text-primary lg:text-2xl">
              {title}
            </span>
          </div>
        )}

        {!titleIsTick && (
          <span className="flex flex-1 flex-col text-center text-lg font-medium text-white">
            {title}
          </span>
        )}

        {onAction && iconAction ? (
          <Button variant="ghost" size="icon" shape="circle" onClick={onAction}>
            <span className="material-symbols-rounded text-lg">
              {iconAction}
            </span>
          </Button>
        ) : (
          <div className="h-10 w-10" />
        )}
      </div>
    </div>
  );
};
