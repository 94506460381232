import { useCallback, useState } from "react";

import {
  FEATURE_ACTIVATION_DUNES,
  REORG_SAFE_MIN_CONFIRMATIONS,
} from "@/constants";
import { fetchDunesListings } from "@/context/helpers/fetchDunesListings";
import {
  DuneListing,
  ListingStatus,
  SortOrder,
  SortParamListingsDunes,
} from "@/types";
import { getTxConfirmations } from "@/utility";

/*
Unlike useFetchDuneListings hook, this hook fetches listings for a specific address.
- It fetches both listed AND PENDING listings, which the other hook does not.
- It also double checks the status of the listed ones.
- It paginates automatically, to make sure, it gets ALL listings.
*/

type UseFetchDuneListingsForAddressReturn = {
  listings: DuneListing[];
  loading: boolean;
  error?: Error | unknown;
  fetchListings: (token?: string, cacheBreaker?: boolean) => Promise<void>;
};

const useFetchDuneListingsForAddress = ({
  address,
}: {
  address?: string;
}): UseFetchDuneListingsForAddressReturn => {
  const [listings, setListings] = useState<DuneListing[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | unknown>();

  const fetchListings = useCallback(
    async (token?: string, cacheBreaker?: boolean) => {
      if (FEATURE_ACTIVATION_DUNES !== "true") {
        return;
      }

      if (!token || !address) {
        return;
      }

      setLoading(true);
      const limit = 20; // Number of listings to fetch per request
      let offset = 0;
      let allListings: DuneListing[] = [];
      let hasMoreData = true;

      try {
        while (hasMoreData) {
          const [listed, pending] = await Promise.all([
            fetchDunesListings(
              {
                tick: token,
                seller: address,
                offset: offset,
                limit: limit,
                sortParam: SortParamListingsDunes.CreatedAt,
                sortOrder: SortOrder.Descending,
              },
              cacheBreaker,
            ),
            fetchDunesListings(
              {
                tick: token,
                seller: address,
                offset: offset,
                limit: limit,
                status: ListingStatus.Pending,
                sortParam: SortParamListingsDunes.CreatedAt,
                sortOrder: SortOrder.Descending,
              },
              cacheBreaker,
            ),
          ]);

          const total = pending.total + listed.total;
          const offers = [...pending.offers, ...listed.offers];

          const result = { offers, total };

          console.log(
            "useFetchDuneListingsForAddress- Fetched listings",
            result.total,
            "offset",
            offset,
          );
          allListings = [...allListings, ...result.offers];

          // If no more listings are returned, stop the loop
          if (result.total < offset + limit) {
            hasMoreData = false;
          } else {
            offset += limit;
            console.log("useFetchDuneListingsForAddress - Offset", offset);
          }
        }

        // Double-check the status of the listed ones
        const safeResult = await Promise.all(
          allListings.map(async (r) => {
            if (r.status === ListingStatus.Listed) {
              const confirmations = await getTxConfirmations(r.txHash);
              const doubleCheckedStatus =
                confirmations >= REORG_SAFE_MIN_CONFIRMATIONS
                  ? ListingStatus.Listed
                  : ListingStatus.Pending;

              return {
                ...r,
                status: doubleCheckedStatus,
              };
            }
            return r;
          }),
        );

        setListings(safeResult);
      } catch (error: Error | unknown) {
        setError(error);
        setListings([]);
        console.error("Error fetching listings", error);
      } finally {
        setLoading(false);
      }
    },
    [address],
  );

  return { listings, loading, error, fetchListings };
};

export { useFetchDuneListingsForAddress };
export type { UseFetchDuneListingsForAddressReturn };
