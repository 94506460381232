import React from "react";

import { Sheet, SheetProps } from "../base";

interface ModalInfoProps extends SheetProps {
  info: {
    title: string;
    content: string | string[];
  };
}

export const ModalInfo: React.FC<ModalInfoProps> = ({
  info: { title, content },
  ...props
}) => {
  return (
    <Sheet {...props} title={title}>
      <div className="flex flex-col px-2 pb-2">
        <div className="flex flex-1 flex-row space-x-2 rounded-lg bg-background-secondary p-4">
          <span className="material-symbols-rounded text-sm text-text-tertiary">
            info
          </span>
          {Array.isArray(content) ? (
            <div className="flex flex-1 flex-col space-y-2 text-base">
              {content.map((item, index) => (
                <p key={index} className="text-sm text-text-primary">
                  {item}
                </p>
              ))}
            </div>
          ) : (
            <p
              className="whitespace-pre-wrap text-sm text-text-primary"
              dangerouslySetInnerHTML={{
                __html: content,
              }}
            />
          )}
        </div>
      </div>
    </Sheet>
  );
};
