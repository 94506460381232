import React from "react";

import { Switch } from "@/components/switch";
import { cn } from "@/lib/utils";

export interface ListItemProps {
  icon?: string;
  label: string | React.ReactNode;
  labelStyles?: string;
  detail?: string | number;
  selectable?: boolean;
  selected?: boolean;
  withChevron?: boolean;
  onClick?: () => void;
  onToggle?: () => void;
  toggleEnabled?: boolean;
  toggleDisabled?: boolean;
  toggleSize?: "default" | "small";
  variant?: "default" | "danger" | "info";
  className?: string;
}

export const ListItem: React.FC<ListItemProps> = ({
  icon,
  label,
  labelStyles,
  detail,
  onClick,
  onToggle,
  variant,
  selectable = false,
  selected = false,
  withChevron = true,
  toggleEnabled = false,
  toggleDisabled = false,
  toggleSize = "default",
  className,
}) => {
  return (
    <div
      className={cn(
        "flex w-full cursor-pointer flex-row items-center justify-between space-x-2 rounded-md p-2",
        onClick && "hover:bg-background-tertiary",
        className,
      )}
      onClick={onClick}
    >
      <div className="flex flex-1 flex-row items-center space-x-2">
        {icon && variant !== "info" && (
          <span
            className={cn(
              "material-symbols-rounded text-lg text-text-primary",
              variant === "danger" && "text-red-600",
            )}
          >
            {icon}
          </span>
        )}
        <div
          className={cn(
            "flex flex-1 flex-row items-center space-x-1 text-sm text-text-primary",
            variant === "danger" && "text-red-600",
            labelStyles,
          )}
        >
          <span>{label}</span>

          {icon && variant === "info" && (
            <span className="material-symbols-rounded text-sm text-text-tertiary">
              info
            </span>
          )}
        </div>
      </div>

      {onClick && withChevron && (
        <span className="material-symbols-rounded text-lg text-text-tertiary">
          chevron_right
        </span>
      )}
      {detail !== undefined && (
        <span
          className="pr-1 text-sm text-text-tertiary"
          dangerouslySetInnerHTML={{ __html: `${detail}` }}
        />
      )}
      {selectable && (
        <div
          className={cn(
            "h-4 w-4 rounded-full border border-border-tertiary bg-background-tertiary p-0.5",
            selected && "border-primary-500",
          )}
        >
          {selected && (
            <div className="h-full w-full rounded-full bg-primary-500" />
          )}
        </div>
      )}
      {onToggle && (
        <Switch
          enabled={toggleEnabled}
          disabled={toggleDisabled}
          onChange={onToggle}
          size={toggleSize}
        />
      )}
    </div>
  );
};
