import { Transition, Variants } from "framer-motion";

export const backgroundVariants: Variants = {
  open: { opacity: 1 },
  closed: { opacity: 0 },
};

export const backgroundTransition: Transition = {
  duration: 0.2,
  ease: "easeInOut",
};

export const sheetTransition: Transition = {
  type: "spring",
  damping: 20,
  mass: 0.7,
  stiffness: 200,
};

export const sheetVariants: Variants = {
  open: { y: 0, opacity: 1 },
  closed: { y: "100%", opacity: 0 },
};

export const cardVariants: Variants = {
  open: { y: 0, opacity: 1 },
  closed: { y: 100, opacity: 0 },
};

export const cardTransition: Transition = {
  type: "spring",
  damping: 20,
  stiffness: 150,
};

export const contentVariants: Variants = {
  open: { y: 0, opacity: 1 },
  closed: { y: 50, opacity: 0 },
  exit: { y: -50, opacity: 0 },
};

export const contentTransition: Transition = {
  type: "easeInOut",
  duration: 0.2,
};
