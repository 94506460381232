import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { AccountView } from "../Account/accountView.tsx";
import { isValidDogecoinAddress } from "@/utility";
import { BalanceProvider } from "@/context";
// Get the wallet address from the URL
const useWalletAddress = () => {
  const { walletAddress } = useParams();

  return useMemo(
    () =>
      isValidDogecoinAddress(walletAddress || "") ? walletAddress : undefined,
    [walletAddress],
  );
};

export const WalletOverview = () => {
  const address = useWalletAddress();

  return (
    <BalanceProvider address={address}>
      <AccountView address={address ?? ""} isOverview={true} />;
    </BalanceProvider>
  );
};
