/*
Gets the current installed version from localStorage, if not set, it sets it to the APP_VERSION from environment.ts
If differs, all version dependend localStore Keys are deleted and the new version is set in localStorage
 */
import { useLocalStorage } from "@/hooks/useLocalStorage.ts";
import { CURRENT_APP_VERION_KEY, APP_VERSION } from "@/constants.ts";

export const useAppVersionManager = () => {
  const {
    value: currentVersion,
    updateValue,
    deleteEntry,
    getValuesByVersion,
  } = useLocalStorage<string | null>(CURRENT_APP_VERION_KEY, null, false);

  if (currentVersion !== APP_VERSION) {
    // remove all version dependend keys (force user to relogin)
    if (currentVersion !== null) {
      const versionKeys = getValuesByVersion(currentVersion);
      versionKeys.forEach(({ key }) => deleteEntry(key));
    }

    // set new version
    updateValue(APP_VERSION);
  }
};
