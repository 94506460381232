import React from "react";

import { ItemProps } from "./types";

export const Item: React.FC<ItemProps> = ({ label, value }) => {
  return (
    <div className="flex flex-row justify-between space-x-2 py-1">
      <span className="text-text-tertiary">{label}</span>
      <div className="flex flex-col justify-end">
        <span
          className="font-medium text-text-secondary"
          dangerouslySetInnerHTML={{ __html: `${value}` }}
        />
      </div>
    </div>
  );
};
