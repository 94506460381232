import { Button } from "@/components";
import { Search, SuggestionDune } from "@/components/search";
import { SearchCloseButton } from "@/components/search/searchCloseButton";
import { useDunesTokenList, useTxWallet } from "@/contextHooks";
import { cn } from "@/lib/utils";
import { DunesToken } from "@/types";
import { useCallback, useState } from "react";

const MAX_MINTS = 20;

type DuneMintFormProps = {
  className?: string;
  dune: DunesToken | null;
  onMint?: (params: any) => void;
};

export const DuneMintForm: React.FC<DuneMintFormProps> = ({
  className,
  dune,
  onMint,
}) => {
  const { login } = useTxWallet();

  const { mintableTableData } = useDunesTokenList();

  // const [externalRecipient, setExternalRecipient] = useState<boolean>(false);
  const [selectedDune, setSelectedDune] = useState<DunesToken | null>(
    dune || null,
  );
  const [recipient, setRecipient] = useState<string>("");
  const [mints, setMints] = useState<number>(MAX_MINTS);

  const handleOnChangeMints = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = Math.floor(Number(e.target.value));
      if (inputValue > MAX_MINTS) {
        setMints(MAX_MINTS);
      } else if (inputValue < 1) {
        setMints(1);
      }
      setMints(inputValue);
    },
    [],
  );

  const handleMint = useCallback(async () => {
    const isLoggedIn = await login();
    if (!isLoggedIn) return;
    if (onMint) {
      onMint({
        ...selectedDune,
        address: recipient,
        amount: mints > MAX_MINTS ? MAX_MINTS : mints < 1 ? 1 : mints,
      });
    }
  }, [login, onMint, selectedDune, recipient, mints]);

  return (
    <div
      className={cn(
        "mx-auto flex w-full max-w-xl flex-col justify-between p-4 xl:mt-12",
        className,
      )}
    >
      <div className="flex flex-col text-[#9F9FA8]">
        {/*<SearchDesktop />*/}
        <span className="px-2 text-xs">DUNE / DUNE ID</span>
        {selectedDune ? (
          <>
            <div className="mt-2 flex flex-1 flex-row items-center rounded-lg bg-background-secondary px-4">
              <input
                type="text"
                className="h-12 w-full bg-background-secondary"
                disabled={true}
                value={selectedDune.name}
              />
              <SearchCloseButton
                isVisible
                onClick={() => setSelectedDune(null)}
              />
            </div>
            <div className="mt-6 flex flex-col text-[#9F9FA8]">
              <span className="px-2 text-xs">
                REPEAT MINT (up to {MAX_MINTS})
              </span>
              <input
                type="number"
                className="mt-2 h-12 w-full rounded-lg bg-background-secondary px-4"
                value={mints}
                onChange={handleOnChangeMints}
              />
            </div>
            {/* 
            <div className="flex flex-col text-[#9F9FA8] mt-6">
              <div className="flex flex-row justify-between">
                <span className="text-xs px-2">EXTERNAL RECEIVER (optional)</span>
                <Checkbox
                  className="ml-2"
                  onClick={() => {
                    setExternalRecipient(!externalRecipient);
                  }} 
                  value={String(externalRecipient)}
                />
              </div>
              {externalRecipient && (
                <input
                  type="text"
                  className="w-full h-12 mt-2 rounded-lg bg-background-secondary px-4"
                  value={recipient}
                  onChange={(e) => setRecipient(e.target.value)}
                />
              )}
            </div>
            */}
          </>
        ) : (
          <Search
            className="mt-2 h-12 w-full rounded-lg bg-background-secondary"
            config={{
              ignoreAddresses: true,
              ignoreDrc20s: true,
              ignoreDoginals: true,
              ignoreInscriptions: true,
            }}
            onSelect={(value: any) => {
              setSelectedDune(value);
            }}
            suggestions={mintableTableData}
            SuggestionComponent={SuggestionDune}
          />
        )}
      </div>
      <div className="mt-24 flex px-4">
        <Button
          className="w-full bg-white text-[#3F3F46]"
          onClick={handleMint}
          disabled={!selectedDune || !mints}
        >
          Mint
        </Button>
      </div>
    </div>
  );
};
