import React from "react";

import { Button } from "@/components";

interface HeaderProps {
  title?: string;
  subTitle?: string;
  iconClose?: string;
  iconAction?: string;
  onClose?: () => void;
  onAction?: () => void;
}

export const Header: React.FC<HeaderProps> = ({
  title = "",
  subTitle = "",
  iconClose = "close",
  iconAction = "",
  onClose,
  onAction,
}) => {
  return (
    <div className="flex flex-none items-center justify-center p-2 pb-4">
      {onAction ? (
        <Button variant="ghost" size="icon" shape="circle" onClick={onAction}>
          <span className="material-symbols-rounded text-lg">{iconAction}</span>
        </Button>
      ) : (
        <div className="h-10 w-10" />
      )}

      <div className="flex flex-1 flex-col">
        <h2 className="text-center text-lg font-bold text-text-primary">
          {title}
        </h2>
        <span className="text-center text-xs text-text-primary">
          {subTitle}
        </span>
      </div>

      {onClose ? (
        <Button variant="ghost" size="icon" shape="circle" onClick={onClose}>
          <span className="material-symbols-rounded text-lg">{iconClose}</span>
        </Button>
      ) : (
        <div className="h-10 w-10" />
      )}
    </div>
  );
};
