import { AnimatePresence, motion } from "framer-motion";
import React from "react";

import { useLock } from "@/hooks";
import { cn } from "@/lib/utils";

import { Header } from "./header";
import {
  backgroundTransition,
  backgroundVariants,
  sheetTransition,
  sheetVariants,
} from "./motion";

export interface SheetProps {
  title?: string;
  isVisible: boolean;
  withHeader?: boolean;
  onClose?: () => void;
  children?: React.ReactElement | React.ReactElement[];
  className?: string;
  classNameContent?: string;
  style?: React.CSSProperties;
}

export const Sheet: React.FC<SheetProps> = ({
  title,
  isVisible,
  onClose,
  children,
  withHeader = true,
  className,
  classNameContent,
  style,
}) => {
  useLock(isVisible);

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          key="action-sheet-background"
          initial="closed"
          animate="open"
          exit="closed"
          variants={backgroundVariants}
          transition={backgroundTransition}
          className={cn(
            "fixed inset-0 z-50 flex items-end justify-center bg-black/50 md:py-8",
            className,
          )}
          style={{ zIndex: 102, ...style }}
        >
          <motion.div
            key="action-sheet"
            initial="closed"
            animate="open"
            exit="closed"
            variants={sheetVariants}
            transition={sheetTransition}
            className={cn(
              "w-full max-w-2xl overflow-y-auto rounded-t-2xl bg-background-primary px-2 pt-2 shadow-lg pb-safe-or-2",
              "md:rounded-2xl md:p-4",
              classNameContent,
            )}
          >
            {withHeader && <Header title={title} onClose={onClose} />}

            <div className="flex flex-1 flex-col space-y-2">{children}</div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
