import React from "react";

import { Button } from "@/components";

import { AnimatedContent } from "./animatedContent";

interface ErrorViewProps {
  handleContinue: () => void;
  title: string;
  description: string;
  buttonLabel?: string;
}

export const ErrorView: React.FC<ErrorViewProps> = ({
  handleContinue,
  title = "Error",
  description = "An error occurred while processing your request.",
  buttonLabel = "Close",
}: ErrorViewProps) => (
  <AnimatedContent id="error">
    <div className="flex flex-1 flex-col items-center justify-center space-y-10">
      <span className="relative flex h-16 w-16">
        <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-red-600 opacity-25" />
        <span className="relative inline-flex h-full w-full items-center justify-center rounded-full bg-red-600">
          <span className="material-symbols-rounded text-4xl text-text-primary">
            error
          </span>
        </span>
      </span>

      <div className="flex flex-col justify-center space-y-0">
        <h2 className="text-center text-xl font-medium">{title}</h2>
        <p className="text-center text-sm text-text-secondary">{description}</p>
      </div>
    </div>
    <Button size="large" variant="default" onClick={handleContinue}>
      {buttonLabel}
    </Button>
  </AnimatedContent>
);
