import { marketplaceApiV2 } from "@/lib/fetch";
import {
  Currency,
  TimeFrames,
  CollectionData,
  CollectionList,
  DoginalListing,
} from "@/types";
import { handleError } from "@/utility";
import { FetchActivityData } from "@/types/watchlist.ts";
import { DOGINALS_LISTINGS_PAGINATION_LIMIT } from "@/constants.ts";

const API_ENDPOINT_ACTIVITY_LIST = "/doginals/list/activity";
const API_ENDPOINT_LIST = "/doginals/list";

const createLookupMap = <T extends keyof CollectionData>(
  data: CollectionList["collections"],
  key: T,
): Record<string, number> => {
  return data.reduce(
    (acc: Record<string, number>, item: CollectionData) => {
      if (typeof item[key] === "number") {
        acc[item.symbol] = item[key] as number;
      } else {
        acc[item.symbol] = 0;
      }
      return acc;
    },
    {} as Record<string, number>,
  );
};

let collectionOffsetCounter = 0;
export const fetchDoginalTokens = async ({
  action,
  offset,
  limit,
  history,
  sortOrder,
  sortParam,
  currency,
  dogePrice,
  collectionSymbol,
  filter,
  cachebreaker = false,
}: FetchActivityData): Promise<CollectionData[]> => {
  try {
    const params = {
      action,
      offset,
      limit,
      history: history ? history : TimeFrames.ALL,
      sortOrder,
      ...(sortParam ? { sortParam } : {}),
      ...(collectionSymbol
        ? { collectionSymbol }
        : filter
          ? { collectionSymbol: filter }
          : {}),
    };

    const fetchListByCollectionActivity = async (apiEndpoint: string) =>
      marketplaceApiV2(cachebreaker).get<CollectionList>(apiEndpoint, {
        params: { ...params },
      });

    const fetchListByCollection = async (
      apiEndpoint: string,
      adjustedOffset: number,
    ) =>
      marketplaceApiV2(cachebreaker).get<CollectionList>(apiEndpoint, {
        params: {
          ...params,
          offset: adjustedOffset,
          sortParam: "createdAt",
          hasActivityEntries: false,
        },
      });

    // First, attempt to fetch from fetchListByCollectionActivity
    const response = await fetchListByCollectionActivity(
      API_ENDPOINT_ACTIVITY_LIST,
    );
    let data = response.data.collections || [];

    // Check if fetchListByCollectionActivity returned insufficient data
    if (
      !response.data?.collections || // No data returned
      (response.data?.collections.length < DOGINALS_LISTINGS_PAGINATION_LIMIT &&
        limit === DOGINALS_LISTINGS_PAGINATION_LIMIT) // Insufficient data to meet pagination limit
    ) {
      // Calculate the remaining items needed
      const itemsShort = DOGINALS_LISTINGS_PAGINATION_LIMIT - data.length;

      // Use collectionOffsetCounter to continue where the last fetchListByCollection left off
      const adjustedOffset = collectionOffsetCounter;

      // Fetch additional items from fetchListByCollection using the independent offset counter
      const responseFallback = await fetchListByCollection(
        API_ENDPOINT_LIST,
        adjustedOffset,
      );

      if (
        responseFallback.data?.collections &&
        responseFallback.data?.collections.length > 0
      ) {
        data = data.concat(
          responseFallback.data.collections.slice(0, itemsShort),
        );
        // Update the independent offset counter by the number of items retrieved in this call
        collectionOffsetCounter += itemsShort;
      }
    }

    const changeMaps = {
      "24h": createLookupMap(data, "change24h"),
      "7d": createLookupMap(data, "change7d"),
      "30d": createLookupMap(data, "change30d"),
      all: createLookupMap(data, "change"),
    };

    const volumeMaps = {
      "24h": createLookupMap(data, "volume24h"),
      "7d": createLookupMap(data, "volume7d"),
      "30d": createLookupMap(data, "volume30d"),
      all: createLookupMap(data, "volume"),
    };

    const priceMaps = {
      "24h": createLookupMap(data, "floorPrice24h"),
      "7d": createLookupMap(data, "floorPrice7d"),
      "30d": createLookupMap(data, "floorPrice30d"),
      all: createLookupMap(data, "floorPrice"),
    };

    const salesMap = {
      "24h": createLookupMap(data, "sales24h"),
      "7d": createLookupMap(data, "sales7d"),
      "30d": createLookupMap(data, "sales30d"),
      all: createLookupMap(data, "sales"),
    };

    const mapItem = (item: CollectionData, index: number) => {
      const {
        collectionSymbol,
        name,
        floorPrice,
        holders,
        supply,
        marketplaceFeatured,
        dogepadCollection,
      } = item;

      const fdvInShibs = floorPrice ? (floorPrice || 0) * (supply || 0) : 0;

      return {
        ...item,
        ...{
          id: collectionSymbol,
          name: name,
          fdv: fdvInShibs,
          rank: index + 1 + (offset || 0),
          price: priceMaps["all"][collectionSymbol] || 0,
          price24h: priceMaps["24h"][collectionSymbol] || 0,
          price7d: priceMaps["7d"][collectionSymbol] || 0,
          price30d: priceMaps["30d"][collectionSymbol] || 0,
          change: changeMaps["all"][collectionSymbol] || 0,
          change24h: changeMaps["24h"][collectionSymbol] || 0,
          change7d: changeMaps["7d"][collectionSymbol] || 0,
          change30d: changeMaps["30d"][collectionSymbol] || 0,
          volume: volumeMaps["all"][collectionSymbol] || 0,
          volume24h: volumeMaps["24h"][collectionSymbol] || 0,
          volume7d: volumeMaps["7d"][collectionSymbol] || 0,
          volume30d: volumeMaps["30d"][collectionSymbol] || 0,
          sales: salesMap["all"][collectionSymbol] || 0,
          sales24h: salesMap["24h"][collectionSymbol] || 0,
          sales7d: salesMap["7d"][collectionSymbol] || 0,
          sales30d: salesMap["30d"][collectionSymbol] || 0,
          currentDogePrice: dogePrice,
          holders: holders || 0,
          currency: currency ?? Currency.USD,
          marketplaceFeatured: marketplaceFeatured as boolean,
          dogepadCollection: dogepadCollection as boolean,
          supply: supply || 0,
        },
      };
    };

    return data.map(mapItem);
  } catch (e: Error | unknown) {
    handleError(e);
    return [];
  }
};

export const fetchDoginalToken = async (
  id: string,
): Promise<DoginalListing> => {
  try {
    const response = await marketplaceApiV2(false).get<{
      doginal: DoginalListing;
    }>(`/doginals/${id}`);

    return response.data?.doginal;
  } catch (e: Error | unknown) {
    handleError(e);
    return {} as DoginalListing;
  }
};
