import { useCallback, useState } from "react";

import { evaluatePasswordStrength } from "@/utility";

interface PasswordConfig {
  passwordNames: string[];
}

interface UsePasswordReturn {
  passwords: { [key: string]: string };

  handleSetPassword: (passwordName: string, value: string) => void;
  handleClearPassword: (passwordName: string) => void;

  passwordSet: (passwordName: string) => boolean;
  passwordRulesMet: (passwordName: string) => boolean;
  passwordsMatch: (passwordName1: string, passwordName2: string) => boolean;
}

export const usePasswords = ({
  passwordNames,
}: PasswordConfig): UsePasswordReturn => {
  // Initialize passwords with empty strings
  const [passwords, setPasswords] = useState<{ [key: string]: string }>(() =>
    passwordNames.reduce<{ [key: string]: string }>((acc, passwordName) => {
      acc[passwordName] = "";
      return acc;
    }, {}),
  );

  // Set a password
  const handleSetPassword = useCallback(
    (passwordName: string, value: string) => {
      setPasswords((prev) => ({
        ...prev,
        [passwordName]: value,
      }));
    },
    [],
  );

  // Clear password by setting it to empty string
  const handleClearPassword = useCallback((passwordName: string) => {
    setPasswords((prev) => ({
      ...prev,
      [passwordName]: "",
    }));
  }, []);

  // Check if password is set
  const passwordSet = useCallback(
    (passwordName: string) => {
      return passwords[passwordName].trim() !== "";
    },
    [passwords],
  );

  // Check if password meets all rules
  const passwordRulesMet = useCallback(
    (passwordName: string) => {
      const password = passwords[passwordName];
      const rules = evaluatePasswordStrength(password).rules;
      return Object.values(rules).every(Boolean);
    },
    [passwords],
  );

  // Check if two passwords match
  const passwordsMatch = useCallback(
    (passwordName1: string, passwordName2: string) => {
      return passwords[passwordName1] === passwords[passwordName2];
    },
    [passwords],
  );

  return {
    passwords,

    handleSetPassword,
    handleClearPassword,

    passwordSet,
    passwordsMatch,
    passwordRulesMet,
  };
};
