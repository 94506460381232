import { useEffect, useMemo, useState } from "react";

import { useOperatingSystem } from "./useOperatingSystem";
import { useLocalStorage } from "@/hooks/useLocalStorage.ts";
import { PWA_LOCAL_STORAGE_KEY } from "@/constants.ts";

export const useShowInstallMessage = () => {
  const {
    value: pwaForcedValue,
    updateValue: updatePwaForcedValue,
    deleteEntry,
  } = useLocalStorage<string | null>(PWA_LOCAL_STORAGE_KEY, null, false);

  const { isDesktop, isMobile, isTablet } = useOperatingSystem();
  const [showInstallMessage, setShowInstallMessage] = useState(false);

  // already installed as PWA
  const isInStandaloneMode = useMemo(
    () =>
      window.matchMedia("(display-mode: standalone)").matches ||
      ("standalone" in window.navigator && window.navigator.standalone),
    [],
  );

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get(PWA_LOCAL_STORAGE_KEY) === "1") {
      if (!pwaForcedValue) {
        updatePwaForcedValue(() => {
          return "1";
        });
      }
    } else {
      deleteEntry(PWA_LOCAL_STORAGE_KEY);
    }
  }, [deleteEntry, pwaForcedValue, updatePwaForcedValue]);

  useEffect(() => {
    if (isDesktop) return;

    const isInStandaloneOnIos = (isTablet || isMobile) && isInStandaloneMode;

    if (!isInStandaloneOnIos && !pwaForcedValue) {
      setShowInstallMessage(true);
    }
  }, [isMobile, isDesktop, isInStandaloneMode, isTablet, pwaForcedValue]);

  return showInstallMessage;
};
