import React from "react";

import { Button, CheckboxField } from "@/components";

import { AnimatedContent } from "../../base";

interface RecoveryPhraseViewProps {
  mnemonic: string | undefined;
  onContinue: () => void;
  onCopyRecoveryPhrase: () => void;
  savedRecoveryPhrase: boolean;
  acknowledgeWarning: boolean;
  toggleSavedRecoveryPhrase: () => void;
  toggleAcknowledgeWarning: () => void;
}

export const RecoveryPhraseView: React.FC<RecoveryPhraseViewProps> = ({
  mnemonic,
  onContinue,
  onCopyRecoveryPhrase,
  savedRecoveryPhrase,
  acknowledgeWarning,
  toggleSavedRecoveryPhrase,
  toggleAcknowledgeWarning,
}) => (
  <AnimatedContent id="onboarding_create_2">
    {/** Header */}
    <div className="flex flex-col justify-center space-y-2">
      <h2 className="text-center text-xl font-medium">Secret Recover Phrase</h2>
      <p className="text-center text-xs text-primary-400">
        This phrase is the ONLY way to recover your wallet. Do NOT share it with
        anyone!
      </p>
    </div>

    {/** Recovery Phrase */}
    <div className="flex flex-1 flex-col justify-center space-y-1">
      <div className="grid grid-cols-3 gap-1">
        {mnemonic?.split(" ").map((word, index) => (
          <div
            key={index}
            className="flex flex-row space-x-2 rounded-lg border-0.5 border-border-secondary bg-background-primary px-4 py-2 text-xs"
          >
            <span>{index + 1}.</span>
            <span>{word}</span>
          </div>
        ))}
      </div>
      <Button
        size="small"
        variant="ghost"
        onClick={onCopyRecoveryPhrase}
        disabled={!mnemonic}
      >
        <span>Copy Recovery Phrase</span>
      </Button>
    </div>

    {/** Actions */}
    <div className="flex flex-col space-y-2">
      <CheckboxField
        id="savedRecoveryPhrase"
        text="I have saved my recovery phrase."
        value={savedRecoveryPhrase}
        onToggleChange={toggleSavedRecoveryPhrase}
      />
      <CheckboxField
        id="acknowledgeWarning"
        text="I will use this phrase in compatible wallets only."
        value={acknowledgeWarning}
        onToggleChange={toggleAcknowledgeWarning}
      />
      <Button
        size="large"
        variant="default"
        onClick={onContinue}
        disabled={!savedRecoveryPhrase || !acknowledgeWarning}
      >
        <span>Continue</span>
      </Button>
    </div>
  </AnimatedContent>
);
