import {
  ONE_DOGE_IN_SHIBES,
  VOLUME_LIMIT_TO_CONSIDER_ITEM_IN_DOGE,
} from "@/constants.ts";
// This function MUST be the same as the one in the backend. Also the constants used in this function must be the same as the ones in the backend.
export const filterTokenByVolumeRule = (volume: number): boolean => {
  if (volume < VOLUME_LIMIT_TO_CONSIDER_ITEM_IN_DOGE * ONE_DOGE_IN_SHIBES) {
    return true;
  }

  return false;
};
