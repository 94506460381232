import { TableCell, TableRow } from "@/components/ui/table";

interface EmptyStateProps {
  colSpan: number;
}

export const EmptyState: React.FC<EmptyStateProps> = ({ colSpan }) => {
  return (
    <TableRow>
      <TableCell
        colSpan={colSpan}
        className="h-24 text-center text-text-primary"
      >
        No data available …
      </TableCell>
    </TableRow>
  );
};
