import React from "react";

import { cn } from "@/lib/utils";

interface TextCellProps {
  value: string;
  className?: string;
  onClick?: () => void;
}

export const TextCell: React.FC<TextCellProps> = ({
  value,
  className,
  onClick,
}) => {
  return (
    <div
      className={cn(
        "text-right text-sm text-text-primary lg:text-md",
        className,
      )}
      onClick={onClick}
    >
      {value}
    </div>
  );
};
