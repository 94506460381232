import { useCallback, useState } from "react";

import { sdoggsApiV2 } from "@/lib/fetch";
import { SortOrder, TimeFrames } from "@/types";
import {
  ActivityDune,
  ActivitySortParam,
  ActivityType,
} from "@/types/activity";
import { handleError } from "@/utility";
import { FEATURE_ACTIVATION_DUNES } from "@/constants";

type DuneActivitiesRequestParams = {
  tick?: string;
  offset: number;
  limit?: number;
  action?: ActivityType;
  sortOrder?: SortOrder;
  sortParam?: ActivitySortParam;
  history?: TimeFrames;
  address?: string;
};

type FetchDuneActivitiesHookReturn = {
  duneActivities: ActivityDune[];
  isDuneActivitiesLoading: boolean;
  getDuneActivities: () => Promise<void>;
};

const fetchDuneActivities = async (params: DuneActivitiesRequestParams) => {
  if (FEATURE_ACTIVATION_DUNES !== "true") {
    return [];
  }

  const {
    tick,
    offset,
    limit,
    action,
    sortOrder,
    sortParam,
    history,
    address,
  } = params;
  const { data } = await sdoggsApiV2(false).get("/offer/dunes/activity", {
    params: {
      tick,
      offset,
      limit,
      action,
      sortOrder,
      sortParam,
      history,
      address,
      t: new Date().getTime(),
    },
  });

  if (!data?.activities || !data.activities.length) {
    return [];
  }

  return data.activities;
};

const useFetchDuneActivities = ({
  tick,
  offset,
  limit = 20,
  action,
  sortOrder,
  sortParam,
  history,
  address,
}: DuneActivitiesRequestParams): FetchDuneActivitiesHookReturn => {
  const [isDuneActivitiesLoading, setIsDuneActivitiesLoading] = useState(false);
  const [duneActivities, setDuneActivities] = useState<ActivityDune[]>([]);

  const getDuneActivities = useCallback(async () => {
    if (FEATURE_ACTIVATION_DUNES !== "true") {
      return;
    }

    try {
      setIsDuneActivitiesLoading(true);
      const res = await fetchDuneActivities({
        tick,
        offset,
        limit,
        action,
        sortOrder,
        sortParam,
        history,
        address,
      });

      setDuneActivities(res);
    } catch (e: Error | unknown) {
      handleError(e);
      setDuneActivities([]);
      // we are throwing again to deal with error in UI further up the dependency chain
      throw new Error("Failed to fetch Dune activity");
    } finally {
      setIsDuneActivitiesLoading(false);
    }
  }, [tick, offset, limit, action, sortOrder, sortParam, history, address]);

  return { duneActivities, isDuneActivitiesLoading, getDuneActivities };
};

export { useFetchDuneActivities, fetchDuneActivities };
