import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  AppNavigation,
  AppScreen,
  Appear,
  Button,
  ButtonBar,
  ListingCardCollectible,
  Skeleton,
} from "@/components";
import { formatValueForChange } from "@/components/table/formatValueForChange";
import { ONE_DOGE_IN_SHIBES, PAGINATION_LIMIT } from "@/constants.ts";
import { useBalance, useCurrency, useDogePrice } from "@/contextHooks";
import { useCollectiblesDetails } from "@/contextHooks/useCollectiblesDetails.tsx";
import {
  FILTER_TO_CONSIDER_FOR_ACTIVE_FILTERS,
  SORT_DEFAULT,
  useCurrentAccount,
  useRemovePendingCollectibles,
} from "@/hooks";
import { NumberFormatType, formatNumber } from "@/lib/numbers.ts";
import { cn } from "@/lib/utils";
import {
  ModalCollectionFilter,
  ModalCollectionSort,
  ModalDelistCollectible,
  ModalListCollectible,
  ModalSendCollectible,
} from "@/modals";
import {
  CollectionListingProps,
  Currency,
  FilterBetween,
  FilterListingsCollection,
  FilterMetadata,
  ListingStatus,
  SortListingsCollection,
  SortOrder,
  SortParamListingsCollection,
} from "@/types";
import {
  calculateFloorDifference,
  createExtendedCollectibleListings,
  formatRange,
  getBackgroundColorForChange,
  getColorForChange,
  isValidRange,
} from "@/utility";
import { PendingCollectibleInscription } from "@/types";
import { useCollectiblePendingItems } from "@/contextHooks/useCollectiblePendingItems";
import { fetchPendingCollectiblesBalance } from "@/hooks/datafetching/useFetchDoginals.ts";

enum ModalType {
  Filter = "filter",
  Sort = "sort",
  Buy = "buy",
  Send = "send",
  List = "list",
  Delist = "delist",
}

enum DisplayType {
  Grid = "grid",
  GridSmall = "gridSmall",
  List = "list",
}

const FILTER_DEFAULT: FilterListingsCollection & {
  listingStatus?: ListingStatus;
} = {
  listingStatus: undefined,
  offset: 0,
  limit: PAGINATION_LIMIT,
  priceMin: undefined,
  priceMax: undefined,
  metadata: [],
  listed: undefined,
};

export const AccountCollectionDetail: React.FC = () => {
  const { currency } = useCurrency();
  const navigate = useNavigate();
  const { collectionSymbol } = useParams();
  const { address } = useCurrentAccount();
  const { dogePrice } = useDogePrice();

  const [modalType, setModalType] = useState<ModalType | undefined>();
  const [display, setDisplay] = useState<DisplayType>(DisplayType.List);
  const [selectedListings, setSelectedListings] = useState<string[]>([]);
  const [waitingForPendingStatusReceived, setWaitingForPendingStatusReceived] =
    useState<boolean>(false);

  const { collectibles, refetchCollectibles } = useBalance();
  // TODO: "collectibleListings"naming is misleading. This is just doginals, but they have a status whether it is listed or not
  const { collectibleListings, setCollectionSymbol, setAddress } =
    useCollectiblesDetails();
  const {
    isLoading,
    setFilters,
    setSort,
    sort,
    filters,
    reset,
    data: collectibleList,
    floorPrice,
    refetch,
  } = collectibleListings;

  const accountCollectionSort = useMemo(() => {
    return {
      sortParam:
        sort.sortParam == "price"
          ? SortParamListingsCollection.ListingPrice
          : sort.sortParam,
      sortOrder: sort.sortOrder ? sort.sortOrder : SortOrder.Ascending,
    };
  }, [sort]);

  const { hasActiveFilters, hasActiveSorting } = useMemo(() => {
    const otherFilters = { ...FILTER_DEFAULT, ...filters };

    return {
      hasActiveFilters: !Object.keys(otherFilters).every((key) => {
        // Skip this field from being considered active
        if (!FILTER_TO_CONSIDER_FOR_ACTIVE_FILTERS.includes(key)) {
          return true;
        }

        // @ts-expect-error internal type is wrong
        const value = otherFilters[key]; // Access the value even if it's undefined

        // @ts-expect-error internal type is wrong
        return FILTER_DEFAULT[key] === value || value === undefined;
      }),

      hasActiveSorting: !Object.entries(sort).every(([key, value]) => {
        // @ts-expect-error internal type is wrong
        return SORT_DEFAULT[key] === value;
      }),
    };
  }, [filters, sort]);

  const { addPendingInscriptions } = useCollectiblePendingItems();
  const { pendingInscriptions } = useRemovePendingCollectibles(
    collectibleList,
    collectionSymbol,
  );

  const {
    changeValue,
    colorPercentage,
    backgroundColorPercentage,
    collectionName,
    value,
  } = useMemo(() => {
    const currentCollection = collectibles.find(
      (collectible) => collectible.collectionSymbol === collectionSymbol,
    );
    if (!currentCollection) {
      return {
        collectionName: "No collection found",
        changeValue: formatValueForChange(0),
        colorPercentage: getColorForChange(0),
        backgroundColorPercentage: getBackgroundColorForChange(0),
      };
    }

    const floorDifferencePercentage = calculateFloorDifference(
      currentCollection.changeDoge * ONE_DOGE_IN_SHIBES,
      floorPrice || 0,
    );

    const changeValue =
      currency === Currency.USD
        ? formatNumber({
            value: currentCollection.changeDollar,
            type: NumberFormatType.Price,
            decimalPlaces: 2,
          })
        : formatNumber({
            value: currentCollection.changeDoge,
            type: NumberFormatType.Price,
            decimalPlaces: 2,
          });

    // const changeValue = formatValueForChange(floorDifferencePercentage);
    const colorPercentage = getColorForChange(floorDifferencePercentage);
    const backgroundColorPercentage = getBackgroundColorForChange(
      floorDifferencePercentage,
    );

    const value =
      currency === Currency.USD
        ? `${Currency.USD}${formatNumber({ value: currentCollection.valueDollar, type: NumberFormatType.Price, decimalPlaces: 2 })}`
        : `${Currency.DOGE}${formatNumber({ value: currentCollection.valueDoge, type: NumberFormatType.Price, decimalPlaces: 2 })}`;

    return {
      collectionName: currentCollection.collectionName,
      changeValue,
      colorPercentage,
      backgroundColorPercentage,
      value,
    };
  }, [collectibles, collectionSymbol, currency, floorPrice]);

  /**
   * Collectible Listings
   */
  const updateListings = useCallback((incriptionId: string) => {
    setSelectedListings((prev) => {
      const isSelected = prev.includes(incriptionId);
      return isSelected
        ? prev.filter((selected) => selected !== incriptionId)
        : [...prev, incriptionId];
    });
  }, []);

  const onSelectListing = useCallback(
    async (inscriptionId: string) => {
      updateListings(inscriptionId);
    },
    [updateListings],
  );

  const openCollectiblePage = useCallback(() => {
    collectionSymbol && navigate(`/collectible/${collectionSymbol}`);
  }, [navigate, collectionSymbol]);

  /**
   * Actions: Modals
   */

  const onOpenFilterModal = useCallback(() => {
    setModalType(ModalType.Filter);
  }, []);

  const onOpenSortModal = useCallback(() => {
    setModalType(ModalType.Sort);
  }, []);

  const onOpenSendModal = useCallback(() => {
    setModalType(ModalType.Send);
  }, []);

  const onOpenDelistModal = useCallback(() => {
    setModalType(ModalType.Delist);
  }, []);

  const onOpenListModal = useCallback(() => {
    setModalType(ModalType.List);
  }, []);

  const onCloseModal = useCallback(() => {
    setModalType(undefined);
  }, []);

  const onNavigateToCollectionDetail = useCallback(
    (id: string) => {
      if (!collectionSymbol) return;
      navigate(`/collectible/${collectionSymbol.toLowerCase()}/${id}`, {
        replace: false,
      });
    },
    [navigate, collectionSymbol],
  );

  /**
   * Actions: Listings
   */

  // Toggle between 3 display modes
  const onToggleDisplay = useCallback(() => {
    setDisplay((prev) => {
      switch (prev) {
        case DisplayType.Grid:
          return DisplayType.GridSmall;
        case DisplayType.GridSmall:
          return DisplayType.List;
        case DisplayType.List:
          return DisplayType.Grid;
        default:
          return DisplayType.Grid;
      }
    });
  }, []);

  // Refresh listings & reset filters
  // @todo: add pagination
  const onRefresh = useCallback(async () => {
    if (!collectionSymbol || !address) return;
    reset(
      {
        ...FILTER_DEFAULT,
        ...{
          collectionSymbol,
          seller: address,
        },
      },
      undefined,
      true, // use a cachebreaker in this case
    );
  }, [address, collectionSymbol, reset]);

  const onSuccess = useCallback(
    //@todo: pendingInscriptionIds are used for delisting only. Listing uses pending status in doginalOffer attached
    // to the doginal listing and is reset by the scheduler after 5 minutes.
    async (pendingInscriptionIds: PendingCollectibleInscription[]) => {
      // reset filters, reset selected items, refetch data without cache
      // actionedInscriptionIds is the list of inscriptionIds that were successfully either delisted or sent
      // we save them in localstorage to display a pending state message
      setSelectedListings([]);

      if (pendingInscriptionIds.length > 0) {
        addPendingInscriptions(pendingInscriptionIds, collectionSymbol);
      }

      if (collectibleListings.isLoading) return;
      if (collectionSymbol) {
        await refetch(collectionSymbol);
      }
    },
    [
      collectibleListings.isLoading,
      refetch,
      addPendingInscriptions,
      collectionSymbol,
    ],
  );

  // For sending collectibles, we need don't need the localstorage based pending solution as we already
  // have pending states more precisely through the mempool requests used in the api
  const onSuccessSent = useCallback(
    async (inscriptionIds: string[]) => {
      setSelectedListings([]);
      setWaitingForPendingStatusReceived(true);

      // the server waits for the balances to update
      const pendingCollectiblesBalanceResponse =
        await fetchPendingCollectiblesBalance(
          address as string,
          inscriptionIds,
        );
      console.log(
        "pendingCollectiblesBalanceResponse: ",
        pendingCollectiblesBalanceResponse,
      );

      setTimeout(() => {
        // Refetches the detail collection page collectible items
        if (collectionSymbol) {
          refetch(collectionSymbol);
        }
        refetchCollectibles();
        setWaitingForPendingStatusReceived(false);
      }, 5000);
    },
    [address, collectionSymbol, refetch, refetchCollectibles],
  );

  /**
   * Actions: Filters/Sorting/Search
   */

  // Apply filters
  const onApplyFilter = useCallback(
    (filters: {
      listingStatus?: ListingStatus;
      metadata?: FilterMetadata[];
      price?: FilterBetween;
    }) => {
      const updatedPriceRange = isValidRange(
        filters?.price?.min,
        filters?.price?.max,
      )
        ? formatRange(filters?.price?.min, filters?.price?.max)
        : { min: undefined, max: undefined };

      setFilters((prev: FilterListingsCollection) => ({
        ...prev,
        ...filters,
        priceMin: updatedPriceRange.min,
        priceMax: updatedPriceRange.max,
        seller: address,
        listed:
          filters.listingStatus === ListingStatus.Listed
            ? true
            : filters.listingStatus === ListingStatus.Unlisted
              ? false
              : undefined,
      }));

      onCloseModal();
    },
    [address, onCloseModal, setFilters],
  );

  // Apply sorting
  const onApplySort = useCallback(
    (sort: SortListingsCollection | undefined) => {
      setSort(sort);
      onCloseModal();
    },
    [onCloseModal, setSort],
  );

  // Display mode icon
  const displayIcon = useMemo(() => {
    // Display icon based on next display mode
    switch (display) {
      case DisplayType.Grid:
        return "transition_dissolve";
      case DisplayType.GridSmall:
        return "reorder";
      case DisplayType.List:
        return "apps";
      default:
        return "apps";
    }
  }, [display]);

  /**
   * Data Transformations
   */
  const extendedCollectibleList = useMemo<CollectionListingProps[]>(() => {
    if (!collectibleList || collectibleList.length === 0) return [];

    return createExtendedCollectibleListings({
      listings: collectibleList,
      selectedListings,
      pendingInscriptions,
      floorPrice,
      onSelectListing,
      currency,
      dogePrice,
      identifier: "inscriptionId",
      checkIfUserIsSeller: (addr: string) => address === addr,
    });
  }, [
    collectibleList,
    selectedListings,
    pendingInscriptions,
    floorPrice,
    onSelectListing,
    currency,
    dogePrice,
    address,
  ]);

  /**
   * Action Bar
   */

  const { selectedList, selectedDelist, selectedSend } = useMemo(() => {
    const selectedList =
      extendedCollectibleList
        .filter((listing) => !listing.listed)
        .filter((listing) =>
          selectedListings.includes(listing.inscriptionId),
        ) || [];

    const selectedDelist =
      extendedCollectibleList
        .filter((listing) => listing.listed)
        .filter((listing) =>
          selectedListings.includes(listing.inscriptionId),
        ) || [];

    const selectedSend =
      extendedCollectibleList.filter((listing) =>
        selectedListings.includes(listing.inscriptionId),
      ) || [];

    return {
      selectedList,
      selectedDelist,
      selectedSend,
    };
  }, [extendedCollectibleList, selectedListings]);

  const isActionBarVisible = useMemo(() => {
    return (
      selectedList.length + selectedDelist.length + selectedSend.length > 0
    );
  }, [selectedDelist, selectedList, selectedSend]);

  const buttonsConfig = useMemo(() => {
    return [
      {
        icon: "filter_list",
        onClick: onOpenFilterModal,
        isActive: hasActiveFilters,
      },
      {
        icon: displayIcon,
        onClick: onToggleDisplay,
        isActive: display !== "list",
      },
      {
        icon: "swap_vert",
        onClick: onOpenSortModal,
        isActive: hasActiveSorting,
      },
      { icon: "refresh", onClick: onRefresh },
    ];
  }, [
    display,
    displayIcon,
    hasActiveFilters,
    hasActiveSorting,
    onOpenFilterModal,
    onOpenSortModal,
    onRefresh,
    onToggleDisplay,
  ]);

  /**
   * Effects
   */

  //@todo: don't add filters as dependency as it will cause an infinite loop
  useEffect(() => {
    if (!collectionSymbol || !address) return;
    setCollectionSymbol(collectionSymbol);
    setAddress(address);
    setFilters((prev) => ({
      ...prev,
      // ...filters,
      seller: address,
      collectionSymbol,
      listed: undefined,
    }));
  }, [collectionSymbol, address, setCollectionSymbol, setAddress, setFilters]);

  useEffect(() => {
    onRefresh();
  }, [onRefresh]);

  const intervalId = useRef<null | NodeJS.Timeout>(null);
  useEffect(() => {
    if (intervalId.current) {
      clearInterval(intervalId.current);
    }
    // Set up the interval to refetch data every 30 seconds if there are pending inscriptions
    intervalId.current = setInterval(() => {
      if (pendingInscriptions.length > 0 && collectionSymbol) {
        refetch(collectionSymbol);
      }
    }, 30000);

    // Cleanup on unmount
    return () => clearInterval(intervalId.current as NodeJS.Timeout);
  }, [collectionSymbol, pendingInscriptions, refetch]);

  return (
    <>
      <AppScreen withNavigationHeader>
        <AppNavigation
          title={collectionName}
          withAppHeader
          onBack={() => navigate(-1)}
          className="lg:hidden"
        />

        {/** Collection Value */}
        <div className="flex flex-col items-center justify-center space-y-2 bg-background-primary pb-4">
          {/** Header */}
          <div className="hidden w-full max-w-7xl flex-row items-center justify-between pt-1 lg:flex">
            <Button
              variant="icon"
              shape="circle"
              onClick={() => navigate(-1)}
              className="h-8 w-8 text-text-tertiary"
            >
              <span className="material-symbols-rounded text-lg">
                arrow_back
              </span>
            </Button>
            <Button
              variant="icon"
              shape="circle"
              onClick={openCollectiblePage}
              className="h-8 w-8 text-text-tertiary"
            >
              <span className="material-symbols-rounded text-lg">
                open_in_new
              </span>
            </Button>
          </div>

          {isLoading || !value ? (
            <Skeleton className="h-12 w-24 rounded-lg pt-2 font-bold text-text-primary" />
          ) : (
            <span
              className={"pt-2 text-4xl font-bold text-text-primary"}
              dangerouslySetInnerHTML={{
                __html: value || "0",
              }}
            />
          )}
          {!!changeValue && changeValue !== "0" && (
            <div
              className={cn(
                "flex min-w-12 items-center justify-center rounded-full px-1 py-0.5",
                backgroundColorPercentage,
              )}
            >
              <span
                className={cn("text-xs font-bold", colorPercentage)}
                dangerouslySetInnerHTML={{
                  __html: `${changeValue}`,
                }}
              />
            </div>
          )}
        </div>

        {/** Collectibles */}
        <div
          className={cn(
            "flex max-w-7xl flex-1 flex-col bg-background-secondary p-4",
            "xl:mx-auto xl:mt-8 xl:w-full xl:flex-none xl:rounded-2xl xl:border-0.5 xl:border-border-secondary",
          )}
        >
          {/** Actions */}
          <div className="w-full px-4 md:px-0">
            <ButtonBar buttons={buttonsConfig} className="max-w-sm" />
          </div>

          {display && (
            <div
              className={cn(
                display === DisplayType.List
                  ? "mt-4 grid min-h-24 w-full grid-cols-1 gap-3"
                  : "mt-4 grid grid-cols-2 gap-3 ",
                display === DisplayType.List
                  ? "sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 3xl:grid-cols-4"
                  : "sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-7",
              )}
            >
              {(isLoading ||
                waitingForPendingStatusReceived ||
                (!isLoading && !extendedCollectibleList.length)) &&
                CardListSkeleton[display]}
              {!isLoading &&
                !waitingForPendingStatusReceived &&
                extendedCollectibleList.map((listing) => {
                  return (
                    <ListingCardCollectible
                      seller={listing.address}
                      image={listing.imageURI}
                      title={listing.name}
                      floorDifferencePercentage={
                        listing.floorDifferencePercentage == -1
                          ? 0
                          : listing.floorDifferencePercentage
                      }
                      listed={listing.listed}
                      status={listing.status}
                      selected={listing.isSelected}
                      price={
                        listing.price ? listing.priceForCurrency || "" : ""
                      }
                      className={
                        display === DisplayType.List
                          ? "w-full flex-shrink-0 bg-background-primary"
                          : "w-full bg-background-primary"
                      }
                      displayPendingState={
                        listing.status === ListingStatus.Pending ||
                        listing.isPending
                      }
                      isReceivingPending={listing.isReceivingPending}
                      isSendingPending={listing.isSendingPending}
                      onClick={() =>
                        onNavigateToCollectionDetail(listing.inscriptionId)
                      }
                      // onBuy={listing.onBuy}
                      onSelect={listing.onAction}
                      key={listing.name}
                      variant={display}
                      displayBuyButton={false}
                      displayStatusBadge
                      isAccountPage
                      isSelectable={true}
                    />
                  );
                })}
            </div>
          )}
        </div>
      </AppScreen>
      <Appear from="bottom" isVisible={isActionBarVisible}>
        <div className="mx-auto flex w-full max-w-md flex-row space-x-3 px-4">
          <div className="relative flex flex-1">
            <Button
              className="flex-1"
              variant="inverse"
              size="large"
              disabled={selectedList.length === 0}
              onClick={onOpenListModal}
            >
              List
            </Button>
            {selectedList.length > 0 && (
              <ButtonBadge
                value={selectedList.length}
                className="absolute -bottom-1 -right-1"
              />
            )}
          </div>
          <div className="relative flex flex-1">
            <Button
              className="flex-1"
              variant="inverse"
              size="large"
              disabled={selectedDelist.length === 0}
              onClick={onOpenDelistModal}
            >
              <div className="flex flex-1 flex-col items-center justify-center -space-y-1">
                Delist
              </div>
            </Button>
            {selectedDelist.length > 0 && (
              <ButtonBadge
                value={selectedDelist.length}
                className="absolute -bottom-1 -right-1"
              />
            )}
          </div>
          <div className="relative">
            <Button
              variant="default"
              className="h-12 w-12"
              disabled={selectedSend.length === 0}
              onClick={onOpenSendModal}
            >
              <span className="material-symbols-rounded text-lg">send</span>
            </Button>
            {selectedSend.length > 0 && (
              <ButtonBadge
                value={selectedSend.length}
                className="absolute -bottom-1 -right-1"
              />
            )}
          </div>
        </div>
      </Appear>

      {modalType === ModalType.Filter && (
        <ModalCollectionFilter
          title="Filter"
          onClose={onCloseModal}
          onApply={onApplyFilter}
          metadata={filters.metadata}
          collectionSymbol={collectionSymbol}
          isVisible={modalType === ModalType.Filter}
          listingStatus={
            filters.listed != undefined
              ? filters.listed
                ? ListingStatus.Listed
                : ListingStatus.Unlisted
              : undefined
          }
        />
      )}

      {modalType === ModalType.Sort && (
        <ModalCollectionSort
          title="Sort"
          onClose={onCloseModal}
          onApply={onApplySort}
          sort={accountCollectionSort}
          sortBy={[SortParamListingsCollection.ListingPrice]}
          isVisible={modalType === ModalType.Sort}
        />
      )}

      {modalType === ModalType.Send && (
        <ModalSendCollectible
          onClose={onCloseModal}
          title="Send"
          isVisible={modalType === ModalType.Send}
          sendings={selectedSend}
          onSuccess={onSuccessSent}
        />
      )}

      {modalType === ModalType.Delist && (
        <ModalDelistCollectible
          onClose={onCloseModal}
          title="Delist"
          isVisible={modalType === ModalType.Delist}
          delistings={selectedDelist}
          onSuccess={onSuccess}
        />
      )}

      {modalType === ModalType.List && (
        <ModalListCollectible
          onClose={onCloseModal}
          title="List"
          isVisible={modalType === ModalType.List}
          listings={selectedList}
          onSuccess={onSuccess}
        />
      )}
    </>
  );
};

interface ButtonBadgeProps {
  value: number;
  className?: string;
}

const ButtonBadge: React.FC<ButtonBadgeProps> = ({ value, className }) => (
  <div
    className={cn(
      "flex h-6 w-6 items-center justify-center rounded-full border-2 border-background-secondary bg-primary-600 text-xs font-bold text-text-primary",
      className,
    )}
  >
    {value}
  </div>
);

const SkeletonListingCardList: React.FC = () => (
  <>
    <Skeleton className="h-24 w-full flex-1 rounded-lg bg-background-tertiary/50" />
    <Skeleton className="h-24 w-full flex-1 rounded-lg bg-background-tertiary/50" />
    <Skeleton className="h-24 w-full flex-1 rounded-lg bg-background-tertiary/50" />
  </>
);

const SkeletonListingCardCollectibleGridItem: React.FC = () => (
  <>
    <Skeleton className="h-full w-full flex-1 rounded-lg bg-background-tertiary/50" />
    <Skeleton className="h-full w-full flex-1 rounded-lg bg-background-tertiary/50" />
    <Skeleton className="h-full w-full flex-1 rounded-lg bg-background-tertiary/50" />
  </>
);

const CardListSkeleton: Record<string, React.ReactElement> = {
  grid: <SkeletonListingCardCollectibleGridItem />,
  gridSmall: <SkeletonListingCardCollectibleGridItem />,
  list: <SkeletonListingCardList />,
};
