import { AnimatePresence, Transition, Variants, motion } from "framer-motion";
import React from "react";

import { Input } from "@/components";
import { useAppSearch } from "@/hooks";
import { cn } from "@/lib/utils";

import { SearchCloseButton } from "./searchCloseButton";
import { SearchLoadingIndicator } from "./searchLoadingIndicator";
import { SuggestionAddress } from "./suggestionAddress";
import { SuggestionDRC20 } from "./suggestionDRC20";
import { SuggestionDune } from "./suggestionDune";
import { SuggestionDoginal } from "./suggestionsDoginal";

interface SearchDesktopProps {
  className?: string;
}

const variants: Variants = {
  open: { opacity: 1 },
  closed: { opacity: 0 },
};

const transition: Transition = {
  duration: 0.1,
};

export const SearchDesktop: React.FC<SearchDesktopProps> = ({ className }) => {
  const {
    isFocused,
    searchValue,
    showResults,
    showSuggestions,
    loading,
    addressSuggestion,
    inscriptionSuggestion,
    drc20Suggestions,
    doginalSuggestions,
    dunesSuggestions,
    handleSetSearchValue,
    handleClearSearch,
    onSelectSuggestion,
    handleOnSubmit,
    handleKeyDown,
    setIsFocused,
  } = useAppSearch(() => console.info("Fire onSelect in SearchDesktop"));
  return (
    <motion.div className="relative flex h-full flex-1 flex-col items-end space-y-2">
      <div className="relative flex h-full w-1/2 flex-col items-end">
        {/** Search Bar */}
        <motion.div
          className={cn(
            "flex cursor-pointer flex-row items-center space-x-2 rounded-lg pl-3 pr-2 hover:bg-background-tertiary",
            isFocused ? "bg-background-tertiary" : "bg-background-secondary",
            showResults || isFocused ? "w-full" : "w-fit",
            className,
          )}
          layout
          transition={transition}
        >
          <motion.div layout transition={transition}>
            <SearchLoadingIndicator loading={loading} />
          </motion.div>

          <motion.div layout className="flex flex-1" transition={transition}>
            <Input
              placeholder="Search"
              className="flex-1 bg-transparent px-0 py-0 focus:bg-transparent"
              value={searchValue}
              onSubmit={handleOnSubmit}
              onKeyDown={handleKeyDown}
              onChange={handleSetSearchValue}
              onBlur={() => setIsFocused(false)}
              onFocus={() => setIsFocused(true)}
            />
          </motion.div>

          <motion.div layout transition={transition}>
            <SearchCloseButton
              isVisible={searchValue !== ""}
              onClick={handleClearSearch}
            />
          </motion.div>
        </motion.div>

        {/** Search Results */}
        <AnimatePresence>
          {showSuggestions && (
            <motion.div
              key="search-bar"
              initial="closed"
              animate="open"
              exit="closed"
              layout
              variants={variants}
              transition={transition}
              className="absolute left-0 right-0 top-12 h-fit max-h-[calc(100vh-250px)] min-h-96 overflow-scroll rounded-lg bg-background-secondary p-4 text-text-primary drop-shadow-xl"
            >
              {!showResults && (
                <motion.div
                  initial="closed"
                  animate="open"
                  exit="closed"
                  layout
                  variants={variants}
                  transition={transition}
                  className="flex h-56 flex-1 flex-col items-center justify-center space-y-10"
                >
                  <span className="relative flex h-16 w-16">
                    <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-background-tertiary opacity-25" />
                    <span className="relative inline-flex h-full w-full items-center justify-center rounded-full bg-background-tertiary">
                      <span className="material-symbols-rounded text-4xl text-text-primary">
                        search
                      </span>
                    </span>
                  </span>

                  <h2 className="text-center text-lg font-medium">
                    No Results Found
                  </h2>
                </motion.div>
              )}

              {addressSuggestion && (
                <motion.div layout transition={transition}>
                  <SuggestionAddress
                    label="Address"
                    value={addressSuggestion.address}
                    onSelect={() => onSelectSuggestion(addressSuggestion)}
                  />
                </motion.div>
              )}

              {inscriptionSuggestion && (
                <motion.div layout transition={transition}>
                  <SuggestionAddress
                    label="Inscription"
                    value={inscriptionSuggestion.inscription}
                    onSelect={() => onSelectSuggestion(inscriptionSuggestion)}
                  />
                </motion.div>
              )}

              {drc20Suggestions && drc20Suggestions.length > 0 && (
                <motion.div
                  className="flex flex-col space-y-1"
                  layout
                  transition={transition}
                >
                  <span className="font-bold uppercase text-text-primary">
                    Drc-20s
                  </span>
                  {drc20Suggestions.map((suggestion) => (
                    <motion.div
                      key={suggestion.tick}
                      layout
                      transition={transition}
                    >
                      <SuggestionDRC20
                        {...suggestion}
                        onSelect={() => onSelectSuggestion(suggestion)}
                      />
                    </motion.div>
                  ))}
                </motion.div>
              )}

              {doginalSuggestions && doginalSuggestions.length > 0 && (
                <motion.div
                  className="flex flex-col space-y-1 pt-4"
                  layout
                  transition={transition}
                >
                  <span className="font-bold uppercase text-text-primary">
                    Collections
                  </span>
                  {doginalSuggestions.map((suggestion) => (
                    <motion.div
                      key={suggestion.tick}
                      layout
                      transition={transition}
                    >
                      <SuggestionDoginal
                        {...suggestion}
                        onSelect={() => onSelectSuggestion(suggestion)}
                      />
                    </motion.div>
                  ))}
                </motion.div>
              )}

              {dunesSuggestions && dunesSuggestions.length > 0 && (
                <motion.div
                  className="flex flex-col space-y-1 pt-4"
                  layout
                  transition={transition}
                >
                  <span className="font-bold uppercase text-text-primary">
                    Dunes
                  </span>
                  {dunesSuggestions.map((suggestion) => (
                    <motion.div
                      key={suggestion.tick}
                      layout
                      transition={transition}
                    >
                      <SuggestionDune
                        {...suggestion}
                        onSelect={() => onSelectSuggestion(suggestion)}
                      />
                    </motion.div>
                  ))}
                </motion.div>
              )}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </motion.div>
  );
};
