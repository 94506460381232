import {
  MAX_MARKETCAP_IN_SHIBES_FOR_VOLUME_COMPARISON,
  MAX_MARKETCAP_VALUE_TO_CONSIDER_ITEM_IN_DOGE,
  MAX_VOLUME_IN_DOGE_FOR_VOLUME_COMPARISON,
  ONE_DOGE_IN_SHIBES,
} from "@/constants.ts";

// This function MUST be the same as the one in the backend. Also the constants used in this function must be the same as the ones in the backend.
export const filterTokenByMarketCapRule = (
  tokenMarketcap: number,
  volume: number,
  token: string,
): boolean => {
  if (
    tokenMarketcap >
    MAX_MARKETCAP_VALUE_TO_CONSIDER_ITEM_IN_DOGE * ONE_DOGE_IN_SHIBES
  ) {
    // ignore tokens with marketcap > 10,000,000,000 DOGE (~ $1,000,000,000)
    // because they are most likely a glitch and upper not real peaks
    console.log(`Ignoring ${token} because of high marketcap`);
    return true;
  } else if (
    tokenMarketcap > MAX_MARKETCAP_IN_SHIBES_FOR_VOLUME_COMPARISON &&
    volume < MAX_VOLUME_IN_DOGE_FOR_VOLUME_COMPARISON * ONE_DOGE_IN_SHIBES
  ) {
    // Must have 1M Doge in volume to have marketcap over 100,000,000 DOGE (~ $10M)
    console.info(
      `Ignoring ${token} because of low volume`,
      volume / ONE_DOGE_IN_SHIBES,
      tokenMarketcap / ONE_DOGE_IN_SHIBES,
    );

    return true;
  }

  return false;
};
