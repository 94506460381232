import { useContext } from "react";

import { RefreshKeyContext } from "@/context/RefreshKeyProvider";

export const useRefreshKey = () => {
  const refreshKeyContext = useContext(RefreshKeyContext);

  if (!refreshKeyContext) {
    throw new Error("useRefreshKey must be used within a RefreshKeyProvider");
  }

  return refreshKeyContext;
};
