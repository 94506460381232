import { useContext } from "react";

import { DoginalCollectionListContext } from "@/context/DoginalCollectionListProvider.tsx";

export const useDoginalCollectionList = () => {
  const doginalCollectionListContext = useContext(DoginalCollectionListContext);

  if (!doginalCollectionListContext) {
    throw new Error(
      "useDoginalCollectionList must be used within a DoginalCollectionListProvider",
    );
  }

  return doginalCollectionListContext;
};
