import { useCallback, useState } from "react";

import {
  createCollectibleOffer,
  getSellerPsdtHex,
} from "@/context/wallet/helpers/listItems.ts";
import { signPsbt } from "@/context/wallet/lib/transaction.ts";
import { TxWallet, WalletContextHandleReturn } from "@/context/wallet/types.ts";
import { InscriptionWithPrice } from "@/types";
import { handleError } from "@/utility";

export type UseListCollectiblesParams = {
  txWallet: TxWallet | undefined;
  address: string | undefined;
};

export type ListCollectiblesParams = {
  inscriptions: InscriptionWithPrice[];
};

export interface ListCollectiblesReturn extends WalletContextHandleReturn {
  execute: (listCollectiblesParams: ListCollectiblesParams) => Promise<void>;
}

export const useListCollectibles = (
  useListParams: UseListCollectiblesParams,
): ListCollectiblesReturn => {
  const [isLoading, setIsListingCollectibles] = useState<boolean>(false);
  const [isError, setIsErrorCollectiblesList] = useState<boolean>(false);
  const [isSuccess, setIsSuccessCollectiblesList] = useState<boolean>(false);
  const [error, setError] = useState<null | string>(null);

  const { txWallet, address } = useListParams;

  const listCollectibles = useCallback(
    async (listCollectiblesParams: ListCollectiblesParams): Promise<void> => {
      setIsListingCollectibles(true);
      setIsErrorCollectiblesList(false);
      setIsSuccessCollectiblesList(false);

      try {
        if (!txWallet) {
          throw new Error("No wallet found");
        }

        if (!address) {
          throw new Error("No address given");
        }

        for (const inscription of listCollectiblesParams.inscriptions) {
          if (!inscription.utxo) {
            console.warn(
              "listCollectibles - no utxo found",
              inscription.inscription_id,
            );
            continue;
          }

          const { priceInShibes, ...inscriptionWithoutPrice } = inscription;

          // get the psdt
          // @todo: make this multi list inscription aware... (not 1 request for each inscription)
          const psdtHex = await getSellerPsdtHex({
            sellerAddress: address,
            inscriptionPrice: priceInShibes,
            inscription: inscriptionWithoutPrice,
            discount: false,
            type: "doginal",
          });

          // Sign psdt
          const signedPsdtHex = signPsbt(psdtHex, txWallet, {
            isHex: true,
            autoFinalized: true,
          }) as string;

          // Create offer
          const response = await createCollectibleOffer({
            psdtHex: signedPsdtHex,
            inscriptionId: inscriptionWithoutPrice.inscription_id,
          });
          console.log("listCollectibles - Offer created", { response });
        }

        setIsSuccessCollectiblesList(true);
      } catch (e: Error | unknown) {
        setIsErrorCollectiblesList(true);
        const message = handleError(e);
        setError(message);
      } finally {
        setIsListingCollectibles(false);
      }
    },
    [txWallet, address],
  );

  const reset = useCallback(() => {
    setIsErrorCollectiblesList(false);
    setIsSuccessCollectiblesList(false);
    setError(null);
  }, []);

  return {
    isLoading,
    isError,
    isSuccess,
    execute: listCollectibles,
    reset,
    error,
  };
};
