import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from "@/components/ui/carousel";
import { cn } from "@/lib/utils";
import { PropsWithChildren } from "react";

interface Props extends PropsWithChildren {
  id?: string;
}

export function ScrollabelCarousel({ children, id }: Props) {
  return (
    <Carousel
      id={id}
      opts={{
        align: "start",
        dragFree: true,
      }}
      className="w-full pl-4"
    >
      <CarouselContent className="-ml-2">{children}</CarouselContent>
      {/* <CarouselPrevious />
            <CarouselNext /> */}
    </Carousel>
  );
}

export function ScrollableCarouselItem({
  className,
  children,
}: PropsWithChildren & { className?: string }) {
  return (
    <CarouselItem
      className={cn(
        "basis-[32%] select-none pl-2 sm:basis-[17.5%] lg:basis-[17.5%]",
        className,
      )}
    >
      {children}
    </CarouselItem>
  );
}
