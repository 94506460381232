import { useCallback, useState } from "react";

import { buildMintDuneTxs } from "@/context/wallet/helpers/mintDune.ts";
import {
  TxWallet,
  TxWithFeeEstimation,
  WalletContextHandleReturn,
} from "@/context/wallet/types.ts";
import { handleError } from "@/utility";
import { broadcastAll } from "./lib/transaction";

export type UseMintDuneParams = {
  txWallet: TxWallet | undefined;
  address: string | undefined;
};

export type MintDuneParams = {
  // priceInShibs: number;
  duneId: string;
  receiver: string | undefined;
  amt: number;
  limit: number;
  feePerVByte: number;
};

export interface MintDuneReturn extends WalletContextHandleReturn {
  execute: (mintDuneParams: MintDuneParams) => Promise<void>;
  txHashes: string[];
}

export const useMintDune = (
  useMintParams: UseMintDuneParams,
): MintDuneReturn => {
  const [isLoading, setIsMintingDune] = useState<boolean>(false);
  const [isError, setIsErrorDuneMint] = useState<boolean>(false);
  const [isSuccess, setIsSuccessDuneMint] = useState<boolean>(false);
  const [error, setError] = useState<null | string>(null);
  const [txHashes, setTxHashes] = useState<any[]>([]);

  const { txWallet, address } = useMintParams;

  const buildMintDunesTx = useCallback(
    async (
      buildMintDuneParams: MintDuneParams,
    ): Promise<TxWithFeeEstimation[] | undefined> => {
      const estimateFeesOnly = false;
      const buildMintDuneTxsParams = {
        txWallet,
        address: address || txWallet?.address,
        estimateFeesOnly,
        ...buildMintDuneParams,
        // duneId: string;
        // amt: number;
        // limit: number;
        // feePerVByte: number;
        // receiver: string | undefined;
      };

      return buildMintDuneTxs(buildMintDuneTxsParams);
    },
    [address, txWallet],
  );

  const mintDune = useCallback(
    async (mintDuneParams: MintDuneParams): Promise<void> => {
      setIsMintingDune(true);
      setIsErrorDuneMint(false);
      setIsSuccessDuneMint(false);

      try {
        // const { priceInShibs } = mintDuneParams;

        if (!txWallet) {
          throw new Error("No wallet found");
        }

        // Build the transactions
        const txsWithFeeEstimation = await buildMintDunesTx(mintDuneParams);
        if (!txsWithFeeEstimation) {
          throw new Error("No transactions found");
        }

        const txs = txsWithFeeEstimation.map(
          (txWithFeeEstimation) => txWithFeeEstimation.tx,
        );

        const sentTxHashes = await broadcastAll(txs, {
          address,
          receiverAddresses: [mintDuneParams.receiver || address!],
          type: "dune",
        });

        if (
          !sentTxHashes ||
          sentTxHashes.length === 0 ||
          sentTxHashes.length !== txs.length
        ) {
          await txWallet.syncUtxos(true);
          throw new Error("Failed to broadcast transaction");
        }

        setTxHashes(sentTxHashes);

        console.log("mintDune - sentTxHashes", { sentTxHashes });
        /*
        // broadcast all txs and get the psdt
        const psdtHex = await getDuneSellerPsdtHex({
          sellerAddress: txWallet.address,
          inscriptionPrice: priceInShibs,
          transactionHexs: preparedTxHexs,
          txHashes: preparedTxHashes,
          transactionOutput: vout,
          discount: false,
          type: "dunes",
        });

        // Sign psdt
        const signedPsdtHex = signPsbt(psdtHex, txWallet, {
          isHex: true,
          autoFinalized: true,
        }, InscriptionType.DUNE) as string;

        // Create offer
        const response = await createDuneOffer(
          signedPsdtHex,
          mintDuneParams.tick,
          mintDuneParams.amt,
        );
        console.log("mintDune - Offer created", { response });
        */
        console.log("mintDune - created");
        setIsSuccessDuneMint(true);
      } catch (e: Error | unknown) {
        setIsErrorDuneMint(true);
        const message = handleError(e);
        setError(message);
      } finally {
        setIsMintingDune(false);
      }
    },
    [txWallet, buildMintDunesTx],
  );

  const reset = useCallback(() => {
    setIsErrorDuneMint(false);
    setIsSuccessDuneMint(false);
    setError(null);
    setTxHashes([]);
  }, []);

  return {
    isLoading,
    isError,
    isSuccess,
    execute: mintDune,
    txHashes,
    reset,
    error,
  };
};
