import { AnimatePresence } from "framer-motion";
import { useCallback, useEffect, useState } from "react";

import { CollectionListingProps, Currency, Inscription } from "@/types";

import {
  DEFAULT_ERROR,
  ErrorView,
  ModalNavigation,
  Sheet,
  SheetProps,
  SuccessView,
} from "../base";
import { TransactionStep } from "./constants";
import { Item, SendView } from "./views/sendView";
import { useBalance, useTxWallet } from "@/contextHooks";
import { isValidDogecoinAddress } from "@/utility";
import { LOW_NETWORK_FEE_RATE } from "@/constants";
import { FeeType } from "@/context/wallet/calculateNetworkFees";

interface ModalSendCollectibleProps extends SheetProps {
  sendings: CollectionListingProps[];
  onRemove?: () => void;
  onSuccess?: (inscriptionIds: string[]) => void;
}

export const ModalSendCollectible: React.FC<ModalSendCollectibleProps> = ({
  onClose,
  onSuccess,
  ...props
}) => {
  /**
   * Local State
   */

  const [error, setError] = useState(DEFAULT_ERROR);
  const [transactionStep, setTransactionStep] = useState(TransactionStep.SEND);
  const { sendings: initialSendings } = props;
  const [items, setItems] = useState<Item[]>([]);
  const { collectiblesBalance } = useBalance();
  const [singleRecipientInputValue, setSingleRecipientInputValue] =
    useState<string>("");
  const { sendCollectibles, calculateNetworkFees } = useTxWallet();
  const [recipients, setRecipients] = useState<string[]>([]);
  const [recipientsInputValue, setRecipientsInputValue] = useState<string[]>(
    [],
  );
  const [txFeeInDoge, setTxFeeInDoge] = useState<string>("0");
  /**
   * Actions
   */

  const handleSendError = useCallback((title: string, description: string) => {
    setError({ title, description });
    setTransactionStep(TransactionStep.ERROR);
  }, []);

  const handleClose = useCallback(() => {
    if (sendCollectibles.isLoading) return;

    // reset values
    setError(DEFAULT_ERROR);
    setTransactionStep(TransactionStep.SEND);
    setRecipients([]);
    sendCollectibles.reset();

    onClose?.();
  }, [sendCollectibles, onClose]);

  useEffect(() => {
    if (calculateNetworkFees.isError && calculateNetworkFees.error) {
      handleSendError(
        "Error",
        (calculateNetworkFees.error as string) ||
          "An error occurred while calculating your fees.",
      );
    }
  }, [
    calculateNetworkFees.error,
    calculateNetworkFees.isError,
    handleSendError,
  ]);

  const handleSend = useCallback(async () => {
    // @todo: Add proper error handling here
    if (!items || !recipients || items.length <= 0 || recipients.length <= 0) {
      console.error("No items or recipients to send");
      handleSendError("Error", "No items or recipients to send");
      return;
    }

    await sendCollectibles.execute({
      receiverAddresses: recipients,
      inscriptions: items,
      feePerVByte: LOW_NETWORK_FEE_RATE,
    });
  }, [handleSendError, items, recipients, sendCollectibles]);

  const handleRecipientChange = useCallback(
    (idx: number, event: React.ChangeEvent<HTMLInputElement>) => {
      const newRecipientInputValue = event.target.value;

      // this is necessary to update the input value for the recipient without updating the recipients array
      // (which is used to send items). It is necessary to: avoid rerendering the send view when the input value changes
      setRecipientsInputValue((prevRecipients) => {
        const newRecipients = [...prevRecipients];
        newRecipients[idx] = newRecipientInputValue;
        return newRecipients;
      });

      // only update the recipients array if the input value is a valid dogecoin address
      if (!isValidDogecoinAddress(newRecipientInputValue)) return;

      setRecipients((prevRecipients) => {
        const newRecipients = [...prevRecipients];
        newRecipients[idx] = newRecipientInputValue;
        return newRecipients;
      });
    },
    [],
  );

  const handleSingleRecipient = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newRecipientInputValue = event.target.value;
      setSingleRecipientInputValue(newRecipientInputValue);

      // only update the recipients array if the input value is a valid dogecoin address
      if (!isValidDogecoinAddress(newRecipientInputValue)) return;

      // overwrite all exiting recipients (based on the num of items) with the new single recipient
      setRecipientsInputValue(() => {
        return items.map(() => newRecipientInputValue);
      });
      setRecipients(() => {
        return items.map(() => newRecipientInputValue);
      });
    },
    [items],
  );

  const removeItemFromSendList = useCallback((id: string) => {
    setItems((prevItems) =>
      prevItems.filter((item) => item.inscription_id !== id),
    );
  }, []);

  /**
   * Effects
   */
  useEffect(() => {
    if (
      !items ||
      !recipients ||
      items.length <= 0 ||
      items.length != recipients.length ||
      calculateNetworkFees.isLoading ||
      calculateNetworkFees.isError ||
      calculateNetworkFees.isSuccess ||
      txFeeInDoge != "0"
    )
      return;

    const invalidRecipients = recipients.filter(
      (recipient) => !isValidDogecoinAddress(recipient),
    );

    if (invalidRecipients.length === 0) {
      const inscriptions: Inscription[] = items;

      calculateNetworkFees.execute(FeeType.COLLECTIBLES, {
        inscriptions,
        feePerVByte: LOW_NETWORK_FEE_RATE,
        receiverAddresses: recipients,
      });
    }
  }, [calculateNetworkFees, items, recipients, txFeeInDoge]);

  // this is executed on initial opening and whenever the recipients array changes (after entering a valid dogecoin address)
  useEffect(() => {
    const sendItems: Item[] = [];

    // this index is necessary to match the correct recipient input to the correct collectible
    let showIndex = 0;
    collectiblesBalance.forEach((collectible) => {
      const matchingSending = initialSendings.find(
        (item) => item.inscriptionId === collectible.inscription_id,
      );
      if (matchingSending) {
        const parsedItem = {
          onRemove: function (): void {
            removeItemFromSendList(matchingSending.inscriptionId);
          },
          imageURI: matchingSending.imageURI,
          itemName: matchingSending.name,
          collectionName: matchingSending.collectionName,
          utxo: collectible.utxo,
          content: collectible.content,
          content_length: collectible.content_length,
          content_type: collectible.content_type,
          genesis_height: collectible.genesis_height,
          inscription_id: collectible.inscription_id,
          inscription_number: collectible.inscription_number,
          timestamp: collectible.timestamp,
          offset: collectible.offset,
          length: collectible.length,
          recipient: recipients[showIndex] || "",
        };
        sendItems.push(parsedItem);
        showIndex++;
      }
    });

    if (sendItems.length > 0) {
      setItems(sendItems);
    }
  }, [
    initialSendings,
    collectiblesBalance,
    recipients,
    removeItemFromSendList,
  ]);

  useEffect(() => {
    if (calculateNetworkFees.isSuccess && calculateNetworkFees.totalFeeInSats) {
      setTxFeeInDoge(calculateNetworkFees.totalFeeInDoge.toString());
    }
  }, [
    calculateNetworkFees.isSuccess,
    calculateNetworkFees.totalFeeInSats,
    calculateNetworkFees.totalFeeInDoge,
  ]);

  useEffect(() => {
    if (sendCollectibles.isSuccess) {
      const sentInscriptions = items.map((item) => item.inscription_id);
      onSuccess?.(sentInscriptions);
      setTransactionStep(TransactionStep.SUCCESS);
    }
  }, [items, onSuccess, sendCollectibles.isSuccess]);

  useEffect(() => {
    if (sendCollectibles.isError && sendCollectibles.error) {
      let message = "An error occurred while processing your request.";
      if (sendCollectibles.error) {
        message = `${message}, ${sendCollectibles.error as string}`;
      }
      handleSendError("Error", message);
    }
  }, [handleSendError, sendCollectibles.isError, sendCollectibles.error]);

  return (
    <Sheet
      withHeader={false}
      classNameContent="flex flex-col text-text-primary h-full rounded-none pt-safe-offset-4 pb-safe-offset-4"
      onClose={handleClose}
      {...props}
    >
      <ModalNavigation title="Send" iconBack="close" onBack={handleClose} />
      <AnimatePresence>
        <div className="relative flex flex-1 flex-col">
          {transactionStep === TransactionStep.SEND && items.length > 0 && (
            <SendView
              fees={{
                network: `${Currency.DOGE}${txFeeInDoge}`,
              }}
              items={items}
              singleRecipientInputValue={singleRecipientInputValue}
              recipientsInputValues={recipientsInputValue}
              loading={sendCollectibles.isLoading}
              handleSend={handleSend}
              handleRecipientChange={handleRecipientChange}
              handleSingleRecipient={handleSingleRecipient}
            />
          )}
          {transactionStep === TransactionStep.SUCCESS && (
            <SuccessView handleContinue={handleClose} />
          )}
          {transactionStep === TransactionStep.ERROR && (
            <ErrorView
              title={error.title}
              description={error.description}
              handleContinue={handleClose}
            />
          )}
        </div>
      </AnimatePresence>
    </Sheet>
  );
};
