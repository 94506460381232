import { useCallback, useState } from "react";

import { marketplaceApiV2 } from "@/lib/fetch";
import { handleError } from "@/utility";

type Drc20Holder = {
  rank: number;
  percentage: string;
  address: string;
  value: number;
  transferable: number;
  available: number;
};

const useFetchTopHolders = (tick?: string, amt: number = 100) => {
  const [topHolders, setTopHolders] = useState<Drc20Holder[]>([]);
  const [isTopHoldersLoading, setIsTopHoldersLoading] = useState(false);

  const fetchTop100Holders = useCallback(async () => {
    setIsTopHoldersLoading(true);
    try {
      if (!tick) throw new Error("Tick not found");
      // @todo: add pagination
      const res = await marketplaceApiV2(false).get(
        `/drc20/holders/${tick}?limit=${amt}&offset=0`,
      );

      if (!res.data.length) {
        setTopHolders([]);
        return;
      }
      const holders: Drc20Holder[] = res.data;
      setTopHolders(holders);
    } catch (e: Error | unknown) {
      handleError(e);
      setTopHolders([]);
      throw new Error("Failed to fetch top 100 holders");
    } finally {
      setIsTopHoldersLoading(false);
    }
  }, [amt, tick]);

  return {
    topHolders,
    isTopHoldersLoading,
    fetchTop100Holders,
  };
};

export { useFetchTopHolders };
export type { Drc20Holder };
