import React, { ReactNode, useState } from "react";

interface RefreshKeyContextProps {
  refreshKey: number;
  updateRefreshKey: (newKey: number) => void;
}

type ProviderProps = {
  children: ReactNode;
};

export const RefreshKeyContext = React.createContext<RefreshKeyContextProps>({
  refreshKey: 0,
  updateRefreshKey: () => {},
});

export const RefreshKeyProvider = ({ children }: ProviderProps) => {
  const [refreshKey, setRefreshKey] =
    useState<RefreshKeyContextProps["refreshKey"]>(0);

  return (
    <RefreshKeyContext.Provider
      value={{ refreshKey, updateRefreshKey: setRefreshKey }}
    >
      {children}
    </RefreshKeyContext.Provider>
  );
};
