import React from "react";
import { twMerge } from "tailwind-merge";

import * as TabsPrimitive from "@radix-ui/react-tabs";

import { TabsContent as TabsContentInner } from "../ui/tabs";

export const TabsContent = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => {
  return (
    <TabsContentInner ref={ref} className={twMerge(className)} {...props} />
  );
});

TabsContent.displayName = "TabsContent";
