import { useCallback, useState } from "react";

import { AVERAGE_NETWORK_FEE_RATE } from "@/constants.ts";
import { getAccessToken } from "@/context/wallet/helpers/listItems.ts";
import {
  BuildCollectibleTxError,
  SignTxResult,
  TxWallet,
  TxWithFeeEstimation,
  UnlistCollectibleOffersResult,
  WalletContextHandleReturn,
} from "@/context/wallet/types.ts";
import { marketplaceApiV2 } from "@/lib/fetch.ts";
import { handleError } from "@/utility";
import { useBalance } from "@/contextHooks";
import { getCollectibleInscriptionsForInscriptionIds } from "@/context/wallet/helpers/delistItems.ts";
import { buildSendCollectiblesTxs } from "@/context/wallet/helpers/sendCollectibles.ts";
import { SendCollectiblesParams } from "@/context/wallet/sendCollectibles.ts";

export type UseDelistCollectiblesParams = {
  txWallet: TxWallet | undefined;
};

export interface DelistCollectiblesReturn extends WalletContextHandleReturn {
  execute: (inscriptionIds: string[], offerIds: string[]) => Promise<void>;
  preparationErrors: null | string[] | object[];
  data: UnlistCollectibleOffersResult | null;
}

export type PreparationError = {
  txId: string;
  txOutput: number;
  inscriptionId: string | undefined;
  error: Error | never;
};

export const useDelistCollectibles = (
  useDelistParams: UseDelistCollectiblesParams,
): DelistCollectiblesReturn => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsErrorCollectiblesDelist] = useState<boolean>(false);
  const [isSuccess, setIsSuccessCollectiblesDelist] = useState<boolean>(false);
  const [error, setError] = useState<null | string>(null);
  const [preparationErrors, setPreparationErrors] = useState<
    null | BuildCollectibleTxError[]
  >(null);
  const [delistData, setDelistData] =
    useState<UnlistCollectibleOffersResult | null>(null);

  const { collectiblesBalance } = useBalance();
  const { txWallet } = useDelistParams;

  const buildDelistCollectibles = useCallback(
    async (
      buildSendCollectiblesParams: SendCollectiblesParams,
    ): Promise<TxWithFeeEstimation[] | undefined> => {
      const estimateFeesOnly = false;
      const buildSendCollectiblesTxsParams = {
        txWallet,
        address: txWallet?.address,
        estimateFeesOnly,
        ...buildSendCollectiblesParams,
      };

      return buildSendCollectiblesTxs(buildSendCollectiblesTxsParams);
    },
    [txWallet],
  );

  const performDelistCollectibles = useCallback(
    async (signedCancelTxs: SignTxResult[], offerIds: string[]) => {
      if (!txWallet) {
        console.log("delistCollectibles - no wallet found");
        return;
      }

      try {
        setIsLoading(true);
        setIsErrorCollectiblesDelist(false);
        setIsSuccessCollectiblesDelist(false);

        // 1. get token
        const token = await getAccessToken(txWallet.address, txWallet);

        // 2. delist put request to BE
        const response = await marketplaceApiV2(
          true,
        ).put<UnlistCollectibleOffersResult>(
          `offer/doginals/unlist`,
          {
            offerIds,
            signedCancelTxs,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        setIsSuccessCollectiblesDelist(true);
        setDelistData(response.data);
      } catch (e: Error | unknown) {
        setIsErrorCollectiblesDelist(true);
        const message = handleError(e);
        setError(message);
      } finally {
        setIsLoading(false);
      }
    },
    [txWallet],
  );

  const delistCollectibless = useCallback(
    async (inscriptionIds: string[], offerIds: string[]) => {
      try {
        if (!collectiblesBalance) {
          throw new Error("No collectiblesBalance found");
        }

        if (!txWallet) {
          throw new Error("No wallet found");
        }

        const inscriptions = getCollectibleInscriptionsForInscriptionIds(
          inscriptionIds,
          collectiblesBalance,
        );
        if (!inscriptions) {
          throw new Error(
            `No inscription objects containing ${inscriptionIds} were found in users wallet`,
          );
        }

        // For each inscription, we need to add a receiver address. We dont care about the index
        // as each address is the same
        const receiverAddresses = inscriptions.map(() => txWallet.address);

        const txsWithFeeEstimation = await buildDelistCollectibles({
          receiverAddresses: receiverAddresses,
          inscriptions,
          feePerVByte: AVERAGE_NETWORK_FEE_RATE,
        });

        if (!txsWithFeeEstimation) {
          throw new Error("Failed to build delist transactions - no txs found");
        }

        // This format is required for the BE
        const signedCancelTxs = txsWithFeeEstimation.map(
          (txWithFeeEstimation) => {
            return {
              tx: txWithFeeEstimation.tx.toString("hex"),
              inscriptionId: txWithFeeEstimation.inscriptionId,
            };
          },
        ) as SignTxResult[];

        if (signedCancelTxs.length > 0) {
          await performDelistCollectibles(signedCancelTxs, offerIds);
        }
      } catch (e: Error | unknown) {
        setIsErrorCollectiblesDelist(true);
        const message = handleError(e);
        setError(message);
      }
    },
    [
      collectiblesBalance,
      txWallet,
      buildDelistCollectibles,
      performDelistCollectibles,
    ],
  );

  const reset = useCallback(() => {
    setIsLoading(false);
    setIsErrorCollectiblesDelist(false);
    setIsSuccessCollectiblesDelist(false);
    setPreparationErrors(null);
    setError(null);
    setDelistData(null);
  }, []);

  return {
    isLoading,
    isError,
    isSuccess,
    execute: delistCollectibless,
    data: delistData,
    error,
    preparationErrors,
    reset,
  };
};
