import { useCallback, useEffect, useState } from "react";

import { marketplaceApiV2 } from "@/lib/fetch";
import { Currency, DoginalInfo } from "@/types";
import { fetchCollectiblesTradingData } from "./useFetchDoginals";
import { getValueForCurrency, handleError } from "@/utility";
import { useDogePrice } from "@/contextHooks";
import { ONE_DOGE_IN_SHIBES } from "@/constants.ts";

type RequestParams = {
  [key: string]: any;
};

// gets all the collectibles includeing collectionSymbol the user has
const fetchDoginalInfo = async (
  address: string,
  params?: RequestParams,
): Promise<DoginalInfo[]> => {
  const response = await marketplaceApiV2(false, { ...params, address }).get<{
    collectibleInfo: DoginalInfo[];
  }>(`/doginals/collection/info`);

  return response?.data.collectibleInfo || [];
};

const useFetchDoginalInfo = (address?: string) => {
  const { dogePrice } = useDogePrice();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | unknown>(undefined);
  const [isError, setIsError] = useState(false);
  const [collectibleInfo, setCollectibleInfo] = useState<DoginalInfo[]>([]);

  const fetch = useCallback(
    async (includeCollectibles?: boolean) => {
      if (!address) {
        setCollectibleInfo([]);
        return;
      }
      let result = [] as DoginalInfo[];
      try {
        setLoading(true);
        setError(undefined);
        setIsError(false);
        const collectibleInfo = await fetchDoginalInfo(address, {
          includeCollectibles: includeCollectibles !== false,
        });

        result = collectibleInfo;

        const symbols = collectibleInfo.map(
          (collectible) => collectible.collectionSymbol,
        );

        try {
          const tradingData = await fetchCollectiblesTradingData(symbols);
          result = result.map((item: DoginalInfo) => {
            const currentTradingData = tradingData.find(
              (data) => data.symbol === item.collectionSymbol,
            );
            if (!currentTradingData) return item;

            const floorPrice = currentTradingData.floorPrice ?? 0;
            const amount =
              collectibleInfo.find(
                (c) => c.collectionSymbol === item.collectionSymbol,
              )?.amount ?? 0;

            const changePercent =
              currentTradingData?.twentyFourHourChangePercent ?? 0;
            const weeklyVolume = currentTradingData?.weeklyVolume ?? 0;
            const volume = currentTradingData?.volume ?? 0;

            const priceData = {
              floorPrice: {
                doge: getValueForCurrency(floorPrice, Currency.DOGE, dogePrice),
                usd: getValueForCurrency(floorPrice, Currency.USD, dogePrice),
              },
              twentyFourHourChangePercent: changePercent,
              weeklyChangePercent: currentTradingData?.weeklyChangePercent ?? 0,
              sales: currentTradingData.weeklySales ?? 0,
              weeklyVolume: {
                doge: getValueForCurrency(
                  weeklyVolume,
                  Currency.DOGE,
                  dogePrice,
                ),
                usd: getValueForCurrency(weeklyVolume, Currency.USD, dogePrice),
              },
              volume: {
                doge: getValueForCurrency(volume, Currency.DOGE, dogePrice),
                usd: getValueForCurrency(volume, Currency.USD, dogePrice),
              },
            };

            const floorPriceDoge = floorPrice / ONE_DOGE_IN_SHIBES;
            const floorPriceUsd = floorPriceDoge * dogePrice;

            const valueDollar = floorPriceUsd * amount;
            const valueDoge = floorPriceDoge * amount;

            // Calculate previous value using the changePercent
            const previousDollar = valueDollar / (1 + changePercent / 100);
            const previousDoge = valueDoge / (1 + changePercent / 100);

            const changeDollar = valueDollar - previousDollar;
            const changeDoge = valueDoge - previousDoge;

            return {
              ...item,
              priceData,
              valueDollar,
              valueDoge,
              changeDollar,
              changeDoge,
            };
          });
        } catch (e) {
          handleError(e);
        }
      } catch (error: Error | unknown) {
        setIsError(true);
        setError(error);
      } finally {
        setCollectibleInfo(result);
        setLoading(false);
      }
    },
    [address, dogePrice],
  );

  useEffect(() => {
    fetch();
  }, [fetch]);

  return { getCollectibles: fetch, collectibleInfo, loading, error, isError };
};

export { useFetchDoginalInfo };
