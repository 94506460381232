import React, { useMemo } from "react";

import { NumberFormatType, formatNumber } from "@/lib/numbers";
import { cn } from "@/lib/utils";
import { getIconForDune } from "@/utility";

import { Skeleton } from "@/components";
import { AnimatedClickable, ImageWithFallback } from "@/components";
import { ChangeIndicator } from "@/routes/Trading/components/changeIndicator";
import { PendingIndicator, PendingIndicatorMessage } from "../pendingIndicator";
import BigNumber from "bignumber.js";

interface BalanceCardDunesProps {
  tick: string;
  amount: number;
  numPendingSending: number;
  numPendingReceiving: number;
  value: string;
  change: string;

  className?: string;
  onClick?: () => void;
  style?: React.CSSProperties;
  colorForBalanceChange?: string;
}

export const BalanceCardDunes: React.FC<BalanceCardDunesProps> = ({
  tick,
  amount,
  value,
  change,
  className,
  style,
  onClick,
  numPendingSending,
  numPendingReceiving,
}) => {
  const image = useMemo(() => {
    return getIconForDune(tick);
    // const colorPercentage = getColorForChange(change);
  }, [tick]); //, change

  return (
    <AnimatedClickable
      onClick={onClick}
      className={cn(
        "flex flex-row items-center justify-between rounded-lg border-0.5 border-border-secondary/50 bg-background-secondary p-3 hover:bg-background-tertiary/50",
        className,
      )}
      style={style}
      whileHover={{ scale: 1.01 }}
    >
      <div className="flex flex-row items-center space-x-3">
        <ImageWithFallback image={image} className="h-11 w-11" />

        <div className="flex flex-col space-y-0 text-text-primary">
          <span className="text-xs font-bold md:text-md">
            {tick.toUpperCase()}
          </span>
          <div className="flex flex-row items-center space-x-3">
            <div className="flex flex-col py-0.5">
              <span className="text-xs text-text-tertiary md:text-sm">{`${
                amount >= 1000
                  ? formatNumber({
                      value: amount,
                      type: NumberFormatType.Large_Number,
                      decimalPlaces: 2,
                    })
                  : amount
              }`}</span>
            </div>
            <PendingIndicator
              pendingAmount={new BigNumber(numPendingSending)
                .plus(new BigNumber(numPendingReceiving))
                .toNumber()}
            />
            {numPendingReceiving > 0 &&
              numPendingSending + numPendingReceiving === 0 && (
                <PendingIndicatorMessage message="Pending transactions" />
              )}
          </div>
        </div>
      </div>

      <div className="flex flex-col items-end space-y-0 text-text-primary">
        <span
          className={"text-xs font-medium text-text-primary md:text-md"}
          dangerouslySetInnerHTML={{
            __html: value,
          }}
        />

        {change != "0" && (
          <ChangeIndicator change={change} className="text-xs md:text-sm" />
        )}
      </div>
    </AnimatedClickable>
  );
};

export const BalanceCardDuneSkeleton = () => {
  return (
    <Skeleton className="flex items-center justify-between rounded-lg bg-background-tertiary/50 p-4">
      <div className="flex w-1/3 flex-row items-center space-x-3">
        <Skeleton className="h-11 w-11 rounded-full bg-background-tertiary/70" />
        <div className="flex flex-col space-y-0.5">
          <Skeleton className="h-5 w-10 bg-background-tertiary/70" />
          <Skeleton className="h-5 w-8 bg-background-tertiary/70" />
        </div>
      </div>
      <div className="flex w-2/3 flex-row items-center space-x-2">
        <div className="justify-cente flex flex-1 flex-col items-end space-y-1">
          <Skeleton className="h-6 w-full max-w-20 flex-shrink bg-background-tertiary/30 font-medium uppercase" />
        </div>
      </div>
    </Skeleton>
  );
};

export const BalanceCardDuneLoading = () => {
  return Array(3)
    .fill("")
    .map((_, index) => <BalanceCardDuneSkeleton key={index} />);
};
