import React, { useCallback, useEffect } from "react";

import { Button, ToggleElement } from "@/components";
import { ActivitySort, ActivitySortParam, SortOrder } from "@/types";

import { Sheet, SheetProps } from "../base";

const SORT_ITEMS = [
  {
    label: "Total Price: Low to High",
    by: ActivitySortParam.Top,
    order: SortOrder.Ascending,
  },
  {
    label: "Total Price: High to Low",
    by: ActivitySortParam.Top,
    order: SortOrder.Descending,
  },
  {
    label: "Time: Oldest to Newest",
    by: ActivitySortParam.Recent,
    order: SortOrder.Ascending,
  },
  {
    label: "Time: Newest to Oldest",
    by: ActivitySortParam.Recent,
    order: SortOrder.Descending,
  },
];

interface ModalActivitySortProps extends SheetProps {
  onApply: (sortType: ActivitySort | undefined) => void;
  sort?: ActivitySort;
}

export const ModalActivitySort: React.FC<ModalActivitySortProps> = ({
  onApply,
  sort,
  ...props
}) => {
  const [localSortType, setLocalSortType] = React.useState<
    ActivitySort | undefined
  >(sort);

  const handleApply = useCallback(() => {
    onApply?.(localSortType);
  }, [onApply, localSortType]);

  useEffect(() => {
    setLocalSortType(sort);
  }, [sort]);

  return (
    <Sheet {...props}>
      <div className="flex flex-col space-y-4 p-2">
        <div className="flex flex-1 flex-col space-y-2 rounded-lg bg-background-secondary p-2">
          {SORT_ITEMS.map((item) => {
            const onClick = () => {
              // If the item is already selected, deselect it
              if (
                localSortType?.sortParam === item.by &&
                localSortType?.sortOrder === item.order
              ) {
                setLocalSortType(undefined);
              } else {
                setLocalSortType({ sortParam: item.by, sortOrder: item.order });
              }
            };
            const isSelected =
              localSortType?.sortParam === item.by &&
              localSortType?.sortOrder === item.order;
            return (
              <ToggleElement
                label={item.label}
                onClick={onClick}
                isSelected={isSelected}
                key={item.label}
              />
            );
          })}
        </div>
        <Button variant="inverse" size="large" onClick={handleApply}>
          Apply
        </Button>
      </div>
    </Sheet>
  );
};
