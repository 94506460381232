import React, { useCallback, useMemo, useState } from "react";

import { Button, WalletCard, useToast } from "@/components";
import { useWalletManagement } from "@/contextHooks";
import { ModalAccountEdit, ModalSecret } from "@/modals";
import { handleError } from "@/utility";

import { Header, Sheet, SheetProps } from "../base";

export const ModalAccount: React.FC<SheetProps> = ({
  isVisible = false,
  onClose,
}) => {
  const { toast } = useToast();

  const { wallet, updatePW, addAccount, changeActiveAccountIndex } =
    useWalletManagement() || {};

  // State for controlling the visibility of the modal for editing wallets
  const [modalEditVisible, setModalEditVisible] = useState(false);

  // State for controlling the visibility of the modal for showing the recovery phrase
  const [modalRecoveryPhraseVisible, setModalRecoveryPhraseVisible] =
    useState(false);

  // State for storing the currently selected wallet address
  const [selectedWalletAddress, setSelectedWalletAddress] = useState("");

  // Handles the closing of the edit modal and resets the selected wallet address
  const handleModalEditClose = useCallback(() => {
    setModalEditVisible(false);
    setSelectedWalletAddress("");
  }, []);

  // Handles the opening of the edit modal and sets the selected wallet address
  const handleModalEditOpen = useCallback((walletAddress: string) => {
    setModalEditVisible(true);
    setSelectedWalletAddress(walletAddress);
  }, []);

  // Sets the active wallet based on the index provided
  const handleSetActiveWallet = useCallback(
    (index: number) => {
      if (changeActiveAccountIndex) {
        try {
          changeActiveAccountIndex(index);
          onClose?.(); // Close any modal or overlay, if applicable
        } catch (e: Error | unknown) {
          const message = handleError(e);
          toast({
            title: "Error",
            description: message ?? "Failed to select account",
          });
        }
      }
    },
    [changeActiveAccountIndex, onClose, toast],
  );

  // Generates a list of wallet accounts with relevant actions and details
  const walletList = useMemo(() => {
    if (!wallet) return null;

    const accounts = wallet.walletData.accounts;

    return accounts
      ?.filter((account) => !account.isHidden)
      .map((account) => ({
        isActive: account.index === wallet.walletData.activeAccountIndex,
        accountName: account.name,
        walletAddress: account.address,
        onEdit: () => handleModalEditOpen(account.address),
        onSetActive: () => handleSetActiveWallet(account.index),
      }));
  }, [wallet, handleModalEditOpen, handleSetActiveWallet]);

  // Adds a new account
  const handleAddAccount = useCallback(async () => {
    try {
      await addAccount?.();
      toast({
        title: "Success",
        description: "Account added",
        variant: "default",
        duration: 3000,
      });
    } catch (e: Error | unknown) {
      handleError(e);
      toast({
        title: "Error",
        description: "Failed to add account",
      });
    }
  }, [addAccount, toast]);

  // Logs out the user by clearing the password and reloading the page
  const handleLogOut = useCallback(() => {
    updatePW?.(undefined);
    window.location.reload();
  }, [updatePW]);

  return (
    <>
      <Sheet
        isVisible={isVisible}
        classNameContent="aspect-3/4 flex flex-col flex-1 max-h-[800px] sm:h-[90vh] sm:aspect-none p-0 pb-safe"
        withHeader={false}
      >
        <Header
          title="Accounts"
          onAction={() => setModalRecoveryPhraseVisible(true)}
          iconAction="visibility_lock"
          onClose={onClose}
        />
        <div className="flex flex-1 flex-col space-y-4 overflow-y-auto px-4 pt-4">
          <div className="flex h-full flex-1 flex-col space-y-2">
            {walletList?.map((account, idx) => {
              return (
                <WalletCard
                  key={idx}
                  isActive={account.isActive}
                  accountName={account.accountName}
                  address={account.walletAddress}
                  onEdit={account.onEdit}
                  onSetActive={account.onSetActive}
                />
              );
            })}
          </div>
        </div>
        <div className="border-t-1 flex flex-col space-y-1 border-t-0.5 border-border-primary bg-background-primary px-4 pt-4">
          <Button
            variant="default"
            size="large"
            shape="default"
            onClick={handleAddAccount}
          >
            Generate Account
          </Button>
          <Button
            variant="ghost"
            size="large"
            onClick={handleLogOut}
            className="opacity-50"
          >
            Lock Wallet
          </Button>
        </div>
      </Sheet>

      <ModalAccountEdit
        isVisible={modalEditVisible}
        onClose={handleModalEditClose}
        walletAddress={selectedWalletAddress}
      />

      <ModalSecret
        variant="recoveryPhrase"
        isVisible={modalRecoveryPhraseVisible}
        onClose={() => setModalRecoveryPhraseVisible(false)}
      />
    </>
  );
};
