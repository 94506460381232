import { cn } from "@/lib/utils";

import { Sheet, SheetProps } from "../base";
import { ChangeIndicator } from "@/routes/Trading/components/changeIndicator";

interface ValueItem {
  icon: string;
  label: string;
  labelDetail: string;
  value: string;
  valueDetail?: string;
  handleOpenInfo?: (title: string, description: string) => void;
}

interface ModalTotalValueProps extends SheetProps {
  items: ValueItem[];
}

export const ModalTotalValue: React.FC<ModalTotalValueProps> = ({
  items,
  ...props
}) => {
  return (
    <Sheet {...props} classNameContent="flex flex-col flex-1">
      <div className="mx-auto flex w-full max-w-xl flex-1 flex-col space-y-2 px-4 pb-safe-or-4">
        {items.map((item, index) => {
          const {
            icon,
            label,
            labelDetail,
            value,
            valueDetail,
            handleOpenInfo,
          } = item;
          // const colorForValueDetail =
          //   valueDetail && valueDetail.includes("-")
          //     ? "text-red-500"
          //     : "text-green-500";
          return (
            <div
              key={index}
              className="flex flex-row items-center justify-between space-x-3 rounded-lg border-0.5 border-border-secondary/50 bg-background-secondary p-3 hover:bg-background-tertiary/50"
            >
              <span className="material-symbols-rounded text-2xl text-text-secondary">
                {icon}
              </span>
              <div className="flex flex-1 items-center space-x-1">
                <span className="text-sm font-bold text-text-primary">
                  {label}
                </span>
                <span
                  onClick={() => handleOpenInfo?.(label, labelDetail)}
                  className="material-symbols-rounded text-sm text-text-tertiary"
                >
                  info
                </span>
              </div>
              <div
                className={cn(
                  "flex flex-1 flex-col items-end",
                  !valueDetail && "justify-center",
                )}
              >
                <span
                  className="text-sm font-semibold text-text-primary"
                  dangerouslySetInnerHTML={{ __html: value }}
                />
                {valueDetail && (
                  <ChangeIndicator
                    change={valueDetail}
                    className="mt-1 text-xs"
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>
    </Sheet>
  );
};
