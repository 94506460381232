import React, { createContext, useEffect, useState } from "react";
import { useOperatingSystem, useShowInstallMessage } from "@/hooks";
import { useOrientation } from "@uidotdev/usehooks";

interface OnboardingModalContextProps {
  isInstallModalOpen: boolean;
  isOrientationModalOpen: boolean;
  hideInstallModal: () => void;
  hideOrientationModal: () => void;
}

const OnboardingModalContext = createContext<
  OnboardingModalContextProps | undefined
>(undefined);

const OnboardingModalContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { isIOS, isAndroid, isMobile, isTablet } = useOperatingSystem();
  const { type: orientation } = useOrientation();
  const showInstallMessage = useShowInstallMessage();

  const [isInstallModalOpen, setInstallModalOpen] = useState(false);
  const [isOrientationModalOpen, setOrientationModalOpen] = useState(false);

  // Decide whether to show the install modal or not based on the device specs
  useEffect(() => {
    if (showInstallMessage) {
      setInstallModalOpen(true);
    } else {
      setInstallModalOpen(false);
    }
  }, [showInstallMessage]);

  // Decide whether to show the orientation modal or not based on the device and orientation
  useEffect(() => {
    const shouldShowOrientationModal =
      (isIOS || isAndroid) &&
      isMobile &&
      !isTablet &&
      (orientation === "landscape" || orientation === "landscape-primary");
    setOrientationModalOpen(shouldShowOrientationModal);
  }, [isIOS, isAndroid, orientation, isMobile, isTablet]);

  const hideInstallModal = () => setInstallModalOpen(false);
  const hideOrientationModal = () => setOrientationModalOpen(false);

  return (
    <OnboardingModalContext.Provider
      value={{
        hideInstallModal,
        isInstallModalOpen,
        hideOrientationModal,
        isOrientationModalOpen,
      }}
    >
      {children}
    </OnboardingModalContext.Provider>
  );
};

export { OnboardingModalContextProvider, OnboardingModalContext };
