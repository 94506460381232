import { ONE_DOGE_IN_SHIBES } from "@/constants";
import { NumberFormatType, formatNumber } from "@/lib/numbers";

/**
 * Calculates the value of a token amount in USD and formats it.
 *
 * @param amount - The amount of the token.
 * @param floorPrice - The floor price of the token.
 * @param dogePrice - The current price of Doge in USD.
 * @param type
 * @returns The formatted value in USD.
 */
export const calculateTokenValueInUSD = (
  amount: number,
  floorPrice: number,
  dogePrice: number,
  type: NumberFormatType = NumberFormatType.Price,
): string => {
  const valueInUSD = amount * (floorPrice / ONE_DOGE_IN_SHIBES) * dogePrice;
  return formatNumber({ value: valueInUSD, type });
};
