import React from "react";

import { cn } from "@/lib/utils";
import {
  Collection,
  CollectionActivity,
  CollectionData,
  CollectionListingProps,
  DRC20Token,
  DunesToken,
  Owner,
  TokenActivity,
  TokenListingProps,
} from "@/types";
import { Column } from "@tanstack/react-table";

import { getSortingSettings } from "../getSortingSettings";

interface SortIconProps {
  isSorted: boolean;
  iconSort: string;
}

const SortIcon: React.FC<SortIconProps> = ({ isSorted, iconSort }) => (
  <span
    className={cn(
      "material-symbols-rounded text-sm text-text-highlight",
      !isSorted && "text-transparent",
    )}
  >
    {iconSort}
  </span>
);

interface HeaderCellProps {
  title: string;
  aligned: "left" | "right";

  className?: string;
  withSorting?: boolean;
  column?:
    | Column<DRC20Token, unknown>
    | Column<DunesToken, unknown>
    | Column<Collection, unknown>
    | Column<CollectionData, unknown>
    | Column<TokenActivity, unknown>
    | Column<CollectionActivity, unknown>
    | Column<TokenListingProps, unknown>
    | Column<CollectionListingProps, unknown>
    | Column<Owner, unknown>;
}

export const HeaderCell: React.FC<HeaderCellProps> = ({
  title,
  column,
  aligned,
  className,
  withSorting = false,
}) => {
  const sorted = column?.getIsSorted() || false;
  const { isSorted, iconSort } = getSortingSettings(sorted);

  const handleSorting = () => withSorting && column?.toggleSorting();

  const isAlignedRight = aligned === "right";
  const flexValue = isAlignedRight ? "flex-shrink-0" : "flex-0";
  const textAlignment = isAlignedRight ? "text-right" : "text-left";
  const justifyValue = isAlignedRight ? "justify-end" : "justify-start";

  return (
    <button
      onClick={handleSorting}
      className={cn(
        "flex h-full w-full flex-1 flex-row items-center space-x-1",
        justifyValue,
        className,
      )}
    >
      {isAlignedRight && withSorting && (
        <SortIcon isSorted={isSorted} iconSort={iconSort} />
      )}
      <span
        className={cn(
          "text-xs font-medium uppercase text-text-tertiary",
          flexValue,
          textAlignment,
        )}
      >
        {title}
      </span>
      {!isAlignedRight && withSorting && (
        <SortIcon isSorted={isSorted} iconSort={iconSort} />
      )}
    </button>
  );
};
