import { useMemo } from "react";

import { useWalletManagement } from "@/contextHooks";

export const useCurrentAccount = () => {
  const { wallet } = useWalletManagement() || {};
  const activeAccountIndex = useMemo(
    () => wallet?.walletData.activeAccountIndex ?? 0,
    [wallet],
  );
  const activeAccount = useMemo(
    () =>
      wallet?.walletData.accounts?.find(
        (acc) => acc.index === activeAccountIndex,
      ),
    [wallet, activeAccountIndex],
  );

  return {
    address: activeAccount?.address,
    publicKey: activeAccount?.publicKey,
    index: activeAccount?.index,
    isHidden: activeAccount?.isHidden,
    name: activeAccount?.name,
  };
};
