import dogecore from "bitcore-lib-doge";
import { useCallback, useState } from "react";

import { AVERAGE_NETWORK_FEE_RATE } from "@/constants.ts";
import { getDrc20UtxosForInscriptionIds } from "@/context/wallet/helpers/delistItems.ts";
import { getAccessToken } from "@/context/wallet/helpers/listItems.ts";
import { fundWallet } from "@/context/wallet/lib/transaction.ts";
import {
  BuildDrc20TxsResult,
  BuildTxError,
  SignTxResult,
  TxWallet,
  UnlistDrc20OffersResult,
  WalletContextHandleReturn,
} from "@/context/wallet/types.ts";
import { marketplaceApiV2 } from "@/lib/fetch.ts";
import { AccountDrc20Data, Drc20Utxo } from "@/types/inscription.ts";
import { handleError } from "@/utility";

const { Transaction } = dogecore;

export type UseDelistDrc20Params = {
  txWallet: TxWallet | undefined;
  accountData: AccountDrc20Data | undefined;
};

export interface DelistDrc20Return extends WalletContextHandleReturn {
  execute: (inscriptionIds: string[], offerIds: string[]) => Promise<void>;
  preparationErrors: null | string[] | object[];
  data: any;
}

export type PreparationError = {
  txId: string;
  txOutput: number;
  inscriptionId: string | undefined;
  error: Error | never;
};

export const useDelistDrc20 = (
  useDelistParams: UseDelistDrc20Params,
): DelistDrc20Return => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsErrorDrc20Delist] = useState<boolean>(false);
  const [isSuccess, setIsSuccessDrc20Delist] = useState<boolean>(false);
  const [error, setError] = useState<null | string>(null);
  const [preparationErrors, setPreparationErrors] = useState<
    null | BuildTxError[]
  >(null);
  const [delistData, setDelistData] = useState<UnlistDrc20OffersResult | null>(
    null,
  );

  const { txWallet, accountData } = useDelistParams;

  const buildDelistDrc20Txs = useCallback(
    async (
      utxosForInscriptionIds: Drc20Utxo[],
    ): Promise<BuildDrc20TxsResult | undefined> => {
      if (!txWallet) {
        throw new Error("No wallet found");
      }

      const errors: BuildTxError[] = [];
      const successfullySignedCancelTxs: SignTxResult[] = [];

      utxosForInscriptionIds.map((utxo) => {
        try {
          const tx = new Transaction();

          const utxoFormattedForTx = {
            txid: utxo.txid,
            vout: utxo.vout,
            satoshis: utxo.shibes,
            script: utxo.script,
          };
          tx.from(utxoFormattedForTx);
          tx.to(txWallet.address, utxo.shibes);

          // Fund & sign
          const { tx: fundedTx } = fundWallet(
            txWallet,
            tx,
            AVERAGE_NETWORK_FEE_RATE,
            false,
          );
          txWallet.updateUtxos({ tx: fundedTx });

          successfullySignedCancelTxs.push({
            tx: fundedTx.toString("hex"),
            inscriptionId: utxo.inscription_id as string,
          });
        } catch (e: Error | unknown) {
          handleError(e);

          console.error(
            `Error creating cancelSellerPsdtHex for utxo ${utxo}:`,
            e,
          );

          errors.push({
            utxo,
            error: e as Error,
          });
          return null;
        }
      });

      return {
        signedTxs: successfullySignedCancelTxs as SignTxResult[],
        errors,
      };
    },
    [txWallet],
  );

  const performDelistDrc20s = useCallback(
    async (signedCancelTxs: SignTxResult[], offerIds: string[]) => {
      if (!txWallet) {
        console.log("delistDrc20 - no wallet found");
        return;
      }

      try {
        setIsLoading(true);
        setIsErrorDrc20Delist(false);
        setIsSuccessDrc20Delist(false);

        // 1. get token
        const token = await getAccessToken(txWallet.address, txWallet);

        // 2. delist put request to BE
        const response = await marketplaceApiV2(
          true,
        ).put<UnlistDrc20OffersResult>(
          `offer/drc20s/unlist`,
          {
            offerIds,
            signedCancelTxs,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        setIsSuccessDrc20Delist(true);
        setDelistData(response.data);
      } catch (e: Error | unknown) {
        setIsErrorDrc20Delist(true);
        const message = handleError(e);
        setError(message);
      } finally {
        setIsLoading(false);
      }
    },
    [txWallet],
  );

  const delistDrc20s = useCallback(
    async (inscriptionIds: string[], offerIds: string[]) => {
      try {
        if (!accountData) {
          throw new Error("No accountDrc20Data found");
        }

        const { utxos } = accountData;
        if (!utxos) {
          throw new Error(`No utxos were found in users wallet`);
        }

        const utxosForInscriptionIds = getDrc20UtxosForInscriptionIds(
          inscriptionIds,
          utxos,
        );
        if (!utxosForInscriptionIds) {
          throw new Error(
            `No utxos containing ${inscriptionIds} were found in users wallet`,
          );
        }

        const buildResult = await buildDelistDrc20Txs(utxosForInscriptionIds);
        if (!buildResult) {
          throw new Error("Failed to build delist transactions");
        }

        const { signedTxs, errors } = buildResult;

        if (signedTxs.length > 0) {
          await performDelistDrc20s(signedTxs, offerIds);
        }

        if (errors.length > 0) {
          console.error(
            "Errors occurred while building delist transactions",
            errors,
          );
          setPreparationErrors(errors);
        }
      } catch (e: Error | unknown) {
        setIsErrorDrc20Delist(true);
        const message = handleError(e);
        setError(message);
      }
    },
    [accountData, buildDelistDrc20Txs, performDelistDrc20s],
  );

  const reset = useCallback(() => {
    setIsLoading(false);
    setIsErrorDrc20Delist(false);
    setIsSuccessDrc20Delist(false);
    setPreparationErrors(null);
    setError(null);
    setDelistData(null);
  }, []);

  return {
    isLoading,
    isError,
    isSuccess,
    execute: delistDrc20s,
    data: delistData,
    error,
    preparationErrors,
    reset,
  };
};
