import { useContext } from "react";
import { OnboardingModalContext } from "@/context/OnboardingModalContextProvider.tsx";

export const useOnboardingModalContext = () => {
  const context = useContext(OnboardingModalContext);
  if (!context) {
    throw new Error(
      "useOnboardingModalContext must be used within an OnboardingModalProvider",
    );
  }
  return context;
};
