import React, { ReactNode, useState } from "react";

import { Currency } from "@/types";

interface CurrencyContextProps {
  currency: Currency;
  setCurrency: (currency: Currency) => void;
}

type ProviderProps = {
  children: ReactNode;
};

export const CurrencyContext = React.createContext<CurrencyContextProps>({
  currency: Currency.USD,
  setCurrency: () => {},
});

export const CurrencyProvider = ({ children }: ProviderProps) => {
  const [currency, setCurrency] = useState<CurrencyContextProps["currency"]>(
    Currency.USD,
  );

  return (
    <CurrencyContext.Provider value={{ currency, setCurrency }}>
      {children}
    </CurrencyContext.Provider>
  );
};
