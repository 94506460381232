import React, { useCallback, useEffect } from "react";

import { Button, ToggleElement } from "@/components";
import { ActivityType } from "@/types";
import { getLabelForActivityType } from "@/utility";

import { Sheet, SheetProps } from "../base";

const FILTER_ITEMS = [
  {
    type: ActivityType.All,
    label: "All",
  },
  {
    type: ActivityType.List,
    label: getLabelForActivityType(ActivityType.List),
  },
  {
    type: ActivityType.Sale,
    label: getLabelForActivityType(ActivityType.Sale),
  },
  {
    type: ActivityType.Unlist,
    label: getLabelForActivityType(ActivityType.Unlist),
  },
];

interface ModalActivityFilterProps extends SheetProps {
  type?: ActivityType;
  onApply: (filters: { type?: ActivityType }) => void;
}

export const ModalActivityFilter: React.FC<ModalActivityFilterProps> = ({
  onApply,
  ...props
}) => {
  const [localType, setLocalType] = React.useState<ActivityType | undefined>(
    ActivityType.All,
  );

  const handleApply = useCallback(() => {
    onApply?.({ type: localType === ActivityType.All ? undefined : localType });
  }, [onApply, localType]);

  useEffect(() => {
    setLocalType(props.type ?? ActivityType.All);
  }, [props.type]);

  return (
    <Sheet {...props}>
      <div className="flex flex-col space-y-4 p-2">
        <div className="flex flex-1 flex-col space-y-2 rounded-lg bg-background-secondary p-2">
          {FILTER_ITEMS.map((item) => {
            const isSelected = item.type === localType;
            const onClick = () => {
              if (item.type === localType) {
                setLocalType(undefined);
              } else {
                setLocalType(item.type);
              }
            };
            return (
              <ToggleElement
                label={item.label}
                onClick={onClick}
                isSelected={isSelected}
                key={`${item.type}_${item.label}`}
              />
            );
          })}
        </div>
        <Button variant="inverse" size="large" onClick={handleApply}>
          Apply
        </Button>
      </div>
    </Sheet>
  );
};
