export const isValidNumber = (value: number): boolean => {
  return !isNaN(value);
};

export const validateMinMax = (
  min: number | undefined,
  max: number | undefined,
  errorMessage: string,
  setError: (error: string | undefined) => void,
) => {
  if (min === undefined || max === undefined) {
    setError(undefined);
    return;
  } else if (isValidNumber(min) && isValidNumber(max)) {
    if (min > max) {
      setError(errorMessage);
      return;
    }
  } else if (!isValidNumber(min) || !isValidNumber(max)) {
    setError(errorMessage);
    return;
  }

  setError(undefined);
};
