import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import {
  Appear,
  Button,
  ButtonBar,
  ListingCardCollectible,
  Skeleton,
  Spinner,
  TableContentListingsCollection,
  TableListings,
} from "@/components";
import { DOGINALS_LISTINGS_PAGINATION_LIMIT } from "@/constants.ts";
import { useCurrency, useDogePrice, useTxWallet } from "@/contextHooks";
import { useCollectiblesDetails } from "@/contextHooks/useCollectiblesDetails.tsx";
import {
  FILTER_TO_CONSIDER_FOR_ACTIVE_FILTERS,
  useCurrentAccount,
  useInfiniteScroll,
} from "@/hooks";
import { cn } from "@/lib/utils";
import {
  ModalBuyCollectible,
  ModalCollectionFilter,
  ModalCollectionSearch,
  ModalCollectionSort,
  ModalInstall,
} from "@/modals";
import {
  CollectionListingProps,
  DoginalListing,
  FilterBetween,
  FilterListingsCollection,
  FilterMetadata,
  ListingStatus,
  SortListingsCollection,
} from "@/types";
import {
  createExtendedCollectibleListings,
  formatRange,
  getFormattedValue,
  isValidDogecoinAddress,
  isValidInscriptionId,
  isValidRange,
} from "@/utility";

import { DisplayType, ModalType } from "../types";
import {
  FILTER_DEFAULT,
  SORT_DEFAULT,
} from "@/hooks/datafetching/useFetchDoginalsOfCollection";
import { useOnboardingModalContext } from "@/contextHooks/useOnboardingModal.ts";

interface TabCollectionListingsProps {
  collectionSymbol: string | undefined;
}

export const TabCollectionListings: React.FC<TabCollectionListingsProps> = ({
  collectionSymbol,
}) => {
  const navigate = useNavigate();
  const { currency } = useCurrency();
  const { dogePrice } = useDogePrice();

  const { login } = useTxWallet();

  const { address: currentAccountAddress } = useCurrentAccount();
  // get address query param
  const [searchParams] = useSearchParams();

  const { isInstallModalOpen, hideInstallModal } = useOnboardingModalContext();

  /**
   * Local State
   */
  const [search, setSearch] = useState("");
  const [modalType, setModalType] = useState<ModalType | undefined>();
  const [display, setDisplay] = useState<DisplayType>(DisplayType.Grid);
  const [selectedListings, setSelectedListings] = useState<string[]>([]);
  const [openModalInstall, setOpenModalInstall] = useState<boolean>(false);
  const [localLoading, setLocalLoading] = useState<boolean>(true);

  const { collectibleListings, setCollectionSymbol, setAddress } =
    useCollectiblesDetails();

  const {
    isLoading,
    hasMoreData,
    isLoadingMoreData,
    setFilters,
    setSort,
    sort,
    filters,
    reset,
    offset,
    setOffset,
    data,
    floorPrice,
    refetch,
  } = collectibleListings;

  const { hasActiveFilters, hasActiveSorting, hasActiveSearch } =
    useMemo(() => {
      const { ...otherFilters } = filters;
      return {
        hasActiveFilters: !Object.keys(otherFilters).every((key) => {
          // Skip this field from being considered active
          if (!FILTER_TO_CONSIDER_FOR_ACTIVE_FILTERS.includes(key)) {
            return true;
          }

          // @ts-expect-error internal type is wrong
          const value = otherFilters[key]; // Access the value even if it's undefined

          // @ts-expect-error internal type is wrong
          return FILTER_DEFAULT[key] === value || value === undefined;
        }),

        hasActiveSorting: !Object.entries(sort).every(
          // @ts-expect-error internal type is wrong
          ([key, value]) => SORT_DEFAULT[key] === value,
        ),
        hasActiveSearch: search !== "",
      };
    }, [filters, search, sort]);

  /**
   * Collectible Listings
   */
  const updateListings = useCallback((offerId: string) => {
    setSelectedListings((prev) => {
      const isSelected = prev.includes(offerId);
      return isSelected
        ? prev.filter((selected) => selected !== offerId)
        : [...prev, offerId];
    });
  }, []);

  const removeFromListings = useCallback((offerId: string) => {
    setSelectedListings((prev) => {
      // Only remove the offerId if it exists in the previous state
      return prev.includes(offerId)
        ? prev.filter((selected) => selected !== offerId)
        : prev;
    });
  }, []);

  const onSelectListing = useCallback(
    async (offerId: string) => {
      if (openModalInstall) return;

      // open the install modal (by setting this internal state variable) if the user clicks on select item and is not
      // on the right device or has not installed the app
      if (isInstallModalOpen) {
        setOpenModalInstall(true);
        return; // do not select the listing or check for login state
      }

      const isLoggedIn = await login();
      if (isLoggedIn) {
        updateListings(offerId);
      }
    },
    [openModalInstall, isInstallModalOpen, login, updateListings],
  );

  // update the internal state of the install modal variable  when the install modal is closed
  useEffect(() => {
    if (!isInstallModalOpen) {
      setOpenModalInstall(false);
    }
  }, [isInstallModalOpen]);

  const onResetSelectedListings = useCallback(() => {
    setSelectedListings([]);
  }, []);

  /**
   * Button Bar: Modals
   */

  const onOpenModalFilter = useCallback(() => {
    setModalType(ModalType.Filter);
  }, []);

  const onOpenModalSort = useCallback(() => {
    setModalType(ModalType.Sort);
  }, []);

  const onOpenModalSearch = useCallback(() => {
    setModalType(ModalType.Search);
  }, []);

  /**
   * Button Bar: Others
   */

  const onToggleDisplay = useCallback(() => {
    setDisplay((prev) =>
      prev === DisplayType.Grid ? DisplayType.List : DisplayType.Grid,
    );
  }, []);

  const onRefresh = useCallback(
    async (resetListing: boolean = false) => {
      if (!collectionSymbol) return;
      if (resetListing) onResetSelectedListings();
      reset({
        collectionSymbol,
        offset: 0,
        limit: DOGINALS_LISTINGS_PAGINATION_LIMIT,
        seller: undefined,
        listed: undefined,
      });
      setSearch("");
      // This calls the api with cachebreaker
      refetch(collectionSymbol);
    },
    [collectionSymbol, onResetSelectedListings, refetch, reset],
  );

  const onCloseModal = useCallback(() => {
    setModalType(undefined);
  }, []);

  const onCloseBuyModal = useCallback(() => {
    setModalType(undefined);
    setSelectedListings([]);
    setTimeout(() => onRefresh(), 500);
  }, [onRefresh]);

  /**
   * Apply Filters/Sort/Search
   */

  const filterPrice = useMemo(() => {
    const { priceMin, priceMax } = filters;
    return {
      min: priceMin?.length && Number(priceMin),
      max: priceMax?.length && Number(priceMax),
    };
  }, [filters]);

  const onApplyFilter = useCallback(
    (filters: {
      price?: FilterBetween;
      listingStatus?: ListingStatus;
      metadata?: FilterMetadata[];
    }) => {
      const updatedPriceRange = isValidRange(
        filters?.price?.min,
        filters?.price?.max,
      )
        ? formatRange(filters?.price?.min, filters?.price?.max)
        : { min: undefined, max: undefined };

      setFilters((prev: FilterListingsCollection) => ({
        ...prev,
        ...filters,
        priceMin: updatedPriceRange.min,
        priceMax: updatedPriceRange.max,
        listed:
          filters.listingStatus === ListingStatus.Listed
            ? true
            : filters.listingStatus === ListingStatus.Unlisted
              ? false
              : undefined,
      }));

      onCloseModal();
    },
    [onCloseModal, setFilters],
  );

  const onApplySort = useCallback(
    (sort: SortListingsCollection | undefined) => {
      setSort(sort);
      onCloseModal();
    },
    [onCloseModal, setSort],
  );

  const onApplySearch = useCallback(
    (search: string) => {
      search = search.trim();
      const isValidSeller = isValidDogecoinAddress(search);
      const isInscriptionId = isValidInscriptionId(search);
      if (isValidSeller) {
        setFilters((prev) => ({ ...prev, seller: search }));
      } else if (isInscriptionId) {
        setFilters((prev) => ({ ...prev, inscriptionId: search }));
      } else {
        setFilters((prev) => ({
          ...prev,
          seller: undefined,
          inscriptionId: undefined,
          name: search,
        }));
      }
      setSearch(search);
      onCloseModal();
    },
    [onCloseModal, setFilters],
  );

  const onBuyListings = useCallback(async () => {
    const isLoggedIn = await login();
    if (isLoggedIn) {
      setModalType(ModalType.Buy);
    }
  }, [login]);

  // Address reset to show all listings, not only the ones of the user
  useEffect(() => {
    setAddress(undefined);
  }, [setAddress]);

  /**
   * Data Transformations
   */

  // Navigation
  const onNavigateToCollectionDetail = useCallback(
    (inscriptionId: string) => {
      if (!collectionSymbol) return;
      navigate(
        `/collectible/${collectionSymbol.toLowerCase()}/${inscriptionId}`,
      );
    },
    [navigate, collectionSymbol],
  );

  // Formatted table data
  const dataListings = useMemo<CollectionListingProps[]>(() => {
    if (!data) return [];

    return createExtendedCollectibleListings({
      listings: data,
      selectedListings,
      floorPrice,
      onSelectListing,
      onNavigateToListing: onNavigateToCollectionDetail,
      currency,
      dogePrice,
      identifier: "offerId",
      checkIfUserIsSeller: (address) => address === currentAccountAddress,
    });
  }, [
    data,
    floorPrice,
    selectedListings,
    currency,
    dogePrice,
    onSelectListing,
    onNavigateToCollectionDetail,
    currentAccountAddress,
  ]);

  // Formatted card data
  const dataListingCards = useMemo(
    () =>
      dataListings.map((listing, index) => {
        const {
          offerId,
          imageURI,
          name,
          floorDifferencePercentage,
          listed,
          isSelected,
          price,
          inscriptionId,
          doginalOffer,
        } = listing;

        const { value: totalPriceForCurrency } = getFormattedValue(
          price,
          dogePrice,
          currency,
          true,
        );

        const status = listed ? ListingStatus.Listed : ListingStatus.Unlisted;

        return {
          key: inscriptionId + "-" + index,
          image: imageURI,
          title: name,
          seller: doginalOffer?.address || "",
          floorDifferencePercentage,
          status,
          selected: isSelected,
          price: `${totalPriceForCurrency}`,
          displayPendingState: false,
          className: cn("w-full", !isSelected && "bg-background-primary/50"),
          isSelectable:
            status === ListingStatus.Listed && !!doginalOffer?.offerId,

          onClick: () => onNavigateToCollectionDetail(inscriptionId),
          onSelect: () => onSelectListing(offerId),
        };
      }),
    [
      dataListings,
      currency,
      dogePrice,
      onNavigateToCollectionDetail,
      onSelectListing,
    ],
  );

  const buttonBarConfig = useMemo(() => {
    return [
      {
        icon: display === DisplayType.Grid ? "reorder" : "apps",
        onClick: onToggleDisplay,
        isActive: display !== DisplayType.Grid,
      },
      {
        icon: "filter_list",
        onClick: onOpenModalFilter,
        isActive: hasActiveFilters,
      },
      {
        icon: "swap_vert",
        onClick: onOpenModalSort,
        isActive: hasActiveSorting,
      },
      { icon: "search", onClick: onOpenModalSearch, isActive: hasActiveSearch },
      { icon: "refresh", onClick: onRefresh },
    ];
  }, [
    display,
    onOpenModalFilter,
    onOpenModalSearch,
    onOpenModalSort,
    onRefresh,
    onToggleDisplay,
    hasActiveFilters,
    hasActiveSorting,
    hasActiveSearch,
  ]);

  const selectedOffers = useMemo(() => {
    return dataListings.filter((listing) =>
      selectedListings.includes(listing.offerId),
    );
  }, [dataListings, selectedListings]);

  /**
   * Effects
   */

  useEffect(() => {
    // ensure to not show already loaded data
    const found = Boolean(
      data.find(
        (listing: DoginalListing) =>
          listing.collectionSymbol === collectionSymbol,
      ),
    );
    setLocalLoading(!found);

    if (!collectionSymbol) return;
    setCollectionSymbol(collectionSymbol);

    return () => {
      setCollectionSymbol(undefined);
      setLocalLoading(true);
    };
  }, [collectionSymbol, setCollectionSymbol, data]);

  // setSearch if addressQueryParam is present
  useEffect(() => {
    if (!searchParams || !searchParams.has("address")) return;
    if (searchParams.has("address")) {
      const addressQueryParam = searchParams.get("address") || "";
      onApplySearch(addressQueryParam);
    }
  }, [onApplySearch, searchParams]);

  return (
    <>
      <div className="flex flex-1 flex-col">
        {/** Actions */}
        <div className="mx-auto w-full px-4 md:px-0">
          <ButtonBar buttons={buttonBarConfig} />
        </div>

        {/** Listings */}
        {isLoading || localLoading ? (
          display === "grid" ? (
            <div
              className={cn(
                "grid grid-cols-2 gap-3 p-4",
                "sm:grid-cols-3 md:mt-8 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-7",
              )}
            >
              <Skeleton className="min-h-72 w-full rounded-lg bg-background-tertiary/30 md:min-h-64" />
              <Skeleton className="min-h-72 w-full rounded-lg bg-background-tertiary/30 md:min-h-64" />
              <Skeleton className="min-h-72 w-full rounded-lg bg-background-tertiary/30 md:min-h-64" />
              <Skeleton className="min-h-72 w-full rounded-lg bg-background-tertiary/30 md:min-h-64" />
              <Skeleton className="min-h-72 w-full rounded-lg bg-background-tertiary/30 md:min-h-64" />
              <Skeleton className="min-h-72 w-full rounded-lg bg-background-tertiary/30 md:min-h-64" />
              <Skeleton className="min-h-72 w-full rounded-lg bg-background-tertiary/30 md:min-h-64" />
            </div>
          ) : (
            <div className="flex min-h-64 flex-1 flex-col items-center justify-center">
              <Spinner />
            </div>
          )
        ) : display === "grid" ? (
          <div className="grid grid-cols-2 gap-3 p-4 sm:grid-cols-3 md:mt-8 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-7">
            {dataListingCards.map((listing, index) => {
              return (
                <ListingCardCollectible
                  {...listing}
                  key={`${listing.key}-${index}`}
                  className="w-full bg-background-primary 2xl:bg-background-secondary"
                />
              );
            })}
            <BottomScrollObserver
              isLoading={isLoading}
              hasMoreData={hasMoreData}
              isLoadingMoreData={isLoadingMoreData}
              setOffset={setOffset}
              offset={offset}
              paginationLimit={DOGINALS_LISTINGS_PAGINATION_LIMIT}
              display="grid"
            />
          </div>
        ) : (
          <div className="mt-4 md:mt-8">
            <TableListings
              data={dataListings}
              columns={TableContentListingsCollection}
              loading={isLoading}
            />
            <BottomScrollObserver
              isLoading={isLoading}
              hasMoreData={hasMoreData}
              isLoadingMoreData={isLoadingMoreData}
              setOffset={setOffset}
              offset={offset}
              paginationLimit={DOGINALS_LISTINGS_PAGINATION_LIMIT}
              display="list"
            />
          </div>
        )}
      </div>

      {/** Buttons */}
      <Appear
        isVisible={selectedListings.length > 0 && modalType !== ModalType.Buy}
        from="bottom"
      >
        <div className="flex w-full flex-1 flex-row items-center justify-center space-x-2">
          <div className="absolute -bottom-6 left-0 right-0 -z-10 h-20 rounded-lg bg-gradient-to-t from-background-primary/80 to-transparent transition-all duration-200" />
          <Button
            size="large"
            shape="circle"
            variant="inverse"
            onClick={onBuyListings}
            className="w-1/2 max-w-xs border-none drop-shadow-xl disabled:cursor-not-allowed disabled:text-text-disabled disabled:opacity-100 disabled:drop-shadow-none"
          >
            {`Buy ${selectedListings.length} ${selectedListings.length > 1 ? "Listings" : "Listing"}`}
          </Button>
          <Button
            size="large"
            shape="circle"
            variant="default"
            className="h-12 w-12 border-text-highlight bg-text-highlight"
            onClick={onResetSelectedListings}
          >
            <span className="material-symbols-rounded text-lg">close</span>
          </Button>
        </div>
      </Appear>

      {/** Modals */}
      {modalType === ModalType.Filter && (
        <ModalCollectionFilter
          title="Filter"
          price={filterPrice}
          onClose={onCloseModal}
          onApply={onApplyFilter}
          metadata={filters.metadata}
          collectionSymbol={collectionSymbol}
          isVisible={modalType === ModalType.Filter}
          listingStatus={
            filters.listed != undefined
              ? filters.listed
                ? ListingStatus.Listed
                : ListingStatus.Unlisted
              : undefined
          }
        />
      )}

      {modalType === ModalType.Search && (
        <ModalCollectionSearch
          title="Search"
          search={search}
          onClose={onCloseModal}
          onApply={onApplySearch}
          isVisible={modalType === ModalType.Search}
        />
      )}

      {modalType === ModalType.Sort && (
        <ModalCollectionSort
          title="Sort"
          sort={sort}
          onClose={onCloseModal}
          onApply={onApplySort}
          isVisible={modalType === ModalType.Sort}
        />
      )}

      {/*Cannot be removed from dom by conditional rendering, as we need the psdt-hex check if a listing changed.
       (selectedOffers)*/}
      <ModalBuyCollectible
        onClose={onCloseBuyModal}
        offers={selectedOffers}
        removeFromListings={removeFromListings}
        refetchListings={onRefresh}
        isVisible={modalType === ModalType.Buy}
      />

      <ModalInstall
        isVisible={openModalInstall}
        onClose={() => {
          hideInstallModal();
          navigate("/");
        }}
      />
    </>
  );
};

const BottomScrollObserver = ({
  isLoading,
  hasMoreData,
  isLoadingMoreData,
  setOffset,
  offset,
  paginationLimit,
  display,
}: {
  isLoading: boolean;
  hasMoreData: boolean;
  isLoadingMoreData: boolean;
  setOffset: (offset: number) => void;
  offset: number;
  paginationLimit: number;
  display?: "grid" | "list";
}) => {
  /**
   * Infinite Scroll
   */

  // For Infinite Scroll
  const bottomTableRef = useInfiniteScroll({
    isLoadingData: isLoading,
    hasMoreData: hasMoreData,
    isLoadingMoreData: isLoadingMoreData,
    setOffset: setOffset,
    offset: offset,
    paginationLimit,
  });

  if (display === "list") {
    return (
      <>
        <div className="flex items-center justify-center">
          <Spinner />
        </div>
        <div ref={bottomTableRef} />
      </>
    );
  }

  return (
    <>
      <div
        ref={bottomTableRef}
        className={cn(
          "min-h-72 w-full rounded-lg bg-none md:min-h-64",
          isLoadingMoreData && "animate-pulse bg-background-tertiary/30",
        )}
      />
      {isLoadingMoreData && (
        <>
          <Skeleton className="min-h-72 w-full rounded-lg bg-background-tertiary/30 md:min-h-64" />
          <Skeleton className="min-h-72 w-full rounded-lg bg-background-tertiary/30 md:min-h-64" />
        </>
      )}
    </>
  );
};
