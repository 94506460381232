import { useContext, useEffect, useRef } from "react";

import { UIContext } from "@/context";

// Save the bottom nav height to the context; used for the main content padding
export const useBottomNavHeight = () => {
  const bottomNavRef = useRef<HTMLDivElement>(null);
  const { setBottomNavHeight } = useContext(UIContext);

  useEffect(() => {
    if (bottomNavRef.current) {
      setBottomNavHeight(bottomNavRef.current.offsetHeight);
    }
  }, [setBottomNavHeight, bottomNavRef]);

  return bottomNavRef;
};
