import { SecurityPreferenceContext } from "@/context/SecurityPreferenceProvider";
import { useContext } from "react";

export const useSecurityPreference = () => {
  const securityPreferenceContext = useContext(SecurityPreferenceContext);

  if (!securityPreferenceContext) {
    throw new Error(
      "useSecurityPreferenceContext must be used within a SecurityPreferenceProvider",
    );
  }

  return securityPreferenceContext;
};
