import isNumber from "lodash/isNumber";

export const isValidRange = (min?: number, max?: number) =>
  min !== undefined &&
  max !== undefined &&
  isNumber(min) &&
  isNumber(max) &&
  max > min &&
  min >= 0;

export const formatRange = (min?: number, max?: number) => ({
  min: min?.toString(),
  max: max?.toString(),
});
