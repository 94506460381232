import { useCallback, useEffect, useState } from "react";

import { marketplaceApiV2 } from "@/lib/fetch";
import { handleError } from "@/utility";
import { CollectibleHolder } from "@/types/holders.ts";

const useFetchTopCollectibleHolders = (
  collectionSymbol?: string,
  limit: number = 100,
  offset: number = 0,
) => {
  const [topHolders, setTopHolders] = useState<CollectibleHolder[]>([]);
  const [isTopHoldersLoading, setIsTopHoldersLoading] = useState(false);

  const fetchTop100Holders = useCallback(async () => {
    if (!collectionSymbol) return;
    setIsTopHoldersLoading(true);
    try {
      const res = await marketplaceApiV2(false).get<{
        holder: CollectibleHolder[];
      }>(
        `/doginals/collection/holder?symbol=${collectionSymbol}&limit=${limit}&offset=${offset}`,
      );

      if (!res.data.holder.length) {
        setTopHolders([]);
        return;
      }
      const holders: CollectibleHolder[] = res.data.holder.map(
        (holder: CollectibleHolder) => ({
          rank: holder.rank,
          percentage: holder.percentage,
          address: holder.address,
          value: holder.value,
          available: holder.available,
        }),
      );

      setTopHolders(holders);
    } catch (e: Error | unknown) {
      handleError(e);
      setTopHolders([]);
      throw new Error("Failed to fetch top 100 holders");
    } finally {
      setIsTopHoldersLoading(false);
    }
  }, [collectionSymbol, limit, offset]);

  useEffect(() => {
    fetchTop100Holders();
  }, [fetchTop100Holders]);

  return {
    topHolders,
    isTopHoldersLoading,
    fetchTop100Holders,
  };
};

export { useFetchTopCollectibleHolders };
