import * as React from "react";

import { cn } from "@/lib/utils";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(
          "dark:border-zinc-800 dark:bg-zinc-950 dark:ring-offset-zinc-950 dark:placeholder:text-zinc-400 flex h-10 w-full rounded-md bg-background-secondary px-3 py-2 text-sm text-text-secondary ring-offset-transparent file:bg-transparent file:text-sm file:font-medium placeholder:text-text-tertiary focus:bg-background-tertiary focus:text-text-primary focus-visible:outline-none disabled:cursor-not-allowed disabled:text-text-disabled disabled:opacity-50",
          className,
        )}
        ref={ref}
        {...props}
      />
    );
  },
);
Input.displayName = "Input";

export { Input };
