import { useCallback, useEffect, useState } from "react";

import { BalanceOverviewWithCalculatedPrices } from "@/types";
import { fetchBalanceOverview } from "@/context/helpers/fetchBalanceOverview.ts";
import { ONE_DOGE_IN_SHIBES } from "@/constants.ts";
import { useDogePrice } from "@/contextHooks";

type UseDogeBalanceOverviewOfAddressReturn = {
  balanceOverview?: BalanceOverviewWithCalculatedPrices;
  isError: boolean;
  error: Error | unknown;
  isLoading: boolean;
  fetchData: () => Promise<void>;
};

const useBalanceOverviewOfAddress = (
  address?: string,
): UseDogeBalanceOverviewOfAddressReturn => {
  const { dogePrice, loading: isLoadingDogePrice } = useDogePrice();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [error, setError] = useState<Error | unknown>();
  const [balanceOverview, setBalanceOverview] = useState<
    BalanceOverviewWithCalculatedPrices | undefined
  >(undefined);

  const fetchData = useCallback(async () => {
    if (!address || !dogePrice || isLoadingDogePrice) return;

    setIsLoading(true);
    setIsError(false);
    setError(undefined);
    try {
      const balanceOverview = await fetchBalanceOverview(address!);

      const doginalsValue =
        balanceOverview.estimated_total_value.doginals.value;
      const drc20sValue = balanceOverview.estimated_total_value.drc20s.value;
      const dunesValue = balanceOverview.estimated_total_value.dunes.value;
      const safeBalance = balanceOverview.estimated_total_value.safeBalance;

      const totalInShibesUnrounded =
        doginalsValue + drc20sValue + safeBalance + dunesValue;
      const totalInDogeUnrounded = totalInShibesUnrounded / ONE_DOGE_IN_SHIBES;
      const inDOGE = totalInDogeUnrounded;
      const inUSD = totalInDogeUnrounded * dogePrice;

      const doginalsValueInDoge = doginalsValue / ONE_DOGE_IN_SHIBES;
      const doginalsValueInUSD = doginalsValueInDoge * dogePrice;
      const drc20sValueInDoge = drc20sValue / ONE_DOGE_IN_SHIBES;
      const drc20sValueInUSD = drc20sValueInDoge * dogePrice;
      const dunesValueInDoge = dunesValue / ONE_DOGE_IN_SHIBES;
      const dunesValueInUSD = dunesValueInDoge * dogePrice;
      const safeBalanceInDoge = safeBalance / ONE_DOGE_IN_SHIBES;
      const safeBalanceInUSD = safeBalanceInDoge * dogePrice;

      setBalanceOverview({
        ...balanceOverview,
        totalInDOGE: inDOGE,
        totalInUSD: inUSD,
        doginalsValue,
        doginalsValueInDoge,
        doginalsValueInUSD,
        drc20sValue,
        drc20sValueInDoge,
        drc20sValueInUSD,
        dunesValue,
        dunesValueInDoge,
        dunesValueInUSD,
        safeBalance,
        safeBalanceInDoge,
        safeBalanceInUSD,
      });
    } catch (e: Error | unknown) {
      console.error("Error fetching balance overview", e);
      setError(e);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  }, [address, dogePrice, isLoadingDogePrice]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    balanceOverview,
    isError,
    isLoading,
    error,
    fetchData,
  };
};

export { useBalanceOverviewOfAddress };
