import { Balance } from "@/context/BalanceProvider.tsx";

const EMPTY_BALANCE: Balance = {
  utxos: [],
  total_utxos: 0,
  total_shibes: 0,
  dogeBalanceInUsd: 0,
};

export { EMPTY_BALANCE };
