import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  ActivityCardDRC20,
  Skeleton,
  TabItem,
  TableContentDRC20,
  TableExplore,
} from "@/components";
import { DRC20_LISTINGS_PAGINATION_LIMIT } from "@/constants";
import {
  useCurrency,
  useDogePrice,
  useDrc20TokenList,
  useDrc20Watchlist,
  useRefreshKey,
  useTxWallet,
} from "@/contextHooks";
import { useInfiniteScroll } from "@/hooks";
import { DRC20Token, Tab } from "@/types";

import { EmptyTopMover, EmptyWatchlist, TopMoverGradient } from "../components";
import {
  ScrollabelCarousel,
  ScrollableCarouselItem,
} from "@/components/scrollableCarousel";
import { TopBase } from "@/context/Drc20TokenListProvider.tsx";
import { useOnboardingModalContext } from "@/contextHooks/useOnboardingModal.ts";
import { ModalInstall } from "@/modals";

interface TradingDRC20Props {
  selectedTab: string;
  availableTabItems: TabItem[];
  setEnabledTabItems: (tabItems: TabItem[]) => void;
}

export const TradingDRC20: React.FC<TradingDRC20Props> = ({
  selectedTab,
  availableTabItems,
  setEnabledTabItems,
}) => {
  const navigate = useNavigate();
  const { currency } = useCurrency();
  const { dogePrice } = useDogePrice();
  const { login } = useTxWallet();
  const { refreshKey, updateRefreshKey } = useRefreshKey();

  /**
   * Tab Checks
   */

  const { isTabTop, isTabTrending, isTabRecent, isTabWatchlist } =
    useMemo(() => {
      return {
        isTabTop: selectedTab === Tab.Top,
        isTabTrending: selectedTab === Tab.Trending,
        isTabRecent: selectedTab === Tab.Recent,
        isTabWatchlist: selectedTab === Tab.Watchlist,
      };
    }, [selectedTab]);

  /**
   * Data Fetching & Processing
   */

  const {
    // Table Data
    tableOffset,
    isLoadingTableData,
    isLoadingMoreTableData,
    isMoversLoading,
    tableData,
    debouncedSetTableOffset,
    hasMoreTableData,
    // Movers Data
    topMovers,
    topSales,
    recentSales,
  } = useDrc20TokenList();

  const enabledTabs = useMemo<TabItem[]>(() => {
    const tabConditions: Record<Tab, TopBase[] | boolean[]> = {
      [Tab.Top]: topMovers,
      [Tab.Trending]: topSales,
      [Tab.Recent]: recentSales,
      // Watchlist tab is enabled ALWAYS. Independent of the data.
      [Tab.Watchlist]: [true],
    };

    return availableTabItems.filter(
      (tab) => tabConditions[tab.value]?.length > 0,
    );
  }, [availableTabItems, recentSales, topMovers, topSales]);

  // Ensure to only load those tabs which have data
  useEffect(() => {
    if (!isLoadingTableData) {
      setEnabledTabItems(enabledTabs);
    }
  }, [enabledTabs, isLoadingTableData, setEnabledTabItems]);

  const { watchlist, addToWatchlist, removeFromWatchlist, getIsOnWatchlist } =
    useDrc20Watchlist();
  const [openModalInstall, setOpenModalInstall] = useState<boolean>(false);
  const { isInstallModalOpen, hideInstallModal } = useOnboardingModalContext();

  const toggleWatchlist = useCallback(
    async (tick?: string) => {
      if (openModalInstall) return;

      // open the install modal (by setting this internal state variable) if the user clicks on add to watchlist and is not
      // on the right device or has not installed the app
      if (isInstallModalOpen) {
        setOpenModalInstall(true);
        return; // do not add to watchlist if the user has not installed the app
      }

      const isLoggedIn = await login();
      if (!isLoggedIn) return;

      if (!tick) return;

      if (getIsOnWatchlist(tick)) {
        removeFromWatchlist(tick);
      } else {
        await addToWatchlist(tick);
      }
    },
    [
      addToWatchlist,
      getIsOnWatchlist,
      isInstallModalOpen,
      login,
      openModalInstall,
      removeFromWatchlist,
    ],
  );

  // update the internal state of the install modal variable  when the install modal is closed
  useEffect(() => {
    if (!isInstallModalOpen) {
      setOpenModalInstall(false);
    }
  }, [isInstallModalOpen]);

  // Table Data with Watchlist
  const drc20DataWithWatchlist = useMemo(() => {
    return tableData.map((token: DRC20Token) => ({
      ...token,
      currency,
      currentDogePrice: dogePrice,
      watchlist: getIsOnWatchlist(token.id),
      onAddToWatchlist: () => toggleWatchlist(token.id),
      onRemoveFromWatchlist: () => toggleWatchlist(token.id),
    }));
  }, [tableData, currency, dogePrice, getIsOnWatchlist, toggleWatchlist]);

  // For Infinite Scroll
  const bottomTableRef = useInfiniteScroll({
    isLoadingData: isLoadingTableData,
    hasMoreData: hasMoreTableData,
    isLoadingMoreData: isLoadingMoreTableData,
    setOffset: debouncedSetTableOffset,
    offset: tableOffset,
    paginationLimit: DRC20_LISTINGS_PAGINATION_LIMIT,
  });

  /**
   * Navigation
   */
  // Navigate to Token
  const onNavigateToToken = useCallback(
    (tick: string) => {
      navigate(`/drc20/${tick.toLowerCase()}`);
    },
    [navigate],
  );

  return (
    <>
      <div className="relative">
        <div className="py-2 lg:py-4">
          {isMoversLoading ? (
            <div className="flex flex-row space-x-2 overflow-x-auto px-4 py-2 lg:space-x-4 lg:py-4">
              {Array.from({ length: 10 }).map((_, index) => (
                <Skeleton
                  key={index}
                  className="h-[7.125rem] w-28 flex-shrink-0 rounded-lg md:h-40 md:w-40"
                />
              ))}
            </div>
          ) : (
            <>
              {isTabTop &&
                (topMovers.length > 0 ? (
                  <ScrollabelCarousel>
                    {topMovers.map((data, index) => {
                      const {
                        id: tick,
                        value,
                        percentage,
                        percentageTimeframe,
                      } = data;
                      const cardData = { ...{ tick }, ...data };
                      const navigateToToken = () => onNavigateToToken(tick);
                      return (
                        <ScrollableCarouselItem
                          key={`carousel-${tick}-${index}`}
                          className="h-[7.125rem] basis-[32%] sm:basis-[18%] md:h-40 lg:basis-[15%] xl:basis-[11.25%]"
                        >
                          <ActivityCardDRC20
                            key={tick}
                            valuePrimary={value}
                            valueSecondary={percentage}
                            valueSecondarySuffix={percentageTimeframe}
                            onClick={navigateToToken}
                            includeIndicatorSymbol
                            {...cardData}
                          />
                        </ScrollableCarouselItem>
                      );
                    })}
                  </ScrollabelCarousel>
                ) : (
                  <EmptyTopMover />
                ))}

              {isTabTrending &&
                (topSales.length > 0 ? (
                  <ScrollabelCarousel>
                    {topSales.map((data, index) => {
                      const { id: tick, value, sales, timeframe } = data;
                      const cardData = { ...{ tick }, ...data };
                      const navigateToToken = () => onNavigateToToken(tick);
                      return (
                        <ScrollableCarouselItem
                          key={`carousel-${tick}-${index}`}
                          className="h-[7.125rem] basis-[32%] sm:basis-[18%] md:h-40 lg:basis-[15%] xl:basis-[11.25%]"
                        >
                          <ActivityCardDRC20
                            key={tick}
                            valuePrimary={value}
                            valueSecondary={sales}
                            valueSecondarySuffix={timeframe}
                            onClick={navigateToToken}
                            {...cardData}
                          />
                        </ScrollableCarouselItem>
                      );
                    })}
                  </ScrollabelCarousel>
                ) : (
                  <EmptyTopMover />
                ))}

              {isTabRecent &&
                (recentSales.length > 0 ? (
                  <ScrollabelCarousel>
                    {recentSales.map((data, index) => {
                      const { id: tick, value, sales, timeframe } = data;
                      const cardData = { ...{ tick }, ...data };
                      const navigateToToken = () => onNavigateToToken(tick);
                      return (
                        <ScrollableCarouselItem
                          key={`carousel-${tick}-${index}`}
                          className="h-[7.125rem] basis-[32%] sm:basis-[18%] md:h-40 lg:basis-[15%] xl:basis-[11.25%]"
                        >
                          <ActivityCardDRC20
                            key={tick}
                            valuePrimary={value}
                            valuePrimarySuffix={timeframe}
                            valueSecondary={sales}
                            onClick={navigateToToken}
                            {...cardData}
                          />
                        </ScrollableCarouselItem>
                      );
                    })}
                  </ScrollabelCarousel>
                ) : (
                  <EmptyTopMover />
                ))}

              {isTabWatchlist &&
                (watchlist.length > 0 ? (
                  <ScrollabelCarousel id="watchlist-drc20">
                    {watchlist.map((data, index) => {
                      const { id: tick, value, mcapInUSD } = data;
                      const cardData = { ...{ tick }, ...data };
                      const navigateToToken = () => onNavigateToToken(tick);
                      return (
                        <ScrollableCarouselItem
                          key={`carousel-${tick}-${index}`}
                          className="h-[7.125rem] basis-[32%] sm:basis-[18%] md:h-40 lg:basis-[15%] xl:basis-[11.25%]"
                        >
                          <ActivityCardDRC20
                            key={tick}
                            valuePrimary={value}
                            valueSecondary={mcapInUSD || ""}
                            valueSecondaryIsChange={false}
                            onClick={navigateToToken}
                            {...cardData}
                          />
                        </ScrollableCarouselItem>
                      );
                    })}
                  </ScrollabelCarousel>
                ) : (
                  <EmptyWatchlist />
                ))}
            </>
          )}
        </div>

        {/** Don't display the gradient on the <EmptyWatchlist /> */}
        {!(isTabWatchlist && watchlist.length === 0) && <TopMoverGradient />}
      </div>

      <TableExplore
        defaultSortId="volumeThirtyDays"
        columns={TableContentDRC20}
        data={drc20DataWithWatchlist}
        loading={isLoadingTableData}
        loadingMore={isLoadingMoreTableData}
      />

      <div
        className="pb-1"
        ref={bottomTableRef}
        key={"scrollbar-trading-collectibles"}
      />
      {/*There can be screen sizes causing the anchor tag not to get in focus of the screen supervisor. This helps.*/}
      <div className="pb-1" key={"scrollbar-trading-collectibles-buffer"} />

      <ModalInstall
        isVisible={openModalInstall}
        onClose={() => {
          hideInstallModal();
          updateRefreshKey(refreshKey + 1);
        }}
      />
    </>
  );
};
