import { useContext } from "react";
import { TxWalletContext } from "@/context/TxWalletProvider";

export const useTxWallet = () => {
  const txWalletContext = useContext(TxWalletContext);
  if (!txWalletContext) {
    throw new Error("useTxWallet must be used within a TxWalletProvider");
  }

  return txWalletContext;
};
