import { useCallback, useState } from "react";
import { Drc20Listing, Utxo } from "@/types";
import { AccountDrc20Data } from "@/types/inscription.ts";

type UseGetMaxSendableDrc20Params = {
  accountData?: AccountDrc20Data;
  amount: string;
  token?: string;
  listings?: Drc20Listing[];
  isForSending: boolean;
};

type UseGetMaxSendableDrc20Return = {
  transferInscriptions: Utxo[];
  maxListable: number;
  getClearableTransferInscriptions: () => void;
  setDrc20Listings: (listings?: Drc20Listing[]) => void;
};

const useGetMaxSendableDrc20 = ({
  accountData,
  amount,
  token,
  listings,
  isForSending,
}: UseGetMaxSendableDrc20Params): UseGetMaxSendableDrc20Return => {
  const [transferInscriptions, setTransferInscription] = useState<Utxo[]>([]);
  const [maxListable, setMaxListable] = useState(0);

  // setDrc20Listings can be called from the outside to update the listings
  const [drc20Listings, setDrc20Listings] = useState<
    Drc20Listing[] | undefined
  >(listings);

  const getClearableTransferInscriptions = useCallback(async () => {
    // return early if amount is less than max amount
    if (!token || !accountData || !accountData?.utxos?.length) {
      setMaxListable(Number(accountData?.available ?? "0"));
      setTransferInscription([]);
      return;
    }

    // 1. calculate max listable amount
    const listedAmount =
      drc20Listings?.reduce((acc, listing) => listing.amount + acc, 0) ?? 0;

    const pendingAmount = isForSending
      ? Number(accountData?.sendingPendingAmount ?? "0")
      : 0;

    setMaxListable(
      Number(accountData?.available ?? "0") +
        Number(accountData?.transferable ?? "0") +
        pendingAmount -
        listedAmount,
    );

    // 2. get clearable transfer inscriptions
    // we are only interested in clearable transfer inscriptions if the amount is greater than available amount
    if (Number(amount) > Number(accountData?.available ?? "0")) {
      // fetch all valid utxos of this drc20
      const validDrc20Utxos = accountData.utxos
        .filter((utxo) => utxo.drc20.valid)
        .map(({ script, shibes: satoshis, txid, vout }) => ({
          script,
          satoshis,
          txid,
          vout,
        }));

      // clearableTransferInscriptions are all valid utxos that are NOT listed
      const clearableUtxos = [];
      for (let i = 0; i < validDrc20Utxos.length; i++) {
        const x = validDrc20Utxos[i];
        const listed =
          drc20Listings?.find(
            (listing) => listing.inscriptionId === `${x.txid}i${x.vout}`,
          ) !== undefined;
        if (!listed) {
          clearableUtxos.push(x);
        }
      }

      setTransferInscription(clearableUtxos);
    } else {
      setTransferInscription([]);
    }
  }, [token, accountData, drc20Listings, isForSending, amount]);

  return {
    transferInscriptions,
    maxListable,
    getClearableTransferInscriptions,
    setDrc20Listings,
  };
};

export { useGetMaxSendableDrc20 };
