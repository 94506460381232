import React, { useCallback, useMemo, useState } from "react";

import { Button, InputPin, useToast } from "@/components";
import { useWalletManagement } from "@/contextHooks";
import { usePins } from "@/hooks";
import { handleError } from "@/utility";

import { Sheet, SheetProps } from "../base";

const PINS = ["currentPin", "pin", "repeatPin"];
const ITEMS = [
  {
    title: "Enter Current 6-Digit PIN",
    pin: PINS[0],
  },
  {
    title: "New 6-Digit PIN",
    pin: PINS[1],
  },
  {
    title: "Repeat New 6-Digit PIN",
    pin: PINS[2],
  },
];

export const ModalPingChange: React.FC<SheetProps> = ({
  isVisible = false,
  onClose,
}) => {
  const { toast } = useToast();
  const { pw, changePassword } = useWalletManagement() || {};
  const [error, setError] = useState<string | null>(null);

  const {
    pins,
    pinsMatch: match,
    handleSetPin,
    handleClearPin,
    isPinSet,
  } = usePins({ pinNames: PINS });

  const currentPinCorrect = useMemo(() => {
    if (!pw || !pins.currentPin.join("")) return true;
    return pins.currentPin.join("") === pw;
  }, [pins.currentPin, pw]);

  const pinsMatch = useMemo(() => match("pin", "repeatPin"), [match]);

  const pinsSet = useMemo(
    () => isPinSet("currentPin") && isPinSet("pin") && isPinSet("repeatPin"),
    [isPinSet],
  );

  const handleSetNewPin = useCallback(() => {
    if (!currentPinCorrect) {
      setError("Incorrect PIN. Please try again.");
      return;
    }

    if (!pinsMatch) {
      setError("PINs do not match. Please try again.");
      return;
    }

    if (pinsSet) {
      try {
        changePassword?.(pins.currentPin.join(""), pins.pin.join(""));
        toast({
          title: "PIN Changed",
          description: "Your PIN has been successfully changed.",
        });
        onClose?.();
      } catch (e: Error | unknown) {
        const message = handleError(e);
        toast({
          title: "Error Changing PIN",
          description: message,
          variant: "error",
        });
      } finally {
        setError(null);
        handleClearPin("currentPin");
        handleClearPin("pin");
        handleClearPin("repeatPin");
      }
    }
  }, [
    handleClearPin,
    changePassword,
    currentPinCorrect,
    onClose,
    pins,
    pinsSet,
    pinsMatch,
    toast,
  ]);

  return (
    <Sheet
      isVisible={isVisible}
      onClose={onClose}
      title="Change Password"
      classNameContent="flex flex-col flex-1 max-h-[800px] sm:h-[90vh] sm:aspect-none"
    >
      <div className="flex flex-1 flex-col space-y-4 p-4 pb-safe-or-4">
        <div className="flex flex-1 flex-col justify-center space-y-8">
          {ITEMS.map((item) => (
            <div
              className="flex flex-col items-center space-y-2"
              key={item.pin}
            >
              <div className="space-y-2">
                <span className="ml-2 text-sm text-white">{item.title}</span>
                <InputPin
                  pin={pins[item.pin]}
                  onChange={(index, value) =>
                    handleSetPin(item.pin, index, value)
                  }
                />
              </div>
            </div>
          ))}
        </div>

        <span className="text-center text-2xs text-red-500">
          {error || "\u00A0"}
        </span>
        <Button
          size="large"
          variant="default"
          onClick={handleSetNewPin}
          disabled={!pinsSet}
        >
          <span>Change PIN</span>
        </Button>
      </div>
    </Sheet>
  );
};
