export function evaluatePasswordStrength(password: string) {
  const hasMinimumLength = password.length >= 8; // Passwords should be at least 8 characters
  const hasUpperCase = /[A-Z]/.test(password); // Check for uppercase letters
  const hasLowerCase = /[a-z]/.test(password); // Check for lowercase letters
  const hasNumbers = /\d/.test(password); // Check for digits
  const hasSpecialChars = /[!@#$%^&*(),.?":{}|<>]/.test(password); // Check for special characters

  // Calculate the strength based on the presence of the criteria
  const strengthScore = [
    hasMinimumLength,
    hasUpperCase,
    hasLowerCase,
    hasNumbers,
    hasSpecialChars,
  ].filter(Boolean).length;

  let strengthLabel;
  switch (strengthScore) {
    case 0:
    case 1:
    case 2:
      strengthLabel = "Weak";
      break;
    case 3:
    case 4:
      strengthLabel = "Medium";
      break;
    case 5:
      strengthLabel = "Strong";
      break;
  }

  return {
    strengthLabel,
    rules: {
      hasMinimumLength,
      hasUpperCase,
      hasLowerCase,
      hasNumbers,
      hasSpecialChars,
    },
    isSecure: strengthScore >= 4, // A password is considered secure if it's medium or strong
  };
}
