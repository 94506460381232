import { motion } from "framer-motion";

import { ImageWithFallback } from "@/components/imageWithFallback";
import { Spinner } from "@/components/spinner";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { shortenAddress } from "@/lib/address";
import { cn } from "@/lib/utils";
import { CollectionListingProps, ListingStatus } from "@/types";
import { getFormattedValue, getTimeDifference } from "@/utility";
import { ColumnDef } from "@tanstack/react-table";

import {
  ChangeCell,
  ChangeCellType,
  HeaderCell,
  NumberCell,
  TextCell,
} from "../components";
import { handleSelectRow } from "./handleSelectRow";
import { Link } from "react-router-dom";

export const TableContentListingsCollection: ColumnDef<CollectionListingProps>[] =
  [
    {
      id: "select",
      header: () => {
        return <HeaderCell title="" aligned="left" className="mx-2 w-4" />;
      },
      cell: ({ row }) => {
        const { isSelected, userIsSeller } = row.original;
        const onClick = () =>
          userIsSeller ? undefined : handleSelectRow(row, !isSelected);
        const classNameSelection = isSelected
          ? "bg-primary-500 text-white"
          : "text-text-tertiary";
        return (
          <div
            className={cn(
              "text-text-xs mx-2 h-4 w-4 overflow-hidden rounded-sm",
              classNameSelection,
            )}
            onClick={onClick}
            aria-disabled={userIsSeller}
          >
            <Checkbox
              checked={isSelected}
              className={cn(
                "rounded-none",
                isSelected && "border-transparent",
                userIsSeller && "border-none",
              )}
            />
          </div>
        );
      },
    },
    {
      accessorKey: "item",
      header: () => {
        return <HeaderCell title="Item" aligned="left" className="w-28" />;
      },
      cell: ({ row }) => {
        const {
          name,
          status,
          // onBuy,
          isSelected,
          onNavigateToListing,
          imageURI,
          isLoading,
          inscriptionNumber,
          inscriptionId,
          userIsSeller,
        } = row.original;
        const onBuy = userIsSeller
          ? undefined
          : () => handleSelectRow(row, !isSelected);
        const onClickListing = () => onNavigateToListing(inscriptionId);
        return (
          <motion.div
            className="flex w-28 flex-row items-center space-x-2 lg:space-x-4"
            whileHover={{ scale: 1.05 }}
            transition={{ duration: 0.1 }}
          >
            <div
              className="relative h-9 w-9 overflow-visible lg:h-14 lg:w-14"
              onClick={onBuy}
            >
              <ImageWithFallback
                image={imageURI}
                className="h-9 w-9 rounded-md lg:h-14 lg:w-14"
              />
              {status == ListingStatus.Pending ? (
                <div className="absolute -bottom-0.5 -right-0.5 h-3 w-3 rounded-full bg-background-secondary lg:-bottom-1 lg:-right-1 lg:h-4 lg:w-4 xl:bg-background-primary">
                  <Spinner size={12} />
                </div>
              ) : (
                <Button
                  size="icon"
                  shape="circle"
                  disabled={isLoading}
                  className="absolute -bottom-2 -right-2 h-6 w-6 items-center justify-center border-none bg-background-secondary disabled:opacity-100 lg:-bottom-3 lg:-right-3 lg:h-7 lg:w-7 xl:bg-background-primary"
                >
                  {isLoading ? (
                    <Spinner size={12} />
                  ) : userIsSeller ? (
                    <span className="material-symbols-rounded text-xs text-text-primary">
                      person
                    </span>
                  ) : (
                    <span className="material-symbols-rounded text-xs text-text-primary">
                      shopping_cart
                    </span>
                  )}
                </Button>
              )}
            </div>

            <div className="flex flex-col -space-y-1" onClick={onClickListing}>
              <span className="w-20 truncate text-sm font-medium uppercase text-text-primary">
                {name}
              </span>
              <span className="text-2xs text-text-tertiary">
                {inscriptionNumber}
              </span>
            </div>
          </motion.div>
        );
      },
    },
    {
      accessorKey: "price",
      header: ({ column }) => {
        return (
          <HeaderCell
            title="Price"
            aligned="right"
            column={column}
            withSorting
            className="w-24"
          />
        );
      },
      cell: ({ row }) => {
        const { price, isSelected, currency, currentDogePrice } = row.original;
        const onClick = () => handleSelectRow(row, !isSelected);

        const { value: priceForCurrency } = getFormattedValue(
          price,
          currentDogePrice,
          currency,
          true,
        );
        return (
          <NumberCell
            value={priceForCurrency}
            onClick={onClick}
            className="w-24"
          />
        );
      },
    },
    {
      accessorKey: "floorDifferencePercentage",
      header: ({ column }) => {
        return (
          <HeaderCell
            title="Floor Diff."
            aligned="right"
            column={column}
            withSorting
            className="w-24"
          />
        );
      },
      cell: ({ row }) => {
        const { floorDifferencePercentage, isSelected } = row.original;
        const onClick = () => handleSelectRow(row, !isSelected);
        return (
          <ChangeCell
            change={floorDifferencePercentage}
            type={ChangeCellType.FloorDifference}
            onClick={onClick}
            className="w-24"
          />
        );
      },
    },
    {
      accessorKey: "lastSalePrice",
      header: ({ column }) => {
        return (
          <HeaderCell
            title="Last Sale"
            aligned="right"
            column={column}
            withSorting
            className="w-24"
          />
        );
      },
      cell: ({ row }) => {
        const { lastSalePrice, isSelected, currency, currentDogePrice } =
          row.original;
        const onClick = () => handleSelectRow(row, !isSelected);

        const { value: lastSalePriceForCurrency } = getFormattedValue(
          lastSalePrice,
          currentDogePrice,
          currency,
          true,
        );
        return (
          <NumberCell
            value={lastSalePriceForCurrency}
            onClick={onClick}
            className="w-24"
          />
        );
      },
    },
    {
      accessorKey: "createdAt",
      header: ({ column }) => {
        return (
          <HeaderCell
            title="Listed"
            aligned="right"
            column={column}
            withSorting
            className="w-24"
          />
        );
      },
      cell: ({ row }) => {
        const { createdAt, isSelected } = row.original;
        const onClick = () => handleSelectRow(row, !isSelected);
        return (
          <TextCell
            value={getTimeDifference(createdAt)}
            onClick={onClick}
            className="w-24"
          />
        );
      },
    },
    {
      accessorKey: "address",
      header: () => {
        return <HeaderCell title="Seller" aligned="left" className="w-24" />;
      },
      cell: ({ row }) => {
        const { address, isSelected } = row.original;
        const onClick = () => handleSelectRow(row, !isSelected);
        return (
          <TextCell
            value={shortenAddress(address)}
            onClick={onClick}
            className="w-24 text-left"
          />
        );
      },
    },
    {
      accessorKey: "actions",
      header: () => (
        <HeaderCell title="" aligned="right" className="mr-4 w-16" />
      ),
      cell: ({ row }) => {
        const {
          isLoading,
          status,
          userIsSeller,
          collectionSymbol,
          inscriptionId,
          isSelected,
        } = row.original;
        const onClick = () =>
          userIsSeller ? undefined : handleSelectRow(row, !isSelected);
        return userIsSeller ? (
          <div className="mr-4 flex w-16 flex-row justify-end">
            <Link to={`/collectible/${collectionSymbol}/${inscriptionId}`}>
              <Button
                size="small"
                variant="inverse"
                shape="circle"
                className="h-7 w-14 bg-text-highlight text-text-primary"
              >
                View
              </Button>
            </Link>
          </div>
        ) : (
          <div className="mr-4 flex w-16 flex-row justify-end">
            <Button
              size="small"
              variant="inverse"
              shape="circle"
              className="h-7 w-14"
              onClick={onClick}
              disabled={isLoading || status === ListingStatus.Pending}
            >
              {isLoading ? <Spinner size={16} /> : "Buy"}
            </Button>
          </div>
        );
      },
    },
  ];
