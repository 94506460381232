import { NETWORK_FEES, NetworkFeeOption } from "@/constants";
import { wait } from "@/lib/time";
import { useEffect, useState } from "react";

const useNetworkFees = () => {
  const [networkFees, setNetworkFees] = useState<NetworkFeeOption[]>();
  const fetchNetworkFees = async () => {
    // TODO: use real api when ready, in the meantime mock fetching with timeout
    await wait(1000);
    setNetworkFees(NETWORK_FEES);
  };

  useEffect(() => {
    fetchNetworkFees();
  }, []);

  return networkFees;
};

export { useNetworkFees };
