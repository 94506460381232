import { DoginalListing } from "@/types/collection.ts";
import {
  FilterListingsCollection,
  FilterListingsDRC20,
  FilterListingsDunes,
} from "@/types/filter.ts";
import {
  SortListingsCollection,
  SortListingsDRC20,
  SortListingsDunes,
} from "@/types/sort.ts";

import { Currency } from "./currency";

export enum ListingStatus {
  Listed = "listed",
  Unlisted = "unlisted",
  Pending = "pending",
  Sold = "sold",
}

export type Drc20Listing = {
  address: string;
  amount: number;
  calcUnitPrice: number;
  createdAt: string;
  inscriptionId: string;
  inscriptionNumber: number;
  marketType: string;
  offerId: string;
  price: number;
  tick: string;
  unitPrice: number;
  id: string;
  status: ListingStatus;
};

export interface TokenListingProps {
  tick: string;
  symbol?: string;
  offerId: string;

  amount: number;
  inscriptionNumber: number;

  unitPrice: number;
  totalPrice: number;
  floorDifferencePercentage: number;

  address: string;
  createdAt: string;
  status: ListingStatus;

  isLoading: boolean;
  isSelected: boolean;

  currency: Currency;
  currentDogePrice: number;

  userIsSeller: boolean;

  onBuy: () => void;
  onAction: () => void;
  toggleSelected?: (value: string | boolean) => void;
}

export type CollectionListingProps = DoginalListing & {
  collectionSymbol: string;
  collectionName: string;
  offerId: string;
  address: string;
  status: ListingStatus;

  inscriptionId: string;
  inscriptionNumber: number;
  imageURI: string;

  price: number;

  lastSalePrice: number;
  floorDifferencePercentage: number;

  isLoading: boolean;
  isPending?: boolean;
  isSelected: boolean;

  currency: Currency;
  currentDogePrice: number;

  onBuy: () => void;
  onAction: () => void;
  onNavigateToListing: (offerId: string) => void;
  toggleSelected?: (value: string | boolean) => void;
  floorPrice?: number | null;

  userIsSeller?: boolean;
  isSelectable?: boolean;
};

export type FetchDoginalListingsReturn = {
  refetch: (collectionSymbol: string) => Promise<void>;
  data: DoginalListing[];
  isLoading: boolean;
  hasMoreData: boolean;
  total: number;
  isLoadingMoreData: boolean;
  setFilters: (
    filters: (prev: FilterListingsCollection) => FilterListingsCollection,
  ) => void;
  setSort: (sort?: SortListingsCollection) => void;
  setOffset: (offset: number) => void;
  offset: number;
  sort: SortListingsCollection;
  filters: FilterListingsCollection;
  reset: (
    filters?: FilterListingsCollection,
    sorting?: SortListingsCollection,
    cachebreaker?: boolean,
  ) => void;
  reset_for_symbol: (forCurrencySymbol?: string) => void;
  floorPrice: null | number;
};

export type FetchDrc20ListingsReturn = {
  refetch: () => Promise<void>;
  data: Drc20Listing[];
  isLoading: boolean;
  hasMoreData: boolean;
  total: number;
  isLoadingMoreData: boolean;
  setFilters: (
    filters: (prev: FilterListingsDRC20) => FilterListingsDRC20,
  ) => void;
  setSort: (sort?: SortListingsDRC20) => void;
  setOffset: (offset: number) => void;
  offset: number;
  sort: SortListingsDRC20;
  filters: FilterListingsDRC20;
  reset: (filters?: FilterListingsDRC20, sorting?: SortListingsDRC20) => void;
  hasActiveFilters?: boolean;
  hasActiveSorting?: boolean;
  hasActiveSearch?: boolean;
};

// SDOGGS enhancements
export type DuneListing = {
  address: string;
  amount: number;
  calcUnitPrice: number;
  createdAt: string;
  txHash: string;
  txIndex: string;
  marketType: string;
  offerId: string;
  price: number;
  tick: string;
  unitPrice: number;
  id: string;
  status: ListingStatus;
};

export type FetchDuneListingsReturn = {
  refetch: () => Promise<void>;
  data: DuneListing[];
  isLoading: boolean;
  hasMoreData: boolean;
  total: number;
  isLoadingMoreData: boolean;
  setFilters: (
    filters: (prev: FilterListingsDunes) => FilterListingsDunes,
  ) => void;
  setSort: (sort?: SortListingsDunes) => void;
  setOffset: (offset: number) => void;
  offset: number;
  sort: SortListingsDunes;
  filters: FilterListingsDunes;
  reset: (filters?: FilterListingsDunes, sorting?: SortListingsDunes) => void;
  hasActiveFilters?: boolean;
  hasActiveSorting?: boolean;
  hasActiveSearch?: boolean;
};

export interface DunesMintingProps {
  duneId: string;
  tick: string;
  symbol: string;

  divisibility: number;

  amount: number;
  limit: number;

  unitPrice: number;
  totalPrice: number;

  address: string;
  receiver: string | undefined;

  isLoading: boolean;
  isSelected: boolean;

  currency: Currency;
  currentDogePrice: number;

  onMint: () => void;
}
