import { NumberFormatType, formatNumber } from "@/lib/numbers";

export function formatValueForChange(value: number, hideZero = true) {
  if (!value && hideZero) return "";

  const formattedValue = formatNumber({
    value: value,
    type: NumberFormatType.Percentage,
  });
  const isPositive = value > 0;
  const isNegative = value < 0;
  return isPositive
    ? `+${formattedValue}`
    : isNegative
      ? `${formattedValue}`
      : formattedValue;
}
