import { AnimatePresence } from "framer-motion";
import React, { useCallback, useMemo, useState } from "react";

import {
  AnimatedClickable,
  Button,
  InputPassword,
  InputPin,
  useToast,
} from "@/components";
import { useWalletManagement } from "@/contextHooks";
import { useSecurityPreference } from "@/contextHooks";
import { usePasswords, usePins } from "@/hooks";
import { cn } from "@/lib/utils";
import { handleError } from "@/utility";

import { AnimatedContent, Sheet, SheetProps } from "../base";

enum PreferenceType {
  Password = "password",
  Pin = "pin",
}

interface ChangePreferenceTypeState {
  variant: PreferenceType | undefined;
  step: number;
}

interface PreferenceListItemTagProps {
  label: string;
  color: string;
}

interface PreferenceListItemProps {
  icon: string;
  title: string;
  selected: boolean;
  description: string;
  onClick: () => void;
  tag: PreferenceListItemTagProps;
}

export const ModalSecurityPreference: React.FC<SheetProps> = ({
  isVisible = false,
  onClose,
}) => {
  const { toast } = useToast();
  const { pw, changePassword } = useWalletManagement() || {};
  const [error, setError] = useState<string | null>(null);
  const { securityPreference, setSecurityPreference } = useSecurityPreference();

  const [selected, setSelected] = useState(securityPreference);

  const [process, setProcess] = useState<ChangePreferenceTypeState>({
    variant: undefined,
    step: 0,
  });

  const title = useMemo(() => {
    if (process.variant === PreferenceType.Password) {
      return "Change To Password";
    } else if (process.variant === PreferenceType.Pin) {
      return "Change To PIN";
    }
    return "Change Security Preference";
  }, [process]);

  const { pins, pinsMatch, handleSetPin, handleClearPin, isPinSet } = usePins({
    pinNames: ["currentPin", "pin", "repeatPin"],
  });

  const {
    passwords,
    handleSetPassword,
    handleClearPassword,
    passwordSet,
    passwordsMatch,
    passwordRulesMet,
  } = usePasswords({
    passwordNames: ["currentPassword", "password", "repeatPassword"],
  });

  const isCurrentPinCorrect = useMemo(() => {
    if (!pw || !pins.currentPin.join("")) return true;
    return pins.currentPin.join("") === pw;
  }, [pins.currentPin, pw]);

  const isCurrentPasswordCorrect = useMemo(() => {
    if (!pw || !passwords.currentPassword) return true;
    return passwords.currentPassword === pw;
  }, [passwords.currentPassword, pw]);

  const preferenceList: PreferenceListItemProps[] = useMemo(
    () => [
      {
        title: "Password",
        description:
          "Your wallet is secured by a longer alphanumeric password.",
        icon: "password",
        tag: {
          label: "More Secure",
          color: "bg-green-900",
        },
        selected: selected === "password",
        onClick: () => setSelected("password"),
      },
      {
        title: "PIN",
        description: "Your wallet is secured by a more convenient 6-digit PIN.",
        icon: "pin",
        tag: {
          label: "Convenient",
          color: "bg-indigo-900",
        },
        selected: selected === "pin",
        onClick: () => setSelected("pin"),
      },
    ],
    [selected],
  );

  // Change Security Preference Locally
  const handleChangeSecurityPreference = useCallback(() => {
    setProcess({
      variant: selected as PreferenceType,
      step: 1,
    });
  }, [selected]);

  // Change Security Preference To Password
  const handleChangeSecurityPreferenceToPassword = useCallback(() => {
    if (!isCurrentPinCorrect) {
      setError("Incorrect PIN. Please try again.");
      return;
    }

    if (!passwordsMatch("password", "repeatPassword")) {
      setError("Passwords do not match. Please try again.");
      return;
    }

    if (
      passwordSet("password") &&
      passwordSet("repeatPassword") &&
      passwordRulesMet("password")
    ) {
      try {
        changePassword?.(pins.currentPin.join(""), passwords.password);
        setSecurityPreference("password");
        toast({
          title: "Security Preference Changed",
          description:
            "Your security preference has been successfully changed to Password.",
        });
      } catch (e: Error | unknown) {
        const message = handleError(e);
        toast({
          title: "Error Changing Security Preference",
          description: message,
          variant: "error",
        });
      } finally {
        onClose?.();
        setError(null);
        handleClearPin("currentPin");
        handleClearPassword("password");
        handleClearPassword("repeatPassword");
        setProcess({
          variant: undefined,
          step: 0,
        });
      }
    }
  }, [
    setSecurityPreference,
    isCurrentPinCorrect,
    passwordRulesMet,
    passwordSet,
    passwordsMatch,
    changePassword,
    handleClearPin,
    handleClearPassword,
    onClose,
    pins,
    passwords,
    toast,
  ]);

  // Change Security Preference To Pin
  const handleChangeSecurityPreferenceToPin = useCallback(() => {
    if (!isCurrentPasswordCorrect) {
      setError("Incorrect Password. Please try again.");
      return;
    }

    if (!passwordSet("currentPassword")) {
      setError("Password is required. Please try again.");
      return;
    }

    if (!pinsMatch("pin", "repeatPin")) {
      setError("PINs do not match. Please try again.");
      return;
    }

    if (isPinSet("pin") && isPinSet("repeatPin")) {
      try {
        changePassword?.(passwords.currentPassword, pins.pin.join(""));
        setSecurityPreference("pin");
        toast({
          title: "Security Preference Changed",
          description:
            "Your security preference has been successfully changed to PIN.",
        });
      } catch (e: Error | unknown) {
        const message = handleError(e);
        toast({
          title: "Error Changing Security Preference",
          description: message,
          variant: "error",
        });
      } finally {
        onClose?.();
        setError(null);
        handleClearPassword("currentPassword");
        handleClearPin("pin");
        handleClearPin("repeatPin");
        setProcess({
          variant: undefined,
          step: 0,
        });
      }
    }
  }, [
    isCurrentPasswordCorrect,
    pinsMatch,
    setSecurityPreference,
    passwordSet,
    changePassword,
    handleClearPin,
    handleClearPassword,
    onClose,
    pins,
    passwords,
    toast,
    isPinSet,
  ]);

  return (
    <Sheet
      isVisible={isVisible}
      onClose={onClose}
      title={title}
      classNameContent="flex flex-col h-full pt-safe max-h-[800px] sm:h-[90vh] sm:aspect-none"
    >
      <div className="relative flex flex-1 flex-col space-y-4 p-4 pb-safe-or-4">
        <AnimatePresence>
          {process.step === 0 && (
            <AnimatedContent id="security-preference-list">
              <div className="flex flex-1 flex-col space-y-4">
                {preferenceList.map((item, index) => (
                  <AnimatedClickable
                    key={index}
                    onClick={item.onClick}
                    whileTap={{ scale: 1 }}
                    whileHover={{ scale: 1.01 }}
                    className={cn(
                      "flex w-full flex-row items-center space-x-8 rounded-xl border border-border-primary bg-background-secondary p-4 text-white",
                      item.selected &&
                        "border-border-tertiary bg-background-tertiary",
                    )}
                  >
                    <span className="material-symbols-rounded text-4xl text-text-primary">
                      {item.icon}
                    </span>
                    <div className="flex flex-1 flex-col space-y-2">
                      <div className="flex flex-row items-center space-x-2">
                        <span className="flex-1 text-lg font-bold">
                          {item.title}
                        </span>
                        {item.tag && (
                          <div
                            className={cn(
                              "rounded-full px-2 py-1 text-2xs font-bold uppercase",
                              item.tag?.color,
                            )}
                          >
                            {item.tag?.label}
                          </div>
                        )}
                      </div>
                      <p>{item.description}</p>
                    </div>
                  </AnimatedClickable>
                ))}
              </div>

              <Button
                size="large"
                disabled={selected === securityPreference}
                onClick={handleChangeSecurityPreference}
              >
                Continue
              </Button>
            </AnimatedContent>
          )}

          {process.step === 1 &&
            process.variant === PreferenceType.Password && (
              <AnimatedContent id="security-change-to-password">
                <div className="flex flex-1 flex-col space-y-4">
                  <div className="flex flex-col items-center space-y-4 rounded-lg bg-background-secondary p-4">
                    <span className="ml-2 text-center text-xs font-bold text-white">
                      Your Current PIN
                    </span>
                    <InputPin
                      pin={pins["currentPin"]}
                      onChange={(index, value) =>
                        handleSetPin("currentPin", index, value)
                      }
                      className="bg-background-secondary"
                      classNameInput="bg-background-primary"
                    />
                  </div>

                  <div className="flex flex-col space-y-4 rounded-lg bg-background-secondary p-4">
                    <span className="ml-2 text-center text-xs font-bold text-white">
                      Your New Password
                    </span>
                    <InputPassword
                      withCriteria
                      password={passwords["password"]}
                      onChange={(event) =>
                        handleSetPassword("password", event.target.value)
                      }
                    />

                    <InputPassword
                      password={passwords["repeatPassword"]}
                      onChange={(event) =>
                        handleSetPassword("repeatPassword", event.target.value)
                      }
                    />
                  </div>
                </div>

                <div className="flex flex-col space-y-1">
                  <span className="text-center text-2xs text-red-500">
                    {error || "\u00A0"}
                  </span>

                  <Button
                    size="large"
                    disabled={
                      !isPinSet("currentPin") ||
                      !passwordSet("password") ||
                      !passwordSet("repeatPassword") ||
                      !passwordRulesMet("password")
                    }
                    onClick={handleChangeSecurityPreferenceToPassword}
                  >
                    Change To Password
                  </Button>
                </div>
              </AnimatedContent>
            )}

          {process.step === 1 && process.variant === PreferenceType.Pin && (
            <AnimatedContent id="security-change-to-password">
              <div className="flex flex-1 flex-col space-y-4">
                <div className="flex flex-col space-y-4 rounded-lg bg-background-secondary p-4">
                  <span className="ml-2 text-center text-xs font-bold text-white">
                    Your Current Password
                  </span>
                  <InputPassword
                    placeholder="Current Password"
                    password={passwords["currentPassword"]}
                    onChange={(event) =>
                      handleSetPassword("currentPassword", event.target.value)
                    }
                  />
                </div>

                <div className="flex flex-col items-center space-y-4 rounded-lg bg-background-secondary p-4">
                  <span className="ml-2 text-center text-xs font-bold text-white">
                    Your New PIN
                  </span>

                  <div className="flex flex-col space-y-2">
                    <span className="ml-2 text-xs text-white">
                      New 6-Digit Pin
                    </span>
                    <InputPin
                      pin={pins["pin"]}
                      onChange={(index, value) =>
                        handleSetPin("pin", index, value)
                      }
                      className="bg-background-secondary"
                      classNameInput="bg-background-primary"
                    />
                  </div>

                  <div className="flex flex-col space-y-2">
                    <span className="ml-2 text-xs text-white">
                      Repeat New 6-Digit Pin
                    </span>
                    <InputPin
                      pin={pins["repeatPin"]}
                      onChange={(index, value) =>
                        handleSetPin("repeatPin", index, value)
                      }
                      className="bg-background-secondary"
                      classNameInput="bg-background-primary"
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-col space-y-1">
                <span className="text-center text-2xs text-red-500">
                  {error || "\u00A0"}
                </span>
                <Button
                  size="large"
                  onClick={handleChangeSecurityPreferenceToPin}
                >
                  Change To PIN
                </Button>
              </div>
            </AnimatedContent>
          )}
        </AnimatePresence>
      </div>
    </Sheet>
  );
};
