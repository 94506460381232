type Bip32 = {
  public: number;
  private: number;
};

type DogeNetwork = {
  messagePrefix: string;
  bip32: Bip32;
  pubKeyHash: number;
  scriptHash: number;
  wif: number;
  bech32: string;
};

const dogecoinNetwork: DogeNetwork = {
  bech32: "",
  messagePrefix: "\x19Dogecoin Signed Message:\n",
  bip32: {
    public: 0x02facafd, // This value corresponds to the public key prefix for Dogecoin
    private: 0x02fac398, // This value corresponds to the private key prefix for Dogecoin
  },
  pubKeyHash: 0x1e, // This value corresponds to the public key hash prefix for Dogecoin (30 in decimal)
  scriptHash: 0x16, // This value corresponds to the script hash prefix for Dogecoin (22 in decimal)
  wif: 0x9e, // This value corresponds to the WIF prefix for Dogecoin (158 in decimal)
};

export { dogecoinNetwork };
