import { useContext } from "react";
import { DunesWatchlistContext } from "@/context/DunesWatchListContext.ts";

export const useDunesWatchlist = () => {
  const context = useContext(DunesWatchlistContext);

  if (!context) {
    throw new Error(
      "useDunesWatchlist must be used within a WatchlistProvider",
    );
  }

  return context;
};
