import React from "react";
import { Spinner } from "../spinner";
import { cn } from "@/lib/utils";


const PendingIndicatorMessage = ({ message, className }: { message?: string, className?: string }) => {
  if (
    typeof message !== "string" ||
    message.length === 0
  ) {
    return null;
  }

  return (
    <div 
      className={cn(
        "mx-auto flex flex-row items-center space-x-1.5 rounded-full bg-background-tertiary px-2 py-0.5",
        className,
      )}
    >
      <Spinner size={10} />
      <span
        className={"text-2xs font-bold text-text-primary"}
      >
        {message}
      </span>
    </div>
  );
};

export { PendingIndicatorMessage };
