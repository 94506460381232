import React, { useCallback } from "react";

import { Button } from "@/components";
import { cn } from "@/lib/utils";

interface ModalNavigationProps {
  onBack?: () => void;
  onNext?: () => void;
  backDisabled?: boolean;
  nextDisabled?: boolean;
  title: string;
  subtitle?: string;
  iconBack?: "arrow_back_ios" | "close";
  iconNext?: "arrow_forward_ios" | "close";
  className?: string;
}

export const ModalNavigation: React.FC<ModalNavigationProps> = ({
  title,
  subtitle = "\u00A0",
  backDisabled,
  nextDisabled,
  iconBack = "arrow_back_ios",
  iconNext = "close",
  onBack,
  onNext,
  className,
}) => {
  const isNextVisible = !!onNext;
  const isBackVisible = !!onBack;
  const isBackDisabled = backDisabled || !onBack || !isBackVisible;
  const isNextDisabled = nextDisabled || !onNext || !isNextVisible;

  const handleBack = useCallback(() => {
    onBack?.();
  }, [onBack]);

  const handleNext = useCallback(() => {
    onNext?.();
  }, [onNext]);

  return (
    <div className={cn("flex flex-col -space-y-2", className)}>
      <div className="flex flex-1 flex-row justify-between">
        <Button
          size="icon"
          variant="ghost"
          onClick={handleBack}
          disabled={isBackDisabled}
          className={cn(!isBackVisible && "invisible")}
        >
          <span className="material-symbols-rounded text-lg">{iconBack}</span>
        </Button>

        <div className="flex flex-col items-center justify-center space-y-0">
          <h2 className="text-center text-lg font-bold text-text-primary">
            {title}
          </h2>
        </div>

        <Button
          size="icon"
          variant="ghost"
          onClick={handleNext}
          disabled={isNextDisabled}
          className={cn(!isNextVisible && "invisible")}
        >
          <span className="material-symbols-rounded text-lg">{iconNext}</span>
        </Button>
      </div>
      <span className="text-center text-2xs text-text-tertiary">
        {subtitle?.toUpperCase()}
      </span>
    </div>
  );
};
