export interface AccountProps {
  address?: string;
  isOverview?: boolean;
}

export enum ChangeRange {
  TWENTY_FOUR_HOURS = "24h",
  SEVEN_DAYS = "7d",
  ONE_MONTH = "30d",
  SIX_MONTH = "6m",
  ONE_YEAR = "1y",
  ALL = "all",
}
