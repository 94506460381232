import React from "react";

export interface BalanceProps {
  value: string;
  icon?: string;
}

export const Balance: React.FC<BalanceProps> = ({ value, icon }) => {
  return (
    <div className="flex h-8 w-16 flex-shrink-0 select-none flex-row items-center justify-center space-x-1 rounded-md text-xs font-semibold text-text-primary">
      {icon && <span>{icon}</span>}
      <span
        dangerouslySetInnerHTML={{
          __html: value,
        }}
      />
    </div>
  );
};
