import { FEATURE_ACTIVATION_DUNES } from "@/constants";
import { marketplaceApiV2, sdoggsApiV2 } from "@/lib/fetch";
import { BalanceOverview } from "@/types";

const defaultEstimatedTotalValue = {
  estimated_total_value: {
    unsafeBalance: 0,
    safeBalance: 0,
    drc20s: {
      value: 0,
      amount: 0,
      performance: 0,
    },
    doginals: {
      value: 0,
      amount: 0,
      performance: 0,
    },
    dunes: {
      value: 0,
      amount: 0,
      performance: 0,
    },
  },
};

const fetchBalanceOverview = async (
  address: string,
): Promise<BalanceOverview> => {
  if (!address) return defaultEstimatedTotalValue;
  const endpoint = `/address/estimated_total_value/${address}`;
  const response = await marketplaceApiV2(false).get<BalanceOverview>(endpoint);

  // SDOGGS Dunes enhancement start
  if (FEATURE_ACTIVATION_DUNES === "true") {
    const responseDunes =
      await sdoggsApiV2(false).get<BalanceOverview>(endpoint);

    if (response.data?.estimated_total_value) {
      response.data.estimated_total_value.dunes =
        responseDunes.data?.estimated_total_value.dunes;
    }
  } else {
    response.data.estimated_total_value.dunes =
      defaultEstimatedTotalValue.estimated_total_value.dunes;
  }
  // SDOGGS Dunes enhancement end
  return response?.data;
};

export { fetchBalanceOverview, defaultEstimatedTotalValue };
export type { BalanceOverview };
