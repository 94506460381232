import { motion } from "framer-motion";
import React from "react";

import { cn } from "@/lib/utils";

import { contentTransition, contentVariants } from "./motion";

export const AnimatedContent: React.FC<{
  children: React.ReactNode;
  className?: string;
  id: string;
}> = ({ children, className, id }) => (
  <motion.div
    key={id}
    layout="position"
    initial="closed"
    animate="open"
    exit="exit"
    variants={contentVariants}
    transition={contentTransition}
    className={cn(
      "absolute bottom-0 left-0 right-0 top-0 flex flex-1 flex-col space-y-4 p-4",
      className,
    )}
  >
    {children}
  </motion.div>
);
