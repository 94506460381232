import React, { useCallback, useMemo } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

import { DOGE_LABS_LOGO_DESKTOP } from "@/constants";
import { useAppContentSettings, useBalance, useCurrency } from "@/contextHooks";
import { cn } from "@/lib/utils";
import { Currency, TickerElement } from "@/types";

import { AnimatedClickable, ToggleSwitch, CurrencySwitch } from "@/components";
import { SearchDesktop } from "../search";
import { Button } from "../ui/button";
import { LiveIndicator } from "@/components/appHeader/liveIndicator.tsx";
import { useOperatingSystem } from "@/hooks";

interface Link {
  name: string;
  path: string;
  disabled?: boolean;
}

interface AppHeaderDesktopProps {
  links: Link[];
  tickers: TickerElement[];
  className?: string;
}

export const AppHeaderDesktop: React.FC<AppHeaderDesktopProps> = ({
  links,
  tickers,
  className,
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { currency, setCurrency } = useCurrency();
  const { content, icon, contentName, changeContent } = useAppContentSettings();
  const { balanceInDOGEForHeader, balanceInUSDForHeader } = useBalance();
  const { isDesktop } = useOperatingSystem();

  // current url:
  // if pathname is /wallet/.* set remove the balance header
  const showBalance = !pathname.includes("/wallet/");

  // Toggle between USD and DOGE
  const toggleCurrency = useCallback(() => {
    setCurrency(currency === Currency.USD ? Currency.DOGE : Currency.USD);
  }, [currency, setCurrency]);

  // Calculate the balance value based on the selected currency
  const value = useMemo(() => {
    if (currency === Currency.USD) {
      return balanceInUSDForHeader;
    }
    return balanceInDOGEForHeader;
  }, [balanceInDOGEForHeader, balanceInUSDForHeader, currency]);

  // Check if the tab is active
  const isActive = useCallback(
    (link: string, index: number) => {
      // Explicitly handle the root path for the first link (index 0)
      return (
        pathname === link ||
        pathname.includes(link) ||
        (pathname === "/" && index === 0)
      );
    },
    [pathname],
  );

  const isDisabled = useCallback((link: Link) => {
    return link.disabled;
  }, []);

  return (
    <div
      className={cn(
        "sticky top-0 flex w-full flex-col border-b-0.5 border-border-primary bg-background-primary drop-shadow-xl",
        className,
      )}
      style={{ zIndex: 101 }}
    >
      <div className="flex w-full flex-row items-center justify-between space-x-4 p-4">
        <Button
          variant="ghost"
          size="default"
          shape="circle"
          className="items-start justify-start p-0"
          onClick={() => navigate("/")}
        >
          <img
            src="/images/icons/alpha-tag.png"
            alt="alpha phase"
            className="h-8"
          />
        </Button>
        <Ticker tickers={tickers} />
        <LiveIndicator isDesktop={true} />
      </div>

      <div className="flex w-full flex-row items-center justify-between space-x-4 border-t-0.5 border-border-primary bg-background-primary px-4 py-4">
        <div className="flex flex-row items-center space-x-8">
          <NavLink to="/trading" className="no-focus cursor-pointer">
            <Logo />
          </NavLink>

          <div className="flex flex-row space-x-4">
            {links.map((link, index) =>
              isDisabled(link) ? (
                <div
                  key={index}
                  className="h-fit cursor-not-allowed items-center justify-center rounded-full border-0 bg-background-primary px-4 py-2 text-sm font-semibold text-text-primary opacity-15"
                >
                  {link.name}
                </div>
              ) : (
                <NavLink
                  key={`NavLink-${index}`}
                  to={link.path}
                  className="no-focus"
                >
                  <AnimatedClickable
                    key={`AnimatedClickable-${index}`}
                    className={cn(
                      "h-fit cursor-pointer items-center justify-center rounded-full border-0.5 px-4 py-2 text-sm font-semibold text-text-primary hover:bg-background-tertiary",
                      isActive(link.path, index)
                        ? "border-border-secondary/50 bg-background-secondary"
                        : "border-transparent bg-background-primary",
                    )}
                    animate={{ opacity: isActive(link.path, index) ? 1 : 0.5 }}
                  >
                    {link.name}
                  </AnimatedClickable>
                </NavLink>
              ),
            )}
          </div>
        </div>

        <div className="flex flex-1 flex-row items-center justify-end space-x-4">
          <SearchDesktop />
          <ToggleSwitch
            value={content}
            contentName={contentName}
            icon={icon}
            onChange={changeContent}
            className={isDesktop ? "rounded-lg" : "hidden"}
          />
          <CurrencySwitch onClick={toggleCurrency} value={currency} />
          {showBalance && <Balance value={value} />}
        </div>
      </div>
    </div>
  );
};

const Logo: React.FC = () => (
  <div className="flex flex-row items-center space-x-3">
    <img src={DOGE_LABS_LOGO_DESKTOP} alt="logo" className="h-15 w-36" />
  </div>
);

interface BalanceProps {
  value: string;
}

const Balance: React.FC<BalanceProps> = ({ value }) => {
  return (
    <Button
      disabled={true}
      variant="default"
      size="default"
      className="min-w-20 border-0.5 border-border-secondary bg-background-primary text-sm font-semibold text-text-primary hover:bg-background-tertiary"
    >
      <span
        dangerouslySetInnerHTML={{
          __html: value,
        }}
      />
    </Button>
  );
};

interface TickerProps {
  tickers: TickerElement[];
  className?: string;
}

const Ticker: React.FC<TickerProps> = ({ tickers, className }) => {
  return (
    <div
      className={cn(
        "flex flex-1 flex-row items-center justify-center",
        className,
      )}
    >
      {tickers.map((ticker, index) => {
        const isLast = index === tickers.length - 1;
        return (
          <div
            key={index}
            className="flex flex-row items-center space-x-2 text-xs font-semibold"
          >
            <span className="text-text-primary">{ticker.label}:</span>
            <span className="text-text-highlight">{ticker.value}</span>
            {!isLast && (
              <>
                <span className="text-text-primary">•</span>
                <span> </span>
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};
