import { motion } from "framer-motion";
import { Link } from "react-router-dom";

import { Button, ImageWithFallback } from "@/components";
import { NumberFormatType, formatNumber } from "@/lib/numbers";
import { cn } from "@/lib/utils";
import { DunesToken } from "@/types";
import { getValueForCurrency } from "@/utility";
import { ColumnDef } from "@tanstack/react-table";

import { HeaderCell, NumberCell } from "../components";

export const TableInscribeDunes: ColumnDef<DunesToken>[] = [
  {
    accessorKey: "fdv",
    header: ({ column }) => {
      return (
        <HeaderCell
          title="Dunes"
          aligned="left"
          className="w-40 pl-4 lg:w-52 lg:pr-1"
          column={column}
          withSorting
        />
      );
    },
    cell: ({ row }) => {
      const { fdv, currentDogePrice, image, ticker, currency, onSelectForMint } = row.original;
      const valueForCurrency = getValueForCurrency(
        fdv,
        currency,
        currentDogePrice,
      );

      const value = formatNumber({
        value: valueForCurrency,
        type: NumberFormatType.Large_Number,
      });

      return (
        <a onClick={() => onSelectForMint && onSelectForMint(row.original.ticker)}>
          <motion.div
            whileHover={{ scale: 1.05 }}
            transition={{ duration: 0.1 }}
          >
            <div className="w-fit-content flex w-32 flex-row items-center space-x-2 lg:w-52 lg:space-x-4 overflow-x-scroll md:overflow-hidden md:hover:overflow-visible">
              <ImageWithFallback
                image={image}
                className="h-7 w-7 lg:h-10 lg:w-10"
              />
              <div className="flex flex-col lg:space-y-1">
                <span className="w-26 mb-1 font-medium text-xs md:text-sm md:truncate md:hover:text-clip lg:w-36 lg:text-md hover:bg-background-secondary hover:w-fit hover:pr-4">
                  {ticker}
                </span>
                <NumberCell
                  value={value}
                  currency={currency}
                  className="mb-1 w-fit text-xs text-text-tertiary lg:text-sm"
                />
              </div>
            </div>
          </motion.div>
        </a>
      );
    },
  },
  /*{
    accessorKey: "change24h",
    header: ({ column }) => {
      return (
        <HeaderCell
          title="24h"
          aligned="right"
          column={column}
          withSorting
          className="w-24"
        />
      );
    },
    cell: ({ row }) => {
      const { change24h } = row.original;
      return <ChangeCell change={change24h} className="w-24" />;
    },
  },
  {
    accessorKey: "change7d",
    header: ({ column }) => {
      return (
        <HeaderCell
          title="7d"
          aligned="right"
          column={column}
          withSorting
          className="w-24"
        />
      );
    },
    cell: ({ row }) => {
      const { change7d } = row.original;
      return <ChangeCell change={change7d} className="w-24" />;
    },
  },*/
  {
    accessorKey: "mints",
    header: ({ column }) => {
      return (
        <HeaderCell
          title="Mints"
          aligned="right"
          column={column}
          withSorting
          className="mr-4 w-24"
        />
      );
    },
    cell: ({ row }) => {
      const { mints } = row.original;
      const value = formatNumber({
        value: mints || 0,
        type: NumberFormatType.Large_Number,
      });
      return (
        <NumberCell value={value} hideZero className="mr-4 w-24" />
      );
    },
  },
  {
    id: "holders",
    accessorKey: "holders",
    header: ({ column }) => {
      return (
        <HeaderCell
          title="Holders"
          aligned="right"
          column={column}
          withSorting
          className="mr-4 w-24"
        />
      );
    },
    cell: ({ row }) => {
      const { holders } = row.original;
      const holderNumber = typeof holders === "number" ? holders : (holders as any).length;
      const value = formatNumber({
        value: holderNumber,
        type: NumberFormatType.Large_Number,
      });
      return (
        <NumberCell value={value} hideZero className="mr-4 w-24" />
      );
    },
  },
  {
    accessorKey: "actions",
    header: () => {
      return <HeaderCell title="Actions" aligned="left" className="w-40 pr-4" />;
    },
    cell: ({ row }) => {
      const { ticker, onSelectForMint } = row.original;

      return (
        <div className="flex flex-row w-40 items-center justify-left pr-4 space-x-2">
          <Link to={`/dune/${ticker}`}>
            <Button
              variant="default"
              size="small"
              shape="default"
            >
              <span
                className={cn(
                  "text-md text-uppercase",
                )}
              >
                Trade
              </span>
            </Button>
          </Link>
          <Button
            variant="highlight"
            size="small"
            shape="default"
            onClick={() => onSelectForMint && onSelectForMint(row.original.ticker)}
          >
            <span
              className={cn(
                "text-md text-uppercase",
              )}
            >
              Mint
            </span>
          </Button>
        </div>
      );
    },
  },
];
