import React, { PropsWithChildren, useContext } from "react";

import { UIContext } from "@/context";
import { cn } from "@/lib/utils";

interface AppScreenProps {
  withNavigationHeader?: boolean;
  withBottomNav?: boolean;
  className?: string;
}

export const AppScreen: React.FC<PropsWithChildren<AppScreenProps>> = ({
  children,
  className,
  withBottomNav,
}) => {
  const { bottomNavHeight } = useContext(UIContext);

  return (
    <div className="lg:show-scrollbar container-with-tabs flex flex-1 flex-col items-center">
      <div className="flex w-full flex-1 flex-col 2xl:w-[1560px]">
        <div
          className={cn("flex flex-1 flex-col overflow-x-clip", className)}
          style={{
            paddingBottom: withBottomNav ? bottomNavHeight : 0,
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};
