import React from "react";
import { ModalInstall, ModalOrientation } from "@/modals";
import { useOnboardingModalContext } from "@/contextHooks/useOnboardingModal.ts";
import { useNavigate } from "react-router-dom";

export const OnboardingModalsContainer: React.FC = () => {
  const {
    isInstallModalOpen,
    hideInstallModal,
    isOrientationModalOpen,
    hideOrientationModal,
  } = useOnboardingModalContext();
  const navigate = useNavigate();

  return (
    <>
      {isInstallModalOpen && (
        <ModalInstall
          isVisible={isInstallModalOpen}
          onClose={() => {
            hideInstallModal();
            navigate("/");
          }}
        />
      )}
      {isOrientationModalOpen && (
        <ModalOrientation
          isVisible={isOrientationModalOpen}
          onClose={() => {
            hideOrientationModal();
            navigate("/");
          }}
        />
      )}
    </>
  );
};
