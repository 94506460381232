import React, { useRef } from "react";

import { Button, Input } from "@/components";

import { AnimatedContent } from "../../base";

interface RecoveryPhraseInputViewProps {
  words: string[];
  allWordsEntered: boolean;
  hidePasteRecoveryPhrase?: boolean;
  onChangeWords: (index: number, value: string) => void;
  onContinue: () => void;
  onPasteRecoveryPhrase: () => void;
}

export const RecoveryPhraseInputView: React.FC<
  RecoveryPhraseInputViewProps
> = ({
  words,
  hidePasteRecoveryPhrase = false,
  allWordsEntered,
  onChangeWords,
  onContinue,
  onPasteRecoveryPhrase,
}) => {
  // Create an array of refs to access the input elements
  const inputRefs = useRef<Array<React.RefObject<HTMLInputElement>>>(
    words.map(() => React.createRef<HTMLInputElement>()),
  );

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
    index: number,
  ) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent form submission if it's in a form
      // Focus the next input when Enter is pressed
      const nextInput = inputRefs.current[index + 1];
      if (nextInput && nextInput.current) {
        nextInput.current.focus();
      }
    }
  };

  return (
    <AnimatedContent id="onboarding_import_2">
      {/** Header */}
      <div className="flex flex-1 flex-col justify-center space-y-2">
        <h2 className="text-center text-xl font-medium">
          Enter Recovery Phrase
        </h2>
        <p className="text-center text-xs">
          Enter your 12 words recovery phrase to import your wallet.
        </p>
      </div>

      {/** Recovery Phrase */}
      <div className="flex flex-1 flex-col justify-center space-y-1">
        <div className="grid grid-cols-3 gap-1">
          {words.map((word, index) => (
            <Input
              key={index}
              value={word}
              ref={inputRefs.current[index]}
              onChange={(e) => onChangeWords(index, e.target.value)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              placeholder={`${index + 1}.`}
              className="text-input border-border-secondary bg-background-primary p-2"
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              spellCheck="false"
            />
          ))}
        </div>
        {!hidePasteRecoveryPhrase && (
          <Button size="small" variant="ghost" onClick={onPasteRecoveryPhrase}>
            <span>Paste Recovery Phrase</span>
          </Button>
        )}
      </div>

      {/** Action */}
      <Button
        size="large"
        variant="default"
        onClick={onContinue}
        disabled={!allWordsEntered}
      >
        <span>Continue</span>
      </Button>
    </AnimatedContent>
  );
};
