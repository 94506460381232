import * as bitcoin from "bitcoinjs-lib";
import * as dogecore from "bitcore-lib-doge";
import * as ecc from "tiny-secp256k1";

import { LOW_NETWORK_FEE_RATE, MINER_FEE, SAFE_DUST_AMOUNT } from "@/constants";

import {
  fundWallet,
  getTotalTransactionFeeInSats,
} from "@/context/wallet/lib/transaction.ts";
import { marketplaceApiV2, sdoggsApiV2 } from "@/lib/fetch";
import { InscriptionType } from "@/types";
import { WalletForTx } from "@/types/transaction";
import { dogecoinNetwork, handleError } from "@/utility";

const { Transaction } = dogecore;
bitcoin.initEccLib(ecc);
import {
  BuildBuyItemTxsParams,
  BuildBuyItemTxWithFeeEstimation,
  BuildBuyMulitpleItemsTxsParams,
  BuyContents,
  BuyResult,
  GetOfferValidityResult,
  PutRefreshStatus,
  TxWallet,
} from "@/context/wallet/types.ts";

const buildItemsTxs = async (
  buildBuyItemsTxsParams: BuildBuyMulitpleItemsTxsParams,
): Promise<{
  items: BuildBuyItemTxWithFeeEstimation[];
  errors: Map<string, string>;
}> => {
  const { txWallet, offerIds, type, estimateFeesOnly } = buildBuyItemsTxsParams;
  const txsWithFeeEstimation: BuildBuyItemTxWithFeeEstimation[] = [];
  const errors: Map<string, string> = new Map();

  // @todo: show error message to user
  for (const offerId of offerIds) {
    try {
      const txWithFeeEstimation = await buildBuyItemTxs({
        txWallet,
        offerId,
        type,
        estimateFeesOnly,
      });
      txsWithFeeEstimation.push(txWithFeeEstimation);
    } catch (e: Error | unknown) {
      const errorMessage = handleError(e);
      console.error("buildItemsTxs - error", errorMessage);
      errors.set(offerId, errorMessage);
    }
  }

  return { items: txsWithFeeEstimation, errors };
};

const buildBuyItemTxs = async (
  buildBuyCollectibleTxsParams: BuildBuyItemTxsParams,
): Promise<BuildBuyItemTxWithFeeEstimation> => {
  const { txWallet, offerId, estimateFeesOnly, type } =
    buildBuyCollectibleTxsParams;

  if (!txWallet) {
    console.warn("buildBuyItemTxs - No wallet found");
    throw new Error("No wallet found");
  }

  // safe utxos
  if (txWallet.utxos.length === 0) {
    console.warn("buildBuyItemTxs - No utxos in wallet");
    throw new Error("No safe utxos in wallet");
  }

  console.log("buildBuyItemTxs - offerId", offerId);
  // 1. get seller psdt hex
  const sellerPsdtHex = await getSellerPsdtByOfferId(offerId, type);

  console.log("buildBuyItemTxs - sellerPsdtHex", sellerPsdtHex);
  // 2. prepare outputs for offer purchase
  // This means, we create a transaction with the needed outputs to
  // pay exactly the amount needed for the purchase
  // the tx is already signed, then
  const signedBuyerPrePurchaseTx = await prepareOutputsForOfferPurchase(
    sellerPsdtHex,
    txWallet,
    type
  );

  // We need to use txWallet.updateUtxos here, because in the caller, we loop through the offers to purchase.
  // So we need it here and after sending the tx to the server.
  if (!estimateFeesOnly) {
    // ensure the utxos in the wallet are updated for the next step
    txWallet.updateUtxos({ tx: signedBuyerPrePurchaseTx });
  }

  return {
    tx: signedBuyerPrePurchaseTx,
    txFeeInSats: getTotalTransactionFeeInSats(
      signedBuyerPrePurchaseTx,
      LOW_NETWORK_FEE_RATE,
    ),
    sellerPsdtHex,
  };
};

const getSellerPsdtByOfferId = async (
  offerId: string,
  type: InscriptionType,
): Promise<string> => {
  const { data } = await (
    type == InscriptionType.DUNE ? sdoggsApiV2(false) : marketplaceApiV2(false)
  ).get(`offer/${type}/psdt-hex?offerId=${offerId}`);

  if (data.status !== 0) {
    return data.psdtHex;
  } else {
    throw new Error("No such offer id");
  }
};

// This is used to get one input (later in the buy process) where the value is
// exactly the amount needed for the purchase. By that we avoid to
// have a change output in the buyer psdt.
const prepareOutputsForOfferPurchase = async (
  sellerPsdtHex: string,
  wallet: WalletForTx | TxWallet,
  type: InscriptionType,
) => {
  const offerPsdt = bitcoin.Psbt.fromHex(sellerPsdtHex, {
    network: dogecoinNetwork,
  });

  if (type == InscriptionType.DUNE) {
    const dunesUtxoPrice = offerPsdt.txOutputs[1].value;
    // contains the trading fee. The listing fee + service fee is deducted from the inscription price in the seller
    // psdt (seller gets the inscription price - listing fee - service fee)
    const servicePrice = offerPsdt.txOutputs[2].value;
    const utxoSats = bitcoin.Transaction.fromBuffer(
      offerPsdt.data.inputs[1].nonWitnessUtxo!,
    ).outs[offerPsdt.txInputs[1].index].value;

    const price = servicePrice + dunesUtxoPrice + MINER_FEE;

    // here we add 2 outputs for the first 2 dummy inputs of the seller psdt
    // these are set by convention, when the seller psdt is created
    const tx = new Transaction();
    tx.to(wallet.address, utxoSats); // Add an output with the given amount of satoshis
    tx.to(wallet.address, price - utxoSats); // This will become the input for the buyer psdt later

    // Now the outputs are set by the safe utxos the wallet has
    // this isSendDoge set to true means that these tx are used for sending doge, only // todo: needs a rework, we update wallet with inscription utxos and will use them this way, changed to false
    // (preparing the outputs for the buyer psdt)
    // At this point in time nothing is broadcasted yet. That happens later.
    const { tx: fundedTx } = fundWallet(wallet, tx, LOW_NETWORK_FEE_RATE, false);

    return fundedTx;
  } else {
    const inscriptionPrice = offerPsdt.txOutputs[2].value;
    // contains the trading fee. The listing fee + service fee is deducted from the inscription price in the seller
    // psdt (seller gets the inscription price - listing fee - service fee)
    const servicePrice = offerPsdt.txOutputs[3].value;
    const inscriptionSats = bitcoin.Transaction.fromBuffer(
      offerPsdt.data.inputs[2].nonWitnessUtxo!,
    ).outs[offerPsdt.txInputs[2].index].value;

    const price = servicePrice + inscriptionPrice + MINER_FEE;

    // here we add 2 outputs for the first 2 dummy inputs of the seller psdt
    // these are set by convention, when the seller psdt is created
    const tx = new Transaction();
    tx.to(wallet.address, inscriptionSats + 1); // Add an output with the given amount of satoshis
    tx.to(wallet.address, inscriptionSats + 1); // Add an output with the given amount of satoshis
    tx.to(wallet.address, price); // This will become the input for the buyer psdt later

    // Now the outputs are set by the safe utxos the wallet has
    // this isSendDoge set to true means that these tx are used for sending doge, only // todo: needs a rework, we update wallet with inscription utxos and will use them this way, changed to false
    // (preparing the outputs for the buyer psdt)
    // At this point in time nothing is broadcasted yet. That happens later.
    const { tx: fundedTx } = fundWallet(wallet, tx, LOW_NETWORK_FEE_RATE, false);

    return fundedTx;
  }
};

const buyOffers = async (
  buyContents: BuyContents[],
  address: string,
  type: InscriptionType = InscriptionType.DRC20,
): Promise<BuyResult[] | null> => {
  try {
    const url =
      type === InscriptionType.DRC20
        ? "/drc20s/buy"
        : type === InscriptionType.DUNE
          ? "/dunes/buy"
          : "/doginals/buy";
    const { data, status } = await (
      type === InscriptionType.DUNE ? sdoggsApiV2(true) : marketplaceApiV2(true)
    ).post<BuyResult[]>(url, {
      buyContents,
      buyer: address,
    });

    if (status > 302) {
      console.error(`buyOffers - post ${type} buy`, status);
      return null;
    }

    return data;
  } catch (e: Error | unknown) {
    return null;
  }
};

const createBuyerPsdt = (
  sellerPsdtHex: string,
  buyerPrePurchaseTxHex: string,
  buyerAddress: string,
  type: InscriptionType = InscriptionType.DOGINALS,
): bitcoin.Psbt => {
  const offerPsdt = bitcoin.Psbt.fromHex(sellerPsdtHex, {
    network: dogecoinNetwork,
  });
  console.log("createBuyerPsdt - ", offerPsdt.toHex());

  const buyerTx = bitcoin.Transaction.fromHex(buyerPrePurchaseTxHex);

  const buyerPsdt = new bitcoin.Psbt({
    network: dogecoinNetwork,
  });

  const txId = buyerTx.getId();

  if (type == InscriptionType.DUNE) {
    buyerPsdt.addInput({
      hash: txId,
      index: 0,
      nonWitnessUtxo: Buffer.from(buyerPrePurchaseTxHex, "hex"),
    });
    buyerPsdt.addInput(offerPsdt.txInputs[1]);
    buyerPsdt.updateInput(1, offerPsdt.data.inputs[1]);
    buyerPsdt.addInput({
      hash: txId,
      index: 1,
      nonWitnessUtxo: Buffer.from(buyerPrePurchaseTxHex, "hex"),
    });

    const inscriptionSats = bitcoin.Transaction.fromBuffer(
      offerPsdt.data.inputs[1].nonWitnessUtxo!,
    ).outs[offerPsdt.txInputs[1].index].value;

    buyerPsdt.addOutput({
      address: buyerAddress,
      value: inscriptionSats,
    });

    buyerPsdt.addOutput(offerPsdt.txOutputs[1]);
    buyerPsdt.updateOutput(1, offerPsdt.data.outputs[1]);

    // this can happen at creation time of the psdt
    const sellerFeeValue = offerPsdt.txOutputs[2].value;
    if (sellerFeeValue < SAFE_DUST_AMOUNT) {
      buyerPsdt.addOutput({
        script: offerPsdt.txOutputs[2].script,
        value: SAFE_DUST_AMOUNT,
        address: offerPsdt.txOutputs[2].address,
      });
    } else {
      buyerPsdt.addOutput(offerPsdt.txOutputs[2]);
    }
    buyerPsdt.updateOutput(2, offerPsdt.data.outputs[2]);

    return buyerPsdt;
  } else {
    buyerPsdt.addInput({
      hash: txId,
      index: 0,
      nonWitnessUtxo: Buffer.from(buyerPrePurchaseTxHex, "hex"),
    });

    buyerPsdt.addInput({
      hash: txId,
      index: 1,
      nonWitnessUtxo: Buffer.from(buyerPrePurchaseTxHex, "hex"),
    });

    buyerPsdt.addInput(offerPsdt.txInputs[2]);
    buyerPsdt.updateInput(2, offerPsdt.data.inputs[2]);
    buyerPsdt.addInput({
      hash: txId,
      index: 2,
      nonWitnessUtxo: Buffer.from(buyerPrePurchaseTxHex, "hex"),
    });

    buyerPsdt.addOutput({
      address: buyerAddress,
      value: buyerTx.outs[0].value + buyerTx.outs[1].value,
    });

    const inscriptionSats = bitcoin.Transaction.fromBuffer(
      offerPsdt.data.inputs[2].nonWitnessUtxo!,
    ).outs[offerPsdt.txInputs[2].index].value;

    buyerPsdt.addOutput({
      address: buyerAddress,
      value: inscriptionSats,
    });

    buyerPsdt.addOutput(offerPsdt.txOutputs[2]);
    buyerPsdt.updateOutput(2, offerPsdt.data.outputs[2]);

    // this can happen at creation time of the psdt
    const sellerFeeValue = offerPsdt.txOutputs[3].value;
    if (sellerFeeValue < SAFE_DUST_AMOUNT) {
      buyerPsdt.addOutput({
        script: offerPsdt.txOutputs[3].script,
        value: SAFE_DUST_AMOUNT,
        address: offerPsdt.txOutputs[3].address,
      });
    } else {
      buyerPsdt.addOutput(offerPsdt.txOutputs[3]);
    }
    buyerPsdt.updateOutput(3, offerPsdt.data.outputs[3]);

    return buyerPsdt;
  }
};

const offerIsStillValid = async (
  offerId: string,
  type: InscriptionType = InscriptionType.DRC20,
): Promise<GetOfferValidityResult> => {
  try {
    const { data } = await (
      type == InscriptionType.DUNE ? sdoggsApiV2(true) : marketplaceApiV2(true)
    ).put<PutRefreshStatus>(`/offer/${type}/refresh-status?offerId=${offerId}`);
    return data.valid
      ? { valid: true, error: null }
      : { valid: false, error: null };
  } catch (e: Error | unknown) {
    return { valid: false, error: handleError(e) };
  }
};

export {
  getSellerPsdtByOfferId,
  prepareOutputsForOfferPurchase,
  buyOffers,
  createBuyerPsdt,
  buildBuyItemTxs,
  buildItemsTxs,
  offerIsStillValid,
};
