import React from "react";

import { ImageWithFallback } from "../imageWithFallback";

interface PaymentItemProps {
  image: string | null;
  value: string;
  valueInUSD?: string;
  valueDetails?: string;
  label: string;
  onClick?: () => void;
}

export const PaymentItem: React.FC<PaymentItemProps> = ({
  image,
  value,
  valueInUSD,
  valueDetails,
  label,
  onClick,
}: PaymentItemProps) => (
  <div className="flex flex-1 flex-row space-x-3" onClick={onClick}>
    <ImageWithFallback image={image} className="h-10 w-10 rounded-full" />
    <div className="flex h-10 flex-1 flex-col justify-between">
      <div className="flex flex-row items-center justify-between text-xs text-text-tertiary">
        <div className="flex flex-row items-center space-x-1">
          <span dangerouslySetInnerHTML={{ __html: label }} />
          {onClick !== undefined && (
            <span className="material-symbols-rounded text-xs text-text-tertiary">
              info
            </span>
          )}
        </div>
        <span
          dangerouslySetInnerHTML={{
            __html: valueInUSD ?? "",
          }}
        />
      </div>

      <div className="flex flex-row items-end space-x-2">
        <span
          className="text-lg font-bold uppercase leading-none text-text-primary"
          dangerouslySetInnerHTML={{ __html: value }}
        />
        {valueDetails && (
          <div className="flex flex-col justify-end">
            <span
              className="text-xs leading-none text-text-secondary"
              dangerouslySetInnerHTML={{ __html: valueDetails }}
            />
          </div>
        )}
      </div>
    </div>
  </div>
);
