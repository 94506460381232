import React from "react";

import { cn } from "@/lib/utils";
import { Currency } from "@/types";

interface NumberCellProps {
  value: string;
  currency?: Currency;
  className?: string;
  hideZero?: boolean;
  onClick?: () => void;
}

export const NumberCell: React.FC<NumberCellProps> = ({
  value,
  currency,
  className,
  hideZero = true,
  onClick,
}) => {
  if (
    ((parseFloat(value) === 0 && !value.includes("span")) ||
      value === "0" ||
      value === undefined) &&
    hideZero
  ) {
    return (
      <div className="flex h-6 flex-1 flex-col justify-center">
        <span
          className={cn(
            "text-right text-sm text-text-primary lg:text-md",
            className,
          )}
        >
          -
        </span>
      </div>
    );
  }

  const formattedValue = currency ? `${currency}${value}` : value;

  return (
    <div className="flex h-6 flex-1 flex-col justify-center" onClick={onClick}>
      <span
        className={cn(
          "text-right text-sm text-text-primary lg:text-md",
          className,
        )}
        dangerouslySetInnerHTML={{ __html: formattedValue }}
      />
    </div>
  );
};
