import React from "react";

import { Button } from "@/components";
import { cn } from "@/lib/utils";
import { AnimatedContent } from "../../base";
import { InputCurrency } from "@/components/inputCurrency";

interface AmountViewProps {
  handleValueChange: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
  handleSwitchCurrency: () => void;
  handleSetMax: () => void;
  handleContinue: () => void;
  value: string;
  currency: string;
  detail: string;
  balance: string;
  hasBalance: boolean;
  errorMessage?: string;
}

export const AmountView: React.FC<AmountViewProps> = ({
  handleSetMax,
  handleValueChange,
  handleSwitchCurrency,
  handleContinue,
  value,
  detail,
  currency,
  balance,
  hasBalance,
  errorMessage,
}) => (
  <AnimatedContent id="amount">
    <div className="relative flex flex-1 flex-row items-center">
      <InputCurrency
        symbol={currency}
        symbolDirection="suffix"
        errorMessage={errorMessage}
        onChange={handleValueChange}
        value={value}
        isFull
      />
      <div className="absolute left-2 right-2 top-2 flex flex-row justify-center">
        <span className="text-center text-xs text-text-tertiary">{detail}</span>
      </div>

      <div className="absolute bottom-2 left-2 right-2 flex flex-row items-end justify-between">
        <span className={cn("text-xs text-red-500")}>{errorMessage}</span>
        <div className="flex flex-row space-x-2">
          <Button
            variant="ghost"
            shape="circle"
            onClick={handleSetMax}
            className="h-8 bg-background-primary"
            disabled={!hasBalance}
          >
            <span className="text-xs font-bold">Max</span>
          </Button>
          <Button
            variant="ghost"
            size="icon"
            shape="circle"
            onClick={handleSwitchCurrency}
            className="h-8 w-8 bg-background-primary"
          >
            <span className="material-symbols-rounded text-md">swap_vert</span>
          </Button>
        </div>
      </div>
    </div>

    <div className="flex flex-col space-y-2">
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-1 flex-row justify-between px-1">
          <span className="text-xs">Available Balance:</span>
          <span className=" text-xs font-bold">{balance}</span>
        </div>
      </div>
      <Button
        size="large"
        variant="inverse"
        onClick={handleContinue}
        disabled={!value || errorMessage !== undefined}
      >
        Continue
      </Button>
    </div>
  </AnimatedContent>
);
