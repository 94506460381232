import { useContext } from "react";
import { CollectiblesDetailsContext } from "@/context";

export const useCollectiblesDetails = () => {
  const context = useContext(CollectiblesDetailsContext);
  if (!context) {
    throw new Error(
      "useCollectiblesDetails must be used within a CollectiblesDetailsProvider",
    );
  }
  return context;
};
