import { useCallback, useEffect, useState } from "react";
import { useCurrentAccount, useFetchDuneListingsForAddress } from "@/hooks";
import { DuneListing } from "@/types";
import { AccountDunesData } from "@/types/inscription.ts";
import { BigNumber } from "bignumber.js";

type UseGetMaxSendableDuneParams = {
  accountData?: AccountDunesData;
  amount?: string;
  token?: string;
  listings?: DuneListing[];
  isForSending?: boolean;
};

type UseGetMaxSendableDuneReturn = {
  maxListable: number;
};

const useGetMaxSendableDune = ({
  accountData,
  token,
  listings,
}: UseGetMaxSendableDuneParams): UseGetMaxSendableDuneReturn => {
  const { address } = useCurrentAccount();
  const [maxListable, setMaxListable] = useState(0);
  // preferably we get the listings as props, but if parent cannot pass it down, we fetch it
  const { listings: fallbackListings } = useFetchDuneListingsForAddress({
    address,
  });
  const [duneListings, _] = useState<DuneListing[]>(
    listings || fallbackListings,
  );

  const getMaxListable = useCallback(async () => {
    // return early if amount is less than max amount
    if (!token || !accountData || !accountData?.utxos?.length) {
      setMaxListable(Number(accountData?.availableBalance ?? "0"));
      return;
    }

    // 1. calculate max listable amount
    const listedAmount = duneListings.reduce(
      (acc, listing) => listing.amount + acc,
      0,
    );

    const balance = new BigNumber(accountData?.availableBalance ?? "0");
    const listed = new BigNumber(listedAmount);

    let maxListable = new BigNumber(0);
    if (balance.gt(listed)) {
      maxListable = balance.minus(listed);
    }
    setMaxListable(maxListable.toNumber());
  }, [token, accountData, duneListings]);

  useEffect(() => {
    getMaxListable();
  }, [getMaxListable]);

  return {
    maxListable,
  };
};

export { useGetMaxSendableDune };
