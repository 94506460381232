import React from "react";
import { Button } from "@/components";
import { Currency } from "@/types";
import { cn } from "@/lib/utils.ts";

interface CurrencySwitchProps {
  value: string;
  onClick: () => void;
  fontSize?: string;
}

export const CurrencySwitch: React.FC<CurrencySwitchProps> = ({
  value,
  onClick,
  fontSize = "1.5rem",
}) => {
  const isUSD = value === Currency.USD;

  return (
    <Button
      variant="default"
      size="default"
      className="flex h-5 w-4 items-center border-0 bg-background-secondary text-sm font-semibold text-text-primary md:h-10 md:hover:bg-background-tertiary"
      onClick={onClick}
    >
      <span
        className="p-1 text-sm md:text-md"
        dangerouslySetInnerHTML={{
          __html: value,
        }}
      />
      {/*<div className="flex flex-col items-center justify-center">*/}
      {/*  <span*/}
      {/*    className={cn(*/}
      {/*      "material-symbols-rounded",*/}
      {/*      isUSD ? "text-primary-400" : "text-gray-400",*/}
      {/*    )}*/}
      {/*    style={{ position: "relative", top: "8px", fontSize }}*/}
      {/*  >*/}
      {/*    arrow_drop_up*/}
      {/*  </span>*/}
      {/*  <span*/}
      {/*    className={cn(*/}
      {/*      "material-symbols-rounded",*/}
      {/*      !isUSD ? "text-primary-400" : "text-gray-400",*/}
      {/*    )}*/}
      {/*    style={{ position: "relative", bottom: "8px", fontSize }}*/}
      {/*  >*/}
      {/*    arrow_drop_down*/}
      {/*  </span>*/}
      {/*</div>*/}
    </Button>
  );
};
