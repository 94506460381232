import React, { useCallback } from "react";

import { Button, PaymentItem, Separator, Spinner } from "@/components";
import { ALPHA_DISCOUNT, BUYING_MATCHING_FEE, LEGAL_TERMS } from "@/constants";
import { FeeType, useInfoModal } from "@/hooks";
import { ModalInfo } from "@/modals/modalInfo";
import { getIconForTick } from "@/utility";

import { AnimatedContent, ExpandableFeeList } from "../../base";
import { BuyViewProps } from "@/types/buy.ts";

export const BuyView: React.FC<BuyViewProps> = ({
  fees,
  pay,
  receive,
  tick,
  loading,
  handleBuy,
}: BuyViewProps) => {
  const {
    info,
    infoVisible,
    handleOpenInfo,
    handleCloseInfo,
    handleOpenInfoFee,
  } = useInfoModal();

  const handleOpenPaymentInfo = useCallback(() => {
    handleOpenInfo("Payment Info", [
      "The amounts displayed here are estimates based on your current inscription selections.",
      "The amount You Pay and You Receive therefore may vary from what is shown, contingent on the remaining availability of these inscriptions, the Dogecoin mempool, status of the network, etc.",
      "By proceeding, you agree to these terms.",
    ]);
  }, [handleOpenInfo]);

  return (
    <AnimatedContent id="buy">
      <div className="flex flex-1 flex-col">
        <div className="flex flex-1 flex-col space-y-2">
          <div className="flex flex-col rounded-lg bg-background-secondary">
            <ExpandableFeeList
              fees={[
                {
                  label: "Total Fees",
                  value: fees.total || "0",
                  onClick: () => handleOpenInfoFee(FeeType.TOTAL),
                },
                {
                  label: "Network Fee",
                  value: fees.network,
                  onClick: () => handleOpenInfoFee(FeeType.NETWORK),
                },
                {
                  label: `Matching Fee (${BUYING_MATCHING_FEE}%)`,
                  value: fees.taker,
                  onClick: () => handleOpenInfoFee(FeeType.MATCHING),
                },
                {
                  label: `Alpha Phase Discount (${ALPHA_DISCOUNT}%)`,
                  value: `- ${fees.takerDiscountAlpha}`,
                  onClick: () => handleOpenInfoFee(FeeType.ALPHA_DISCOUNT),
                },
              ]}
              expandableHead={{
                header: "You Pay",
                value: pay.value,
                valueInUSD: pay.valueInUSD,
                withExpandableHeaderImage: true,
                onClick: handleOpenPaymentInfo,
                className: "text-xs text-text-tertiary",
                imageSize: "h-10 w-10",
              }}
            />
            <Separator />
            <div className="space-y-4 p-4">
              <PaymentItem
                image={getIconForTick(tick)}
                label="You Receive"
                value={receive.value}
                valueInUSD={receive.valueInUSD}
                valueDetails={receive.valueDetails}
                onClick={handleOpenPaymentInfo}
              />
            </div>
          </div>
        </div>
        <div className="flex w-full flex-col space-y-2">
          <Button
            size="large"
            variant="inverse"
            onClick={handleBuy}
            disabled={loading}
          >
            {loading ? <Spinner size={24} /> : "Buy Now"}
          </Button>
          <p className="text-center text-2xs italic text-text-tertiary">
            By clicking Buy Now, you confirm the accuracy of the input data and
            agree to the
            <span
              onClick={() => window.open(LEGAL_TERMS, "_blank")}
              className="ml-1 cursor-pointer underline"
            >
              Terms of Service
            </span>
            .
          </p>
        </div>
      </div>

      <ModalInfo
        info={info}
        isVisible={infoVisible}
        onClose={handleCloseInfo}
      />
    </AnimatedContent>
  );
};
