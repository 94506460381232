import { useRef, useEffect } from "react";
import debounce from "lodash/debounce";

export const useDebounce = (callback: (x?: any) => void, delay: number) => {
  const debouncedCallback = useRef(debounce(callback, delay)).current;

  useEffect(() => {
    return () => {
      debouncedCallback.cancel();
    };
  }, [debouncedCallback]);

  return debouncedCallback;
};
