import { useCallback, useState } from "react";

import { PIN_LENGTH } from "@/constants";

interface PinConfig {
  pinNames: string[]; // Array of pin names
}

interface UsePinsReturn {
  pins: { [key: string]: string[] };
  handleSetPin: (pinName: string, index: number, value: string) => void;
  handleClearPin: (pinName: string) => void;
  isPinSet: (pinName: string) => boolean;
  pinsMatch: (pinName1: string, pinName2: string) => boolean;
}

export const usePins = ({ pinNames }: PinConfig): UsePinsReturn => {
  // Initialize pins with empty strings
  const [pins, setPins] = useState<{ [key: string]: string[] }>(() =>
    pinNames.reduce<{ [key: string]: string[] }>((acc, pinName) => {
      acc[pinName] = new Array<string>(PIN_LENGTH).fill("");
      return acc;
    }, {}),
  );

  // Set a digit of a pin
  const handleSetPin = useCallback(
    (pinName: string, index: number, value: string) => {
      setPins((prev) => ({
        ...prev,
        [pinName]: [
          ...prev[pinName].slice(0, index),
          value,
          ...prev[pinName].slice(index + 1),
        ],
      }));
    },
    [],
  );

  // Clear pin by setting all digits to empty string
  const handleClearPin = useCallback((pinName: string) => {
    setPins((prev) => ({
      ...prev,
      [pinName]: new Array<string>(PIN_LENGTH).fill(""),
    }));
  }, []);

  // Check if all digits of a pin are set
  const isPinSet = useCallback(
    (pinName: string) => {
      return pins[pinName].every((digit) => digit.trim() !== "");
    },
    [pins],
  );

  // Check if two pins match
  const pinsMatch = useCallback(
    (pinName1: string, pinName2: string) => {
      // Return true if both pins not set
      if (!isPinSet(pinName1) || !isPinSet(pinName2)) {
        return true;
      }

      return pins[pinName1].join("") === pins[pinName2].join("");
    },
    [pins, isPinSet],
  );

  return { pins, pinsMatch, handleSetPin, handleClearPin, isPinSet };
};
