export enum Sorting {
  top = "top",
  gainers = "gainers",
  trending = "trending",
  volume30d = "volume30d",
  sales7d = "sales7d",
  sales30d = "sales30d",
}

export enum TimeFrames {
  "1h" = "1h",
  "6h" = "6h",
  "24h" = "24h",
  "7d" = "7d",
  "30d" = "30d",
  "ALL" = "all",
}

export enum InscriptionType {
  DRC20 = "drc20",
  DOGINALS = "doginals",
  DUNE = "dunes",
}
