import { ONE_DOGE_IN_SHIBES } from "@/constants";
import { Currency } from "@/types";

export function getValueForCurrency(
  valueInShibes: number,
  currency: Currency,
  currentDogePrice: number,
): number {
  const valueToCalculate = isNaN(valueInShibes) ? 0 : valueInShibes;
  const valueInDoge = valueToCalculate / ONE_DOGE_IN_SHIBES;
  const valueInUSD = valueInDoge * currentDogePrice;
  const valueForCurrency =
    currency === Currency.DOGE ? valueInDoge : valueInUSD;
  return valueForCurrency;
}
