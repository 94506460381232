import { useCallback, useState } from "react";

import {
  buildBuyItemTxs,
  createBuyerPsdt,
  buyOffers,
  offerIsStillValid,
} from "@/context/wallet/helpers/buyItem.ts";
import { signPsbt } from "@/context/wallet/lib/transaction.ts";
import {
  BuildBuyItemTxWithFeeEstimation,
  BuyContents,
  BuyItemParams,
  BuyItemReturn,
} from "@/context/wallet/types.ts";
import { handleError } from "@/utility";
import { Psbt } from "bitcoinjs-lib";
import { InscriptionType } from "@/types";
import dogecore from "bitcore-lib-doge";
const { Transaction } = dogecore;

export const useBuyItem = (buyParams: BuyItemParams): BuyItemReturn => {
  const [isLoading, setIsBuying] = useState<boolean>(false);
  const [isError, setIsErrorBuy] = useState<boolean>(false);
  const [isSuccess, setIsSuccessBuy] = useState<boolean>(false);
  const [error, setError] = useState<null | string>(null);
  const [buyContents, setBuyContents] = useState<null | BuyContents[]>(null);
  const [buyErrors, setBuyErrors] = useState<string[]>([]);

  const { txWallet } = buyParams;

  const buildBuyTx = useCallback(
    async (
      offerId: string,
      type: InscriptionType = InscriptionType.DRC20,
    ): Promise<BuildBuyItemTxWithFeeEstimation | undefined> => {
      if (!txWallet) {
        console.warn("buildBuyTx - No wallet found");
        throw new Error("No wallet found");
      }

      // estimateFeesOnly = false ensures the wallet is locally updated and contains the new utxos to be spent
      // for the next transaction
      const buildBuyTxsParams = {
        txWallet,
        offerId,
        type,
        estimateFeesOnly: false,
      };

      return buildBuyItemTxs(buildBuyTxsParams);
    },
    [txWallet],
  );

  const buys = useCallback(
    async (offerIds: string[], type: InscriptionType) => {
      setIsBuying(true);

      const localBuyContents: BuyContents[] = [];
      const buyErrors: string[] = [];
      try {
        if (!txWallet) {
          throw new Error("No wallet found");
        }
        // Prepare the tx for each offer
        for (const offerId of offerIds) {
          try {
            const { valid, error } = await offerIsStillValid(offerId, type);
            if (!valid) {
              throw new Error("Offer is no longer valid: " + error);
            }

            const buildBuyTxResult = await buildBuyTx(offerId, type);
            if (!buildBuyTxResult) {
              throw new Error("Error building Buy transaction");
            }

            const {
              tx: buyerPrePurchaseTx,
              sellerPsdtHex,
              txFeeInSats,
            } = buildBuyTxResult;
            console.log("buy - buyerPrePurchaseTx", buyerPrePurchaseTx);
            console.log("buy - sellerPsdtHex", sellerPsdtHex);
            console.log("buy - txFeeInSats", txFeeInSats);

            console.log("buy - offerId", offerId);
            const buyerPrePurchaseTxHex = buyerPrePurchaseTx
              .toBuffer()
              .toString("hex");

            const buyerPsdt = createBuyerPsdt(
              sellerPsdtHex,
              buyerPrePurchaseTxHex,
              txWallet.address,
              type
            );
            console.log("buy - buyerPsdt", buyerPsdt.toHex());

            console.log(
              "buy - walletForTx before signing signedBuyerPsdtHex",
              txWallet,
            );

            const signedBuyerPsdt = signPsbt(buyerPsdt.toHex(), txWallet, {
              isSeller: false,
              isHex: false,
              autoFinalized: true,
            }) as Psbt;

            // we have to send each tx separately, because the server will build the final tx containing the psdt from
            // the seller (including its signatures) and the buyers signature. This is why we have to send it
            // one by one, get the result and use it for the next tx as inputs.
            const sendContent: BuyContents = {
              buildBuyItemTxResults: {
                tx: buyerPrePurchaseTxHex, // important to be the hex string here
                txFeeInSats: txFeeInSats,
                sellerPsdtHex: sellerPsdtHex,
              },
              offerId,
              signedBuyerPsdtHex: signedBuyerPsdt.toHex(),
            };

            // After sending, update the wallet
            const buyResults = await buyOffers(
              [sendContent],
              txWallet.address,
              type,
            );
            console.log("buy - buyResults", buyResults);
            if (buyResults === null || buyResults.length === 0) {
              throw new Error(`Error buying Item ${offerId} - No results`);
            }

            const txHex = buyResults[0].rawTx;
            const buyTx = new Transaction(txHex);
            txWallet.updateUtxos({ tx: buyTx });

            localBuyContents.push(sendContent);
          } catch (e: Error | unknown) {
            const message = handleError(e);
            console.error("buy - error", message);
            buyErrors.push(message);
          }
        }

        if (localBuyContents.length === 0) {
          throw new Error("Error buying Item");
        } else {
          setIsErrorBuy(false);
          setIsSuccessBuy(true);
          setBuyContents(localBuyContents);
          setBuyErrors(buyErrors);
          setIsBuying(false);
        }
      } catch (e: Error | unknown) {
        setIsErrorBuy(true);
        setIsSuccessBuy(false);
        setBuyErrors(buyErrors);
        setIsBuying(false);
        const errorMessage = handleError(e);
        setError(errorMessage);
        return;
      }
    },
    [txWallet, buildBuyTx],
  );

  const reset = useCallback(() => {
    setIsBuying(false);
    setIsErrorBuy(false);
    setIsSuccessBuy(false);
    setBuyErrors([]);
    setError(null);
    setBuyContents(null);
  }, []);

  return {
    isLoading,
    isError,
    isSuccess,
    execute: buys,
    result: buyContents,
    errors: buyErrors,
    reset,
    error,
  };
};
