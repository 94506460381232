import React, { useCallback } from "react";

import { AnimatedClickable, Button, toast } from "@/components";
import { useCurrency } from "@/contextHooks";
import { useDogeBalanceOfAddress } from "@/hooks";
import { shortenAddress } from "@/lib/address";
import { cn } from "@/lib/utils";
import { Currency } from "@/types";
import { useCopyToClipboard } from "usehooks-ts";

interface WalletCardProps {
  accountName: string;
  address: string;
  isActive: boolean;
  onEdit: () => void;
  onSetActive: () => void;
}

export const WalletCard: React.FC<WalletCardProps> = ({
  accountName,
  address,
  isActive,
  onEdit,
  onSetActive,
}) => {
  const { currency } = useCurrency();
  const { balanceInUSD, balanceInDOGE } = useDogeBalanceOfAddress(address);
  const [, copy] = useCopyToClipboard();

  const onCopyAddress = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      address &&
        copy(address).then(() => {
          toast({
            title: "Address Copied",
            description: `This wallet address ${shortenAddress(address)} has been copied to the clipboard.`,
            variant: "default",
            duration: 800,
          });
        });
    },
    [copy, address],
  );

  const handleEdit = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation(); // Prevent the event from bubbling up to the parent
      onEdit();
    },
    [onEdit],
  );

  return (
    <AnimatedClickable
      className={cn(
        "flex cursor-pointer flex-row items-center justify-between space-x-4 rounded-lg border-0.5 border-border-secondary bg-background-secondary p-4",
        isActive && "border-border-tertiary bg-background-tertiary",
      )}
      whileHover={{ scale: 1.01 }}
      whileTap={{ scale: 1 }}
    >
      <div className="flex flex-1 flex-row items-center" onClick={onSetActive}>
        <div className="flex flex-1 flex-col">
          <span className="text-lg font-bold text-text-primary">
            {accountName}
          </span>
          <div className="flex flex-row items-center">
            <span className="text-xs text-text-tertiary">
              {shortenAddress(address)}
            </span>
            <Button
              variant="icon"
              shape="circle"
              onClick={onCopyAddress}
              className="h-8 w-8 text-text-tertiary"
            >
              <span className="material-symbols-rounded text-md">
                content_copy
              </span>
            </Button>
          </div>
        </div>

        <span
          className="text-sm text-text-tertiary"
          dangerouslySetInnerHTML={{
            __html: currency === Currency.USD ? balanceInUSD : balanceInDOGE,
          }}
        />
      </div>
      <Button variant="default" size="icon" shape="circle" onClick={handleEdit}>
        <span className="material-symbols-rounded text-lg">edit</span>
      </Button>
    </AnimatedClickable>
  );
};
