import { AnimatePresence } from "framer-motion";
import { useCallback, useEffect, useState } from "react";

import { useBalance, useTxWallet } from "@/contextHooks";
import {
  PendingCollectibleInscription,
  CollectiblePendingActionType,
  CollectionListingProps,
  Currency,
  Inscription,
} from "@/types";

import {
  DEFAULT_ERROR,
  ErrorView,
  ModalNavigation,
  Sheet,
  SheetProps,
  SuccessView,
} from "../base";
import { TransactionStep } from "./constants";
import { DelistView, DelistItem } from "./views";
import { LOW_NETWORK_FEE_RATE, ONE_DOGE_IN_SHIBES } from "@/constants";
import { NumberFormatType, formatNumber } from "@/lib/numbers";
import { FeeType } from "@/context/wallet/calculateNetworkFees";
import { useCurrentAccount } from "@/hooks";

interface ModalDelistCollectibleProps extends SheetProps {
  delistings: CollectionListingProps[];
  onRemove?: () => void;
  onSuccess?: (actionedInscriptions: PendingCollectibleInscription[]) => void;
}

export const ModalDelistCollectible: React.FC<ModalDelistCollectibleProps> = ({
  onClose,
  onSuccess,
  ...props
}) => {
  const { calculateNetworkFees } = useTxWallet();
  const { address } = useCurrentAccount();

  /**
   * Local State
   */

  const { delistings: initialDelistings } = props;
  // todo: rework as it's the same logic as for sending collectibles
  const { collectiblesBalance } = useBalance();
  const { delistCollectibles } = useTxWallet();
  const [error, setError] = useState(DEFAULT_ERROR);
  const [transactionStep, setTransactionStep] = useState(
    TransactionStep.DELIST,
  );
  const [txFeeInDoge, setTxFeeInDoge] = useState<string>("0");
  const [items, setItems] = useState<DelistItem[]>([]);
  const [delistedInscriptionIds, setDelistedInscriptionIds] = useState<
    PendingCollectibleInscription[]
  >([]);
  /**
   * Actions
   */

  const handleDelistError = useCallback(
    (title: string, description: string) => {
      setError({ title, description });
      setTransactionStep(TransactionStep.ERROR);
    },
    [],
  );

  const handleClose = useCallback(() => {
    if (delistCollectibles.isLoading) return;

    // reset values
    setError(DEFAULT_ERROR);
    setTransactionStep(TransactionStep.DELIST);
    delistCollectibles.reset();

    onClose?.();
  }, [delistCollectibles, onClose]);

  useEffect(() => {
    if (calculateNetworkFees.isError && calculateNetworkFees.error) {
      handleDelistError(
        "Error",
        (calculateNetworkFees.error as string) ||
          "An error occurred while calculating your fees.",
      );
    }
  }, [
    calculateNetworkFees.error,
    calculateNetworkFees.isError,
    handleDelistError,
  ]);

  const handleDelist = useCallback(async () => {
    const [inscriptionIds, offerIds] = initialDelistings.reduce<
      [string[], string[]]
    >(
      ([inscriptionIds, offerIds], item) => {
        inscriptionIds.push(item.inscriptionId);
        offerIds.push(item.offerId);
        return [inscriptionIds, offerIds];
      },
      [[], []],
    );
    await delistCollectibles.execute(inscriptionIds, offerIds);
    setDelistedInscriptionIds(
      inscriptionIds.map((id) => ({
        inscriptionId: id,
        action: CollectiblePendingActionType.Delist,
      })),
    );
  }, [delistCollectibles, initialDelistings]);

  const removeItemFromDelistingList = useCallback((id: string) => {
    setItems((prevItems) =>
      prevItems.filter((item) => item.inscription_id !== id),
    );
  }, []);

  /**
   * Effects
   */

  useEffect(() => {
    const delistItems: DelistItem[] = [];
    collectiblesBalance.forEach((collectible) => {
      const matchingDelisting = initialDelistings.find(
        (item) => item.inscriptionId === collectible.inscription_id,
      );
      if (matchingDelisting) {
        const parsedItem: DelistItem = {
          onRemove: function (): void {
            removeItemFromDelistingList(matchingDelisting.inscriptionId);
          },
          imageURI: matchingDelisting.imageURI,
          itemName: matchingDelisting.name,
          collectionName: matchingDelisting.collectionName,
          utxo: collectible.utxo,
          content: collectible.content,
          content_length: collectible.content_length,
          content_type: collectible.content_type,
          genesis_height: collectible.genesis_height,
          inscription_id: collectible.inscription_id,
          inscription_number: collectible.inscription_number,
          length: collectible.length,
          timestamp: collectible.timestamp,
          offset: collectible.offset,
          valueUSD: formatNumber({
            value:
              (matchingDelisting.price * matchingDelisting.currentDogePrice) /
              ONE_DOGE_IN_SHIBES,
            type: NumberFormatType.Price,
          }),
          valueDoge: (matchingDelisting.price / ONE_DOGE_IN_SHIBES).toString(),
        };
        delistItems.push(parsedItem);
      }
    });

    if (delistItems.length > 0) {
      setItems(delistItems);
    }
  }, [initialDelistings, collectiblesBalance, removeItemFromDelistingList]);

  useEffect(() => {
    if (
      !items ||
      items.length <= 0 ||
      !address ||
      calculateNetworkFees.isLoading ||
      calculateNetworkFees.isError ||
      calculateNetworkFees.isSuccess ||
      txFeeInDoge != "0"
    )
      return;

    const inscriptions: Inscription[] = items;

    calculateNetworkFees.execute(FeeType.COLLECTIBLES, {
      inscriptions,
      feePerVByte: LOW_NETWORK_FEE_RATE,
      receiverAddresses: new Array(inscriptions.length).fill(address),
    });
  }, [address, calculateNetworkFees, items, txFeeInDoge]);

  useEffect(() => {
    if (calculateNetworkFees.isSuccess && calculateNetworkFees.totalFeeInSats) {
      setTxFeeInDoge(calculateNetworkFees.totalFeeInDoge.toString());
    }
  }, [
    calculateNetworkFees.isSuccess,
    calculateNetworkFees.totalFeeInDoge,
    calculateNetworkFees.totalFeeInSats,
  ]);

  useEffect(() => {
    if (delistCollectibles.isSuccess && !delistCollectibles.isLoading) {
      setTransactionStep(TransactionStep.SUCCESS);
      // @todo: add checking for pending collectibles as it is done in AccountCollectionDetail.tsx for
      // sendingCollectibles to get rid of this timeout
      setTimeout(() => onSuccess?.(delistedInscriptionIds), 5000);
    }
  }, [
    handleClose,
    delistCollectibles.isSuccess,
    delistCollectibles.isLoading,
    onSuccess,
    delistedInscriptionIds,
  ]);

  useEffect(() => {
    if (delistCollectibles.isError && delistCollectibles.error) {
      let message = "An error occurred while processing your request.";
      if (delistCollectibles.error) {
        message = `${message}, ${delistCollectibles.error as string}`;
      }
      handleDelistError("Error", message);
    }
  }, [handleDelistError, delistCollectibles.isError, delistCollectibles.error]);

  /**
   * UI
   */

  const fees = {
    network: `${Currency.DOGE}${txFeeInDoge}`,
    total: `${Currency.DOGE}${txFeeInDoge}`, // todo: why?
  };

  return (
    <Sheet
      withHeader={false}
      classNameContent="flex flex-col text-text-primary h-full rounded-none pt-safe-offset-4 pb-safe-offset-4"
      onClose={handleClose}
      {...props}
    >
      <ModalNavigation title="Delist" iconBack="close" onBack={handleClose} />
      <AnimatePresence>
        <div className="relative flex flex-1 flex-col">
          {transactionStep === TransactionStep.DELIST && (
            <DelistView
              fees={fees}
              loading={delistCollectibles.isLoading}
              handleDelist={handleDelist}
              items={items}
            />
          )}
          {transactionStep === TransactionStep.SUCCESS && (
            <SuccessView handleContinue={handleClose} />
          )}
          {transactionStep === TransactionStep.ERROR && (
            <ErrorView
              handleContinue={handleClose}
              title={error.title}
              description={error.description}
            />
          )}
        </div>
      </AnimatePresence>
    </Sheet>
  );
};
