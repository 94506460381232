import { motion } from "framer-motion";
import React from "react";

import { cn } from "@/lib/utils";

interface SwitchProps {
  enabled?: boolean;
  disabled?: boolean;
  onChange?: () => void;
  size?: "default" | "small";
}

export const Switch: React.FC<SwitchProps> = ({
  enabled = false,
  disabled = false,
  size = "default",
  onChange,
}) => {
  const toggleSwitch = () => !disabled && onChange?.();

  const switchHeight = size === "small" ? 24 : 32;
  const switchWidth = size === "small" ? 40 : 56;
  const switchPadding = size === "small" ? 2 : 4;
  const knobSize = switchHeight - 2 * switchPadding;

  const translateX = enabled
    ? switchWidth - knobSize - 2 * switchPadding
    : switchPadding / 2;

  return (
    <div
      className={cn(
        "cursor-pointer rounded-full bg-background-tertiary",
        enabled && "bg-primary-500",
        disabled && "opacity-50",
      )}
      style={{
        width: switchWidth,
        height: switchHeight,
        padding: switchPadding,
      }}
      onClick={toggleSwitch}
    >
      <motion.div
        layout
        animate={{ x: translateX }}
        className="rounded-full bg-white shadow-md"
        style={{ width: knobSize, height: knobSize }}
        transition={{ type: "spring", stiffness: 700, damping: 30 }}
      />
    </div>
  );
};
