import { FALLBACK_DOGE_PRICE } from "@/constants";
import { marketplaceApiV2 } from "@/lib/fetch";
import { handleError } from "@/utility";

type DogePriceInUsdResponse = {
  dogePriceInUsd: number;
};

const fromDogeToUSD = (dogeAmount: number, dogePrice: number): number => {
  return dogeAmount * dogePrice;
};

const DOGE_PRICE_KEY = "dogePriceInUsd";

const storePriceInLocalStorage = (dogePriceInUsd: number) => {
  localStorage.setItem(DOGE_PRICE_KEY, dogePriceInUsd.toString());
};

const getStoredPriceFromLocalStorage = (): number => {
  const storedPrice = localStorage.getItem(DOGE_PRICE_KEY);
  return storedPrice ? parseFloat(storedPrice) : FALLBACK_DOGE_PRICE;
};

const fetchDogePrice = async () => {
  try {
    const endpoint = `/config/doge/price`;
    const response =
      await marketplaceApiV2(false).get<DogePriceInUsdResponse>(endpoint);

    let dogePriceInUsd = response?.data.dogePriceInUsd || 0;
    if (dogePriceInUsd <= 0) {
      dogePriceInUsd = getStoredPriceFromLocalStorage();
    } else {
      storePriceInLocalStorage(dogePriceInUsd);
    }
    return dogePriceInUsd;
  } catch (e: Error | unknown) {
    handleError(e);
    return getStoredPriceFromLocalStorage();
  }
};

export { fromDogeToUSD, fetchDogePrice };
