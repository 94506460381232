import React, { useCallback, useMemo, useState } from "react";

import { Button, InputPassword, useToast } from "@/components";
import { useWalletManagement } from "@/contextHooks";
import { usePasswords } from "@/hooks";
import { handleError } from "@/utility";

import { Sheet, SheetProps } from "../base";

const PASSWORDS = ["currentPassword", "password", "repeatPassword"];
const ITEMS = [
  {
    title: "Enter Current Password",
    password: PASSWORDS[0],
  },
  {
    title: "New Password",
    password: PASSWORDS[1],
  },
  {
    title: "Repeat New Password",
    password: PASSWORDS[2],
  },
];

export const ModalPasswordChange: React.FC<SheetProps> = ({
  isVisible = false,
  onClose,
}) => {
  const { toast } = useToast();
  const { pw, changePassword } = useWalletManagement() || {};

  const [error, setError] = useState<string | null>(null);

  const {
    passwords,
    handleSetPassword,
    handleClearPassword,
    passwordSet,
    passwordRulesMet,
    passwordsMatch,
  } = usePasswords({ passwordNames: PASSWORDS });

  const isCurrentPasswordCorrect = useMemo(() => {
    if (!pw || !passwords.currentPassword) return true;
    return passwords.currentPassword === pw;
  }, [passwords.currentPassword, pw]);

  const isPasswordMatch = useMemo(
    () => passwordsMatch("password", "repeatPassword"),
    [passwordsMatch],
  );

  const isPasswordsSet = useMemo(
    () =>
      passwordSet("currentPassword") &&
      passwordSet("password") &&
      passwordSet("repeatPassword"),
    [passwordSet],
  );

  const handleChangePasswords = useCallback(
    (
      passwordName: "password" | "repeatPassword" | "currentPassword",
      value: string,
    ) => {
      handleSetPassword(passwordName, value);
    },
    [handleSetPassword],
  );

  const onChangePassword = useCallback(() => {
    if (!isCurrentPasswordCorrect) {
      setError("Incorrect password. Please try again.");
      return;
    }

    if (!isPasswordMatch) {
      setError("Passwords do not match. Please try again.");
      return;
    }

    if (isPasswordsSet && passwordRulesMet("password")) {
      try {
        changePassword?.(passwords.currentPassword, passwords.password);
        toast({
          title: "Password Changed",
          description: "Your password has been successfully changed.",
        });
        onClose?.();
      } catch (e: Error | unknown) {
        const message = handleError(e);
        toast({
          title: "Error Changing Password",
          description: message,
          variant: "error",
        });
      } finally {
        setError(null);
        handleClearPassword("currentPassword");
        handleClearPassword("password");
        handleClearPassword("repeatPassword");
      }
    }
  }, [
    isPasswordsSet,
    isCurrentPasswordCorrect,
    isPasswordMatch,
    handleClearPassword,
    passwordRulesMet,
    changePassword,
    onClose,
    toast,
    passwords,
  ]);

  return (
    <Sheet
      isVisible={isVisible}
      onClose={onClose}
      title="Change Password"
      classNameContent="flex flex-col flex-1"
    >
      <div className="flex flex-1 flex-col space-y-4 p-4 pb-safe-or-4">
        {ITEMS.map((item) => (
          <div className="flex flex-col" key={item.password}>
            <InputPassword
              placeholder={item.title}
              onChange={(event) =>
                handleChangePasswords(item.password as any, event.target.value)
              }
              password={passwords[item.password]}
              withCriteria={item.password === "password"}
            />
          </div>
        ))}

        <span className="text-2xs text-red-500">{error || "\u00A0"}</span>
        <Button
          size="large"
          variant="default"
          onClick={onChangePassword}
          disabled={!isPasswordsSet}
        >
          <span>Change Password</span>
        </Button>
      </div>
    </Sheet>
  );
};
