import React, { PropsWithChildren, createContext, useState } from "react";
import { useDeviceSelectors, useMobileOrientation } from "react-device-detect";

interface UIContextType {
  appHeaderHeight: number;
  bottomNavHeight: number;
  navigationHeaderHeight: number;
  isDeviceMobile: boolean;
  isDeviceLandscape: boolean;
  setAppHeaderHeight: (height: number) => void;
  setBottomNavHeight: (height: number) => void;
  setNavigationHeaderHeight: (height: number) => void;
}

const UIContext = createContext<UIContextType>({
  appHeaderHeight: 0,
  bottomNavHeight: 0,
  navigationHeaderHeight: 0,
  isDeviceMobile: false,
  isDeviceLandscape: false,
  setAppHeaderHeight: () => {},
  setBottomNavHeight: () => {},
  setNavigationHeaderHeight: () => {},
});

const UIProvider: React.FC<PropsWithChildren<object>> = ({ children }) => {
  const [appHeaderHeight, setAppHeaderHeight] = useState(0);
  const [bottomNavHeight, setBottomNavHeight] = useState(0);
  const [navigationHeaderHeight, setNavigationHeaderHeight] = useState(0);
  const { isLandscape } = useMobileOrientation();
  const [selectors] = useDeviceSelectors(window?.navigator?.userAgent);
  return (
    <UIContext.Provider
      value={{
        isDeviceMobile: selectors?.isMobile || selectors?.isTablet,
        isDeviceLandscape: isLandscape,
        appHeaderHeight,
        setAppHeaderHeight,
        bottomNavHeight,
        setBottomNavHeight,
        navigationHeaderHeight,
        setNavigationHeaderHeight,
      }}
    >
      {children}
    </UIContext.Provider>
  );
};

export { UIContext, UIProvider };
