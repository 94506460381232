import { useContext } from "react";

import { ContentContext } from "@/context/ContentSwitchProvider";

export const useAppContentSettings = () => {
  const contentContext = useContext(ContentContext);

  if (!contentContext) {
    throw new Error(
      "useAppContentSettings must be used within a ContentProvider",
    );
  }

  return contentContext;
};
