/*
Provides the current backend health status to set Live bulb to red or green
 */

import { useCallback, useState } from "react";
import { marketplaceApiV2 } from "@/lib/fetch.ts";
import { HealthStatus } from "@/types/healthStatus.ts";

export const useAppHealthStatus = () => {
  const [healthStatus, setHealthStatus] = useState<boolean>(true);

  const checkHealthStatus = useCallback(async () => {
    try {
      const response =
        await marketplaceApiV2(false).get<HealthStatus>(`/health`);

      setHealthStatus(response.data.status === "ok");
    } catch (error) {
      console.log(error);
      setHealthStatus(false);
    }
  }, []);

  return { healthStatus, checkHealthStatus };
};
