import React from "react";
import { Spinner } from "../spinner";
import { NumberFormatType, formatNumber } from "@/lib/numbers";
import { getColorForChange } from "@/utility";
import { cn } from "@/lib/utils";

const isPositive = (num: number) => num > 0;

const PendingIndicator = ({ pendingAmount, className }: { pendingAmount?: number, className?: string }) => {
  if (
    typeof pendingAmount !== "number" ||
    isNaN(pendingAmount) ||
    pendingAmount === 0
  ) {
    return null;
  }

  const colorPending = getColorForChange(pendingAmount);

  return (
    <div 
      className={cn(
        "mx-auto flex flex-row items-center space-x-1.5 rounded-full bg-background-tertiary px-2 py-0.5",
        className,
      )}
    >
      <Spinner size={10} />
      <span
        className={cn("text-2xs font-bold text-text-primary", colorPending)}
      >
        {isPositive(pendingAmount) ? "+" : ""}
        {formatNumber({
          value: pendingAmount,
          type: Math.abs(pendingAmount) >= 1000 ? NumberFormatType.Large_Number : NumberFormatType.Default,
          decimalPlaces: Math.abs(pendingAmount) < 1 ? 4 : 2,
        })}
      </span>
    </div>
  );
};

export { PendingIndicator };
