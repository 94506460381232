import React from "react";
import { Outlet } from "react-router-dom";
import { OnboardingModalContextProvider } from "@/context";
import { OnboardingModalsContainer } from "@/components/onboardingModalsContainer";

export const OnboardingModalsContextWrapper: React.FC = () => {
  return (
    <OnboardingModalContextProvider>
      <Outlet />
      <OnboardingModalsContainer />
    </OnboardingModalContextProvider>
  );
};
