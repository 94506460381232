// Define fee types
import { useCallback, useState } from "react";

import {
  buildItemsTxs,
  buildBuyItemTxs,
} from "@/context/wallet/helpers/buyItem.ts";
import {
  buildListCollectibleTxs,
  buildListDrc20Txs,
  buildListDuneTxs,
} from "@/context/wallet/helpers/listItems.ts";
import { buildSendDogeTxs } from "@/context/wallet/helpers/sendDoge.ts";
import { buildSendDrc20Txs } from "@/context/wallet/helpers/sendDrc20.ts";
import {
  BuildBuyItemTxsParams,
  BuildBuyMulitpleItemsTxsParams,
  TxWallet,
  TxWithFeeEstimation,
  WalletContextHandleReturn,
} from "@/context/wallet/types.ts";
import { satoshisToDoge } from "@/lib/numbers.ts";
import { DunesUtxo, Inscription, InscriptionType, Utxo } from "@/types";
import { handleError } from "@/utility";
import { buildSendCollectiblesTxs } from "@/context/wallet/helpers/sendCollectibles.ts";
import { buildSendDuneTxs } from "./helpers/sendDune";
import { buildMintDuneTxs } from "./helpers/mintDune";

export enum FeeType {
  DOGE = "DOGE",
  DRC20 = "DRC20",
  COLLECTIBLES = "COLLECTIBLES",
  BUYDRC20S = "BUYDRC20S",
  BUYCOLLECTIBLES = "BUYCOLLECTIBLES",
  LISTDRC20 = "LISTDRC20",
  LISTCOLLECTIBLES = "LISTCOLLECTIBLES",
  BUYDRC20 = "BUYDRC20",
  BUYCOLLECTIBLE = "BUYCOLLECTIBLE",
  // DUNES
  DUNE = "DUNE",
  BUYDUNE = "BUYDUNE",
  BUYDUNES = "BUYDUNES",
  LISTDUNE = "LISTDUNE",
  MINTDUNE = "MINTDUNE",
}

interface BaseFeeParams {
  feePerVByte: number;
  receiverAddress: string;
}

// Define interfaces for fee calculation parameters
interface DogeFeeParams extends BaseFeeParams {
  amt: number;
}

interface Drc20FeeParams extends BaseFeeParams {
  transferInscriptionUtxos: Utxo[];
  amt: number;
  tick: string;
  isClearRequired: boolean;
}

interface ListDrc20FeeParams {
  amt: number;
  tick: string;
  transferInscriptionUtxos: Utxo[];
  feePerVByte: number;
}
interface ListDuneFeeParams {
  amt: number;
  tick: string;
  duneUtxos: DunesUtxo[];
  feePerVByte: number;
}
interface MintDuneFeeParams {
  amt: number;
  receiver: string | undefined;
  duneId: string;
  limit: number;
  duneUtxos: DunesUtxo[];
  feePerVByte: number;
}
interface BuyItemFeeParams {
  offerId: string;
}

interface BuyItemsFeeParams {
  offerIds: string[];
}

interface DoginalsFeeParams {
  feePerVByte: number;
  receiverAddresses: string[];
  inscriptions: Inscription[];
}

interface DuneFeeParams extends BaseFeeParams {
  duneUtxos: DunesUtxo[];
  amt: number;
  tick: string;
  isClearRequired: boolean;
}

interface ListDoginalsFeeParams extends DoginalsFeeParams {}

export type FeeParams<T extends FeeType> = T extends FeeType.DOGE
  ? DogeFeeParams
  : T extends FeeType.DRC20
    ? Drc20FeeParams
    : T extends FeeType.COLLECTIBLES
      ? DoginalsFeeParams
      : T extends FeeType.LISTDRC20
        ? ListDrc20FeeParams
        : T extends FeeType.BUYDRC20
          ? BuyItemFeeParams
          : T extends FeeType.BUYDRC20S
            ? BuyItemsFeeParams
            : T extends FeeType.BUYCOLLECTIBLE
              ? BuyItemFeeParams
              : T extends FeeType.BUYCOLLECTIBLES
                ? BuyItemsFeeParams
                : T extends FeeType.LISTCOLLECTIBLES
                  ? ListDoginalsFeeParams
                  : T extends FeeType.DUNE
                    ? DuneFeeParams
                    : T extends FeeType.BUYDUNES
                      ? BuyItemsFeeParams
                      : T extends FeeType.LISTDUNE
                        ? ListDuneFeeParams
                        : T extends FeeType.MINTDUNE
                          ? MintDuneFeeParams
                          : never;

export interface CalculateNetworFeesReturn extends WalletContextHandleReturn {
  execute: <T extends FeeType>(feeType: T, params: FeeParams<T>) => void;
  totalFeeInDoge: number;
  totalFeeInSats: number;
}

interface CalculateNetworFeesFunctionReturn {
  feeInDoge: number;
  feeInSats: number;
}

type CalculateNetworkFeeParams = {
  txWallet: TxWallet | undefined;
  address: string | undefined;
};

const estimateFeesOnly = true;

const calculateTotalFeeInSats = (
  transactions: TxWithFeeEstimation[] | undefined,
): number => {
  if (!transactions || transactions.length === 0) {
    return 0;
  }

  return transactions?.reduce((acc, tx) => acc + tx.txFeeInSats, 0);
};

async function getBuyItemTransactionFees(
  buildBuyItemTxsParams: BuildBuyItemTxsParams,
): Promise<{
  fees: CalculateNetworFeesFunctionReturn;
  errors: Map<string, string>;
}> {
  const errors = new Map<string, string>();
  let feeInSats = 0;
  let feeInDoge = 0;
  try {
    const transaction = await buildBuyItemTxs(buildBuyItemTxsParams);
    feeInSats = transaction?.txFeeInSats || 0;
    feeInDoge = satoshisToDoge(feeInSats);
  } catch (e: Error | unknown) {
    const errorMessage = handleError(e);
    console.error("buildItemsTxs - error", errorMessage);
    errors.set(buildBuyItemTxsParams.offerId, errorMessage);
  }

  return { fees: { feeInDoge, feeInSats }, errors };
}

async function getBuyItemsTransactionFees(
  buildBuyItemsTxsParams: BuildBuyMulitpleItemsTxsParams,
): Promise<{
  fees: CalculateNetworFeesFunctionReturn;
  errors: Map<string, string>;
}> {
  // @todo: show error message to user
  const { items: transactionsWithFeeEstimation, errors } = await buildItemsTxs(
    buildBuyItemsTxsParams,
  );
  const feeInSats = calculateTotalFeeInSats(transactionsWithFeeEstimation);
  const feeInDoge = satoshisToDoge(feeInSats);

  return { fees: { feeInDoge, feeInSats }, errors };
}

export const useCalculateNetworkFees = (
  calculateNetworkFeeParams: CalculateNetworkFeeParams,
): CalculateNetworFeesReturn => {
  // State variables and setters (as already defined)
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [error, setError] = useState<null | string | Map<string, string>>(null);
  const [totalFeeInSats, setTotalFeeInSats] = useState<number>(0);
  const [totalFeeInDoge, setTotalFeeInDoge] = useState<number>(0);

  const { txWallet, address } = calculateNetworkFeeParams;

  const calculateNetworkFees = useCallback(
    async <T extends FeeType>(feeType: T, params: FeeParams<T>) => {
      if (!txWallet) {
        throw new Error("No wallet found");
      }

      const calculateDogeFees = async (
        params: DogeFeeParams,
      ): Promise<CalculateNetworFeesFunctionReturn> => {
        const buildSendDogeTxsParams = {
          txWallet,
          estimateFeesOnly: false,
          ...params,
        };

        const transaction = await buildSendDogeTxs(buildSendDogeTxsParams);
        if (!transaction) {
          throw new Error("No transaction found");
        }

        const feeInSats = calculateTotalFeeInSats([transaction]);
        const feeInDoge = satoshisToDoge(feeInSats);

        return {
          feeInSats,
          feeInDoge,
        };
      };

      const calculateDrc20Fees = async (
        params: Drc20FeeParams,
      ): Promise<CalculateNetworFeesFunctionReturn> => {
        const buildSendDrc20TxsParams = {
          txWallet,
          address,
          estimateFeesOnly,
          ...params,
        };

        const transactions = await buildSendDrc20Txs(buildSendDrc20TxsParams);
        const feeInSats = calculateTotalFeeInSats(transactions);
        const feeInDoge = satoshisToDoge(feeInSats);

        return {
          feeInSats,
          feeInDoge,
        };
      };

      const calculateListDrc20Fees = async (
        params: ListDrc20FeeParams,
      ): Promise<CalculateNetworFeesFunctionReturn> => {
        const buildListDrc20TxsParams = {
          txWallet,
          address,
          estimateFeesOnly,
          ...params,
        };

        const transactions = await buildListDrc20Txs(buildListDrc20TxsParams);
        const feeInSats = calculateTotalFeeInSats(transactions);
        const feeInDoge = satoshisToDoge(feeInSats);

        return {
          feeInSats,
          feeInDoge,
        };
      };

      const calculateListCollectiblesFees = async (
        params: ListDoginalsFeeParams,
      ): Promise<CalculateNetworFeesFunctionReturn> => {
        const buildListCollectiblesTxsParams = {
          txWallet,
          address,
          estimateFeesOnly,
          ...params,
        };

        const transactions = await buildListCollectibleTxs(
          buildListCollectiblesTxsParams,
        );
        const feeInSats = calculateTotalFeeInSats(transactions);
        const feeInDoge = satoshisToDoge(feeInSats);

        return {
          feeInSats,
          feeInDoge,
        };
      };

      const calculateBuyDrc20Fees = async (
        params: BuyItemFeeParams,
      ): Promise<{
        fees: CalculateNetworFeesFunctionReturn;
        errors: Map<string, string>;
      }> => {
        const buildBuyDrc20TxsParams = {
          type: InscriptionType.DRC20,
          estimateFeesOnly,
          txWallet,
          ...params,
        };

        return await getBuyItemTransactionFees(buildBuyDrc20TxsParams);
      };

      const calculateBuyDrc20sFees = async (
        params: BuyItemsFeeParams,
      ): Promise<{
        fees: CalculateNetworFeesFunctionReturn;
        errors: Map<string, string>;
      }> => {
        const buildBuyDrc20TxsParams = {
          type: InscriptionType.DRC20,
          estimateFeesOnly,
          txWallet,
          ...params,
        };

        return await getBuyItemsTransactionFees(buildBuyDrc20TxsParams);
      };

      const calculateBuyCollectibleFees = async (
        params: BuyItemFeeParams,
      ): Promise<{
        fees: CalculateNetworFeesFunctionReturn;
        errors: Map<string, string>;
      }> => {
        const buildBuyCollectibleTxsParams = {
          type: InscriptionType.DOGINALS,
          estimateFeesOnly,
          txWallet,
          ...params,
        };

        return await getBuyItemTransactionFees(buildBuyCollectibleTxsParams);
      };

      const calculateBuyCollectiblesFees = async (
        params: BuyItemsFeeParams,
      ): Promise<{
        fees: CalculateNetworFeesFunctionReturn;
        errors: Map<string, string>;
      }> => {
        const buildBuyCollectiblesTxsParams = {
          type: InscriptionType.DOGINALS,
          estimateFeesOnly,
          txWallet,
          ...params,
        };

        return getBuyItemsTransactionFees(buildBuyCollectiblesTxsParams);
      };

      const calculateDoginalsFees = async (
        params: DoginalsFeeParams,
      ): Promise<CalculateNetworFeesFunctionReturn> => {
        const buildSendCollectiblesTxsParams = {
          txWallet,
          address,
          estimateFeesOnly,
          ...params,
        };

        const transactions = await buildSendCollectiblesTxs(
          buildSendCollectiblesTxsParams,
        );
        const feeInSats = calculateTotalFeeInSats(transactions);
        const feeInDoge = satoshisToDoge(feeInSats);

        return {
          feeInSats,
          feeInDoge,
        };
      };

      // DUNES
      const calculateDuneFees = async (
        params: DuneFeeParams,
      ): Promise<CalculateNetworFeesFunctionReturn> => {
        const buildSendDuneTxsParams = {
          txWallet,
          address,
          estimateFeesOnly,
          ...params,
        };

        const transactions = await buildSendDuneTxs(buildSendDuneTxsParams);
        const feeInSats = calculateTotalFeeInSats(transactions);
        const feeInDoge = satoshisToDoge(feeInSats);

        return {
          feeInSats,
          feeInDoge,
        };
      };

      const calculateListDuneFees = async (
        params: ListDuneFeeParams,
      ): Promise<CalculateNetworFeesFunctionReturn> => {
        const buildListDuneTxsParams = {
          txWallet,
          address,
          estimateFeesOnly,
          ...params,
        };

        const transactions = await buildListDuneTxs(buildListDuneTxsParams);
        const feeInSats = calculateTotalFeeInSats(transactions);
        const feeInDoge = satoshisToDoge(feeInSats);

        return {
          feeInSats,
          feeInDoge,
        };
      };

      const calculateMintDuneFees = async (
        params: MintDuneFeeParams,
      ): Promise<CalculateNetworFeesFunctionReturn> => {
        const buildMintDuneTxsParams = {
          txWallet,
          address,
          estimateFeesOnly,
          ...params,
        };

        const transactions = await buildMintDuneTxs(buildMintDuneTxsParams);
        const feeInSats = calculateTotalFeeInSats(transactions);
        const feeInDoge = satoshisToDoge(feeInSats);

        return {
          feeInSats,
          feeInDoge,
        };
      };

      const calculateBuyDuneFees = async (
        params: BuyItemFeeParams,
      ): Promise<{
        fees: CalculateNetworFeesFunctionReturn;
        errors: Map<string, string>;
      }> => {
        const buildBuyDuneTxsParams = {
          type: InscriptionType.DUNE,
          estimateFeesOnly,
          txWallet,
          ...params,
        };

        return await getBuyItemTransactionFees(buildBuyDuneTxsParams);
      };

      const calculateBuyDunesFees = async (
        params: BuyItemsFeeParams,
      ): Promise<{
        fees: CalculateNetworFeesFunctionReturn;
        errors: Map<string, string>;
      }> => {
        const buildBuyDuneTxsParams = {
          type: InscriptionType.DUNE,
          estimateFeesOnly,
          txWallet,
          ...params,
        };

        return await getBuyItemsTransactionFees(buildBuyDuneTxsParams);
      };

      setIsLoading(true);
      setIsError(false);
      setIsSuccess(false);

      // @todo: this needs to be refactored, to show multiple errors along with successes
      // this is used to throw an error after processing item(s) fee calculation.
      let throwErrorAfterProcessing: boolean = false;
      try {
        let fees = undefined;
        let errors = new Map<string, string>();
        switch (feeType) {
          case FeeType.DOGE:
            fees = await calculateDogeFees(params as DogeFeeParams);
            break;
          case FeeType.DRC20:
            fees = await calculateDrc20Fees(params as Drc20FeeParams);
            break;
          case FeeType.LISTDRC20:
            fees = await calculateListDrc20Fees(params as ListDrc20FeeParams);
            break;
          case FeeType.LISTCOLLECTIBLES:
            fees = await calculateListCollectiblesFees(
              params as ListDoginalsFeeParams,
            );
            break;
          case FeeType.BUYDRC20:
            ({ fees, errors } = await calculateBuyDrc20Fees(
              params as BuyItemFeeParams,
            ));
            throwErrorAfterProcessing = true;
            break;
          case FeeType.BUYDRC20S:
            ({ fees, errors } = await calculateBuyDrc20sFees(
              params as BuyItemsFeeParams,
            ));
            // remove if we want to show multiple errors
            throwErrorAfterProcessing = true;
            break;
          case FeeType.BUYCOLLECTIBLE:
            ({ fees, errors } = await calculateBuyCollectibleFees(
              params as BuyItemFeeParams,
            ));
            throwErrorAfterProcessing = true;
            break;
          case FeeType.BUYCOLLECTIBLES:
            ({ fees, errors } = await calculateBuyCollectiblesFees(
              params as BuyItemsFeeParams,
            ));
            // remove if we want to show multiple errors
            throwErrorAfterProcessing = true;
            break;
          case FeeType.COLLECTIBLES:
            fees = await calculateDoginalsFees(params as DoginalsFeeParams);
            break;
          // DUNES
          case FeeType.DUNE:
            fees = await calculateDuneFees(params as DuneFeeParams);
            break;
          case FeeType.LISTDUNE:
            fees = await calculateListDuneFees(params as ListDuneFeeParams);
            break;
          case FeeType.BUYDUNE:
            ({ fees, errors } = await calculateBuyDuneFees(
              params as BuyItemFeeParams,
            ));
            throwErrorAfterProcessing = true;
            break;
          case FeeType.BUYDUNES:
            ({ fees, errors } = await calculateBuyDunesFees(
              params as BuyItemsFeeParams,
            ));
            // remove if we want to show multiple errors
            throwErrorAfterProcessing = true;
            break;
          case FeeType.MINTDUNE:
            fees = await calculateMintDuneFees(params as MintDuneFeeParams);
            break;
          default:
            throw new Error("Unknown fee type");
        }

        // @todo: make all function above return errors and fees.
        if (!fees) {
          throw new Error("Failed to calculate fees");
        }

        // These errors are used to display error messages to the user
        if (errors.size > 0) {
          setError(errors);

          if (throwErrorAfterProcessing) {
            throw new Error("Failed to calculate fees");
          }
        }

        console.log(`Calculated ${feeType} fees:`, fees);
        setIsSuccess(true);
        setTotalFeeInSats(fees.feeInSats);
        setTotalFeeInDoge(fees.feeInDoge);
      } catch (e: Error | unknown) {
        const message = handleError(e);
        console.error("Error calculating network fees:", message);
        setIsError(true);
        // this is used if the above functions throw an error, but not already set an
        // error map
        if (!throwErrorAfterProcessing) {
          setError(message);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [address, txWallet],
  );

  const reset = useCallback(() => {
    setIsLoading(false);
    setIsError(false);
    setIsSuccess(false);
    setError(null);
    setTotalFeeInDoge(0);
    setTotalFeeInSats(0);
  }, []);

  return {
    isLoading,
    isError,
    isSuccess,
    error,
    totalFeeInDoge,
    totalFeeInSats,
    execute: calculateNetworkFees,
    reset,
  };
};
