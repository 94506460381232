import { useCallback, useState } from "react";

import { sdoggsApiV2 } from "@/lib/fetch";
import { handleError } from "@/utility";

type DuneHolder = {
  rank: number;
  percentage: string;
  address: string;
  value: number;
};

const useFetchTopDuneHolders = (tick?: string) => {
  const [topHolders, setTopHolders] = useState<DuneHolder[]>([]);
  const [isTopHoldersLoading, setIsTopHoldersLoading] = useState(false);

  const fetchTop100Holders = useCallback(async () => {
    if (!tick) return;
    try {
      const res = await sdoggsApiV2(false).get(`/dunes/holders/${tick}`);

      if (!res.data.length) {
        setTopHolders([]);
        return;
      }

      const holders: DuneHolder[] = res.data.map((holder: DuneHolder) => ({
        address: holder.address,
        rank: holder.rank,
        percentage: holder.percentage,
        value: holder.value,
      }));

      setTopHolders(holders);
    } catch (e: Error | unknown) {
      handleError(e);
      setTopHolders([]);
      throw new Error("Failed to fetch top 100 holders");
    } finally {
      setIsTopHoldersLoading(false);
    }
  }, [tick]);

  return {
    topHolders,
    isTopHoldersLoading,
    fetchTop100Holders,
  };
};

export { useFetchTopDuneHolders };
export type { DuneHolder };
