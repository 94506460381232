import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getMessaging } from "firebase/messaging";
import { FIREBASE_CONFIG } from "@/constants";

// Initialize Firebase
const app = initializeApp(FIREBASE_CONFIG);

// Initialize services
// const analytics = getAnalytics(app);
const messaging = getMessaging(app);

export { messaging };
