import { useEffect, useRef, useCallback, useState } from "react";
import createPersistStore from "@/context/wallet/helpers/persistStore.ts";
import {
  UpdateUtxosParams,
  UTXOService,
  UtxoStore,
} from "@/context/wallet/lib/utxoService.ts";
import { DogeUtxo, Utxo } from "@/types";

const storeTemplate: {
  utxos: Utxo[];
  usedUtxos: string[];
  initialized: boolean;
} = { utxos: [], usedUtxos: [], initialized: true };

export const useUtxoService = (address?: string) => {
  const serviceRef = useRef<UTXOService | null>(null);
  const [initialized, setInitialized] = useState(false);

  const init = useCallback(async () => {
    if (!address) return;

    console.log(
      "useUtxoService - init - Initializing UTXO service for address",
      address,
    );

    const persistedStore = await createPersistStore({
      name: "local-utxo-store",
      template: {
        [address]: storeTemplate,
      },
    });

    serviceRef.current = new UTXOService(persistedStore);
    setInitialized(true);
  }, [address]);

  const clearUtxos = useCallback(() => {
    if (serviceRef.current) {
      serviceRef.current.clearUtxos(address!);
    } else {
      console.warn("useUtxoService - syncUtxos - Service not initialized");
    }
  }, [address]);

  const syncUtxos = useCallback(
    (params: { safeUtxos: DogeUtxo[]; address: string }) => {
      console.log(
        "useUtxoService - syncUtxos - Syncing UTXOs for address",
        params.address,
      );
      if (serviceRef.current) {
        serviceRef.current.syncUtxos(params);
      } else {
        console.warn("useUtxoService - syncUtxos - Service not initialized");
      }
    },
    [],
  );

  const updateUtxos = useCallback((params: UpdateUtxosParams) => {
    console.log(
      "useUtxoService - updateUtxos - Trying to update UTXOs for address",
      params.address,
    );
    if (serviceRef.current) {
      console.log(
        "useUtxoService - updateUtxos - Updating UTXOs for address",
        params.address,
      );
      serviceRef.current.updateUtxos(params);
    } else {
      console.warn("useUtxoService - updateUtxos - Service not initialized");
    }
  }, []);

  const getStore = useCallback((address: string): UtxoStore[string] | null => {
    if (serviceRef.current) {
      return serviceRef.current.getStore(address);
    } else {
      console.warn("useUtxoService - getUtxos - Service not initialized");
      return null;
    }
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  return {
    clearUtxos,
    syncUtxos,
    updateUtxos,
    initialized,
    getStore,
  };
};
