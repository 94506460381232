import React, { ReactNode, useCallback, useMemo, useState } from "react";

import { Content, Tab } from "@/types";
import { useLocalStorage } from "@/hooks";
import { CONTENT_TYPE_KEY, FEATURE_ACTIVATION_DUNES } from "@/constants";

const CONTENT_OPTIONS =
  FEATURE_ACTIVATION_DUNES === "true"
    ? [
        { name: "drc-20", icon: "toll", value: Content.DRC20 },
        { name: "Collections", icon: "image", value: Content.COLLECTIBLES },
        { name: "Dunes", icon: "radio_button_checked", value: Content.DUNES },
      ]
    : [
        { name: "drc-20", icon: "toll", value: Content.DRC20 },
        { name: "Collections", icon: "image", value: Content.COLLECTIBLES },
      ];

type ProviderProps = {
  children: ReactNode;
};

interface ContentContextProps {
  content: Content;
  icon: string;
  contentName: string;
  setContent: (content: Content) => void;
  changeContent: () => void;

  tabExplore: Tab;
  setTabExplore: (tab: Tab) => void;
}

export const ContentContext = React.createContext<ContentContextProps>({
  content: Content.COLLECTIBLES,
  icon: "image",
  contentName: "Collections",
  setContent: () => {},
  changeContent: () => {},
  tabExplore: Tab.Top,
  setTabExplore: () => {},
});

export const ContentProvider = ({ children }: ProviderProps) => {
  const [tabExplore, setTabExplore] = useState<Tab>(Tab.Top);
  const { value: content, updateValue: setContent } = useLocalStorage<Content>(
    CONTENT_TYPE_KEY,
    Content.DRC20,
    true,
  );

  // Find the index of the current content option
  const currentIndex = useMemo(() => {
    return CONTENT_OPTIONS.findIndex((c) => c.value === content);
  }, [content]);

  // Compute the index of the next content option
  const nextIndex = useMemo(() => {
    return (currentIndex + 1) % CONTENT_OPTIONS.length;
  }, [currentIndex]);

  // Get the icon for the current content option
  const icon = useMemo(() => {
    return CONTENT_OPTIONS[currentIndex].icon;
  }, [currentIndex]);

  const changeContent = useCallback(() => {
    setContent(CONTENT_OPTIONS[nextIndex].value);
  }, [nextIndex, setContent]);

  const contentName = useMemo(() => {
    return CONTENT_OPTIONS[currentIndex].name;
  }, [currentIndex]);

  return (
    <ContentContext.Provider
      value={{
        content,
        icon,
        contentName,
        setContent,
        changeContent,
        tabExplore,
        setTabExplore,
      }}
    >
      {children}
    </ContentContext.Provider>
  );
};
