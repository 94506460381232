import { ONE_DOGE_IN_SHIBES } from "@/constants";
import { NumberFormatType, formatNumber } from "@/lib/numbers";
import { Currency } from "@/types";
import { getColorForChange } from "@/utility";

export const getFormattedValue = (
  valueInShibes: number,
  conversionRate: number,
  currency: Currency,
  formatPrice = true,
) => {
  const valueInDoge = valueInShibes / ONE_DOGE_IN_SHIBES;
  const valueInUSD = valueInDoge * conversionRate;
  return {
    value:
      currency === Currency.DOGE
        ? `${Currency.DOGE}${formatNumber({ value: valueInDoge, type: formatPrice ? NumberFormatType.Price : NumberFormatType.Large_Number })}`
        : `${Currency.USD}${formatNumber({ value: valueInUSD, type: formatPrice ? NumberFormatType.Price : NumberFormatType.Large_Number })}`,
    valueInDoge: `${Currency.DOGE}${formatNumber({ value: valueInDoge, type: formatPrice ? NumberFormatType.Price : NumberFormatType.Large_Number })}`,
    valueInUSD: `${Currency.USD}${formatNumber({ value: valueInUSD, type: formatPrice ? NumberFormatType.Price : NumberFormatType.Large_Number })}`,
  };
};

export const formatChangeValue = (changePercent: number) => ({
  value: formatNumber({
    value: changePercent,
    type: NumberFormatType.Percentage,
  }),
  color: getColorForChange(changePercent),
});

export const ensureUniqueTableData = <T, K extends keyof T>(
  newData: T[],
  uniqueProp: K,
) => {
  return (prev: T[]): T[] => {
    // Create a map to ensure unique objects based on the unique property
    const uniqueDataMap = new Map<T[K], T>(
      prev.map((item) => [item[uniqueProp], item]),
    );
    newData.forEach((item) => {
      if (!uniqueDataMap.has(item[uniqueProp])) {
        uniqueDataMap.set(item[uniqueProp], item);
      }
    });
    return Array.from(uniqueDataMap.values());
  };
};

export const ensureUniqueTableDataByTwoKeys = <
  T,
  K1 extends keyof T,
  K2 extends keyof T,
>(
  newData: T[],
  key1: K1,
  key2: K2,
) => {
  return (prev: T[]): T[] => {
    // Create a map to ensure unique objects based on the combination of two keys
    const uniqueDataMap = new Map<string, T>(
      prev.map((item) => [`${item[key1]}-${item[key2]}`, item]),
    );

    newData.forEach((item) => {
      const combinedKey = `${item[key1]}-${item[key2]}`;
      if (!uniqueDataMap.has(combinedKey)) {
        uniqueDataMap.set(combinedKey, item);
      }
    });

    return Array.from(uniqueDataMap.values());
  };
};
