import * as dogecore from "bitcore-lib-doge";
const { Transaction } = dogecore;
import { TxWallet, TxWithFeeEstimation } from "@/context/wallet/types.ts";
import {
  fundWallet,
  getTotalTransactionFeeInSats,
} from "@/context/wallet/lib/transaction.ts";

type BuildSendDogeTxsParams = {
  txWallet: TxWallet | undefined;
  receiverAddress: string;
  amt: number;
  feePerVByte: number;
  estimateFeesOnly: boolean;
};

export const buildSendDogeTxs = async (
  buildSendDogeTxsParams: BuildSendDogeTxsParams,
): Promise<TxWithFeeEstimation | undefined> => {
  const { txWallet, receiverAddress, amt, feePerVByte } =
    buildSendDogeTxsParams;

  if (!txWallet) {
    console.log("buildSendDogeTx - no wallet found");
    return;
  }

  // Build transaction
  const tx = new Transaction();
  tx.to(receiverAddress, amt);

  // Fund and sign
  fundWallet(txWallet, tx, feePerVByte, true);

  return {
    tx,
    txFeeInSats: getTotalTransactionFeeInSats(tx, feePerVByte),
  };
};
