import { Button, CollectibleCard, Spinner } from "@/components";
import { useInfoModal } from "@/hooks";
import { AnimatedContent, FeeListItem, LegalFooter } from "@/modals/base";
import { ModalInfo } from "@/modals/modalInfo";
import { Currency, Inscription } from "@/types";

interface Fees {
  network: string;
  total: string;
}

interface DelistViewProps {
  fees: Fees;
  items: DelistItem[];
  loading: boolean;
  handleDelist: () => void;
}

export interface DelistItem extends Inscription {
  onRemove: () => void;
  imageURI: string;
  itemName: string;
  collectionName: string;
  valueUSD: string;
  valueDoge: string;
}

export const DelistView: React.FC<DelistViewProps> = ({
  fees,
  items,
  loading,
  handleDelist,
}: DelistViewProps) => {
  const { info, infoVisible, FeeType, handleOpenInfoFee, handleCloseInfo } =
    useInfoModal();

  return (
    <AnimatedContent id="delist" className="p-0">
      <div className="relative flex max-h-screen flex-1 flex-col overflow-y-scroll px-4 pb-48">
        {/** Content */}
        <div className="flex flex-1 flex-col space-y-4">
          <div className="flex flex-col space-y-2">
            {items.map((item, idx) => (
              <CollectibleCard
                key={idx}
                image={item.imageURI}
                itemName={item.itemName}
                collectionName={item.collectionName}
                onRemove={item.onRemove}
                valueUSD={`${Currency.USD}${item.valueUSD}`}
                valueDOGE={`${Currency.DOGE}${item.valueDoge}`}
              />
            ))}
          </div>
        </div>

        {/** Action Bar */}
        <div className="fixed bottom-0 left-0 flex w-full flex-col space-y-2 border-t border-border-primary bg-background-primary/80 px-4 pt-2 drop-shadow-xl backdrop-blur-xl pb-safe-offset-2">
          {/** Fees */}
          <div className="flex flex-col space-y-1 px-1">
            <FeeListItem
              label="Network Fee"
              value={fees.network}
              onClick={() => handleOpenInfoFee(FeeType.NETWORK)}
            />
          </div>

          {/** Actions */}
          <Button size="large" variant="inverse" onClick={handleDelist}>
            {loading ? <Spinner size={24} /> : "Delist"}
          </Button>
          <LegalFooter label="Delist" />
        </div>
      </div>

      <ModalInfo
        info={info}
        isVisible={infoVisible}
        onClose={handleCloseInfo}
      />
    </AnimatedContent>
  );
};
