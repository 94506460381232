import { cn } from "@/lib/utils";
import { ActivityType } from "@/types";
import { getColorsForActivityType, getLabelForActivityType } from "@/utility";

interface ActivityTypeCellProps {
  type: ActivityType;
  className?: string;
}
export const ActivityTypeCell: React.FC<ActivityTypeCellProps> = ({
  type,
  className,
}) => {
  const label = getLabelForActivityType(type);
  const colors = getColorsForActivityType(type);
  return (
    <div
      className={cn(
        "flex w-12 flex-col items-center rounded-md py-1 text-xs font-medium",
        "lg:w-16 lg:py-1 lg:text-sm",
        colors,
        className,
      )}
    >
      {label}
    </div>
  );
};
