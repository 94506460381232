import React from "react";

import { cn } from "@/lib/utils";

import { AnimatedClickable } from "@/components";
import { useLocation, useNavigate } from "react-router-dom";
import {
  SHOULD_NAVIGATE_TO_ACCOUNT_HOME_IF_ITEM_TYPE_SWITCHES,
  SHOULD_NAVIGATE_TO_HOME_IF_ITEM_TYPE_SWITCHES,
} from "@/constants.ts";
import { useOperatingSystem } from "@/hooks";

interface ToggleSwitchProps {
  value: string;
  icon: string;
  contentName?: string;
  className?: string;
  disabled?: boolean;
  onChange: () => void;
}

export const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  value,
  icon,
  contentName = "",
  className,
  disabled = false,
  onChange,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleChange = () => {
    if (!disabled) {
      onChange();
      if (
        SHOULD_NAVIGATE_TO_HOME_IF_ITEM_TYPE_SWITCHES.some((pattern) =>
          pattern.test(location.pathname),
        )
      ) {
        navigate("/");
      }

      if (
        SHOULD_NAVIGATE_TO_ACCOUNT_HOME_IF_ITEM_TYPE_SWITCHES.some((pattern) =>
          pattern.test(location.pathname),
        )
      ) {
        navigate("/account");
      }
    }
  };

  return (
    <AnimatedClickable
      key={value}
      className={cn(
        "relative flex h-8 items-center rounded-full bg-background-secondary",
        "hover:bg-background-tertiary",
        contentName ? "px-4 lg:h-10 lg:w-36" : "lg:h-10 lg:w-10",
        className,
      )}
      whileHover={{ scale: 1.1 }}
      onClick={handleChange}
    >
      {contentName && (
        <span
          className={cn(
            "text-sm",
            disabled
              ? "font-semibold text-text-disabled"
              : "font-semibold text-text-primary",
            "hidden flex-grow lg:inline-flex",
            "mr-2.5",
          )}
        >
          {contentName}
        </span>
      )}
      <span
        className={cn(
          "material-symbols-rounded items-center text-xl",
          disabled ? "text-text-disabled" : "text-text-primary",
          "lg:text-2xl",
          "mr-1",
          !contentName && "ml-1",
        )}
      >
        {icon}
      </span>

      <div
        className={cn(
          "absolute flex h-4 w-4 items-center justify-center rounded-full border-2 border-background-primary",
          "lg:h-6 lg:w-6",
          "lg:right -bottom-0.5 -right-0.5 lg:-bottom-1",
          disabled
            ? "bg-background-secondary text-text-disabled"
            : "bg-primary-500 text-text-primary",
        )}
      >
        {!contentName && (
          <span
            className="material-symbols-rounded"
            style={{ fontSize: "0.75rem" }}
          >
            swap_horizontal_circle
          </span>
        )}
        {contentName && (
          <span className="material-symbols-rounded md:text-md">
            swap_horizontal_circle
          </span>
        )}
      </div>
    </AnimatedClickable>
  );
};
