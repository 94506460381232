export enum SortOrder {
  Ascending = "asc",
  Descending = "desc",
}

export enum SortParamListingsDRC20 {
  CreatedAt = "createdAt",
  UnitPrice = "unitPrice",
  InscriptionNumber = "inscriptionNumber",
}

export interface SortListingsDRC20 {
  sortParam: SortParamListingsDRC20;
  sortOrder: SortOrder;
}

export enum SortParamListingsCollection {
  CreatedAt = "createdAt",
  Price = "price",
  InscriptionNumber = "inscriptionNumber",
  ListingPrice = "listingPrice",
}

export interface SortListingsCollection {
  sortParam: SortParamListingsCollection;
  sortOrder: SortOrder;
}

// SDOGGS enhancements
export enum SortParamListingsDunes {
  CreatedAt = "createdAt",
  UnitPrice = "unitPrice",
  InscriptionNumber = "inscriptionNumber",
}

export interface SortListingsDunes {
  sortParam: SortParamListingsDunes;
  sortOrder: SortOrder;
}
