import React from "react";

import { Item } from "./item";
import { ListProps } from "./types";

export const List: React.FC<ListProps> = ({ items, title }) => {
  return (
    <div className="flex flex-col space-y-2 p-4">
      <span className="text-lg font-medium text-text-primary">{title}</span>
      <div className="flex flex-col space-y-2 rounded-lg bg-background-secondary p-4">
        {items.map((item, idx) => (
          <Item key={idx} label={item.label} value={item.value} />
        ))}
      </div>
    </div>
  );
};
