import { DogePriceContext } from "@/context/DogePriceProvider";
import { useContext } from "react";

export const useDogePrice = () => {
  const dogePriceContext = useContext(DogePriceContext);
  if (!dogePriceContext) {
    throw new Error("useDogePrice must be used within a DogePriceProvider");
  }

  return dogePriceContext;
};
