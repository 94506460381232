import React from "react";

import { cn } from "@/lib/utils";
import { Tab, InscribeTab, InscribeContentType } from "@/types";

import { AnimatedClickable } from "@/components";

export interface TabItem {
  label: string;
  value: Tab;
  isActive: boolean;
  disabled?: boolean;
  onSelect: (tab: any) => void;
}

export interface InscribeTabItem {
  label: string;
  value: InscribeTab;
  isActive: boolean;
  disabled?: boolean;
  onSelect: (tab: any) => void;
}

export interface InscribeContentTypeTabItem {
  label: string;
  value: InscribeContentType;
  isActive: boolean;
  disabled?: boolean;
  onSelect: (tab: any) => void;
}

interface ScrollableTabsProps {
  tabs: TabItem[] | InscribeTabItem[] | InscribeContentTypeTabItem[];
  className?: string;
  classNameTab?: string;
  classNameActiveTab?: string;
}

export const ScrollableTabs: React.FC<ScrollableTabsProps> = ({
  tabs,
  className,
  classNameTab,
  classNameActiveTab,
}) => {
  return (
    <div className={cn("relative flex w-full flex-col", className)}>
      <div className="flex flex-row space-x-2 overflow-x-auto pr-6 h-full">
        {tabs &&
          tabs.length > 0 &&
          tabs.map((tab) => tab.disabled ? (
            <div key={tab.value} className={cn("flex-shrink-0 p-2 text-md font-semibold text-text-tertiary opacity-70 cursor-not-allowed content-center", classNameTab)}>
              {tab.label}
            </div>
          ) : (
            <AnimatedClickable
              key={tab.value}
              onClick={!tab.disabled && tab.onSelect.bind(null, tab.value)}
              className={cn(
                "flex-shrink-0 cursor-pointer p-2 text-md font-semibold transition-colors duration-100 hover:text-text-highlight focus:outline-none content-center",
                tab.isActive
                  ? classNameActiveTab && classNameActiveTab.length > 0
                    ? classNameActiveTab
                    : "border-b-2 border-primary-500 text-text-highlight"
                  : "text-text-tertiary",
              )}
            >
              {tab.label}
            </AnimatedClickable>
          ))}
      </div>
      <div className="pointer-events-none absolute bottom-0 right-0 top-0 w-10 bg-gradient-to-l from-background-primary to-transparent" />
    </div>
  );
};
