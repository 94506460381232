import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  InscribeTabItem,
  TableInscribeDunes,
  TableInscribe,
} from "@/components";
import { DUNES_LISTINGS_PAGINATION_LIMIT } from "@/constants";
import {
  useCurrency,
  useDogePrice,
  useDunesTokenList,
} from "@/contextHooks";
import { useInfiniteScroll } from "@/hooks";
import { DunesToken, InscribeTab } from "@/types";

import { DuneMintForm } from "../components";
import { ModalMintDune } from "@/modals/modalMintDune";

interface InscribeDunesProps {
  selectedTab: string;
  availableTabItems: InscribeTabItem[];
  setSelectedTab: (value: InscribeTab) => void;
  setEnabledTabItems: (tabItems: InscribeTabItem[]) => void;
}

export const InscribeDunes: React.FC<InscribeDunesProps> = ({
  selectedTab,
  availableTabItems,
  setEnabledTabItems,
  setSelectedTab,
}) => {
  const { currency } = useCurrency();
  const { dogePrice } = useDogePrice();
  const [selectedDune, setSelectedDune] = useState<DunesToken | null>(null);
  const [mintParams, setMintParams] = useState<any>(null);
  const [showReviewMintModal, setShowReviewMintModal] =
    useState<boolean>(false);

  /**
   * Tab Checks
   */

  const { isTabExplore, isTabMint, isTabEtch } = useMemo(() => {
    return {
      isTabExplore: selectedTab === InscribeTab.EXPLORE,
      isTabMint: selectedTab === InscribeTab.MINT,
      isTabEtch: selectedTab === InscribeTab.ETCH,
    };
  }, [selectedTab]);

  /**
   * Data Fetching & Processing
   */
  const {
    // Table Data
    mintableTableOffset,
    isLoadingMintableTableData,
    isLoadingMoreMintableTableData,
    mintableTableData,
    debouncedSetMintableTableOffset,
    hasMoreMintableTableData,
  } = useDunesTokenList();

  const enabledTabs = useMemo<InscribeTabItem[]>(() => {
    const tabConditions: Record<InscribeTab, boolean[]> = {
      [InscribeTab.EXPLORE]: [true],
      [InscribeTab.MINT]: [true],
      [InscribeTab.ETCH]: [true],
    };

    return availableTabItems.filter(
      (tab: InscribeTabItem) => tabConditions[tab.value]?.length > 0,
    );
  }, [availableTabItems]);

  // Ensure to only load those tabs which have data
  useEffect(() => {
    if (!isLoadingMintableTableData) {
      setEnabledTabItems(enabledTabs);
    }
  }, [enabledTabs, isLoadingMintableTableData, setEnabledTabItems]);

  const dunesDataEnhanced = useMemo(() => {
    return mintableTableData.map((token: DunesToken) => ({
      ...token,
      currency,
      currentDogePrice: dogePrice,
      onSelectForMint: () => {
        setSelectedDune(token);
        setSelectedTab(InscribeTab.MINT);
      },
    }));
  }, [currency, dogePrice, setSelectedTab, mintableTableData]);

  // For Infinite Scroll
  const bottomTableRef = useInfiniteScroll({
    isLoadingData: isLoadingMintableTableData,
    hasMoreData: hasMoreMintableTableData,
    isLoadingMoreData: isLoadingMoreMintableTableData,
    setOffset: debouncedSetMintableTableOffset,
    offset: mintableTableOffset,
    paginationLimit: DUNES_LISTINGS_PAGINATION_LIMIT,
  });

  return (
    <>
      <div className="relative">
        <div className="py-2 lg:py-4">
          <>
            {isTabExplore && (
              <TableInscribe
                defaultSortId="holders"
                columns={TableInscribeDunes}
                data={dunesDataEnhanced}
                loading={isLoadingMintableTableData}
                loadingMore={isLoadingMoreMintableTableData}
              />
            )}

            {isTabMint && (
              <DuneMintForm
                dune={selectedDune}
                onMint={(params) => {
                  setMintParams(params);
                  setSelectedDune(params);
                  setShowReviewMintModal(true);
                }}
              />
            )}

            {isTabEtch && <div>Etch Dunes coming soon</div>}
          </>
        </div>
      </div>

      <div
        className="pb-1"
        ref={bottomTableRef}
        key={"scrollbar-trading-collectibles"}
      />
      {/*There can be screen sizes causing the anchor tag not to get in focus of the screen supervisor. This helps.*/}
      <div className="pb-1" key={"scrollbar-trading-collectibles-buffer"} />

      {showReviewMintModal && selectedDune && (
        <ModalMintDune
          isVisible={showReviewMintModal}
          onClose={() => {
            setShowReviewMintModal(false);
            setSelectedDune(null);
            setSelectedTab(InscribeTab.EXPLORE);
          }}
          duneMintOptions={{
            ...mintParams,
            divisibility: selectedDune?.divisibility || 0,
            duneId: selectedDune.id,
            tick: selectedDune?.name || "",
            limit: selectedDune?.mintTerms?.limit || 0,
            isLoading: false,
            isSelected: false,
            currency,
            currentDogePrice: dogePrice,
            onMint: () => {
              console.log("### Minting Dune ###");
            },
          }}
        />
      )}
    </>
  );
};

export const InscribeDunesInfo: React.FC = () => {
  const [currentBlock, setCurrentBlock] = useState<number>(0);
  const startBlock = 5084000;
  const phaseLength = 175000;

  useEffect(() => {
    const fetchBlockCount = async () => {
      const resp = await fetch("https://wonky-ord-v2.dogeord.io/block-count");
      const data = await resp.json();
      setCurrentBlock(Number(data));
    };

    fetchBlockCount();
  }, []);

  const phase = Math.floor((currentBlock - startBlock) / phaseLength) + 1;
  const nextPhase = phase * phaseLength + startBlock - currentBlock;
  const blocksPerDay = 1440;
  const nextPhaseInDays = Math.ceil(nextPhase / blocksPerDay);
  const phaseMinLength = 13 - phase;

  return (
    <div className="flex flex-row bg-[#27272A] justify-between mr-4 ml-4 mt-[10px] mb-[8px] px-2 rounded-lg text-white text-[12px] leading-[30px] md:mt-[24px]">
      <div>
        <span className="text-[#9F9FA8]">Phase:</span> {phase} ({phaseMinLength}{" "}
        ~ 26 Charsets)
      </div>
      <div>
        <span className="text-[#9F9FA8]">Next Phase:</span> {nextPhaseInDays}{" "}
        Days
      </div>
    </div>
  );
};
