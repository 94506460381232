import React, { useMemo, useState } from "react";

import { ListItem, ListItemProps } from "@/components";
import { ModalUTXO } from "@/modals";

import { Sheet, SheetProps } from "../base";

interface ModalExperimentalProps extends SheetProps {}

export const ModalExperimental: React.FC<ModalExperimentalProps> = ({
  isVisible = false,
  onClose,
}) => {
  const [isUTXOVisible, setIsUTXOVisible] = useState(false);

  const mainList: ListItemProps[] = useMemo(() => {
    return [
      {
        icon: "account_balance",
        label: "Unblock UTXOs",
        onClick: () => setIsUTXOVisible(true),
      },
    ];
  }, []);

  return (
    <>
      <Sheet
        isVisible={isVisible}
        onClose={onClose}
        title="Experimental"
        classNameContent="flex flex-col flex-1"
      >
        <div className="flex flex-1 flex-col space-y-4 p-4 pb-safe-or-4">
          <div className="flex flex-col space-y-2 rounded-xl bg-background-secondary p-2">
            {mainList.map((item, index) => (
              <ListItem key={index} {...item} />
            ))}
          </div>
        </div>
      </Sheet>
      <ModalUTXO
        isVisible={isUTXOVisible}
        onClose={() => setIsUTXOVisible(false)}
      />
    </>
  );
};
