import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  AnimatedClickable,
  AppNavigation,
  AppScreen,
  Appear,
  Button,
  List,
  ListingCardDune,
  Skeleton,
  Spinner,
  useToast,
  ImageWithFallback,
  PendingIndicator,
} from "@/components";
import { ONE_DOGE_IN_SHIBES } from "@/constants";
import { useCurrency, useDogePrice, useTxWallet } from "@/contextHooks";
import { calculateMarketCap } from "@/hooks";
import { NumberFormatType, formatBigValue, formatNumber } from "@/lib/numbers";
import { cn } from "@/lib/utils";
import { ModalListDune, ModalSendDune } from "@/modals";
import { Currency, DuneListing } from "@/types";
import {
  calculateFloorDifference,
  getFormattedValue,
  getIconForDune,
} from "@/utility";
import {
  ScrollabelCarousel,
  ScrollableCarouselItem,
} from "@/components/scrollableCarousel";
import { PendingIndicatorMessage } from "@/components/pendingIndicator";
import BigNumber from "bignumber.js";

export const AccountDuneDetail: React.FC = () => {
  const { toast } = useToast();
  const { token } = useParams();
  const navigate = useNavigate();
  const { currency } = useCurrency();
  const { dogePrice } = useDogePrice();
  const {
    txWallet,
    accountDuneListings,
    accountDuneData: accountData,
    delistDune,
  } = useTxWallet();

  /**
   * Local State
   */

  const intervalId = useRef<null | NodeJS.Timeout>(null);
  const [selectedListings, setSelectedListings] = useState<string[]>([]);
  const [modalSendVisible, setModalSendVisible] = useState(false);
  const [modalListVisible, setModalListVisible] = useState(false);

  /**
   * Data Fetching
   */

  const { error, isError, preparationErrors, isSuccess, execute, isLoading } =
    delistDune;

  /**
   * Actions
   */

  const toggleModalSend = useCallback(() => {
    setModalSendVisible((prev) => !prev);
  }, []);

  const toggleModalList = useCallback(() => {
    setModalListVisible((prev) => !prev);
  }, []);

  const onSelectListing = useCallback(
    (id: string) => {
      setSelectedListings((prev) => {
        if (prev.includes(id)) {
          return prev.filter((i) => i !== id);
        }
        return [...prev, id];
      });
    },
    [setSelectedListings],
  );

  // Open the token page
  const openTokenPageHandler = useCallback(() => {
    token && navigate(`/dune/${token}`);
  }, [navigate, token]);

  // Send, List and Buy actions
  const onSend = useCallback(() => {
    toggleModalSend();
  }, [toggleModalSend]);

  const onList = useCallback(() => {
    toggleModalList();
  }, [toggleModalList]);

  const onDelist = useCallback(() => {
    // get the inscriptionIds of the selected listings by
    // filtering the listings based on the selected offerIds
    const filteredListings = accountDuneListings.listings.filter(
      (listing: DuneListing) => selectedListings.includes(listing.offerId),
    );

    const tx_hashes = filteredListings.map(
      (listing: DuneListing) => listing.txHash,
    );
    const tx_vouts = filteredListings.map((listing: DuneListing) =>
      Number(listing.txIndex),
    );
    const offerIds = filteredListings.map(
      (listing: DuneListing) => listing.offerId,
    );

    execute(tx_hashes, tx_vouts, offerIds);
  }, [accountDuneListings.listings, execute, selectedListings]);

  const onBuy = useCallback(() => {
    openTokenPageHandler();
  }, [openTokenPageHandler]);

  // Value and valueDetail for the token
  const { value, valueDetail } = useMemo(() => {
    if (
      !accountData ||
      accountData.loading ||
      !accountData.accountDunesData ||
      accountData.accountDunesData.availableBalance == 0 ||
      accountData.accountDunesData.tick !== token
    )
      return { value: "0.00", valueDetail: "0.00" };

    const detail =
      currency === Currency.USD
        ? accountData.accountDunesData.walletTokenValueInUSD
        : accountData.accountDunesData.walletTokenValueInDoge;

    return {
      value: `${accountData?.accountDunesData?.availableBalance} ${accountData.tokenData?.symbol?.toUpperCase()}`,
      valueDetail: `${currency}${detail}`,
    };
  }, [accountData, token, currency]);

  const numPendingSending = useMemo(() => {
    if (!accountData || accountData.loading || !accountData.accountDunesData)
      return 0;
    const accountDunesData = accountData?.accountDunesData;
    const pendingSend = formatBigValue(
      Number(accountDunesData?.sendingPendingAmount),
      accountDunesData?.divisibility,
    );
    return parseFloat(pendingSend);
  }, [accountData]);
  const numPendingReceiving = useMemo(() => {
    if (!accountData || accountData.loading || !accountData.accountDunesData)
      return 0;
    const accountDunesData = accountData?.accountDunesData;
    const pendingRec = formatBigValue(
      Number(accountDunesData?.receivingPendingAmount),
      accountDunesData?.divisibility,
    );
    return parseFloat(pendingRec);
  }, [accountData]);

  const listAbout = useMemo(() => {
    if (!accountData || !accountData.tokenData) return [];
    return [
      {
        key: "symbol",
        label: "Symbol",
        value: accountData.tokenData?.symbol || "",
      },
      {
        key: "floor",
        label: "Floor",
        value: `${getFormattedValue(accountData.tokenData.floorPrice || 0, dogePrice, currency, true).value}`,
      },
      {
        key: "marketCap",
        label: "Market Cap",
        value: `${getFormattedValue(calculateMarketCap(accountData.tokenData), dogePrice, currency, false).value}`,
      },
      {
        key: "twentyFourHourVolume",
        label: "24 Hour Volume",
        value: `${getFormattedValue(accountData.tokenData.twentyFourHourVolume || 0, dogePrice, currency, false).value}`,
      },
      {
        key: "volume",
        label: "Volume",
        value: `${getFormattedValue(accountData.tokenData.volume || 0, dogePrice, currency, false).value}`,
      },
      {
        key: "holders",
        label: "Owners",
        value: formatNumber({
          value: accountData.tokenData.holders || 0,
          type: NumberFormatType.Large_Number,
        }),
      },
    ];
  }, [dogePrice, accountData, currency]);

  /**
   * Effects
   */
  const refetch = useCallback(
    async (cachebreaker: boolean = false) => {
      if (token && txWallet?.address && !accountData.loading) {
        await Promise.all([
          accountDuneListings.fetchListings(token, cachebreaker),
          accountData.getDuneData(token),
          // for now we need the cachebreaker (maybe :D) - if something weird happens set it to true
          accountData.getDuneAccountData(token, false, true),
        ]);
      }
    },
    [token, txWallet?.address, accountDuneListings, accountData],
  );

  // Delist Success
  useEffect(() => {
    if (isSuccess) {
      const numberOfListings = selectedListings.length;
      toast({
        title: "Success",
        variant: "default",
        description: `Successfully delisted ${numberOfListings} ${numberOfListings === 1 ? "listing" : "listings"}.`,
      });

      setSelectedListings([]);
      // reload with waiting for caches to be invalidated
      setTimeout(() => accountDuneListings.fetchListings(token), 5000);
    }
  }, [isSuccess, selectedListings, toast, accountDuneListings, token]);

  // Delist Error
  useEffect(() => {
    if ((isError && error) || preparationErrors) {
      toast({
        title: "Error",
        variant: "error",
        description: error ?? "An unknown error occurred …",
      });

      setSelectedListings([]);
      refetch();
    }
  }, [token, error, isError, toast, preparationErrors, refetch]);

  // call on initial loading and on address change
  useEffect(() => {
    if (txWallet?.address) {
      if (!intervalId.current) {
        refetch();
      }

      if (intervalId.current) {
        clearInterval(intervalId.current);
      }

      // Set a new interval
      intervalId.current = setInterval(() => refetch(), 60_000);

      // Cleanup on unmount
      return () => clearInterval(intervalId.current as NodeJS.Timeout);
    }
  }, [txWallet?.address]);

  return !accountData ? (
    <></>
  ) : (
    <>
      <AppScreen withNavigationHeader>
        <AppNavigation
          title={token?.toUpperCase() || " "}
          onAction={openTokenPageHandler}
          onBack={() => navigate(-1)}
          iconAction="open_in_new"
          withAppHeader
          className="lg:hidden"
        />

        {accountData && accountData.loading ? (
          <div
            className={cn(
              "flex flex-col space-y-8 py-4",
              "mx-auto w-full max-w-xl xl:mt-12 xl:rounded-2xl xl:border-0.5 xl:border-border-primary xl:bg-gradient-to-t xl:from-background-primary xl:to-background-secondary xl:p-4",
            )}
          >
            <div className="hidden flex-row items-center justify-between lg:flex">
              <Button
                variant="icon"
                shape="circle"
                onClick={() => navigate(-1)}
                className="h-8 w-8 text-text-tertiary"
              >
                <span className="material-symbols-rounded text-lg">
                  arrow_back
                </span>
              </Button>
              <Button
                variant="icon"
                shape="circle"
                onClick={openTokenPageHandler}
                className="h-8 w-8 text-text-tertiary"
              >
                <span className="material-symbols-rounded text-lg">
                  open_in_new
                </span>
              </Button>
            </div>
            <TokenDetailSkeleton />
          </div>
        ) : (
          <div
            className={cn(
              "flex flex-col space-y-8 py-4",
              "mx-auto w-full max-w-xl drop-shadow-lg xl:mt-12 xl:rounded-2xl xl:border-0.5 xl:border-border-primary xl:bg-gradient-to-t xl:from-background-primary xl:to-background-secondary xl:p-4",
            )}
          >
            {/** Header */}
            <div className="hidden flex-row items-center justify-between lg:flex">
              <Button
                variant="icon"
                shape="circle"
                onClick={() => navigate(-1)}
                className="h-8 w-8 text-text-tertiary"
              >
                <span className="material-symbols-rounded text-lg">
                  arrow_back
                </span>
              </Button>
              <Button
                variant="icon"
                shape="circle"
                onClick={openTokenPageHandler}
                className="h-8 w-8 text-text-tertiary"
              >
                <span className="material-symbols-rounded text-lg">
                  open_in_new
                </span>
              </Button>
            </div>

            {/** Balance */}
            <div className="flex flex-col space-y-1">
              <div className="flex flex-1 flex-row items-center justify-center space-x-2 lg:space-x-4">
                {token && (
                  <ImageWithFallback
                    image={getIconForDune(token.toLowerCase())}
                    className="hidden h-8 w-8 md:block lg:h-10 lg:w-10"
                  />
                )}
                <span
                  className="text-center text-4xl font-bold text-text-primary"
                  dangerouslySetInnerHTML={{ __html: value }}
                />
              </div>
              <span
                className="text-center text-sm font-medium text-text-tertiary"
                dangerouslySetInnerHTML={{ __html: valueDetail }}
              />
              <PendingIndicator
                pendingAmount={new BigNumber(numPendingSending)
                  .plus(new BigNumber(numPendingReceiving))
                  .toNumber()}
              />
              {numPendingReceiving > 0 &&
                numPendingSending + numPendingReceiving === 0 && (
                  <PendingIndicatorMessage message="Pending transactions" />
                )}
            </div>

            {/** Actions */}
            <div className="flex w-full flex-row space-x-2 px-6">
              <Button
                className="flex-1"
                shape="circle"
                onClick={onSend}
                disabled={accountData.loading || Number(value) === 0}
              >
                Send
              </Button>
              <Button
                className="flex-1"
                shape="circle"
                onClick={onList}
                disabled={accountData.loading || Number(value) === 0}
              >
                List
              </Button>
              <Button
                className="flex-1"
                shape="circle"
                onClick={onBuy}
                disabled={accountData.loading}
              >
                Buy
              </Button>
            </div>

            {/** Listings */}
            <div className="flex flex-col space-y-2">
              <span className="ml-4 text-lg font-medium text-text-primary">
                Listings
              </span>
              <div className="w-full overflow-hidden py-2">
                {accountDuneListings.listings.length ? (
                  <ScrollabelCarousel>
                    {[...accountDuneListings.listings].map((listing) => {
                      const {
                        offerId,
                        tick,
                        amount,
                        price,
                        status,
                        unitPrice,
                      } = listing;
                      const onSelect = () => onSelectListing(offerId);
                      const selected = selectedListings.includes(offerId);
                      const totalPriceInDoge = formatNumber({
                        value: price / ONE_DOGE_IN_SHIBES,
                        type: NumberFormatType.Price,
                      });
                      const unitPriceInDoge = formatNumber({
                        value: unitPrice / ONE_DOGE_IN_SHIBES,
                        type: NumberFormatType.Price,
                      });
                      const totalPriceInUsd = formatNumber({
                        value: (price / ONE_DOGE_IN_SHIBES) * (dogePrice ?? 0),
                        type: NumberFormatType.Price,
                      });
                      const unitPriceInUsd = formatNumber({
                        value:
                          (unitPrice / ONE_DOGE_IN_SHIBES) * (dogePrice ?? 0),
                        type: NumberFormatType.Price,
                      });
                      const floorDifferencePercentage =
                        calculateFloorDifference(
                          unitPrice,
                          accountData.tokenData?.floorPrice || 0,
                        );
                      return (
                        <ScrollableCarouselItem
                          key={`carousel-${offerId}`}
                          className="sm:min-w-auto min-w-28 basis-[50%] sm:basis-[50%] lg:basis-[40%] xl:basis-[40%]"
                        >
                          <ListingCardDune
                            key={offerId}
                            tick={tick}
                            symbol={accountData.tokenData?.symbol}
                            onSelect={onSelect}
                            selected={selected}
                            className="w-full flex-shrink-0"
                            value={`${amount}`}
                            floorDifferencePercentage={
                              floorDifferencePercentage
                            }
                            totalPriceDoge={`${Currency.DOGE}${totalPriceInDoge}`}
                            unitPriceDoge={`${Currency.DOGE}${unitPriceInDoge}`}
                            totalPriceUSD={`${Currency.USD}${totalPriceInUsd}`}
                            unitPriceUSD={`${Currency.USD}${unitPriceInUsd}`}
                            status={status}
                            displayPendingState
                          />
                        </ScrollableCarouselItem>
                      );
                    })}
                  </ScrollabelCarousel>
                ) : (
                  Number(value) > 0 && (
                    <div className="flex flex-row space-x-2 overflow-x-auto px-4">
                      <AnimatedClickable
                        onClick={onList}
                        className="flex h-52 min-w-36 cursor-pointer flex-col items-center justify-center space-y-2 rounded-lg bg-background-secondary p-4 hover:bg-background-tertiary"
                      >
                        <span className="material-symbols-rounded text-2xl text-text-primary">
                          add_circle
                        </span>
                        <span className="text-center text-sm font-semibold text-text-primary">
                          {`List ${token?.toUpperCase()}`}
                        </span>
                      </AnimatedClickable>
                    </div>
                  )
                )}
              </div>
            </div>

            {/** About */}
            <List title={`About ${token?.toUpperCase()}`} items={listAbout} />
          </div>
        )}
      </AppScreen>

      <Appear isVisible={selectedListings.length > 0} from="bottom">
        <Button
          size="large"
          shape="circle"
          variant="inverse"
          onClick={onDelist}
          className="w-1/2 shadow-lg"
          disabled={isLoading}
        >
          {isLoading ? <Spinner size={24} /> : "Delist Selected"}
        </Button>
      </Appear>

      {token !== undefined && (
        <ModalSendDune
          token={token}
          isVisible={modalSendVisible}
          listings={accountDuneListings.listings}
          onClose={toggleModalSend}
        />
      )}

      {token !== undefined && true && accountData.tokenData !== undefined && (
        <ModalListDune
          listings={accountDuneListings.listings}
          token={token}
          tokenData={accountData.tokenData}
          accountData={accountData.accountDunesData}
          isVisible={modalListVisible}
          onClose={toggleModalList}
          onSuccess={refetch}
        />
      )}
    </>
  );
};

const TokenDetailSkeleton = () => {
  return (
    <>
      {/** Balance */}
      <div className="flex flex-col space-y-1">
        <div className="flex flex-1 flex-row items-center justify-center space-x-2 lg:space-x-4">
          <Skeleton className="hidden h-8 w-8 rounded-full bg-background-tertiary/40 md:block lg:h-10 lg:w-10" />
          <Skeleton className="h-10 w-36 bg-background-tertiary/40 font-bold text-text-primary" />
        </div>
        <Skeleton className="mx-auto h-4 w-20 bg-background-tertiary/40 text-text-primary" />
      </div>
      {/** Actions */}
      <div className="flex w-full flex-row space-x-2 px-6">
        <Skeleton className="h-12 w-full rounded-full" />
        <Skeleton className="h-12 w-full rounded-full" />
        <Skeleton className="h-12 w-full rounded-full" />
      </div>
      <div className="flex flex-col space-y-2">
        <Skeleton className="ml-4 h-8 w-24 rounded-lg" />
        <div className="flex flex-row gap-2 space-x-2 overflow-x-hidden px-4 py-2 ">
          <Skeleton className="h-52 w-36 shrink-0 rounded-lg" />
          <Skeleton className="h-52 w-36 shrink-0 rounded-lg" />
        </div>
      </div>
      <div className="flex flex-col space-y-2">
        <Skeleton className="ml-4 h-8 w-24 rounded-lg" />
        <div className="flex flex-row gap-2 space-x-2 overflow-x-hidden px-4 py-2">
          <Skeleton className="h-52 w-full rounded-lg" />
        </div>
      </div>
    </>
  );
};
