import { Link } from "react-router-dom";

import { shortenAddress } from "@/lib/address";
import { cn } from "@/lib/utils";

interface FromToCellProps {
  from: string;
  to: string | null;
  className?: string;
}

export const FromToCell: React.FC<FromToCellProps> = ({
  from,
  to,
  className,
}) => {
  return (
    <div className={cn("mr-4 w-28", className)}>
      <div
        className={cn(
          "flex flex-row items-center space-x-1 text-sm font-medium text-text-primary",
          "lg:space-x-2 lg:text-md",
        )}
      >
        <Link to={`/wallet/${from}`}>
          <span>{shortenAddress(from, 3)}</span>
        </Link>
        {to && (
          <>
            <span
              className={cn(
                "material-symbols-rounded text-xs text-text-tertiary",
                "lg:text-sm",
              )}
            >
              arrow_right_alt
            </span>
            <Link to={`/wallet/${to}`}>
              <span>{shortenAddress(to, 3)}</span>
            </Link>
          </>
        )}
      </div>
    </div>
  );
};
