import { cn } from "@/lib/utils";
import { getColorForFloorDifference } from "@/utility";

import { formatValueForChange } from "../formatValueForChange";
import { ChangeCellType } from "./type";
import { ChangeIndicator } from "@/routes/Trading/components/changeIndicator";

interface ChangeCellProps {
  change: number;
  type?: ChangeCellType;
  className?: string;
  onClick?: () => void;
  hideZero?: boolean;
}

export const ChangeCell: React.FC<ChangeCellProps> = ({
  change,
  type = ChangeCellType.Change,
  className,
  hideZero = true,
  onClick,
}) => {
  if ((change === 0 || change === undefined) && hideZero) {
    return (
      <div className="flex h-6 flex-1 flex-col justify-center">
        <span
          className={cn(
            "text-right text-sm text-text-primary lg:text-md",
            className,
          )}
        >
          -
        </span>
      </div>
    );
  }

  const value = formatValueForChange(change, hideZero);
  const textColor =
    type === ChangeCellType.Change ? "" : getColorForFloorDifference(change);

  return (
    <div className={cn("text-sm lg:text-md")} onClick={onClick}>
      <ChangeIndicator
        includeArrow={type !== ChangeCellType.FloorDifference}
        change={value}
        className={cn(className, "justify-end", textColor)}
      />
    </div>
  );
};
