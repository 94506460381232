import { cn } from "@/lib/utils";

export const ChangeIndicator = ({
  change,
  className,
  includeArrow = true,
  showEmptyIndicator = false,
}: {
  change: string;
  className?: string;
  prefix?: string;
  suffix?: string;
  includeArrow?: boolean;
  showEmptyIndicator?: boolean;
}) => {
  const isPositive = change.includes("+") || !change.includes("-");
  const isNegative = change.includes("-");
  const changeWithoutSign = change.replace(/[+-]/g, "");

  const shouldShowEmptyIndicator = !changeWithoutSign && showEmptyIndicator;

  if (!changeWithoutSign && !showEmptyIndicator) return null;

  return (
    <div
      className={cn(
        "text-inherit flex items-center text-text-disabled",
        includeArrow && "-ml-1",
        isPositive && "text-green-500",
        isNegative && "text-red-500",
        shouldShowEmptyIndicator && "text-text-disabled",
        className,
      )}
    >
      {!shouldShowEmptyIndicator && includeArrow && (
        <span className="material-symbols-rounded -mr-1 text-xl leading-none">
          {isPositive ? "arrow_drop_up" : "arrow_drop_down"}
        </span>
      )}
      <span
        dangerouslySetInnerHTML={{
          __html: shouldShowEmptyIndicator ? "-" : changeWithoutSign,
        }}
      />
    </div>
  );
};
