import { useLocation, useNavigate } from "react-router-dom";

export const PageNotFound = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const state = (location.state as { from: { pathname: string } }) || {};

  const handleGoBack = () => {
    const { from = { pathname: "/" } } = state;

    if (from === undefined || from.pathname === location.pathname) {
      navigate("/", { replace: true });
      return;
    }

    navigate(from, { replace: true });
  };

  return (
    <div className="flex flex-1 flex-col py-safe-or-4">
      <div className="flex flex-1 flex-col items-center justify-center">
        <div className="flex flex-col space-y-2 p-4 text-center">
          <h1 className="font-display text-2xl font-black text-text-primary">
            404
          </h1>
          <p className="text-sm text-text-secondary">404 Message.</p>
        </div>
      </div>
      <div className="flex justify-center">
        <button className="w-1/2" onClick={handleGoBack}>
          Back
        </button>
      </div>
    </div>
  );
};
