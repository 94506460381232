export function getIconForTick(tick: string | null | undefined) {
  return tick
    ? `https://drc-20-icons.dogeord.io/${tick.toLowerCase()}.png`
    : null;
}

export function getIconForDune(dune: string | null | undefined) {
  return dune
    ? `https://dune-icons.sdoggs.exchange/${dune.toLowerCase()}.png`
    : `https://dune-icons.sdoggs.exchange/noicon_large.png`;
}
