import * as bitcoin from "bitcoinjs-lib";
import * as dogecore from "bitcore-lib-doge";
import * as ecc from "tiny-secp256k1";

import {
    fundWallet,
  getTotalTransactionFeeInSats,
} from "@/context/wallet/lib/transaction.ts";

bitcoin.initEccLib(ecc);

import { TxWallet, TxWithFeeEstimation } from "@/context/TxWalletProvider";
import { Edict, constructScript, parseDuneId } from "./sendDune";
import { DUNES_INSCRIBE_FEE_WALLET, ONE_DOGE_IN_SHIBES } from "@/constants";
const { Transaction } = dogecore;

type BuildMintDuneTxsParams = {
  txWallet: TxWallet | undefined;
  receiver: string | undefined;
  duneId: string;
  amt: number;
  limit: number;
  feePerVByte: number;
  estimateFeesOnly: boolean;
};

const buildMintDuneTxs = async (
  buildMintDuneTxsParams: BuildMintDuneTxsParams,
): Promise<TxWithFeeEstimation[] | undefined> => {
  const {
    txWallet,
    receiver,
    duneId,
    amt,
    limit,
    feePerVByte,
    estimateFeesOnly
  } = buildMintDuneTxsParams;

  if (!txWallet) {
    console.warn("buildMintDunes - no wallet found");
    throw new Error("No wallet found");
  }

  if (!duneId) throw "No dune id provided";

  // array to store all txs that we will broadcast at the end
  let txs: any = [];

  const duneTxs = await createMintDuneTxs(
    txWallet,
    receiver,
    feePerVByte,
    estimateFeesOnly,
    amt,
    limit,
    duneId,
  );

  txs = txs.concat(duneTxs).map((tx: any) => ({
    tx,
    txFeeInSats: getTotalTransactionFeeInSats(tx, feePerVByte),
  }));

  return txs;
};

// inscribes and creates Dune minting transactions
/**
 *
 * @param fullWallet
 * @param feePerVByte
 * @param estimateFeesOnly - don't update the wallet utxos if set to true.
 * @param amount
 * @param duneId
 */
const createMintDuneTxs = async (
    fullWallet: TxWallet,
    receiver: string | undefined,
    feePerVByte: number,
    estimateFeesOnly: boolean = false,
    amount: number,
    limit: number,
    duneId: string,
  ) => {
    const txs = [];
    const wallet = fullWallet;
  
    // Parse given id string to dune id
    const parsedDuneId = parseDuneId(duneId, true);

    // mint dune with encoded id, max mintable amount on output 1
    const edicts = [new Edict(parsedDuneId, limit, 1)];

    // Create script for given dune statements
    const script = constructScript(null, undefined, null, edicts);

    for (let i = 0; i < amount; i++) {
        // Create a new transaction
        const tx = new Transaction();

        // Output carries the protocol message
        tx.addOutput(
            new dogecore.Transaction.Output({ script: script, satoshis: 0 })
        );

        // Add receiver output holding dune amount
        if (receiver) {
            tx.to(receiver, 100_000);
        } else {
            tx.to(wallet.address, 100_000);
        }

        // Add service fee output
        tx.to(DUNES_INSCRIBE_FEE_WALLET, ONE_DOGE_IN_SHIBES);

        // Fund wallet with transaction
        fundWallet(wallet, tx, feePerVByte, false);

        // Push transaction to txs array
        txs.push(tx);

        // Update wallet UTXOs if not estimating fees only - skip op_return and dune output
        if (!estimateFeesOnly) {
            wallet.updateUtxos({ tx, outputsToSkip: [0, 1] });
        }
    }

    console.log('#### createMintDuneTxs txs', txs);
    return txs;
  };


export { buildMintDuneTxs };
