import { useCallback, useState } from "react";

import { buildSendDrc20Txs } from "@/context/wallet/helpers/sendDrc20.ts";
import { broadcastAll } from "@/context/wallet/lib/transaction.ts";
import {
  TxWallet,
  TxWithFeeEstimation,
  WalletContextHandleReturn,
} from "@/context/wallet/types.ts";
import { Utxo } from "@/types";
import { handleError } from "@/utility";

type UseSendDrc20Params = {
  txWallet: TxWallet | undefined;
  address: string | undefined;
};

export type SendDrc20Params = {
  receiverAddress: string;
  tick: string;
  amt: number;
  transferInscriptionUtxos: Utxo[];
  isClearRequired: boolean;
  feePerVByte: number;
};

export interface SendDrc20Return extends WalletContextHandleReturn {
  execute: (sendDrc20Params: SendDrc20Params) => Promise<void>;
  txHashes: any[];
}

export const useSendDrc20 = (
  useSendParams: UseSendDrc20Params,
): SendDrc20Return => {
  const [isLoading, setIsSendingDrc20] = useState<boolean>(false);
  const [isError, setIsErrorDrc20Send] = useState<boolean>(false);
  const [isSuccess, setIsSuccessDrc20Send] = useState<boolean>(false);
  const [error, setError] = useState<null | string>(null);
  const [txHashes, setTxHashes] = useState<any[]>([]);

  const { txWallet, address } = useSendParams;

  const buildSendDrc20s = useCallback(
    async (
      buildSendDrc20Params: SendDrc20Params,
    ): Promise<TxWithFeeEstimation[] | undefined> => {
      const estimateFeesOnly = false;
      const buildSendDrc20TxsParams = {
        txWallet,
        address,
        estimateFeesOnly,
        ...buildSendDrc20Params,
      };

      return buildSendDrc20Txs(buildSendDrc20TxsParams);
    },
    [address, txWallet],
  );

  const sendDrc20 = useCallback(
    async (sendDrc20Params: SendDrc20Params) => {
      setIsSendingDrc20(true);
      setIsErrorDrc20Send(false);
      setIsSuccessDrc20Send(false);

      try {
        if (!txWallet) {
          throw new Error("No wallet found");
        }

        const txsWithFeeEstimation = await buildSendDrc20s(sendDrc20Params);
        if (!txsWithFeeEstimation) {
          throw new Error("No txs found");
        }

        const txs = txsWithFeeEstimation.map(
          (txWithFeeEstimation) => txWithFeeEstimation.tx,
        );
        const sentTxHashes = await broadcastAll(txs, {
          receiverAddresses: [sendDrc20Params.receiverAddress],
          address,
          type: "drc20",
        });

        if (
          !sentTxHashes ||
          sentTxHashes.length === 0 ||
          sentTxHashes.length !== txs.length
        ) {
          await txWallet.syncUtxos(true);
          throw new Error("Failed to broadcast transaction");
        }

        for (const tx of txs) {
          txWallet.updateUtxos({ tx: tx });
        }

        setTxHashes(sentTxHashes);

        setIsSuccessDrc20Send(true);
      } catch (e: Error | unknown) {
        setIsErrorDrc20Send(true);
        const message = handleError(e);
        setError(message);
      } finally {
        setIsSendingDrc20(false);
      }
    },
    [txWallet, buildSendDrc20s, address],
  );

  const reset = useCallback(() => {
    setIsSendingDrc20(false);
    setIsErrorDrc20Send(false);
    setIsSuccessDrc20Send(false);
    setError(null);
    setTxHashes([]);
  }, []);

  return {
    isLoading,
    isError,
    isSuccess,
    execute: sendDrc20,
    reset,
    txHashes,
    error,
  };
};
