import React, { useMemo } from "react";

import { cn } from "@/lib/utils";
import { getIconForDune } from "@/utility";

import { AnimatedClickable, ImageWithFallback } from "@/components";
import { ChangeIndicator } from "@/routes/Trading/components/changeIndicator";

interface ActivityCardDuneProps {
  className?: string;
  onClick?: () => void;
  style?: React.CSSProperties;
  title: string;
  valuePrimary: string;
  valuePrimarySuffix?: string;
  valueSecondary: string;
  valueSecondarySuffix?: string;
  valueSecondaryIsChange?: boolean;
  tick: string;
  includeIndicatorSymbol?: boolean;
}

export const ActivityCardDune: React.FC<ActivityCardDuneProps> = ({
  tick,
  title,
  valuePrimary,
  valuePrimarySuffix,
  valueSecondary,
  valueSecondarySuffix,
  // this is ugly but we would need to refactor the whole component to make cleaner
  valueSecondaryIsChange = true,
  className,
  style,
  onClick,
  includeIndicatorSymbol = false,
}) => {
  const image = useMemo(() => getIconForDune(tick), [tick]);
  const suffixClassName =
    "flex justify-center items-center !ml-1 rounded-full bg-background-tertiary/50 px-2 py-0.5 text-2xs font-bold text-text-tertiary md:!ml-2 md:text-xs";

  const duneNameParts = title.split("•");
  return (
    <AnimatedClickable
      onClick={onClick}
      className={cn(
        "flex z-0 flex h-full w-full flex-shrink-0 cursor-pointer flex-col rounded-lg border-0.5 border-border-primary bg-background-secondary p-3 md:space-y-2 relative min-w-full hover:w-fit hover:text-clip hover:z-10 hover:border-2 hover:border-border-secondary",
        className,
      )}
      style={style}
      whileHover={{ scale: 1.03 }}
    >
      <div className="flex flex-row items-center space-x-2">
        <ImageWithFallback
          image={image}
          className="h-6 w-6 md:h-8 md:w-8 lg:h-10 lg:w-10"
        />
        <div className="flex flex-row items-center space-x-2 truncate">
          <span className="sm:hidden text-md font-bold text-text-primary break-words leading-[16px] sm:leading-normal md:break-normal md:text-lg lg:text-xl">
            {
              // This is a hack to split the title by the bullet character and then render the bullet character in between each word to enable a line break after dots
              duneNameParts.map((word, index) => (
                  <span key={`dune-name-${title}-${index}`}>
                    {word}
                    {index < duneNameParts.length - 1 && "•"}
                    <wbr className="" />
                  </span>
                )
              )
            }
          </span>
          <span className="hidden sm:inline-block text-md font-bold text-text-primary leading-[16px] truncate sm:leading-normal md:text-lg lg:text-xl">
            {title}
          </span>
        </div>
      </div>

      <div className="flex flex-1 flex-col justify-end space-y-2">
        <div className="flex">
          <span
            className="text-sm font-semibold text-text-primary md:text-md lg:text-lg"
            dangerouslySetInnerHTML={{ __html: valuePrimary }}
          />

          {valuePrimarySuffix && (
            <span className={suffixClassName}>{valuePrimarySuffix}</span>
          )}
        </div>

        <div className="flex items-center justify-start space-x-2">
          {valueSecondaryIsChange ? (
            <ChangeIndicator
              includeArrow={includeIndicatorSymbol}
              change={valueSecondary}
              className="text-xs font-semibold md:text-sm lg:text-md"
            />
          ) : (
            <span className="text-xs font-semibold text-text-primary md:text-sm lg:text-md">
              {valueSecondary}
            </span>
          )}

          {valueSecondarySuffix && (
            <span className={suffixClassName}>{valueSecondarySuffix}</span>
          )}
        </div>
      </div>
    </AnimatedClickable>
  );
};
