import { Currency, TokenListingProps } from "@/types";
import { CalculateFeesReturn } from "@/utility/buy.ts";
import {
  ALPHA_DISCOUNT,
  BUYING_MATCHING_FEE,
  ONE_DOGE_IN_SHIBES,
} from "@/constants.ts";
import {
  formatNumber,
  NumberFormatType,
  satoshisToDoge,
} from "@/lib/numbers.ts";

export const calculateItemBuyFees = ({
  offers,
  txFeeInSats,
  dicountAlpha = ALPHA_DISCOUNT,
}: {
  offers?: TokenListingProps[];
  txFeeInSats: number;
  dicountAlpha?: number;
}): CalculateFeesReturn => {
  if (!offers?.length)
    return {
      network: "N/A",
      taker: "N/A",
      takerDiscountAlpha: "N/A",
      total: "N/A",
    };

  let totalFeeInShibes = 0;
  let totalSellingPriceWithoutFees = 0;

  offers.forEach((offer) => {
    const { totalPrice, amount } = offer;

    // Assuming unitPrice can be derived from totalPrice / amount
    const unitPrice = totalPrice / amount;
    const totalPriceInShibes = amount * unitPrice;
    const sellingPriceWithoutFees =
      totalPriceInShibes - (BUYING_MATCHING_FEE / 100) * totalPriceInShibes;
    const feeInShibes = (BUYING_MATCHING_FEE / 100) * sellingPriceWithoutFees;

    totalSellingPriceWithoutFees += sellingPriceWithoutFees;
    totalFeeInShibes += feeInShibes;
  });

  const takerFeeInDoge = satoshisToDoge(totalFeeInShibes);
  const takerDiscountAlpha = (takerFeeInDoge / 100) * dicountAlpha;
  const networkFeeInDoge = satoshisToDoge(txFeeInSats / ONE_DOGE_IN_SHIBES);

  return {
    totalSellingPriceWithoutFees,
    network: `${Currency.DOGE} ${formatNumber({ value: networkFeeInDoge, type: NumberFormatType.Price })}`,
    taker: `${Currency.DOGE} ${formatNumber({ value: takerFeeInDoge, type: NumberFormatType.Price })}`,
    total: `${Currency.DOGE} ${formatNumber({ value: takerFeeInDoge + networkFeeInDoge - takerDiscountAlpha, type: NumberFormatType.Price })}`,
    takerDiscountAlpha: `${Currency.DOGE} ${formatNumber({ value: takerDiscountAlpha, type: NumberFormatType.Price })}`,
  };
};
