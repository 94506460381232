import { useCallback, useState } from "react";

import { ONE_DOGE_IN_SHIBES } from "@/constants.ts";
import { Balance } from "@/context/BalanceProvider.tsx";
import { buildSendDogeTxs } from "@/context/wallet/helpers/sendDoge.ts";
import { broadcastAll } from "@/context/wallet/lib/transaction.ts";
import {
  TxWallet,
  TxWithFeeEstimation,
  WalletContextHandleReturn,
} from "@/context/wallet/types.ts";
import { NumberFormatType, formatNumber } from "@/lib/numbers.ts";
import { Currency } from "@/types";
import { handleError } from "@/utility";

export type UseSendDogeParams = {
  txWallet: TxWallet | undefined;
  dogePrice: number | undefined;
  dogeBalance: Balance | undefined;
};

export type SendDogeParams = {
  receiver: string;
  amount: number;
  feeRate: number;
};

export type MaxSendableDogeParams = SendDogeParams & {
  currency: string;
};

export interface SendDogeReturn extends WalletContextHandleReturn {
  txHash: string | null;
  calculateMaxSendableDoge: (
    params: MaxSendableDogeParams,
  ) => Promise<string | undefined>;
}

export const useSendDoge = (
  useSendParams: UseSendDogeParams,
): SendDogeReturn => {
  const [isLoading, setIsSendingDoge] = useState<boolean>(false);
  const [isError, setIsErrorDogeSend] = useState<boolean>(false);
  const [isSuccess, setIsSuccessDogeSend] = useState<boolean>(false);
  const [error, setError] = useState<null | string>(null);
  const [txHash, setTxHash] = useState<string | null>(null);

  const { txWallet, dogePrice, dogeBalance } = useSendParams;

  const buildSendDogeTx = useCallback(
    async (
      buildSendDogeParams: SendDogeParams,
    ): Promise<TxWithFeeEstimation | undefined> => {
      console.log(
        "sendDoge.buildSendDogeTx - buildSendDogeParams",
        buildSendDogeParams,
      );

      const estimateFeesOnly = false;
      const { receiver, amount, feeRate } = buildSendDogeParams;
      const buildSendDogeTxsParams = {
        txWallet,
        receiverAddress: receiver,
        amt: amount,
        feePerVByte: feeRate,
        estimateFeesOnly: estimateFeesOnly,
      };

      return buildSendDogeTxs(buildSendDogeTxsParams);
    },
    [txWallet],
  );

  const sendDoge = useCallback(
    async (sendDogeParams: SendDogeParams) => {
      console.log("sendDoge.execute - sendDogeParams", sendDogeParams);

      setIsSendingDoge(true);
      setIsErrorDogeSend(false);
      setIsSuccessDogeSend(false);

      try {
        if (!txWallet) {
          throw new Error("No wallet found");
        }

        const tx = await buildSendDogeTx(sendDogeParams);
        if (!tx) {
          throw new Error("No transaction built");
        }

        const { receiver } = sendDogeParams;
        const txHashes = await broadcastAll([tx.tx.toString()], {
          address: txWallet.address,
          receiverAddresses: [receiver],
          type: "doge",
        });
        if (!txHashes) {
          // Ensure that in casse of an error, the UTXOs are synced
          await txWallet.syncUtxos(true);
          throw new Error("Failed to broadcast transaction");
        }

        txWallet.updateUtxos({ tx: tx.tx });

        setTxHash(txHashes[0]);
        setIsSuccessDogeSend(true);
        console.log("sendDoge.execute - success");
      } catch (e: Error | unknown) {
        setIsErrorDogeSend(true);
        const message = handleError(e);
        setError(message);
      } finally {
        setIsSendingDoge(false);
      }
    },
    [txWallet, buildSendDogeTx],
  );

  const calculateMaxSendableDoge = useCallback(
    async (params: MaxSendableDogeParams) => {
      if (!dogePrice || !dogeBalance) return;

      const { receiver, amount, currency, feeRate } = params;

      let maxSendableDoge = 0;
      const preparedTx = await buildSendDogeTx({
        receiver,
        amount,
        feeRate,
      });

      maxSendableDoge = preparedTx?.txFeeInSats
        ? // - 100000 as a safety padding
          (dogeBalance.total_shibes - preparedTx?.txFeeInSats - 100000) /
          ONE_DOGE_IN_SHIBES
        : 0;

      if (currency === Currency.DOGE) {
        return maxSendableDoge.toString();
      } else {
        return formatNumber({
          value: maxSendableDoge * dogePrice,
          type: NumberFormatType.Price,
          decimalPlaces: 2,
        });
      }
    },
    [buildSendDogeTx, dogePrice, dogeBalance],
  );

  const reset = useCallback(() => {
    setIsErrorDogeSend(false);
    setIsSuccessDogeSend(false);
    setError(null);
    setTxHash(null);
  }, []);

  return {
    isLoading,
    isError,
    isSuccess,
    execute: sendDoge,
    calculateMaxSendableDoge,
    txHash,
    reset,
    error,
  };
};
