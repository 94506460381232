import { getToken, onMessage } from "firebase/messaging";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Workbox } from "workbox-window";

import {
  LOCAL_STORAGE_MESSAGING_TOKEN_KEY_DEV,
  LOCAL_STORAGE_MESSAGING_TOKEN_KEY_PROD,
} from "@/constants";
import { handleError } from "@/utility";

import { messaging } from "../firebase/config";
import { useLocalStorage } from "@/hooks/useLocalStorage.ts";

export const useNotifications = () => {
  const wb = useRef<InstanceType<typeof Workbox>>();
  const registration = useRef<ServiceWorkerRegistration>();
  const [isSettingUpNotifications, setIsSettingUpNotifications] =
    useState(false);

  const {
    VITE_FIREBASE_VAPID_KEY_PROD,
    VITE_FIREBASE_VAPID_KEY_DEV,
    VITE_MODE,
  } = import.meta.env;
  const VITE_FIREBASE_VAPID_KEY =
    VITE_MODE === "prod"
      ? VITE_FIREBASE_VAPID_KEY_PROD
      : VITE_FIREBASE_VAPID_KEY_DEV;
  const LOCAL_STORAGE_MESSAGING_TOKEN_KEY =
    VITE_MODE === "prod"
      ? LOCAL_STORAGE_MESSAGING_TOKEN_KEY_PROD
      : LOCAL_STORAGE_MESSAGING_TOKEN_KEY_DEV;
  const areNotificationsSupported = useMemo(
    () =>
      "Notification" in window &&
      "serviceWorker" in navigator &&
      "PushManager" in window,
    [],
  );

  const { value: FCMToken, updateValue } = useLocalStorage<string | null>(
    LOCAL_STORAGE_MESSAGING_TOKEN_KEY,
    null,
    false,
  );

  const setupNotifications = useCallback(async () => {
    setIsSettingUpNotifications(true);

    if (!registration.current || !areNotificationsSupported) {
      setIsSettingUpNotifications(false);
      throw new Error("Service worker or notifications not supported");
    }

    try {
      if (FCMToken === null) {
        // get fresh FCM token
        // If notification permission isn't already granted, this method asks the user for permission.
        // The returned promise rejects if the user does not allow the app to show notifications.
        const token = await getToken(messaging, {
          vapidKey: VITE_FIREBASE_VAPID_KEY,
          serviceWorkerRegistration: registration.current,
        });
        updateValue(token);
      }

      onMessage(messaging, (payload) => {
        // TODO: update UI e.g. show notification
        const notificationText = payload?.notification?.body ?? "New Message";
        alert(notificationText);
      });
    } catch (e: Error | unknown) {
      handleError(e);
    } finally {
      setIsSettingUpNotifications(false);
    }
  }, [
    FCMToken,
    VITE_FIREBASE_VAPID_KEY,
    areNotificationsSupported,
    updateValue,
  ]);

  useEffect(() => {
    if ("serviceWorker" in navigator) {
      wb.current = new Workbox(
        import.meta.env.MODE === "production" ? "/sw.js" : "/dev-sw.js?dev-sw",
        {
          type: import.meta.env.MODE === "production" ? "classic" : "module",
        },
      );

      wb.current?.addEventListener("controlling", () => {
        window.location.reload();
      });

      wb.current.register().then((swRegistration: any) => {
        registration.current = swRegistration;
        setupNotifications();
      });
    }
  }, [setupNotifications]);

  return {
    areNotificationsSupported,
    setupNotifications,
    isSettingUpNotifications,
  };
};
