import { cn } from "@/lib/utils";
import { Input, InputProps } from "../ui/input";
import { NumericFormat } from "react-number-format";

interface InputCurrencyProps extends InputProps {
  symbolDirection?: "prefix" | "suffix";
  symbol?: string;
  errorMessage?: string;
  isFull?: boolean;
  value: string;
}

export const InputCurrency: React.FC<InputCurrencyProps> = ({
  value,
  errorMessage,
  symbol,
  isFull = false,
  symbolDirection = "prefix",
  ...props
}) => {
  symbol = symbol?.toUpperCase() ?? "";

  // // Handle input change to update internal value
  const handleChange = (values: any) => {
    const { floatValue } = values;
    props.onChange?.({
      ...values,
      target: { name: props.name, value: floatValue?.toString() ?? "" },
    });
  };

  return (
    <NumericFormat
      value={value}
      placeholder={symbolDirection === "prefix" ? `${symbol}0` : `0 ${symbol}`}
      thousandSeparator=","
      decimalScale={18}
      allowNegative={false}
      allowLeadingZeros={false}
      fixedDecimalScale={false}
      onValueChange={handleChange}
      className={cn(
        errorMessage && "text-red-500 focus:text-red-500",
        props.className,
        isFull && "h-full bg-background-secondary text-center text-lg",
      )}
      customInput={Input}
      // Prefix or suffix for the currency symbol based on symbolDirection
      prefix={symbolDirection === "prefix" ? symbol : ""}
      suffix={symbolDirection === "suffix" ? ` ${symbol}` : ""}
      // Transform commas to periods as decimal separator
      allowedDecimalSeparators={[","]}
    />
  );
};
