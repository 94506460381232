import { ListingStatus } from "@/types";

export enum ModalType {
  Filter = "filter",
  Sort = "sort",
  Search = "search",
  Buy = "buy",
}

export enum DisplayType {
  Grid = "grid",
  List = "list",
}

/**
 * Collection Details
 */

export interface CurrencyType {
  dollar: string;
  doge: string;
}

export interface MintedCollectibles {
  minted: string;
  supply: string;
}

export interface CollectionDetails {
  price: CurrencyType;
  volume: CurrencyType;
  volume24h: CurrencyType;

  minted: MintedCollectibles;

  holders: string;
  sales: string;
}

export interface CollectionDetailProps {
  label: string;
  value: string;
  color?: string;
}

export interface CollectionListingProps {
  offerId: string;
  collectionSymbol: string;

  name: string; // TODO: Is this correct or should it be doginalName?
  imageURI: string;
  inscriptionId: string; // TODO: Is this correct or should it be doginalId?
  inscriptionNumber: number; // TODO: Is this correct or should it be doginalNumber?

  price: number;
  priceDoge: string;
  priceUSD: string;

  lastSalePrice: number;
  lastSalePriceDoge: string;
  lastSalePriceUSD: string;

  floorDifferencePercentage: number;

  address: string;
  createdAt: string;
  status: ListingStatus;

  onBuy: (id: string) => void;
  onAction: (id: string) => void;

  isLoading: boolean;
  isSelected: boolean;
}
