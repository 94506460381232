import coinstring from "coinstring";
import { handleError } from "./handleError";

export const isValidDogecoinAddress = (address: string): boolean => {
  try {
    const version = 0x1e; //Dogecoin Public Address
    const addressValidator = coinstring.createValidator(version);
    return addressValidator(address);
  } catch (e: Error | unknown) {
    handleError(e);
    return false;
  }
};

export const isValidPrivateImportDogecoinAddress = (
  address: string,
): boolean => {
  try {
    const version = 0x9e; //Dogecoin Private Import Address
    const addressValidator = coinstring.createValidator(version);
    return addressValidator(address);
  } catch (e: Error | unknown) {
    handleError(e);
    return false;
  }
};
