import { cn } from "@/lib/utils";
import { Skeleton } from "../ui/skeleton";

interface DetailCardProps {
  label: string;
  value: string;
  color?: string;
  width?: string;
  className?: string;
}

export const DetailCard: React.FC<DetailCardProps> = ({
  label,
  value,
  color,
  width,
  className,
}) => {
  return (
    <div
      key={label}
      className={cn(
        "flex aspect-3/2 min-w-28 flex-col justify-center space-y-1 rounded-lg border-0.5 border-border-primary bg-background-secondary px-3 py-2 text-left lg:min-w-32",
        className,
      )}
      style={{ width }}
    >
      <span className="text-2xs uppercase text-text-tertiary md:text-xs">
        {label}
      </span>
      <span
        className={cn("text-md font-bold text-text-primary lg:text-lg", color)}
        dangerouslySetInnerHTML={{ __html: value }}
      />
    </div>
  );
};

export const TokenDetailsCardSkeleton: React.FC = () => {
  return Array(11)
    .fill("")
    .map((_, i) => (
      <Skeleton
        key={i}
        className="aspect-3/2 min-w-28 rounded-lg lg:min-w-32"
      />
    ));
};
