import React from "react";

import { Button, Spinner } from "@/components";
import { Currency } from "@/types";

import { AnimatedContent } from "../../base";

interface ConfirmViewProps {
  recipient: string;
  amount: string;
  currency: string;
  fee: string;
  handleContinue: () => Promise<void>;
  isSendLoading: boolean;
}

export const ConfirmView: React.FC<ConfirmViewProps> = ({
  handleContinue,
  recipient,
  amount,
  currency,
  fee,
  isSendLoading,
}: ConfirmViewProps) => (
  <AnimatedContent id="confirm">
    <div className="flex flex-1 flex-col space-y-2">
      <div className="flex flex-1 flex-col justify-center space-y-1 rounded-md bg-background-secondary px-4 py-2">
        <span className="text-sm font-bold text-text-tertiary">Recipient</span>
        <span className="text-sm">{recipient}</span>
      </div>
      <div className="flex flex-1 flex-col justify-center space-y-1 rounded-md bg-background-secondary px-4 py-2">
        <span className="text-sm font-bold text-text-tertiary">Amount</span>
        <span
          className="text-sm text-text-secondary"
          dangerouslySetInnerHTML={{ __html: `${amount}${currency}` }}
        />
      </div>
      <div className="flex flex-1 flex-col justify-center space-y-1 rounded-md bg-background-secondary px-4 py-2">
        <span className="text-sm font-bold text-text-tertiary">Fee</span>
        <span
          className="text-sm text-text-secondary"
          dangerouslySetInnerHTML={{ __html: fee }}
        />
      </div>
    </div>
    <Button size="large" variant="default" onClick={handleContinue}>
      {isSendLoading ? <Spinner size={20} /> : `Send ${Currency.DOGE}`}
    </Button>
  </AnimatedContent>
);
