import { useEffect, useMemo } from "react";

import { useCollectiblePendingItems } from "@/contextHooks/useCollectiblePendingItems";
import { CollectiblePendingActionType, DoginalListing } from "@/types";

/*
  This hook is used to remove items from the pending collectible list (see CollectiblePendingItemsProvider) when they are confirmed.
*/

const useRemovePendingCollectibles = (
  collectibleList: DoginalListing[],
  collectionSymbol?: string,
) => {
  const { pendingItems, removePendingInscriptions } =
    useCollectiblePendingItems();

  const pendingInscriptions = useMemo(
    () => (collectionSymbol ? pendingItems[collectionSymbol] || [] : []),
    [pendingItems, collectionSymbol],
  );

  useEffect(() => {
    if (
      !pendingInscriptions.length ||
      !collectibleList.length ||
      !collectionSymbol
    )
      return;

    const interval = setInterval(() => {
      for (const pendingInscription of pendingInscriptions) {
        const { inscriptionId, action } = pendingInscription;

        const collectible = collectibleList.find(
          (collectible) => collectible.inscriptionId === inscriptionId,
        );

        const pendingItemAge =
          Date.now() - new Date(pendingInscription.timestamp || "").getTime();

        // Remove pending items that are older than 10 minutes or if no timestamp is set
        // This is a safety measure to prevent the list from growing indefinitely
        const tooOld =
          pendingItemAge > 600000 || pendingInscription.timestamp === undefined;

        if (
          (action === CollectiblePendingActionType.Send && !collectible) ||
          (action === CollectiblePendingActionType.Delist &&
            collectible &&
            !collectible.listed) ||
          tooOld
        ) {
          removePendingInscriptions([inscriptionId], collectionSymbol);
        }
      }
    }, 10000);

    return () => clearInterval(interval);
  }, [
    pendingInscriptions,
    collectibleList,
    collectionSymbol,
    removePendingInscriptions,
  ]);

  return {
    pendingInscriptions,
  };
};

export { useRemovePendingCollectibles };
