export enum SearchSuggestionType {
  Address = "Address",
  Inscription = "Inscription",
  DRC20 = "DRC20",
  Doginal = "Doginal",
  Dunes = "Dunes",
}

export type GenericSearchSuggestions = {
  type: SearchSuggestionType;
  [key: string]: any;
};

export type AddressSearchSuggestions = {
  type: SearchSuggestionType;
  address: string;
};

export type InscriptionSearchSuggestions = {
  type: SearchSuggestionType;
  inscription: string;
};

export type Drc20SearchSuggestion = {
  tick: string;
  image: string | null;
  volume: string;
  floor: string;
  type: SearchSuggestionType;
};

export type DoginalSearchSuggestion = {
  tick: string;
  collectionSymbol: string;
  image: string | null;
  volume: string;
  floor: string;
  type: SearchSuggestionType;
};

export type DunesSearchSuggestion = {
  tick: string;
  image: string | null;
  volume: string;
  floor: string;
  type: SearchSuggestionType;
};
