import { Drc20Utxo, DunesUtxo } from "@/types/inscription.ts";
import { Inscription } from "@/types";

export const getDrc20UtxosForInscriptionIds = (
  inscriptionIds: string[],
  utxos: Drc20Utxo[],
): null | Drc20Utxo[] => {
  const filteredUtxo = utxos.filter(
    (utxo: Drc20Utxo) =>
      utxo.inscription_id && inscriptionIds.includes(utxo.inscription_id),
  );

  if (filteredUtxo.length === 0) {
    return null;
  }

  return filteredUtxo;
};

export const getCollectibleInscriptionsForInscriptionIds = (
  inscriptionIds: string[],
  inscriptions: Inscription[],
): null | Inscription[] => {
  const filteredInscriptions = inscriptions.filter(
    (inscription: Inscription) =>
      inscription.inscription_id &&
      inscriptionIds.includes(inscription.inscription_id),
  );

  if (filteredInscriptions.length === 0) {
    return null;
  }

  return filteredInscriptions;
};

// SDOGGS Dunes enhancements
export const getDuneUtxosForTxOutpoints = (
  tx_outs: number[],
  tx_hashes: string[],
  utxos: DunesUtxo[],
): null | DunesUtxo[] => {
  if (tx_outs.length !== tx_hashes.length) {
    return null; // Ensure the lengths match
  }

  const filteredUtxos = utxos.filter((utxo: DunesUtxo) =>
    tx_hashes.some(
      (tx_hash, idx) => tx_hash === utxo.txid && tx_outs[idx] === utxo.vout,
    ),
  );

  return filteredUtxos.length ? filteredUtxos : null;
};
