import { useCallback, useEffect, useMemo, useState } from "react";

import { Button, ToggleElement } from "@/components";
import {
  SortListingsCollection,
  SortOrder,
  SortParamListingsCollection,
} from "@/types";

import { Sheet, SheetProps } from "../base";

interface SortItem {
  label: string;
  by: SortParamListingsCollection;
  order: SortOrder;
}

const SORT_ITEMS = [
  {
    label: "Floor Price: Low to High",
    by: SortParamListingsCollection.Price,
    order: SortOrder.Ascending,
  },
  {
    label: "Floor Price: High to Low",
    by: SortParamListingsCollection.Price,
    order: SortOrder.Descending,
  },
  {
    label: "List Time: Oldest to Newest",
    by: SortParamListingsCollection.CreatedAt,
    order: SortOrder.Ascending,
  },
  {
    label: "List Time: Newest to Oldest",
    by: SortParamListingsCollection.CreatedAt,
    order: SortOrder.Descending,
  },
  {
    label: "Listing Price: Low to High",
    by: SortParamListingsCollection.ListingPrice,
    order: SortOrder.Ascending,
  },
  {
    label: "Listing Price: High to Low",
    by: SortParamListingsCollection.ListingPrice,
    order: SortOrder.Descending,
  },
];

interface ModalCollectionSortProps extends SheetProps {
  onApply: (sortType: SortListingsCollection | undefined) => void;
  sortBy?: SortParamListingsCollection[];
  sort?: SortListingsCollection;
}

export const ModalCollectionSort: React.FC<ModalCollectionSortProps> = ({
  onApply,
  sortBy = [
    SortParamListingsCollection.Price,
    SortParamListingsCollection.CreatedAt,
  ],
  sort,
  ...props
}) => {
  const [localSortType, setLocalSortType] = useState<
    SortListingsCollection | undefined
  >(sort);

  const filteredSortItems = useMemo(() => {
    return SORT_ITEMS.filter((item) => sortBy.includes(item.by));
  }, [sortBy]);

  const handleApply = useCallback(() => {
    onApply?.(localSortType);
  }, [onApply, localSortType]);

  const handleSortClick = useCallback(
    (item: SortItem) => {
      return () => {
        if (
          localSortType?.sortParam === item.by &&
          localSortType?.sortOrder === item.order
        ) {
          setLocalSortType(undefined);
        } else {
          setLocalSortType({ sortParam: item.by, sortOrder: item.order });
        }
      };
    },
    [localSortType, setLocalSortType],
  );

  useEffect(() => {
    setLocalSortType(sort);
  }, [sort]);

  return (
    <Sheet {...props}>
      <div className="flex flex-col space-y-4 p-2">
        <div className="flex flex-1 flex-col space-y-2 rounded-lg bg-background-secondary p-2">
          {filteredSortItems.map((item) => {
            const onClick = () => handleSortClick(item)();
            const isSelected =
              localSortType?.sortParam === item.by &&
              localSortType?.sortOrder === item.order;
            return (
              <ToggleElement
                label={item.label}
                onClick={onClick}
                isSelected={isSelected}
                key={item.label}
              />
            );
          })}
        </div>
        <Button variant="inverse" size="large" onClick={handleApply}>
          Apply
        </Button>
      </div>
    </Sheet>
  );
};
