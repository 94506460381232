import { AnimatePresence, motion } from "framer-motion";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Button, Input, Spinner, ToggleElement } from "@/components";
import { Checkbox } from "@/components/ui/checkbox";
import { cn } from "@/lib/utils";
import {
  Currency,
  FilterBetween,
  FilterMetadata,
  ListingStatus,
} from "@/types";
import { handleError, validateMinMax } from "@/utility";

import { Sheet, SheetProps } from "../base";
import { marketplaceApiV2 } from "@/lib/fetch.ts";

const STATUS = [
  { label: "All", value: undefined },
  { label: "Listed", value: ListingStatus.Listed },
  { label: "Not Listed", value: ListingStatus.Unlisted },
];

interface ModalCollectionFilterProps extends SheetProps {
  collectionSymbol?: string;
  price?: FilterBetween;
  listingStatus?: ListingStatus;
  metadata?: FilterMetadata[];
  onApply: (filters: {
    price?: FilterBetween;
    listingStatus?: ListingStatus;
    metadata?: FilterMetadata[];
  }) => void;
}

export const ModalCollectionFilter: React.FC<ModalCollectionFilterProps> = ({
  onApply,
  price,
  listingStatus,
  collectionSymbol,
  metadata,
  ...props
}) => {
  /**
   * Local State
   */
  const [expandedTraits, setExpandedTraits] = useState<string[]>([]);
  const [error, setError] = useState<string | undefined>(undefined);
  const [localPrice, setLocalPrice] = useState<FilterBetween | undefined>(
    price,
  );
  const [localStatus, setLocalStatus] = useState<ListingStatus | undefined>(
    listingStatus,
  );
  const [localFilteredMetadata, setLocalFilteredMetadata] = useState<
    FilterMetadata[] | undefined
  >(metadata);

  const isApplyDisabled = error !== undefined;

  /**
   * Collection Metadata
   */

  const {
    metadata: collectionMetadata,
    loading: metadataLoading,
    error: metadataError,
  } = useCollectionMetadata(collectionSymbol);

  /**
   * Apply Filters
   */

  const handlePriceChange = useCallback(
    (key: keyof FilterBetween) =>
      (event: React.ChangeEvent<HTMLInputElement>) => {
        if (localPrice !== undefined) {
          const value = parseFloat(event.target.value);
          setLocalPrice((prev) => {
            if (!prev) {
              return { min: undefined, max: undefined, [key]: value };
            }
            return { ...prev, [key]: value };
          });
        }
      },
    [localPrice],
  );

  const handleBlurPrice = useCallback(() => {
    if (localPrice) {
      validateMinMax(
        localPrice.min,
        localPrice.max,
        "Min price cannot be greater than max price",
        setError,
      );
    }
  }, [localPrice, setError]);

  const handleSelectStatus = useCallback(
    (status: ListingStatus | undefined) => () => {
      if (status === localStatus) {
        setLocalStatus(undefined);
      } else {
        setLocalStatus(status);
      }
    },
    [localStatus],
  );

  const handleApply = useCallback(() => {
    onApply?.({
      price: localPrice,
      listingStatus: localStatus,
      metadata: localFilteredMetadata,
    });
  }, [onApply, localPrice, localStatus, localFilteredMetadata]);

  /**
   * Expandable Items
   */

  const handleExpandItem = useCallback((label: string) => {
    setExpandedTraits((prev) => {
      if (prev.includes(label)) {
        return prev.filter((item) => item !== label);
      }
      return [...prev, label];
    });
  }, []);

  const expandableItems = useMemo(() => {
    if (collectionMetadata === null) return null;

    // Remove item with key "Rarity Rank" from metadata
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { "Rarity Rank": _, ...rest } = collectionMetadata;

    // Sort by key alphabetically and map to ExpandableItem
    return Object.entries(rest)
      .sort(([a], [b]) => a.localeCompare(b))
      .map(([trait, traitValues]) => {
        const onClick = () => handleExpandItem(trait);

        const isExpanded = expandedTraits.includes(trait);

        const numberOfTraitsSelected = localFilteredMetadata?.filter(
          (item) => item.metadataKey === trait,
        ).length;

        const expandedItems = traitValues.map((value) => {
          const isSelected =
            localFilteredMetadata?.some(
              (item) =>
                item.metadataKey === trait && item.metadataValue === value,
            ) ?? false;

          const onClick = (filterMetaData: FilterMetadata) => {
            setLocalFilteredMetadata((prev) => {
              if (isSelected) {
                return prev?.filter(
                  (item) =>
                    item.metadataKey !== filterMetaData.metadataKey ||
                    item.metadataValue !== filterMetaData.metadataValue,
                );
              }
              return prev ? [...prev, filterMetaData] : [filterMetaData];
            });
          };

          return { value, isSelected, onClick };
        });

        const detail = expandedItems?.length || 0;

        return {
          trait,
          traitValues,
          onClick,
          isExpanded,
          expandedItems,
          detail,
          numberOfTraitsSelected,
        };
      });
  }, [
    collectionMetadata,
    expandedTraits,
    localFilteredMetadata,
    handleExpandItem,
  ]);

  useEffect(() => {
    if (localStatus === ListingStatus.Unlisted) {
      setLocalPrice({
        min: undefined,
        max: undefined,
      });
    }
  }, [localStatus]);

  useEffect(() => {
    setLocalFilteredMetadata(metadata);
  }, [metadata]);

  useEffect(() => {
    setLocalStatus(listingStatus);
  }, [listingStatus]);

  useEffect(() => {
    setLocalPrice(price);
  }, [price]);

  return (
    <Sheet {...props} classNameContent="">
      <div className="lg:show-scrollbar flex h-full max-h-[60vh] flex-col space-y-4 overflow-y-auto p-2 md:max-h-[70vh]">
        <div className="flex flex-1 flex-col space-y-2 rounded-lg bg-background-secondary p-3">
          <span className="text-sm font-bold text-text-secondary">Status</span>
          <div className="mt-4 flex flex-col space-y-2 border-t-0.5 border-border-secondary pt-4">
            {STATUS.map((item) => {
              const { label } = item;
              const onClick = () => handleSelectStatus(item.value)();
              const isSelected = localStatus === item.value;
              return (
                <ToggleElement
                  label={label}
                  onClick={onClick}
                  isSelected={isSelected}
                  key={label}
                />
              );
            })}
          </div>
        </div>

        {localPrice !== undefined && (
          <div className="flex flex-1 flex-col space-y-2 rounded-lg bg-background-secondary p-3">
            <span className="text-sm font-bold text-text-secondary">Price</span>
            <div className="mt-4 flex flex-row space-x-2 border-t-0.5 border-border-secondary pt-4">
              <div className="flex flex-1 flex-col space-y-1">
                <span className="text-xs uppercase text-text-tertiary">{`${Currency.DOGE} Min`}</span>
                <Input
                  type="number"
                  placeholder="0"
                  inputMode="decimal"
                  value={
                    localStatus === ListingStatus.Unlisted ? "" : localPrice.min
                  }
                  onChange={handlePriceChange("min")}
                  className="bg-background-tertiary"
                  onBlur={handleBlurPrice}
                  disabled={localStatus === ListingStatus.Unlisted}
                />
              </div>
              <div className="flex flex-1 flex-col space-y-1">
                <span className="text-xs uppercase text-text-tertiary">{`${Currency.DOGE} Max`}</span>
                <Input
                  type="number"
                  placeholder="1,000"
                  inputMode="decimal"
                  value={
                    localStatus === ListingStatus.Unlisted ? "" : localPrice.max
                  }
                  onChange={handlePriceChange("max")}
                  className="bg-background-tertiary"
                  onBlur={handleBlurPrice}
                  disabled={localStatus === ListingStatus.Unlisted}
                />
              </div>
            </div>
          </div>
        )}

        <div className="flex flex-1 flex-col space-y-2 rounded-lg bg-background-secondary p-3">
          <span className="text-sm font-bold text-text-secondary">Traits</span>
          <div className="mt-4 flex flex-col space-y-1 border-t-0.5 border-border-secondary pt-4">
            {metadataLoading ? (
              <div className="flex flex-1 flex-col items-center justify-center py-8">
                <Spinner size={20} />
              </div>
            ) : metadataError ? (
              <div className="flex flex-1 flex-col items-center justify-center py-8">
                <span className="text-center text-xs text-red-500">
                  {metadataError || "Error: No metadata found…"}
                </span>
              </div>
            ) : expandableItems?.length ? (
              expandableItems?.map((item) => {
                const {
                  trait,
                  detail,
                  onClick,
                  isExpanded,
                  expandedItems,
                  numberOfTraitsSelected,
                } = item;
                return (
                  <ExpandableItem
                    key={trait}
                    label={trait}
                    detail={`${detail}`}
                    expanded={isExpanded}
                    expandedItems={expandedItems}
                    numberOfTraitsSelected={numberOfTraitsSelected}
                    onClick={onClick}
                  />
                );
              })
            ) : (
              <p className="text-center text-xs text-text-tertiary">
                No traits
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-col space-y-1">
        <span className="text-center text-xs text-red-600">
          {error || "\u00A0"}
        </span>

        <Button
          variant="inverse"
          size="large"
          onClick={handleApply}
          disabled={isApplyDisabled}
        >
          Apply
        </Button>
      </div>
    </Sheet>
  );
};

interface Metadata {
  [key: string]: string[];
}

// TODO: Move this to a hook
const useCollectionMetadata = (collectionSymbol?: string) => {
  const [metadata, setMetadata] = useState<Metadata | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!collectionSymbol) return;

    const fetchMetadata = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await marketplaceApiV2(false, {
          collectionSymbol,
        }).get("doginals/collection-metadata");

        if (response.status !== 200) {
          throw new Error("Failed to fetch metadata");
        }

        const data = await response.data;
        setMetadata(data.metadata);
      } catch (err) {
        handleError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchMetadata();
  }, [collectionSymbol]);

  return { metadata, loading, error };
};

interface ExpandedItem {
  value: string;
  isSelected: boolean;
  onClick: (filterMetaData: FilterMetadata) => void;
}

interface ExpandableItemProps extends React.HTMLProps<HTMLDivElement> {
  label: string;
  detail?: string;
  expanded: boolean;
  expandedItems?: ExpandedItem[];
  numberOfTraitsSelected?: number;
}

const ExpandableItem: React.FC<ExpandableItemProps> = ({
  label,
  detail,
  expanded,
  expandedItems,
  numberOfTraitsSelected = 0,
  className,
  onClick,
}) => {
  return (
    <div
      className={cn(
        "flex flex-1 flex-col rounded-md bg-background-tertiary p-3",
        className,
      )}
    >
      <div
        className="flex flex-row items-center justify-between"
        onClick={onClick}
      >
        <div className="flex flex-row space-x-2">
          <span className="text-sm font-medium text-text-primary">{label}</span>
          {numberOfTraitsSelected > 0 && (
            <div className="flex w-7 flex-col items-center justify-start rounded-full bg-primary-500 py-0.5 text-xs font-medium text-text-primary">
              {numberOfTraitsSelected}
            </div>
          )}
        </div>
        <div className="flex flex-row items-center space-x-2">
          <span className="rounded-full bg-background-primary/50 px-2 py-0.5 text-xs font-medium text-text-tertiary">
            {detail}
          </span>
          <span
            className={cn(
              "material-symbols-rounded text-lg text-text-primary transition-transform duration-200",
              { "rotate-180": expanded },
            )}
          >
            expand_circle_down
          </span>
        </div>
      </div>
      <AnimatePresence>
        {expanded && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="mt-4 flex flex-1 flex-col space-y-1"
          >
            {expandedItems?.map((item, index) => {
              const { value, isSelected, onClick } = item;
              const handleClick = () =>
                onClick({ metadataKey: label, metadataValue: value });
              return (
                <div
                  key={index}
                  className="flex flex-1 flex-row items-center justify-between rounded-md bg-background-quaternary p-3"
                  onClick={handleClick}
                >
                  <span className="text-sm text-text-primary">{value}</span>
                  <Checkbox
                    checked={isSelected}
                    onChange={handleClick}
                    className="h-5 w-5 rounded-full border-none bg-background-tertiary text-white data-[state=checked]:bg-primary-500"
                  />
                </div>
              );
            })}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
