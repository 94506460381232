import { useEffect } from "react";
import { useRouteError } from "react-router-dom";

interface RouteError {
  statusText?: string;
  message?: string;
}

export const PageError = () => {
  const error = useRouteError() as RouteError;

  useEffect(() => {
    if (error) {
      throw error;
    }
  }, [error]);

  return (
    <div className="flex flex-1 flex-col py-safe-or-4">
      <div className="flex flex-1 flex-col items-center justify-center">
        <div className="flex flex-col space-y-2 p-4 text-center">
          <h1 className="font-display text-2xl font-black text-text-primary">
            Error!
          </h1>
          <p className="text-sm text-text-secondary">Error Message …</p>
          <i className="text-xs text-red-600">
            {error.statusText || error.message}
          </i>
        </div>
      </div>
    </div>
  );
};
