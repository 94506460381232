import React, { useMemo, useState } from "react";

import { ListItem, ListItemProps } from "@/components";
import { useSecurityPreference } from "@/contextHooks";

import {
  ModalPasswordChange,
  ModalPingChange,
  ModalResetApp,
  ModalSecurityPreference,
} from "../";
import { Sheet, SheetProps } from "../base";

interface ModalSecurityProps extends SheetProps {}

export const ModalSecurity: React.FC<ModalSecurityProps> = ({
  isVisible = false,
  onClose,
}) => {
  const { securityPreference } = useSecurityPreference();

  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [isModalPasswordVisible, setIsModalPasswordVisible] = useState(false);
  const [isModalPinVisible, setIsModalPinVisible] = useState(false);
  // const [isNotificationEnabled, setIsNotificationEnabled] = useState(false);
  const [
    isModalSecurityPreferenceVisible,
    setIsModalSecurityPreferenceVisible,
  ] = useState(false);

  const mainList: ListItemProps[] = useMemo(() => {
    const isPassword = securityPreference === "password";
    return [
      {
        icon: "encrypted",
        label: isPassword ? "Change Password" : "Change PIN",
        onClick: () =>
          isPassword
            ? setIsModalPasswordVisible(true)
            : setIsModalPinVisible(true),
      },
      {
        icon: "shield_with_heart",
        label: "Security Preference",
        onClick: () => setIsModalSecurityPreferenceVisible(true),
      },
      // {
      //   icon: "notifications",
      //   label: "Allow Notifications",
      //   toggleEnabled: isNotificationEnabled,
      //   toggleDisabled: true,
      //   onToggle: () => setIsNotificationEnabled(!isNotificationEnabled),
      // },
      {
        icon: "restart_alt",
        label: "Reset App",
        variant: "danger",
        onClick: () => setIsAlertVisible(true),
      },
    ];
  }, [securityPreference]);

  return (
    <>
      <Sheet
        isVisible={isVisible}
        onClose={onClose}
        title="Privacy & Security"
        classNameContent="flex flex-col flex-1"
      >
        <div className="flex flex-1 flex-col space-y-4 p-4 pb-safe-or-4">
          <div className="flex flex-col space-y-2 rounded-xl bg-background-secondary p-2">
            {mainList.map((item, index) => (
              <ListItem key={index} {...item} />
            ))}
          </div>
        </div>
      </Sheet>
      <ModalPasswordChange
        isVisible={isModalPasswordVisible}
        onClose={() => setIsModalPasswordVisible(false)}
      />
      <ModalPingChange
        isVisible={isModalPinVisible}
        onClose={() => setIsModalPinVisible(false)}
      />
      <ModalSecurityPreference
        isVisible={isModalSecurityPreferenceVisible}
        onClose={() => setIsModalSecurityPreferenceVisible(false)}
      />
      <ModalResetApp
        visible={isAlertVisible}
        onClose={() => setIsAlertVisible(false)}
      />
    </>
  );
};
