import React, { useMemo, useState } from "react";

import { Button, ListItem, ListItemProps } from "@/components";
import {
  APP_VERSION,
  LEGAL_PRIVACY,
  LEGAL_TERMS,
  SOCIAL_DISCORD,
  SOCIAL_X,
  SUPPORT,
} from "@/constants";

import { ModalAccount, ModalExperimental, ModalSecurity } from "../";
import { Sheet, SheetProps } from "../base";

interface ModalSettingsProps extends SheetProps {}

const onOpenDiscord = () => {
  window.open(SOCIAL_DISCORD, "_blank");
};

const onOpenX = () => {
  window.open(SOCIAL_X, "_blank");
};

export const ModalSettings: React.FC<ModalSettingsProps> = ({
  isVisible = false,
  onClose,
}) => {
  const [isModalWalletsVisible, setIsModalWalletsVisible] = useState(false);
  const [isModalSecurityVisible, setIsModalSecurityVisible] = useState(false);
  const [isModalExperimentalVisible, setIsModalExperimentalVisible] =
    useState(false);

  const mainList: ListItemProps[] = useMemo(() => {
    return [
      {
        icon: "account_circle",
        label: "Manage Accounts",
        onClick: () => setIsModalWalletsVisible(true),
      },
      {
        icon: "security",
        label: "Privacy & Security",
        onClick: () => setIsModalSecurityVisible(true),
      },
      {
        icon: "experiment",
        label: "Experimental",
        onClick: () => setIsModalExperimentalVisible(true),
      },
    ];
  }, []);

  const aboutList: ListItemProps[] = useMemo(() => {
    return [
      {
        label: "Terms of Service",
        onClick: () => window.open(LEGAL_TERMS, "_blank"),
      },
      {
        label: "Privacy Policy",
        onClick: () => window.open(LEGAL_PRIVACY, "_blank"),
      },
      {
        label: "Support",
        onClick: () => window.open(SUPPORT, "_blank"),
      },
      { label: "Version", detail: APP_VERSION.toString() },
    ];
  }, []);

  return (
    <>
      <Sheet
        isVisible={isVisible}
        onClose={onClose}
        title="Settings"
        classNameContent="flex flex-col flex-1 max-h-[800px] sm:h-[90vh]"
      >
        <div className="flex flex-1 flex-col space-y-4 p-4 pb-safe-or-4">
          <div className="flex flex-col space-y-2 rounded-xl bg-background-secondary p-2">
            {mainList.map((item, index) => (
              <ListItem key={index} {...item} />
            ))}
          </div>

          <div className="flex flex-col space-y-1 pt-2">
            <span className="ml-2 text-lg font-bold text-text-tertiary">
              About
            </span>
            <div className="flex flex-col space-y-2 rounded-xl bg-background-primary p-0 pr-2">
              {aboutList.map((item, index) => (
                <ListItem key={index} {...item} />
              ))}
            </div>
          </div>

          <div className="flex flex-row justify-center space-x-10 pt-6">
            <Button
              size="icon"
              className="rounded-full"
              onClick={onOpenDiscord}
            >
              <img
                src="/images/icons/icon-social-discord.svg"
                alt="discord"
                className="h-4 w-4 opacity-80"
              />
            </Button>
            <Button size="icon" className="rounded-full" onClick={onOpenX}>
              <img
                src="/images/icons/icon-social-x.svg"
                alt="x"
                className="h-4 w-4 opacity-80"
              />
            </Button>
          </div>
        </div>
      </Sheet>

      <ModalAccount
        isVisible={isModalWalletsVisible}
        onClose={() => setIsModalWalletsVisible(false)}
      />

      <ModalSecurity
        isVisible={isModalSecurityVisible}
        onClose={() => setIsModalSecurityVisible(false)}
      />

      <ModalExperimental
        isVisible={isModalExperimentalVisible}
        onClose={() => setIsModalExperimentalVisible(false)}
      />
    </>
  );
};
