import { motion } from "framer-motion";
import React from "react";

import { Modal, ModalProps, cardTransition, cardVariants } from "../base";

export const ModalOrientation: React.FC<ModalProps> = ({ ...props }) => {
  return (
    <Modal
      opacity={80}
      backdropBlur={false}
      classNameContent="justify-center p-4"
      {...props}
    >
      <motion.div
        key="modal-card"
        initial="closed"
        animate="open"
        exit="closed"
        variants={cardVariants}
        transition={cardTransition}
        className="relative flex aspect-3/4 flex-col overflow-clip rounded-2xl bg-background-secondary text-text-primary"
      >
        <div className="flex flex-1 flex-col items-center justify-center space-y-2 p-4 text-center">
          <h1 className="text-2xl font-black">Portrait Mode Preferred!</h1>
          <p>
            For the best experience, please switch to{" "}
            <strong>portrait mode.</strong>
          </p>
        </div>
      </motion.div>
    </Modal>
  );
};
