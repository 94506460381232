import { BalanceContext } from "@/context/BalanceProvider";
import { useContext } from "react";

export const useBalance = () => {
  const balanceContext = useContext(BalanceContext);
  if (!balanceContext) {
    throw new Error("useBalance must be used within a BalanceProvider");
  }

  return balanceContext;
};
