import { NumberFormatType, formatNumber } from "@/lib/numbers";
import { TokenActivity } from "@/types";
import { getTimeDifference } from "@/utility";
import { ColumnDef } from "@tanstack/react-table";

import {
  ActivityTypeCell,
  ChangeCell,
  FromToCell,
  HeaderCell,
  NumberCell,
  TextCell,
} from "../components";
import { ChangeCellType } from "../components/type";

export const TableContentActivityDune: ColumnDef<TokenActivity>[] = [
  {
    accessorKey: "createdAt",
    header: ({ column }) => {
      return (
        <HeaderCell
          title="Time"
          aligned="left"
          className="ml-4 mr-2 w-8"
          column={column}
          withSorting
        />
      );
    },
    cell: ({ row }) => {
      const { createdAt } = row.original;
      return (
        <TextCell
          value={getTimeDifference(createdAt)}
          className="ml-4 mr-2 w-8"
        />
      );
    },
  },
  /*
  {
    accessorKey: "item",
    header: () => (
      <HeaderCell
        title="Item"
        aligned="left"
        className="w-28"
        withSorting={false}
      />
    ),
    cell: ({ row }) => {
      const { inscriptionNumber, inscriptionId } = row.original;
      const onClick = () => {
        window.open(
          `${WONKY_ORD_URL}shibescription/${inscriptionId}`,
          "_blank",
        );
      };

      return (
        <div className="flex w-28 flex-row items-center space-x-2 lg:space-x-4">
          <div className="flex flex-1 flex-col justify-center -space-y-1 lg:space-y-1">
            <span
              className="cursor-pointer text-2xs text-text-tertiary hover:text-text-primary lg:text-xs"
              onClick={onClick}
            >
              {inscriptionNumber}
            </span>
          </div>
        </div>
      );
    },
  },
  */
  {
    accessorKey: "type",
    header: ({ column }) => {
      return (
        <HeaderCell
          title="Action"
          aligned="left"
          className="w-12"
          withSorting
          column={column}
        />
      );
    },
    cell: ({ row }) => {
      const { type } = row.original;
      return <ActivityTypeCell type={type} className="w-12" />;
    },
  },
  {
    accessorKey: "amount",
    header: ({ column }) => {
      return (
        <HeaderCell
          title="Amount"
          aligned="right"
          className="w-24"
          withSorting
          column={column}
        />
      );
    },
    cell: ({ row }) => {
      const { amount } = row.original;
      return (
        <NumberCell
          value={formatNumber({
            value: amount,
            type: NumberFormatType.Large_Number,
          })}
          className="w-24"
        />
      );
    },
  },
  {
    accessorKey: "totalPrice",
    header: ({ column }) => {
      return (
        <HeaderCell
          title="Total Price"
          aligned="right"
          column={column}
          withSorting
          className="w-24"
        />
      );
    },
    cell: ({ row }) => {
      const { totalPriceForCurrency } = row.original;
      return <NumberCell value={totalPriceForCurrency} className="w-24" />;
    },
  },
  {
    accessorKey: "unitPrice",
    header: ({ column }) => {
      return (
        <HeaderCell
          title="Unit Price"
          aligned="right"
          column={column}
          withSorting
          className="w-24"
        />
      );
    },
    cell: ({ row }) => {
      const { priceForCurrency } = row.original;
      return <NumberCell value={priceForCurrency} className="w-24" />;
    },
  },
  {
    accessorKey: "floorDifference",
    header: ({ column }) => {
      return (
        <HeaderCell
          title="Floor Diff."
          aligned="right"
          column={column}
          withSorting
          className="w-24"
        />
      );
    },
    cell: ({ row }) => {
      const { floorDifference } = row.original;
      return (
        <ChangeCell
          change={floorDifference}
          type={ChangeCellType.FloorDifference}
          className="w-24"
        />
      );
    },
  },
  {
    accessorKey: "fromTo",
    header: () => (
      <HeaderCell
        title="From/To"
        aligned="left"
        withSorting={false}
        className="w-24"
      />
    ),
    cell: ({ row }) => {
      const { from, to } = row.original;
      return <FromToCell from={from} to={to} className="w-24" />;
    },
  },
];
