import {
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useState,
  useRef,
} from "react";

import { FALLBACK_DOGE_PRICE } from "@/constants";
import { handleError } from "@/utility";

import { fetchDogePrice } from "./helpers/fetchDogePrice";

interface DogePriceContextType {
  dogePrice: number;
  loading: boolean;
  getDogePrice: () => Promise<void>;
}

type DogePriceProviderProps = {
  children: ReactNode;
};

const DogePriceContext = createContext<DogePriceContextType>({
  dogePrice: FALLBACK_DOGE_PRICE,
  loading: false,
  getDogePrice: async () => {},
});

const DogePriceProvider = ({ children }: DogePriceProviderProps) => {
  const [dogePrice, setDogePrice] = useState<number>(FALLBACK_DOGE_PRICE);
  const [loading, setLoading] = useState<boolean>(false);

  const getDogePrice = useCallback(async () => {
    try {
      setLoading(true);
      const price = await fetchDogePrice();
      setDogePrice(price);
    } catch (e: Error | unknown) {
      handleError(e);
    } finally {
      setLoading(false);
    }
  }, [setDogePrice]);

  const intervalId = useRef<null | NodeJS.Timeout>(null);
  useEffect(() => {
    if (intervalId.current === null) {
      getDogePrice();
    }

    if (intervalId.current) {
      clearInterval(intervalId.current);
    }

    // Set a new interval
    intervalId.current = setInterval(() => getDogePrice(), 6300000); // every 10,5 minutes

    // Cleanup on unmount
    return () => clearInterval(intervalId.current as NodeJS.Timeout);
  }, [getDogePrice]);

  return (
    <DogePriceContext.Provider
      value={{
        dogePrice,
        loading,
        getDogePrice,
      }}
    >
      {children}
    </DogePriceContext.Provider>
  );
};

export { DogePriceProvider, DogePriceContext };
