import { Currency, TokenListingProps } from "@/types";
import { getFormattedValue } from "@/utility/table.ts";
import { formatNumber, NumberFormatType } from "@/lib/numbers.ts";

export const processOffers = ({
  offers,
  dogePrice,
  currency,
}: {
  offers?: TokenListingProps[];
  dogePrice: number;
  currency: Currency;
}) => {
  if (offers === undefined || offers?.length === 0) {
    return {
      pay: { value: "N/A", valueInUSD: "N/A" },
      receive: { value: "N/A", valueInUSD: "N/A" },
      tick: undefined,
    };
  }

  let totalValuePay = 0;
  let totalValueReceive = 0;
  let tick = offers[0].tick;
  const symbol = offers[0].symbol;

  // Aggregate total values from the offers
  for (const { totalPrice, amount, tick: offerTick } of offers) {
    totalValuePay += totalPrice;
    totalValueReceive += amount;
    tick = offerTick; // Assumes all offers have the same tick value
  }

  // Get the formatted total price in both DOGE and USD
  const { valueInDoge, valueInUSD } = getFormattedValue(
    totalValuePay,
    dogePrice,
    currency,
  );

  // Extract numeric values from formatted strings
  const totalPriceValueForCurrency =
    currency === Currency.DOGE
      ? parseFloat(valueInDoge.replace(/[^0-9,.]/g, "").replace(",", ""))
      : parseFloat(valueInUSD.replace(/[^0-9,.]/g, "").replace(",", ""));

  // Calculate and format the average unit price
  let formattedUnitPrice = "N/A";
  if (totalValueReceive > 0) {
    const averageUnitPrice = totalPriceValueForCurrency / totalValueReceive;
    if (!isNaN(averageUnitPrice) && isFinite(averageUnitPrice)) {
      formattedUnitPrice = formatNumber({
        value: averageUnitPrice,
        type: NumberFormatType.Price,
      });
    }
  }

  const offerLabel = (symbol ?? tick).toUpperCase();

  return {
    pay: {
      value: valueInDoge,
      valueInUSD,
    },
    receive: {
      value: `${formatNumber({ value: totalValueReceive, type: totalValueReceive >= 1 ? NumberFormatType.Large_Number : NumberFormatType.Price })} ${offerLabel}`,
      valueInUSD: undefined,
      valueDetails: `at ${currency}${formattedUnitPrice}/${offerLabel}`,
    },
    tick,
  };
};
