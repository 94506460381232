import React, { useMemo, useState } from "react";

import {
  AppScreen,
  InscribeContentTypeTabItem,
  InscribeTabItem,
  ScrollableTabs,
  TabItem,
} from "@/components";
import { InscribeContentType, InscribeTab } from "@/types";

import { InscribeDunes, InscribeDunesInfo } from "./tabs";

const INSCRIBE_TYPES: {
  value: InscribeContentType;
  label: string;
  link?: string;
  disabled?: boolean;
}[] = [
  { value: InscribeContentType.DUNES, label: "DUNES" },
  {
    value: InscribeContentType.DRC20,
    label: "DRC20",
    link: "https://drc-20.org/service/inscribe",
  },
  {
    value: InscribeContentType.FILES,
    label: "FILES",
    link: "https://drc-20.org/service/inscribe",
  },
  {
    value: InscribeContentType.TEXT,
    label: "TEXT",
    link: "https://drc-20.org/service/inscribe",
  },
  {
    value: InscribeContentType.DOGEMAP,
    label: "DOGEMAP",
    link: "https://drc-20.org/service/inscribe",
  },
  {
    value: InscribeContentType.DELEGATE,
    label: "DELEGATE",
    link: "https://drc-20.org/service/inscribe",
  },
];

const TABS = [
  { value: InscribeTab.EXPLORE, label: "Explore" },
  { value: InscribeTab.MINT, label: "Mint" },
  { value: InscribeTab.ETCH, label: "Etch", disabled: true },
];

export const InscriptionService: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState<InscribeTab>(
    InscribeTab.EXPLORE,
  );
  const [enabledTabItems, setEnabledTabItems] = useState<InscribeTabItem[]>([]);
  const [inscriptionContentType, setInscriptionContentType] =
    useState<InscribeContentType>(InscribeContentType.DUNES);

  const contentChecks = useMemo(() => {
    return {
      isContentDunes: inscriptionContentType === InscribeContentType.DUNES,
    };
  }, [inscriptionContentType]);

  const inscribeContentTypeItems = useMemo<InscribeContentTypeTabItem[]>(() => {
    return INSCRIBE_TYPES.map((tab) => ({
      label: tab.label,
      value: tab.value,
      isActive: inscriptionContentType === tab.value,
      disabled: tab.disabled || false,
      onSelect: (value: InscribeContentType) => {
        tab.link
          ? window.open(tab.link, "_blank")
          : setInscriptionContentType(value);
      },
    }));
  }, [inscriptionContentType]);

  const tabItems = useMemo<InscribeTabItem[]>(() => {
    return TABS.map((tab) => ({
      label: tab.label,
      value: tab.value,
      isActive: selectedTab === tab.value,
      disabled: tab.disabled || false,
      onSelect: (value: InscribeTab) => setSelectedTab(value),
    }));
  }, [selectedTab, setSelectedTab]);

  return (
    <AppScreen withBottomNav>
      <div className="flex w-full flex-1 flex-col">
        <div className="flex h-12 border-b-0.5 border-border-primary md:h-auto">
          {inscribeContentTypeItems && inscribeContentTypeItems.length > 0 && (
            <ScrollableTabs
              tabs={inscribeContentTypeItems as unknown as TabItem[]}
              className="mr-4 h-12 overflow-hidden pl-4 md:h-auto"
              classNameTab="lg:py-3"
              classNameActiveTab="bg-[#27272A] text-text-highlight"
            />
          )}
        </div>
        {inscriptionContentType === InscribeContentType.DUNES && (
          <InscribeDunesInfo />
        )}
        <div className="flex h-12 w-full flex-row items-center space-x-2 pr-4 md:h-auto">
          {enabledTabItems && enabledTabItems.length > 0 && (
            <ScrollableTabs
              tabs={enabledTabItems}
              className="mr-4 overflow-hidden pl-4 lg:py-2"
            />
          )}
        </div>
        {contentChecks.isContentDunes && (
          <InscribeDunes
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            setEnabledTabItems={setEnabledTabItems}
            availableTabItems={tabItems}
          />
        )}
      </div>
    </AppScreen>
  );
};
