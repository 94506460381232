import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  AppScreen,
  Button,
  DetailCard,
  ImageWithFallback,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  TokenDetailsCardSkeleton,
} from "@/components";
import {
  useCurrency,
  useDogePrice,
  useDunesWatchlist,
  useTxWallet,
} from "@/contextHooks";
import { useCurrentAccount, useFetchDune } from "@/hooks";
import { cn } from "@/lib/utils";
import { ModalInstall } from "@/modals";
import { getIconForDune } from "@/utility";

import { getFormattedTokenDetails } from "./helper";
import {
  TabTokenActivity,
  TabTokenDetails,
  TabTokenListings,
  TabTokenOwners,
} from "./tabs";
import { TokenDetailProps } from "./types";
import { useOnboardingModalContext } from "@/contextHooks/useOnboardingModal.ts";

export const Dune: React.FC = () => {
  const navigate = useNavigate();
  const { currency } = useCurrency();
  const { token: tick } = useParams();
  const { dogePrice } = useDogePrice();
  const { address } = useCurrentAccount();
  const { login } = useTxWallet();

  const { tokenData, getDuneData } = useFetchDune();

  const [isOnWatchList, setIsOnWatchList] = useState<boolean>(false);
  const [openModalInstall, setOpenModalInstall] = useState<boolean>(false);
  const { isInstallModalOpen, hideInstallModal } = useOnboardingModalContext();

  const {
    addToWatchlist: addToDunesWatchlist,
    removeFromWatchlist: removeFromDunesWatchlist,
    getIsOnWatchlist: getIsOnDunesWatchlist,
  } = useDunesWatchlist();

  const tokenImage = getIconForDune(tick);

  /**
   * Token Details: Horizontal List
   */

  const tokenDetails: TokenDetailProps[] | undefined = useMemo(() => {
    if (!tokenData) return;
    return getFormattedTokenDetails(tokenData, dogePrice, currency);
  }, [tokenData, currency, dogePrice]);

  const listActionVisible = useMemo(
    () =>
      tick !== undefined && tokenData !== undefined && address !== undefined,
    [tick, tokenData, address],
  );

  const toggleWatchlist = useCallback(
    async (tick?: string) => {
      if (!tick) return;
      if (openModalInstall) return;

      // open the install modal (by setting this internal state variable) if the user clicks on add to watchlist and is not
      // on the right device or has not installed the app
      if (isInstallModalOpen) {
        setOpenModalInstall(true);
        return; // do not add to watchlist if the user has not installed the app
      }

      const isLoggedIn = await login();
      if (!isLoggedIn) return;

      if (getIsOnDunesWatchlist(tick)) {
        removeFromDunesWatchlist(tick);
        setIsOnWatchList(false);
      } else {
        await addToDunesWatchlist(tick);
        setIsOnWatchList(true);
      }
    },
    [
      addToDunesWatchlist,
      getIsOnDunesWatchlist,
      isInstallModalOpen,
      login,
      openModalInstall,
      removeFromDunesWatchlist,
    ],
  );

  // update the internal state of the install modal variable  when the install modal is closed
  useEffect(() => {
    if (!isInstallModalOpen) {
      setOpenModalInstall(false);
    }
  }, [isInstallModalOpen]);

  useEffect(() => {
    getDuneData(tick).then();
  }, [getDuneData, tick]);

  return (
    <>
      <AppScreen>
        <div className="flex w-full flex-1 flex-col pt-2">
          {/* Screen Header */}
          <div className="flex flex-row justify-between px-2 py-2 md:px-4 md:py-4">
            <div className="flex flex-1 flex-row justify-start space-x-1 lg:space-x-4">
              <Button
                variant="ghost"
                size="icon"
                shape="circle"
                asChild
                className="h-8 w-8 lg:hidden"
                onClick={() => navigate(-1)}
              >
                <span className="material-symbols-rounded text-lg">
                  arrow_back_ios
                </span>
              </Button>
            </div>

            <div className="flex flex-1 flex-row items-center justify-center space-x-2 lg:space-x-4">
              <ImageWithFallback
                image={tokenImage}
                className="h-8 w-8 lg:h-10 lg:w-10"
              />
              <span className="text-center text-lg font-bold uppercase text-text-primary lg:text-2xl">
                {tick}
              </span>
              <div className="h-8 w-8 bg-transparent" />
            </div>

            <div className="flex flex-1 flex-row justify-end space-x-1 lg:space-x-4">
              <Button
                variant="ghost"
                size="icon"
                className="h-8 w-20 text-text-highlight hover:text-text-primary hover:bg-background-secondary lg:h-10 lg:w-24"
                onClick={() => navigate(`/account/dune/${tick}`)}
                disabled={!listActionVisible}
              >
                <span className="material-symbols-rounded text-xl lg:text-2xl">
                  sell
                </span>
                <span className="px-2 text-md lg:text-lg">List</span>
              </Button>
              <Button
                variant="ghost"
                size="icon"
                shape="circle"
                className="h-8 w-8 hover:bg-background-secondary lg:h-10 lg:w-10 "
                onClick={() => toggleWatchlist(tick)}
              >
                <span
                  className={cn(
                    "material-symbols-rounded text-2xl hover:text-text-primary lg:text-3xl",
                    tick && isOnWatchList
                      ? "text-text-highlight"
                      : "text-text-tertiary",
                  )}
                >
                  star
                </span>
              </Button>
            </div>
          </div>
          {/** Token Details */}
          <div className="flex w-full flex-row space-x-2 overflow-x-auto p-4 xl:px-5">
            {!tokenDetails || !tokenDetails?.length ? (
              <TokenDetailsCardSkeleton />
            ) : (
              tokenDetails?.map((detail) => (
                <DetailCard key={detail.label} {...detail} />
              ))
            )}
          </div>

          {/** Tabs */}
          <div
            className={cn(
              "flex flex-1 flex-col bg-background-secondary",
              "lg:w-full lg:items-center 2xl:bg-transparent",
            )}
          >
            <Tabs defaultValue="listings" className="w-full">
              <TabsList
                className={cn(
                  "mt-2 grid grid-cols-4 px-4",
                  "w-full max-w-2xl bg-transparent md:mx-auto md:mt-8 md:px-0",
                )}
              >
                <TabsTrigger value="listings" className="bg-transparent">
                  Listings
                </TabsTrigger>
                <TabsTrigger value="activity" className="bg-transparent">
                  Activity
                </TabsTrigger>
                <TabsTrigger value="details" className="bg-transparent">
                  Details
                </TabsTrigger>
                <TabsTrigger value="owners" className="bg-transparent">
                  Owners
                </TabsTrigger>
              </TabsList>

              <TabsContent value="listings">
                <TabTokenListings tick={tick} tokenData={tokenData} />
              </TabsContent>

              <TabsContent value="activity">
                <TabTokenActivity tick={tick} />
              </TabsContent>

              <TabsContent value="details">
                <TabTokenDetails tick={tick} tokenData={tokenData} />
              </TabsContent>

              <TabsContent value="owners">
                <TabTokenOwners tick={tick} />
              </TabsContent>
            </Tabs>
          </div>
        </div>
      </AppScreen>

      <ModalInstall
        isVisible={openModalInstall}
        onClose={() => {
          hideInstallModal();
          navigate("/");
        }}
      />
    </>
  );
};
