import React from "react";
import { twMerge } from "tailwind-merge";

import * as TabsPrimitive from "@radix-ui/react-tabs";

import { TabsList as TabsListInner } from "../ui/tabs";

export const TabsList = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.List>
>(({ className, ...props }, ref) => {
  return (
    <TabsListInner
      ref={ref}
      className={twMerge("rounded-full bg-background-secondary", className)}
      {...props}
    />
  );
});

TabsList.displayName = "TabsList";
