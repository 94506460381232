import React from "react";

import { cn } from "@/lib/utils";

import { AnimatedClickable } from "../animatedClickable";

interface ToggleElementProps {
  label: string;
  isSelected: boolean;
  onClick: () => void;
  className?: string;
}

export const ToggleElement: React.FC<ToggleElementProps> = ({
  label,
  isSelected,
  onClick,
  className,
}) => {
  return (
    <AnimatedClickable
      className={cn(
        "flex flex-row items-center justify-between rounded-md bg-background-tertiary p-3",
        className,
      )}
      onClick={onClick}
    >
      <span className="text-sm font-medium text-text-primary">{label}</span>
      <div className="flex h-4 w-4 flex-col items-center justify-center rounded-full bg-background-secondary">
        {isSelected && <div className="h-2 w-2 rounded-full bg-white" />}
      </div>
    </AnimatedClickable>
  );
};
