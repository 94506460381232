import { useContext } from "react";
import { Drc20WatchlistContext } from "@/context/Drc20WatchListContext.ts";

export const useDrc20Watchlist = () => {
  const context = useContext(Drc20WatchlistContext);

  if (!context) {
    throw new Error(
      "useDrc20Watchlist must be used within a WatchlistProvider",
    );
  }

  return context;
};
