export enum ModalType {
  Filter = "filter",
  Sort = "sort",
  Search = "search",
  Buy = "buy",
}

export enum DisplayType {
  Grid = "grid",
  List = "list",
}

/**
 * Token Details
 */

export interface CurrencyType {
  dollar: string;
  doge: string;
}

export interface MintedToken {
  minted: string;
  supply: string;
}

export interface TokenDetails {
  price: CurrencyType;
  volume: CurrencyType;
  volume24h: CurrencyType;

  minted: MintedToken;

  holders: string;
  sales: string;
}

export interface TokenDetailProps {
  label: string;
  value: string;
  color?: string;
}
