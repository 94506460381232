import React from "react";

import { Button, Input } from "@/components";
import { cn } from "@/lib/utils";

import { AnimatedContent } from "../../base";

interface RecipientViewProps {
  handleValueChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handlePasteAddress: () => void;
  handleClearAddress: () => void;
  handleContinue: () => void;
  value: string;
  errorMessage?: string;
}

export const RecipientView: React.FC<RecipientViewProps> = ({
  handleValueChange,
  handlePasteAddress,
  handleClearAddress,
  handleContinue,
  value,
  errorMessage,
}) => (
  <AnimatedContent id="recipient" className="justify-end">
    <div className="relative flex flex-1 flex-row items-center">
      <Input
        placeholder="Recipient Address"
        className="h-full bg-background-secondary text-center"
        onChange={handleValueChange}
        value={value}
      />

      <div className="absolute bottom-2 left-2 right-2 flex flex-row items-end justify-between">
        <span className={cn("text-xs text-red-500")}>{errorMessage}</span>
        <Button
          variant="ghost"
          size="icon"
          shape="circle"
          onClick={value === "" ? handlePasteAddress : handleClearAddress}
          className="h-8 w-8 bg-background-primary"
        >
          <span className="material-symbols-rounded text-md">
            {value === "" ? "content_paste_go" : "close"}
          </span>
        </Button>
      </div>
    </div>

    <Button
      size="large"
      variant="inverse"
      onClick={handleContinue}
      disabled={!value || !!errorMessage}
    >
      Continue
    </Button>
  </AnimatedContent>
);
