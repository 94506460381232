import { AnimatePresence, motion } from "framer-motion";
import {
  Button,
  ImageWithFallback,
  Input,
  ListItem,
  Spinner,
} from "@/components";
import { useInfoModal } from "@/hooks";
import { AnimatedContent, FeeListItem, LegalFooter } from "@/modals/base";
import { ModalInfo } from "@/modals/modalInfo";
import { useToggle } from "@uidotdev/usehooks";
import { FeeType as ModalFeeType } from "@/hooks/useInfoModal";
import { Inscription } from "@/types";
import { isValidDogecoinAddress } from "@/utility";
import { useMemo } from "react";

interface Fees {
  network: string;
}

export interface Item extends Inscription {
  onRemove: () => void;
  imageURI: string;
  itemName: string;
  collectionName: string;
  recipient?: string;
  withRecipient?: boolean;
  onRecipientChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

interface SendViewProps {
  fees: Fees;
  items: Item[];
  loading: boolean;
  handleSend: () => void;
  recipientsInputValues: string[];
  singleRecipientInputValue: string;
  handleRecipientChange: (
    idx: number,
    e: React.ChangeEvent<HTMLInputElement>,
  ) => void;
  handleSingleRecipient: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const SendView: React.FC<SendViewProps> = ({
  fees,
  items = [],
  handleSend,
  handleRecipientChange,
  handleSingleRecipient,
  loading,
  recipientsInputValues,
  singleRecipientInputValue,
}) => {
  const { info, infoVisible, handleOpenInfoFee, handleCloseInfo } =
    useInfoModal();

  const [singleReceiver, toggleSingleReceiver] = useToggle(false);

  const { hasInvalidRecievers, canSend } = useMemo(() => {
    // Will show invalid address if single receiver is selected and input is invalid
    let hasInvalidRecievers = false;
    // Will disable send button if reciever is not filled or is invalid
    let canSend = false;

    if (singleReceiver) {
      hasInvalidRecievers =
        !!singleRecipientInputValue &&
        !isValidDogecoinAddress(singleRecipientInputValue);
      canSend =
        !!singleRecipientInputValue &&
        isValidDogecoinAddress(singleRecipientInputValue);
    } else {
      hasInvalidRecievers = recipientsInputValues.some(
        (recipient) => !!recipient && !isValidDogecoinAddress(recipient),
      );
      canSend =
        recipientsInputValues.length == 0
          ? false
          : recipientsInputValues.every(
              (recipient) => !!recipient && isValidDogecoinAddress(recipient),
            );
    }

    return { hasInvalidRecievers, canSend };
  }, [recipientsInputValues, singleReceiver, singleRecipientInputValue]);

  return (
    <AnimatedContent id="send" className="p-0">
      {items.length > 0 && (
        <AnimatePresence>
          <div className="relative flex max-h-screen flex-1 flex-col space-y-4 overflow-y-scroll px-4 pb-32">
            <motion.div
              layout="position"
              transition={{ duration: 0.1 }}
              className="flex flex-col space-y-2"
            >
              {items.map((item, idx) => (
                <SendCardCollectible
                  key={`${idx}-${item.inscription_id}`}
                  image={item.imageURI}
                  itemName={item.itemName}
                  collectionName={item.collectionName}
                  withRecipient={!singleReceiver}
                  recipient={recipientsInputValues[idx]}
                  onRecipientChange={(event) =>
                    handleRecipientChange(idx, event)
                  }
                  onRemove={item.onRemove}
                />
              ))}
            </motion.div>

            <motion.div
              layout="position"
              transition={{ duration: 0.1 }}
              className="fixed bottom-0 left-0 flex w-full flex-col space-y-2 border-t border-border-primary bg-background-primary/80 px-4 pt-2 drop-shadow-xl backdrop-blur-xl pb-safe-offset-2"
            >
              <div className="flex flex-col px-1">
                <FeeListItem
                  label="Network Fee"
                  value={fees.network}
                  onClick={() => handleOpenInfoFee(ModalFeeType.NETWORK)}
                />
              </div>

              {singleReceiver && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.1 }}
                  className="flex flex-col"
                >
                  <Input
                    placeholder="Recipient"
                    value={singleRecipientInputValue}
                    className="my-2 h-12 bg-background-tertiary"
                    onChange={handleSingleRecipient}
                  />
                  {hasInvalidRecievers && (
                    <span className="text-xs text-red-500">
                      Invalid address
                    </span>
                  )}
                </motion.div>
              )}

              <ListItem
                label="Single Receiver"
                toggleEnabled={singleReceiver}
                onToggle={toggleSingleReceiver}
                toggleSize="small"
                className="h-10 px-1"
              />

              <motion.div
                layout
                transition={{ duration: 0.1 }}
                className="flex flex-col space-y-2"
              >
                <Button
                  disabled={!canSend}
                  size="large"
                  variant="inverse"
                  onClick={handleSend}
                >
                  {loading ? <Spinner size={24} /> : "Send"}
                </Button>
                <LegalFooter label="Send" />
              </motion.div>
            </motion.div>
          </div>

          <ModalInfo
            info={info}
            isVisible={infoVisible}
            onClose={handleCloseInfo}
          />
        </AnimatePresence>
      )}
    </AnimatedContent>
  );
};

interface SendCardCollectibleProps {
  image: string;
  itemName: string;
  collectionName: string;
  onRemove: () => void;
  recipient?: string;
  withRecipient?: boolean;
  onRecipientChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const SendCardCollectible: React.FC<SendCardCollectibleProps> = ({
  image,
  itemName,
  collectionName,
  recipient,
  withRecipient,
  onRemove,
  onRecipientChange,
}: SendCardCollectibleProps) => {
  const isValidRecipient = useMemo(
    () =>
      !recipient || recipient == ""
        ? true
        : isValidDogecoinAddress(recipient ?? ""),
    [recipient],
  );
  return (
    <motion.div
      layout="position"
      transition={{ duration: 0.1 }}
      className="relative flex flex-col overflow-visible rounded-lg bg-background-secondary p-2"
    >
      <div className="flex flex-row space-x-4">
        <ImageWithFallback image={image} className="h-16 w-16 rounded-md" />
        <div className="flex flex-1 flex-col justify-center space-y-1">
          <span className="text-xs font-semibold text-text-primary">
            {itemName}
          </span>
          <span className="text-xs text-text-tertiary">{collectionName}</span>
        </div>
      </div>

      {withRecipient && (
        <div className="mt-2 flex flex-col space-y-1">
          <div className="relative flex flex-row items-center space-x-1 rounded-md bg-background-tertiary">
            <Input
              className="h-10 bg-background-tertiary text-sm font-medium text-text-primary"
              placeholder="Recipient"
              onChange={onRecipientChange}
              value={recipient || ""}
            />
            {!isValidRecipient && (
              <span className="absolute right-2 top-3 text-xs text-red-500">
                Invalid address
              </span>
            )}
          </div>
        </div>
      )}

      <button
        onClick={onRemove}
        className="absolute -right-1 -top-1 flex h-6 w-6 items-center justify-center rounded-full border-0.5 border-background-primary bg-background-secondary"
      >
        <span className="material-symbols-rounded text-md text-text-primary">
          close
        </span>
      </button>
    </motion.div>
  );
};
