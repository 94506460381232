import { CollectibleHolder } from "@/types/holders.ts";
import { TimeFrames } from "@/types/trading.ts";

import { SortOrder } from "./sort";

export enum ActivityType {
  Sale = "sale",
  Unlist = "unlist",
  List = "list",
  All = "all",
}

export interface ActivityCollection {
  collectionSymbol: string;
  createdAt: string;
  type: ActivityType;

  doginalName: string;
  imageURI: string;
  inscriptionId: string;
  inscriptionNumber: number;

  price: number;
  floorPrice: number;
  floorDifference: number;

  from: string;
  to: string | null;
}

export interface ActivityDRC20 {
  tick: string;
  amount: number;
  createdAt: string;
  type: ActivityType;

  price: number;
  totalPrice: number;

  inscriptionId: string;
  inscriptionNumber: number;

  from: string;
  to: string | null;

  floorPrice: number;
  floorDifference: number;
}

export interface TokenActivity extends ActivityDRC20 {
  priceDoge: string;
  priceUSD: string;
  priceForCurrency: string;
  totalPriceDoge: string;
  totalPriceUSD: string;
  totalPriceForCurrency: string;
}

export interface CollectionActivity extends ActivityCollection {
  priceDoge: string;
  priceUSD: string;
  priceForCurrency: string;
  onNavigateToListing: () => void;
}

export enum ActivitySortParam {
  Top = "top",
  Recent = "recent",
}

export interface ActivitySort {
  sortParam: ActivitySortParam;
  sortOrder: SortOrder;
}

export type ActivityFilters = {
  offset: number;
  limit: number;
  tick?: string;
  collectionSymbol?: string;
  type?: ActivityType;
  address?: string;
};

export type CollectionActivitiesRequestParams = {
  collectionSymbol?: string;
  offset: number;
  limit?: number;
  type?: ActivityType;
  sortOrder?: SortOrder;
  sortParam?: ActivitySortParam;
  history?: TimeFrames;
  address?: string;
};

export type FetchCollectionActivitiesReturn = {
  data: ActivityCollection[];
  isLoading: boolean;
  setSort: (sort?: ActivitySort) => void;
  setFilters: (filters: (prev: ActivityFilters) => ActivityFilters) => void;
  setOffset: (offset: number) => void;
  offset: number;
  total: number;
  hasMoreData: boolean;
  isLoadingMoreData: boolean;
  reset: (forCurrencySymbol: string | undefined) => void;
  sort: ActivitySort;
  filters: ActivityFilters;
};

export type CollectibleHoldersReturn = {
  topHolders: CollectibleHolder[];
  isTopHoldersLoading: boolean;
  fetchTop100Holders: () => void;
};

// SDOGGS enhancements
export interface ActivityDune {
  tick: string;
  amount: number;
  createdAt: string;
  type: ActivityType;

  price: number;
  totalPrice: number;

  inscriptionId: string;
  inscriptionNumber: number;

  from: string;
  to: string | null;

  floorPrice: number;
  floorDifference: number;
}

export interface DuneTokenActivity extends ActivityDune {
  priceDoge: string;
  priceUSD: string;
  priceForCurrency: string;
  totalPriceDoge: string;
  totalPriceUSD: string;
  totalPriceForCurrency: string;
}
