export function getColorForValue(value: number | string | undefined) {
  if (!value || value == 0) {
    return "text-text-disabled";
  } else {
    return "text-text-primary";
  }
}

export function getColorForChange(change: number | string) {
  if (typeof change === "string") {
    if (change.includes("-")) {
      return "text-red-500";
    }

    if (change === "0%" || change === "0" || change === "0 %" || !change) {
      return "text-text-tertiary";
    }

    return "text-green-500";
  }

  if (typeof change === "number") {
    if (change < 0) {
      return "text-red-500";
    }

    if (change === 0) {
      return "text-text-tertiary";
    }

    return "text-green-500";
  }
}

export function getBackgroundColorForChange(change: number | string) {
  if (typeof change === "string") {
    if (change.includes("-")) {
      return "bg-red-950";
    }

    if (change === "0%" || change === "0" || change === "0 %" || !change) {
      return "bg-base-950";
    }

    return "bg-green-950";
  }

  if (typeof change === "number") {
    if (change < 0) {
      return "bg-red-950";
    }

    if (change === 0) {
      return "bg-base-950";
    }

    return "bg-green-950";
  }
}
