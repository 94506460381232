import { sdoggsApiV2 } from "@/lib/fetch";
import { DuneListing, FilterListingsDunes, SortListingsDunes } from "@/types";

export const fetchDunesListings = async (
  fetchParams: SortListingsDunes & FilterListingsDunes,
  cachebreaker: boolean = false,
): Promise<{
  offers: DuneListing[];
  total: number;
}> => {
  const {
    tick,
    seller,
    offset,
    limit,
    sortOrder,
    sortParam,
    status,
    amount,
    txHash,
    txOut,
    priceMin,
    priceMax,
    floorDiffMinPercent,
    floorDiffMaxPercent,
  } = fetchParams;

  const ticker = tick ? tick.toUpperCase() : tick;

  const { data } = await sdoggsApiV2(cachebreaker).get("/offer/dunes/list", {
    params: {
      tick: ticker,
      seller,
      offset,
      limit,
      sortOrder,
      sortParam,
      status,
      amount,
      txHash,
      txOut,
      priceMin,
      priceMax,
      floorDiffMinPercent,
      floorDiffMaxPercent,
    },
  });

  return data;
};
