import { AnimatePresence, Transition, Variants, motion } from "framer-motion";

import { Input } from "@/components";
import { useAppSearch } from "@/hooks";
import { cn } from "@/lib/utils";

import { SearchCloseButton } from "./searchCloseButton";
import { SearchLoadingIndicator } from "./searchLoadingIndicator";
import { SuggestionDoginal } from "./suggestionsDoginal";
import { SuggestionAddress, SuggestionDRC20, SuggestionDune } from ".";

const variants: Variants = {
  open: { opacity: 1 },
  closed: { opacity: 0 },
};

const transition: Transition = {
  duration: 0.1,
};

interface SearchProps {
  className?: string;
  autoFocus?: boolean;
  onSelect: (suggestion: any) => void;
  config?: {
    ignoreAddresses?: boolean;
    ignoreDrc20s?: boolean;
    ignoreDoginals?: boolean;
    ignoreDunes?: boolean;
    ignoreInscriptions?: boolean;
  };
  suggestions?: any;
  SuggestionComponent?: React.FC<any>;
}

export const Search: React.FC<SearchProps> = ({
  className,
  autoFocus,
  onSelect,
  config,
  suggestions,
  SuggestionComponent,
}) => {
  const {
    isFocused,
    searchValue,
    showResults,
    showSuggestions,
    loading,
    addressSuggestion,
    inscriptionSuggestion,
    drc20Suggestions,
    doginalSuggestions,
    dunesSuggestions,
    handleSetSearchValue,
    handleClearSearch,
    onSelectSuggestion,
    handleOnSubmit,
    handleKeyDown,
    setIsFocused,
  } = useAppSearch(() => {});

  const handleSelect = (suggestion: any) => {
    if (onSelect) {
      onSelect(suggestion);
    } else {
      onSelectSuggestion(suggestion);
    }
  }

  return (
    <div className="flex flex-col space-y-2 bg-transparent">
      {/** Search Bar */}
      <div className={cn("z-20 flex rounded-lg", className)}>
        <div
          className={cn(
            "flex flex-1 flex-row items-center space-x-2 rounded-md pl-3 pr-2",
            isFocused ? "bg-background-tertiary" : "bg-background-secondary",
          )}
        >
          <SearchLoadingIndicator loading={loading} />

          <Input
            placeholder="Search"
            className="flex-1 bg-transparent px-0 py-0 focus:bg-transparent"
            value={searchValue}
            onSubmit={handleOnSubmit}
            onKeyDown={handleKeyDown}
            onChange={handleSetSearchValue}
            onBlur={() => setIsFocused(false)}
            onFocus={() => setIsFocused(true)}
            autoFocus={autoFocus}
          />

          <SearchCloseButton
            isVisible={searchValue !== ""}
            onClick={handleClearSearch}
          />
        </div>
      </div>

      {/** Search Results */}
      <AnimatePresence>
        {suggestions && SuggestionComponent && searchValue.length >= 2 && (
          <div className="flex flex-col space-y-1 pt-4">
            {suggestions.filter((suggestion: any) => suggestion.name.indexOf(searchValue) > -1).map((suggestion: any) => (
              <SuggestionComponent
                {...suggestion}
                onSelect={() => handleSelect(suggestion)}
                key={suggestion.tick}
              />
            ))}
          </div>
        )}
        {!suggestions && showSuggestions && (
          <motion.div
            key="search-bar"
            initial="closed"
            animate="open"
            exit="closed"
            variants={variants}
            transition={transition}
            className="flex h-fit max-h-[calc(100vh-100px)] flex-col overflow-scroll bg-background-primary p-4 text-text-primary"
          >
            {!showResults && (
              <motion.div
                initial="closed"
                animate="open"
                exit="closed"
                variants={variants}
                transition={transition}
                className="flex flex-1 flex-col items-center justify-center space-y-10"
              >
                <span className="relative flex h-16 w-16">
                  <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-background-tertiary opacity-25" />
                  <span className="relative inline-flex h-full w-full items-center justify-center rounded-full bg-background-tertiary">
                    <span className="material-symbols-rounded text-4xl text-text-primary">
                      search
                    </span>
                  </span>
                </span>

                <h2 className="text-center text-lg font-medium">
                  No Results Found
                </h2>
              </motion.div>
            )}

            {!config?.ignoreAddresses && addressSuggestion && (
              <SuggestionAddress
                label="Address"
                value={addressSuggestion.address}
                onSelect={() => handleSelect(addressSuggestion)}
              />
            )}

            {!config?.ignoreInscriptions && inscriptionSuggestion && (
              <SuggestionAddress
                label="Inscription"
                value={inscriptionSuggestion.inscription}
                onSelect={() => handleSelect(inscriptionSuggestion)}
              />
            )}

            {!config?.ignoreDrc20s && drc20Suggestions && drc20Suggestions.length > 0 && (
              <div className="flex flex-col space-y-1">
                <span className="font-bold uppercase text-text-primary">
                  Drc-20s
                </span>
                {drc20Suggestions.map((suggestion) => (
                  <SuggestionDRC20
                    {...suggestion}
                    onSelect={() => handleSelect(suggestion)}
                    key={suggestion.tick}
                  />
                ))}
              </div>
            )}

            {!config?.ignoreDoginals && doginalSuggestions && doginalSuggestions.length > 0 && (
              <div className="mt-4 flex flex-col space-y-1 pt-4">
                <span className="font-bold uppercase text-text-primary">
                  Collections
                </span>
                {doginalSuggestions.map((suggestion) => (
                  <SuggestionDoginal
                    {...suggestion}
                    onSelect={() => handleSelect(suggestion)}
                    key={suggestion.tick}
                  />
                ))}
              </div>
            )}

            {!config?.ignoreDunes && dunesSuggestions && dunesSuggestions.length > 0 && (
              <div className="flex flex-col space-y-1 pt-4">
                <span className="font-bold uppercase text-text-primary">
                  Dunes
                </span>
                {dunesSuggestions.map((suggestion) => (
                  <SuggestionDune
                    {...suggestion}
                    onSelect={() => handleSelect(suggestion)}
                    key={suggestion.tick}
                  />
                ))}
              </div>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
