import { useCallback } from "react";
import { useShowInstallMessage } from "./useShowInstallMessage";
import { AccountProps } from "@/types";
import { useLocalStorage } from "@/hooks/useLocalStorage.ts";
import { HAS_COMPLETED_WALLET_SETUP_KEY } from "@/constants.ts";

interface ShowMissingWalletFunctionReturn {
  onShowMissingWallet: () => boolean;
}

export const useShowMissingWallet = ({
  address,
}: AccountProps): ShowMissingWalletFunctionReturn => {
  const showInstallMessage = useShowInstallMessage();
  const { value: hasCompletedWalletSetup } = useLocalStorage<string | null>(
    HAS_COMPLETED_WALLET_SETUP_KEY,
    null,
    false,
  );

  const onShowMissingWallet = useCallback((): boolean => {
    if (!showInstallMessage) {
      const shouldOpenMissingWalletModal =
        !(hasCompletedWalletSetup === "true") && !address;

      const shouldCloseModal = hasCompletedWalletSetup === "true";

      // Open the missing wallet modal if necessary
      if (shouldOpenMissingWalletModal) {
        return true;
      }
      // Close the modal if the wallet setup is completed
      else if (shouldCloseModal) {
        return false;
      }
    } else {
      return false;
    }
    return false;
  }, [address, hasCompletedWalletSetup, showInstallMessage]);

  return {
    onShowMissingWallet,
  };
};
