import { ChangeEvent, useCallback, useEffect, useState } from "react";

import { Button, Input } from "@/components";

import { Sheet, SheetProps } from "../base";

interface ModalCollectionSearchProps extends SheetProps {
  search: string;
  onApply: (search: string) => void;
}

// Search for: Seller Wallet Address | Collectible Name | Amount
export const ModalCollectionSearch: React.FC<ModalCollectionSearchProps> = ({
  search,
  onApply,
  ...props
}) => {
  const [localSearch, setLocalSearch] = useState<string>(search);

  const handleSearchChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setLocalSearch(event.target.value);
    },
    [],
  );

  const handleApply = useCallback(() => {
    onApply?.(localSearch);
  }, [onApply, localSearch]);

  useEffect(() => {
    setLocalSearch(search);
  }, [search]);

  return (
    <Sheet {...props}>
      <div className="flex flex-col space-y-4 p-2">
        <Input
          className="h-12"
          value={localSearch}
          onChange={handleSearchChange}
          placeholder="Seller, Collectible, Amount"
        />
        <Button variant="inverse" size="large" onClick={handleApply}>
          Apply
        </Button>
      </div>
    </Sheet>
  );
};
