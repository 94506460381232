import { AnimatePresence, motion } from "framer-motion";
import React from "react";

import { cn } from "@/lib/utils";

interface AppearProps {
  isVisible: boolean;
  withOffset?: boolean;
  withBottomNav?: boolean;
  from: "bottom" | "top" | "left" | "right";
  children: React.ReactNode;
}

const getAnimationProps = (
  from: "bottom" | "top" | "left" | "right",
  withOffset: boolean,
) => {
  switch (from) {
    case "top":
      return {
        positionClass: cn(
          "top-0 left-0 right-0 flex flex-col items-center transform -translate-x-1/2",
          withOffset ? "mt-safe-offset-4" : "mt-0",
        ),
        variants: {
          hidden: { opacity: 0, y: -50 },
          visible: { opacity: 1, y: 0 },
        },
      };
    case "left":
      return {
        positionClass: cn(
          "left-0 top-0 bottom-0 flex flex-col items-center transform -translate-y-1/2",
          withOffset ? "ml-safe-offset-4" : "ml-0",
        ),
        variants: {
          hidden: { opacity: 0, x: -50 },
          visible: { opacity: 1, x: 0 },
        },
      };
    case "right":
      return {
        positionClass: cn(
          "right-0 top-0 bottom-0 flex flex-col items-center transform -translate-y-1/2",
          withOffset ? "mr-safe-offset-4" : "mr-0",
        ),
        variants: {
          hidden: { opacity: 0, x: 50 },
          visible: { opacity: 1, x: 0 },
        },
      };
    case "bottom":
    default:
      return {
        positionClass: cn(
          "bottom-0 left-0 right-0 flex flex-col items-center transform -translate-x-1/2",
          withOffset ? "mb-safe-offset-4" : "mb-0",
        ),
        variants: {
          hidden: { opacity: 0, y: 50 },
          visible: { opacity: 1, y: 0 },
        },
      };
  }
};

export const Appear: React.FC<AppearProps> = ({
  isVisible,
  from,
  children,
  withOffset = true,
}) => {
  const { positionClass, variants } = getAnimationProps(from, withOffset);
  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          className={`fixed ${positionClass} z-50 overflow-visible`}
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={variants}
          transition={{
            type: "spring",
            damping: 22.5,
            stiffness: 300,
          }}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};
