import { marketplaceApiV2 } from "@/lib/fetch";
import {
  Currency,
  DRC20Token,
  Drc20ActivityList,
  Drc20Data,
  Drc20List,
  Sorting,
} from "@/types";
import { FetchActivityData } from "@/types/watchlist.ts";
import { getIconForTick, handleError } from "@/utility";

const API_ENDPOINT = "/drc20/list/activity";

const gainersSort = (a: DRC20Token, b: DRC20Token) => b.change - a.change;

const createLookupMap = <T extends keyof Drc20Data>(
  data: Drc20List["list"],
  key: T,
) => {
  return data.reduce(
    (acc: any, item: any) => {
      if (typeof item[key] === "number") {
        acc[item.tick] = item[key] as number;
      } else {
        acc[item.tick] = 0;
      }
      return acc;
    },
    {} as Record<string, number>,
  );
};

export const fetchDrc20Tokens = async ({
  offset,
  limit,
  // history,
  sortOrder,
  sortParam,
  currency,
  dogePrice,
  filter,
  cachebreaker = false,
}: FetchActivityData): Promise<DRC20Token[]> => {
  try {
    const params = {
      offset,
      limit,
      sortOrder,
      sortParam: sortParam === Sorting.gainers ? Sorting.top : sortParam,
      action: "sale",
      filterByTick: filter,
    };

    const response = await marketplaceApiV2(
      cachebreaker,
    ).get<Drc20ActivityList>(API_ENDPOINT, {
      params: { ...params },
    });
    const data = response.data?.list || [];

    const changeMaps = {
      "24h": createLookupMap(data, "changePercent24h"),
      "7d": createLookupMap(data, "changePercent7d"),
      "30d": createLookupMap(data, "changePercent30d"),
      all: createLookupMap(data, "changePercent"),
    };

    const volumeMaps = {
      "24h": createLookupMap(data, "volume24h"),
      "7d": createLookupMap(data, "volume7d"),
      "30d": createLookupMap(data, "volume30d"),
      all: createLookupMap(data, "volume"),
    };

    const priceMaps = {
      "24h": createLookupMap(data, "floorPrice24h"),
      "7d": createLookupMap(data, "floorPrice7d"),
      "30d": createLookupMap(data, "floorPrice30d"),
      all: createLookupMap(data, "floorPrice"),
    };

    const salesMap = {
      "24h": createLookupMap(data, "sales24h"),
      "7d": createLookupMap(data, "sales7d"),
      "30d": createLookupMap(data, "sales30d"),
      all: createLookupMap(data, "sales"),
    };

    const mapItem = (item: Drc20Data, index: number) => {
      const {
        tick,
        owner,
        sales,
        volume,
        holders,
        maxSupply,
        floorPrice,
        changePercent,
        currentSupply,
      } = item;

      const ticker = tick.toUpperCase();
      // floor price is already correctly calculated in shibes with 18decimals
      const fdvInShibs = isNaN(floorPrice) ? 0 : floorPrice * maxSupply || 0;

      return {
        ticker,
        id: tick,
        name: ticker,
        fdv: fdvInShibs,
        maxSupply,
        rank: index + 1 + (offset || 0),
        image: getIconForTick(ticker),
        price: isNaN(floorPrice) ? 0 : floorPrice,
        price24h: priceMaps["24h"][tick],
        price7d: priceMaps["7d"][tick],
        price30d: priceMaps["30d"][tick],
        change: isNaN(changePercent) ? 0 : changePercent,
        change24h: changeMaps["24h"][tick] || 0,
        change7d: changeMaps["7d"][tick] || 0,
        change30d: changeMaps["30d"][tick] || 0,
        volume: isNaN(volume) ? 0 : volume,
        volume24h: volumeMaps["24h"][tick] || 0,
        volume7d: volumeMaps["7d"][tick] || 0,
        volume30d: volumeMaps["30d"][tick] || 0,
        sales: isNaN(sales) ? 0 : sales,
        sales24h: salesMap["24h"][tick] || 0,
        sales7d: salesMap["7d"][tick] || 0,
        sales30d: salesMap["30d"][tick] || 0,
        owner,
        currentDogePrice: dogePrice || 0,
        holders: isNaN(holders) ? 0 : holders,
        currency: currency ?? Currency.USD,
        currentSupply: currentSupply || 0,
      };
    };

    let listings = data.map(mapItem);

    if (sortParam === Sorting.gainers) {
      listings = listings.sort(gainersSort);
    }

    return listings;
  } catch (e: Error | unknown) {
    handleError(e);
    return [];
  }
};
