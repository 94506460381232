import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  ActivityCardNFT,
  Skeleton,
  TabItem,
  TableContentCollection,
  TableExplore,
} from "@/components";
import { DOGINALS_LISTINGS_PAGINATION_LIMIT } from "@/constants";
import {
  useCurrency,
  useDogePrice,
  useDoginalCollectionList,
  useDoginalWatchlist,
  useTxWallet,
} from "@/contextHooks";
import { useInfiniteScroll } from "@/hooks";
import { CollectionActivityData, Tab } from "@/types";

import { EmptyTopMover, EmptyWatchlist, TopMoverGradient } from "../components";
import {
  ScrollableCarouselItem,
  ScrollabelCarousel,
} from "@/components/scrollableCarousel";
import { useOnboardingModalContext } from "@/contextHooks/useOnboardingModal.ts";
import { ModalInstall } from "@/modals";

interface TradingCollectiblesProps {
  selectedTab: string;
  availableTabItems: TabItem[];
  setEnabledTabItems: (tabItems: TabItem[]) => void;
}

export const TradingCollectibles: React.FC<TradingCollectiblesProps> = ({
  selectedTab,
  availableTabItems,
  setEnabledTabItems,
}) => {
  const navigate = useNavigate();
  const { currency } = useCurrency();
  const { dogePrice } = useDogePrice();
  const { login } = useTxWallet();

  /**
   * Tab Checks
   */

  const { isTabTop, isTabTrending, isTabRecent, isTabWatchlist } =
    useMemo(() => {
      return {
        isTabTop: selectedTab === Tab.Top,
        isTabTrending: selectedTab === Tab.Trending,
        isTabRecent: selectedTab === Tab.Recent,
        isTabWatchlist: selectedTab === Tab.Watchlist,
      };
    }, [selectedTab]);

  /**
   * Data Fetching & Processing
   */

  const {
    // Table Data
    tableOffset,
    isLoadingTableData,
    isLoadingMoreTableData,
    isMoversLoading,
    tableData,
    debouncedSetTableOffset,
    hasMoreTableData,
    // Movers Data
    topMovers,
    topSales,
    recentSales,
  } = useDoginalCollectionList();

  const enabledTabs = useMemo<TabItem[]>(() => {
    const tabConditions: Record<Tab, CollectionActivityData[] | boolean[]> = {
      [Tab.Top]: topMovers,
      [Tab.Trending]: topSales,
      [Tab.Recent]: recentSales,
      // Watchlist tab is enabled ALWAYS. Independent of the data.
      [Tab.Watchlist]: [true],
    };

    return availableTabItems.filter(
      (tab) => tabConditions[tab.value]?.length > 0,
    );
  }, [availableTabItems, recentSales, topMovers, topSales]);

  // Ensure to only load those tabs which have data
  useEffect(() => {
    if (!isLoadingTableData) {
      setEnabledTabItems(enabledTabs);
    }
  }, [enabledTabs, isLoadingTableData, setEnabledTabItems]);

  const { watchlist, addToWatchlist, removeFromWatchlist, getIsOnWatchlist } =
    useDoginalWatchlist();
  const [openModalInstall, setOpenModalInstall] = useState<boolean>(false);
  const { isInstallModalOpen, hideInstallModal } = useOnboardingModalContext();

  const toggleWatchlist = useCallback(
    async (collectionSymbol?: string) => {
      if (openModalInstall) return;

      // open the install modal (by setting this internal state variable) if the user clicks on add to watchlist and is not
      // on the right device or has not installed the app
      if (isInstallModalOpen) {
        setOpenModalInstall(true);
        return; // do not add to watchlist if the user has not installed the app
      }

      const isLoggedIn = await login();
      if (!isLoggedIn) return;

      if (!collectionSymbol) return;

      if (getIsOnWatchlist(collectionSymbol)) {
        removeFromWatchlist(collectionSymbol);
      } else {
        await addToWatchlist(collectionSymbol);
      }
    },
    [
      addToWatchlist,
      getIsOnWatchlist,
      isInstallModalOpen,
      login,
      openModalInstall,
      removeFromWatchlist,
    ],
  );

  const collectionDataWithWatchlist = useMemo(() => {
    return tableData.map((token) => ({
      ...token,
      currency,
      currentDogePrice: dogePrice,
      listed: `${token.totalListed} / ${token.supply}`,
      watchlist: getIsOnWatchlist(token.collectionSymbol),
      onAddToWatchlist: () => toggleWatchlist(token.collectionSymbol),
      onRemoveFromWatchlist: () => toggleWatchlist(token.collectionSymbol),
    }));
  }, [tableData, currency, dogePrice, getIsOnWatchlist, toggleWatchlist]);

  const onNavigateToCollection = useCallback(
    (collectionSymbol: string) => {
      navigate(`/collectible/${collectionSymbol.toLowerCase()}`);
    },
    [navigate],
  );

  const bottomTableRef = useInfiniteScroll({
    isLoadingData: isLoadingTableData,
    hasMoreData: hasMoreTableData,
    isLoadingMoreData: isLoadingMoreTableData,
    setOffset: debouncedSetTableOffset,
    offset: tableOffset,
    paginationLimit: DOGINALS_LISTINGS_PAGINATION_LIMIT,
  });

  return (
    <>
      <ScrollabelCarousel />
      <div className="relative">
        <div className="py-2 lg:py-4">
          {isMoversLoading ? (
            <div className="flex flex-row space-x-2 overflow-x-auto px-4 py-2 lg:space-x-4 lg:py-4">
              {Array.from({ length: 10 }).map((_, index) => (
                <Skeleton
                  key={index}
                  className="aspect-square w-28 flex-shrink-0 rounded-lg md:w-40"
                />
              ))}
            </div>
          ) : (
            <>
              {isTabTop &&
                (topMovers.length > 0 ? (
                  <ScrollabelCarousel>
                    {topMovers.map((collection, index) => (
                      <ScrollableCarouselItem
                        key={`carousel-${collection.id}-${index}`}
                      >
                        <ActivityCardNFT
                          key={collection.id}
                          onClick={onNavigateToCollection.bind(
                            null,
                            collection.id,
                          )}
                          {...collection}
                        />
                      </ScrollableCarouselItem>
                    ))}
                  </ScrollabelCarousel>
                ) : (
                  <EmptyTopMover />
                ))}
              {isTabTrending &&
                (topSales.length > 0 ? (
                  <ScrollabelCarousel>
                    {topSales.map((collection, index) => (
                      <ScrollableCarouselItem
                        key={`carousel-${collection.id}-${index}`}
                      >
                        <ActivityCardNFT
                          key={collection.id}
                          onClick={onNavigateToCollection.bind(
                            null,
                            collection.id,
                          )}
                          {...collection}
                        />
                      </ScrollableCarouselItem>
                    ))}
                  </ScrollabelCarousel>
                ) : (
                  <EmptyTopMover />
                ))}

              {isTabRecent &&
                (recentSales.length > 0 ? (
                  <ScrollabelCarousel>
                    {recentSales.map((recentSale, index) => (
                      <ScrollableCarouselItem
                        key={`carousel-${recentSale.id}-${index}`}
                      >
                        <ActivityCardNFT
                          key={recentSale.id}
                          onClick={onNavigateToCollection.bind(
                            null,
                            recentSale.collectionSymbol ?? "",
                          )}
                          showInfoOnHover={false}
                          {...recentSale}
                        />
                      </ScrollableCarouselItem>
                    ))}
                  </ScrollabelCarousel>
                ) : (
                  <EmptyTopMover />
                ))}

              {isTabWatchlist &&
                (watchlist.length > 0 ? (
                  <ScrollabelCarousel id="watchlist-collectibles">
                    {watchlist.map((data, index) => {
                      const {
                        id,
                        imageURI,
                        title,
                        value,
                        description,
                        mcapInUSD,
                      } = data;
                      return (
                        <ScrollableCarouselItem key={`carousel-${id}-${index}`}>
                          <ActivityCardNFT
                            key={id}
                            onClick={onNavigateToCollection.bind(null, id)}
                            title={title}
                            valuePrimary={value}
                            valueSecondary={mcapInUSD}
                            valueSecondaryHideOnMobile={true}
                            valueSecondaryIsChange={false}
                            image={imageURI || ""}
                            description={description}
                          />
                        </ScrollableCarouselItem>
                      );
                    })}
                  </ScrollabelCarousel>
                ) : (
                  <EmptyWatchlist
                    className={
                      "flex h-[7.125rem] w-full flex-shrink-0 cursor-pointer flex-col items-center justify-center" +
                      " lg:h-64" +
                      " space-y-2 rounded-lg border-border-primary bg-background-secondary md:h-40"
                    }
                  />
                ))}
            </>
          )}
        </div>

        {/** Don't display the gradient on the <EmptyWatchlist /> */}
        {!(isTabWatchlist && watchlist.length === 0) && <TopMoverGradient />}
      </div>

      <TableExplore
        defaultSortId="volumeThirtyDays"
        columns={TableContentCollection}
        data={collectionDataWithWatchlist}
        loading={isLoadingTableData}
        loadingMore={isLoadingMoreTableData}
      />

      <div
        className="pb-1"
        ref={bottomTableRef}
        key={"scrollbar-trading-collectibles"}
      />
      {/*There can be screen sizes causing the anchor tag not to get in focus of the screen supervisor. This helps.*/}
      <div className="pb-1" key={"scrollbar-trading-collectibles-buffer"} />

      <ModalInstall
        isVisible={openModalInstall}
        onClose={() => {
          hideInstallModal();
          window.location.reload(); // this is needed to reset the internal state of the install modal on this page (it's a hack)
        }}
      />
    </>
  );
};
