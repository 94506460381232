import React, { useCallback, useMemo, useState } from "react";

import { Button, Input } from "@/components";
import { Currency, FilterBetween } from "@/types";
import { validateMinMax } from "@/utility";

import { Sheet, SheetProps } from "../base";

interface ModalTokenFilterProps extends SheetProps {
  price: FilterBetween;
  floorDifference: FilterBetween;
  onApply: (filters: {
    price: FilterBetween;
    floorDifference: FilterBetween;
  }) => void;
}

export const ModalTokenFilter: React.FC<ModalTokenFilterProps> = ({
  onApply,
  price,
  floorDifference,
  ...props
}) => {
  const [error, setError] = useState<string | undefined>(undefined);
  const [localPrice, setLocalPrice] = useState<FilterBetween>(price);
  const [localFloorDifference, setLocalFloorDifference] =
    useState<FilterBetween>(floorDifference);

  const handleBlurPrice = useCallback(() => {
    validateMinMax(
      localPrice.min,
      localPrice.max,
      "Min price cannot be greater than max price",
      setError,
    );
  }, [localPrice, setError]);

  const handleBlurFloorDifference = useCallback(() => {
    validateMinMax(
      localFloorDifference.min,
      localFloorDifference.max,
      "Min floor diff. cannot be greater than max floor diff.",
      setError,
    );
  }, [localFloorDifference, setError]);

  const handlePriceChange = useCallback(
    (key: keyof FilterBetween) =>
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseFloat(event.target.value);
        setLocalPrice((prev) => ({ ...prev, [key]: value }));
      },
    [],
  );

  const handleFloorDifferenceChange = useCallback(
    (key: keyof FilterBetween) =>
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseFloat(event.target.value);
        setLocalFloorDifference((prev) => ({ ...prev, [key]: value }));
      },
    [],
  );

  const handleApply = useCallback(() => {
    onApply?.({ price: localPrice, floorDifference: localFloorDifference });
  }, [onApply, localPrice, localFloorDifference]);

  const isApplyDisabled = useMemo(() => {
    return error !== undefined;
  }, [error]);

  return (
    <Sheet {...props}>
      <div className="flex flex-col space-y-4 p-2">
        <div className="flex flex-1 flex-col space-y-2 rounded-lg bg-background-secondary p-3">
          <span className="text-sm font-bold text-text-secondary">
            Unit Price
          </span>
          <div className="mt-4 flex flex-row space-x-2 border-t-0.5 border-border-secondary pt-4">
            <div className="flex flex-1 flex-col space-y-1">
              <span className="text-xs uppercase text-text-tertiary">{`${Currency.DOGE} Min`}</span>
              <Input
                type="number"
                placeholder="0"
                inputMode="decimal"
                value={localPrice.min}
                onChange={handlePriceChange("min")}
                className="bg-background-tertiary"
                onBlur={handleBlurPrice}
              />
            </div>
            <div className="flex flex-1 flex-col space-y-1">
              <span className="text-xs uppercase text-text-tertiary">{`${Currency.DOGE} Max`}</span>
              <Input
                type="number"
                placeholder="1,000"
                inputMode="decimal"
                value={localPrice.max}
                onChange={handlePriceChange("max")}
                className="bg-background-tertiary"
                onBlur={handleBlurPrice}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-1 flex-col space-y-2 rounded-lg bg-background-secondary p-3">
          <span className="text-sm font-bold text-text-secondary">
            Floor Difference
          </span>
          <div className="mt-4 flex flex-row space-x-2 border-t-0.5 border-border-secondary pt-4">
            <div className="flex flex-1 flex-col space-y-1">
              <span className="text-xs uppercase text-text-tertiary">{`% Min`}</span>
              <Input
                type="number"
                placeholder="0"
                inputMode="decimal"
                value={localFloorDifference.min}
                onChange={handleFloorDifferenceChange("min")}
                className="bg-background-tertiary"
                onBlur={handleBlurFloorDifference}
              />
            </div>
            <div className="flex flex-1 flex-col space-y-1">
              <span className="text-xs uppercase text-text-tertiary">{`% Max`}</span>
              <Input
                type="number"
                placeholder="100"
                inputMode="decimal"
                value={localFloorDifference.max}
                onChange={handleFloorDifferenceChange("max")}
                className="bg-background-tertiary"
                onBlur={handleBlurFloorDifference}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col space-y-1">
          <span className="text-center text-xs text-red-600">
            {error || "\u00A0"}
          </span>

          <Button
            variant="inverse"
            size="large"
            onClick={handleApply}
            disabled={isApplyDisabled}
          >
            Apply
          </Button>
        </div>
      </div>
    </Sheet>
  );
};
