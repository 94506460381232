import bitcore from "bitcore-lib";
import * as dogecore from "bitcore-lib-doge";
const { PrivateKey, Transaction } = dogecore;

import { marketplaceApiV2, sdoggsApiV2 } from "@/lib/fetch";
import { TxWallet, TxWithFeeEstimation } from "@/context/wallet/types.ts";
import {
  createDrc20Txs,
  createDuneTxs,
  fundWallet,
  getTotalTransactionFeeInSats,
} from "@/context/wallet/lib/transaction.ts";
import { INSCRIPTION_SATS, LOW_NETWORK_FEE_RATE } from "@/constants.ts";
import { Inscription, Utxo } from "@/types";
import { Drc20Utxo, DunesUtxo } from "@/types/inscription.ts";

const getSellerPsdtHex = async ({
  sellerAddress,
  inscriptionPrice,
  transactionHexs,
  transactionOutput,
  inscription,
  discount,
  type,
}: {
  sellerAddress: string;
  inscriptionPrice: number;
  transactionHexs?: string[];
  transactionOutput?: number;
  inscription?: Inscription;
  discount: boolean;
  type: "drc20" | "doginal";
}) => {
  const { data } = await marketplaceApiV2(true).post("/psdt/seller/create", {
    sellerAddress,
    inscriptionPrice,
    transactionHexs,
    transactionOutput,
    inscription,
    discount,
    type,
  });

  return data.sellerPsdtHex;
};

const MINER_FEE = 30000000;
const cancelSellerPsdtHex = async (
  sellerAddress: string,
  transactionId: string,
  transactionOutput: number,
) => {
  const { data } = await marketplaceApiV2(true).post("/psdt/seller/cancel", {
    sellerAddress,
    transactionId,
    transactionOutput,
    minerFee: MINER_FEE,
  });

  return data.sellerCancelPsdtHex;
};

const cancelMultipleSellerPsdtHex = async (
  sellerAddress: string,
  drc20Utxos: Drc20Utxo[],
): Promise<string[]> => {
  const { data } = await marketplaceApiV2(false).post(
    "/psdt/seller/cancel/multi",
    {
      sellerAddress,
      drc20Utxos,
      minerFee: MINER_FEE,
    },
  );

  return data.sellerCancelPsdtHex;
};

const createDrc20Offer = async (psdtHex: string) => {
  const { data } = await marketplaceApiV2(true).post("/offer/drc20/create", {
    psdtHex,
  });

  return data;
};

const createCollectibleOffer = async ({
  psdtHex,
  inscriptionId,
}: {
  psdtHex: string;
  inscriptionId: string;
}) => {
  const { data } = await marketplaceApiV2(true).post("/offer/doginals/create", {
    psdtHex,
    inscriptionId,
  });

  return data;
};

const getRandomMessage = async (
  address: string,
  isDunesApi: boolean = false,
): Promise<string> => {
  const res = await (
    isDunesApi ? sdoggsApiV2(true) : marketplaceApiV2(true)
  ).get<{
    randomMessage: string;
  }>(`/auth/message?address=${address}`);

  return res.data.randomMessage;
};

const generateAccessToken = async (
  address: string,
  message: string,
  pubkey: string,
  signature: string,
  isDunesApi: boolean = false,
) => {
  return await (isDunesApi ? sdoggsApiV2(true) : marketplaceApiV2(true)).get<{
    accessToken: string;
  }>(
    `auth/token?address=${address}&message=${message}&pubkey=${pubkey}&signature=${encodeURIComponent(
      signature,
    )}`,
  );
};

const getAccessToken = async (
  address: string,
  wallet: TxWallet,
  isDunesApi: boolean = false,
): Promise<string> => {
  const randomMessage = await getRandomMessage(address, isDunesApi);
  if (!randomMessage) throw new Error("Failed to get random message");

  const privateKey = new PrivateKey(wallet.privKey);
  const message = new bitcore.Message(randomMessage);
  const signature = message.sign(new bitcore.PrivateKey(privateKey));

  const response = await generateAccessToken(
    address,
    randomMessage,
    wallet.pubKey,
    signature,
    isDunesApi,
  );
  if (!response.data.accessToken) throw new Error("Failed to get access token");
  return response.data.accessToken;
};

type BuildListDrc20TxsParams = {
  txWallet: TxWallet | undefined;
  address: string | undefined;
  tick: string;
  amt: number;
  transferInscriptionUtxos: Utxo[];
  feePerVByte: number;
  estimateFeesOnly: boolean;
};

const buildListDrc20Txs = async (
  params: BuildListDrc20TxsParams,
): Promise<TxWithFeeEstimation[] | undefined> => {
  const {
    txWallet,
    address,
    tick,
    amt,
    transferInscriptionUtxos,
    feePerVByte,
    estimateFeesOnly,
  } = params;

  if (!txWallet) {
    console.warn("buildListDrc20Txs - no wallet found");
    throw new Error("No wallet found");
  }

  if (!address) {
    console.warn("buildListDrc20Txs - no address given");
    throw new Error("No address given");
  }

  let txs: any[] = [];

  // Clear txs: first send all transfer inscriptions to oneself
  if (transferInscriptionUtxos?.length > 0) {
    const batchSize = 500;
    for (let i = 0; i < transferInscriptionUtxos.length; i += batchSize) {
      const batchInscriptionUtxos = transferInscriptionUtxos.slice(
        i,
        i + batchSize,
      );

      // Build transaction
      const tx = new Transaction();
      batchInscriptionUtxos.forEach((utxo) => {
        tx.from(utxo);
        tx.to(address, INSCRIPTION_SATS);
      });

      // Fund & sign
      const { tx: fundedTx } = fundWallet(
        txWallet,
        tx,
        LOW_NETWORK_FEE_RATE,
        false,
      );
      if (!estimateFeesOnly) {
        txWallet.updateUtxos({ tx: fundedTx });
      }
      txs.push(fundedTx);
    }
  }

  // Build inscription
  const drc20 = {
    p: "drc-20",
    op: "transfer",
    tick: `${tick.toLowerCase()}`,
    amt: amt.toString(),
  };
  const parsedDogeDrc20Tx = JSON.stringify(drc20);
  const hexData = Buffer.from(parsedDogeDrc20Tx).toString("hex");
  const contentType = "text/plain;charset=utf-8";
  const data = Buffer.from(hexData, "hex");

  // Create inscription txs: commit, reveal & send
  const inscribeTxs = createDrc20Txs(
    txWallet,
    contentType,
    data,
    feePerVByte,
    true,
    estimateFeesOnly,
    undefined,
  );

  // Return txs with fee estimations
  txs = txs.concat(inscribeTxs).map((tx) => ({
    tx,
    txFeeInSats: getTotalTransactionFeeInSats(tx, feePerVByte),
  }));

  return txs;
};

type BuildListCollectibleTxsParams = {
  txWallet: TxWallet | undefined;
  address: string | undefined;
  feePerVByte: number;
  inscriptions: Inscription[];
  estimateFeesOnly: boolean;
};

const buildListCollectibleTxs = async (
  params: BuildListCollectibleTxsParams,
): Promise<TxWithFeeEstimation[] | undefined> => {
  const { txWallet, address, inscriptions, feePerVByte } = params;

  if (!txWallet) {
    console.warn("buildListCollectibleTxs - no wallet found");
    throw new Error("No wallet found");
  }

  if (!address) {
    console.warn("buildListCollectibleTxs - no address given");
    throw new Error("No address given");
  }

  const txs = inscriptions.map((inscription) => {
    const tx = new Transaction();

    const utxoFormattedForTx = {
      txid: inscription.utxo.txid,
      vout: inscription.utxo.vout,
      satoshis: inscription.utxo.shibes,
      script: inscription.utxo.script,
    };
    tx.from(utxoFormattedForTx);
    // these transactions should be sent to the address of the user if ever executed.
    // for inscriptions we won't need any pre transaction, but to calculate the fees we will need the transaction
    tx.to(address, inscription.utxo.shibes);

    // Fund & sign
    const { tx: fundedTx } = fundWallet(
      txWallet,
      tx,
      LOW_NETWORK_FEE_RATE,
      false,
    );

    // Never execute updateUtxos here, because we will need it for calculating psbt tx fees, only
    // if (!estimateFeesOnly) {
    //   txWallet.updateUtxos({tx:fundedTx});
    // }

    return fundedTx;
  });

  // Return txs with fee estimations
  return txs.map((tx) => ({
    tx: null,
    txFeeInSats: getTotalTransactionFeeInSats(tx, feePerVByte),
  }));
};

// SDOGGS enhancement
const getDuneSellerPsdtHex = async ({
  sellerAddress,
  inscriptionPrice,
  transactionHexs,
  transactionOutput,
  txHashes,
  inscription,
  discount,
  type,
}: {
  sellerAddress: string;
  inscriptionPrice: number;
  transactionHexs?: string[];
  transactionOutput?: number;
  txHashes?: string[];
  inscription?: Inscription;
  discount: boolean;
  type: "dunes";
}) => {
  const { data } = await sdoggsApiV2().post("/psdt/seller/create", {
    sellerAddress,
    inscriptionPrice,
    transactionHexs,
    transactionOutput,
    txHashes,
    inscription,
    discount,
    type,
  });

  return data.sellerPsdtHex;
};

type BuildListDuneTxsParams = {
  txWallet: TxWallet | undefined;
  address: string | undefined;
  tick: string;
  amt: number;
  duneUtxos: DunesUtxo[];
  feePerVByte: number;
  estimateFeesOnly: boolean;
};

const buildListDuneTxs = async (
  params: BuildListDuneTxsParams,
): Promise<TxWithFeeEstimation[] | undefined> => {
  const { txWallet, address, amt, duneUtxos, feePerVByte, estimateFeesOnly, tick } =
    params;

  if (!txWallet) {
    console.warn("buildListDuneTxs - no wallet found");
    throw new Error("No wallet found");
  }

  if (!address) {
    console.warn("buildListDuneTxs - no address given");
    throw new Error("No address given");
  }

  const filteredDuneUtxos = duneUtxos.filter((utxo) => utxo.dunes.dune == tick);

  if (!filteredDuneUtxos) {
    throw new Error("No dunes for transaction found")
  }

  const { id } = filteredDuneUtxos[0]?.dunes || null;

  if (!id) throw "Couldn't get id of dune";

  let txs: any[] = [];

  const dunesTxs = await createDuneTxs(
    txWallet,
    feePerVByte,
    true,
    filteredDuneUtxos,
    estimateFeesOnly,
    amt,
    id,
  );

  // Return txs with fee estimations
  txs = txs.concat(dunesTxs).map((tx) => ({
    tx,
    txFeeInSats: getTotalTransactionFeeInSats(tx, feePerVByte) || 0,
  }));

  return txs;
};

const createDuneOffer = async (psdtHex: string, tick: string, amt: number) => {
  const { data } = await sdoggsApiV2().post("/offer/dunes/create", {
    psdtHex,
    tick,
    amount: amt,
  });

  return data;
};

export {
  getSellerPsdtHex,
  cancelSellerPsdtHex,
  createDrc20Offer,
  getAccessToken,
  buildListDrc20Txs,
  cancelMultipleSellerPsdtHex,
  buildListCollectibleTxs,
  createCollectibleOffer,
  // Dunes
  getDuneSellerPsdtHex,
  buildListDuneTxs,
  createDuneOffer,
};
