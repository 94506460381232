import React, { useEffect } from "react";

import { Button, Input } from "@/components";
import { isValidDogecoinAddress } from "@/utility";

import { Sheet, SheetProps } from "../base";

interface ModalActivitySearchProps extends SheetProps {
  search: string;
  onApply: (search: string) => void;
}

// Search for: Seller Wallet Address | Inscription Number | Amount
export const ModalActivitySearch: React.FC<ModalActivitySearchProps> = ({
  search,
  onApply,
  ...props
}) => {
  const [localSearch, setLocalSearch] = React.useState<string>(search);
  const [error, setError] = React.useState<string | undefined>();

  const handleSearchChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setLocalSearch(event.target.value);
    },
    [],
  );

  const handleApply = React.useCallback(() => {
    onApply?.(localSearch);
  }, [onApply, localSearch]);

  useEffect(() => {
    if (
      localSearch.trim().length === 0 ||
      isValidDogecoinAddress(localSearch)
    ) {
      setError(undefined);
    } else {
      setError("Invalid wallet address");
    }
  }, [localSearch]);

  useEffect(() => {
    setLocalSearch(search);
  }, [search]);

  return (
    <Sheet {...props}>
      <div className="flex flex-col space-y-4 p-2">
        <Input
          className="h-12"
          value={localSearch}
          onChange={handleSearchChange}
          placeholder="Wallet"
        />
        <span className="text-center text-2xs text-red-500">
          {error || "\u00A0"}
        </span>
        <Button
          variant="inverse"
          size="large"
          onClick={handleApply}
          disabled={!!error}
        >
          Apply
        </Button>
      </div>
    </Sheet>
  );
};
