import { motion } from "framer-motion";

import { cn } from "@/lib/utils";

interface SeparatorProps {
  title?: string;
  className?: string;
}

export const Separator: React.FC<SeparatorProps> = ({ title, className }) => (
  <motion.div
    className={cn(
      "flex w-full flex-row items-center justify-center px-1",
      className,
    )}
  >
    <div className="h-0.125 w-full rounded-full bg-border-tertiary/50" />
    {title === undefined ? null : (
      <>
        <span className="mx-4 text-center text-lg font-bold text-text-primary">
          {title}
        </span>
        <div className="h-0.125 w-full rounded-full bg-border-tertiary/50" />
      </>
    )}
  </motion.div>
);
