import * as bitcoin from "bitcoinjs-lib";
import * as dogecore from "bitcore-lib-doge";
import * as ecc from "tiny-secp256k1";

import { LOW_NETWORK_FEE_RATE } from "@/constants";

import {
  createDrc20Txs,
  fundWallet,
  getTotalTransactionFeeInSats,
} from "@/context/wallet/lib/transaction.ts";
import { Utxo } from "@/types/transaction";
import { TxWallet, TxWithFeeEstimation } from "@/context/wallet/types.ts";

const { Transaction } = dogecore;
bitcoin.initEccLib(ecc);

type BuildSendDrc20TxsParams = {
  txWallet: TxWallet | undefined;
  address: string | undefined;
  receiverAddress: string;
  tick: string;
  amt: number;
  transferInscriptionUtxos: Utxo[];
  isClearRequired: boolean;
  feePerVByte: number;
  estimateFeesOnly: boolean;
};

const buildSendDrc20Txs = async (
  buildSendDrc20TxsParams: BuildSendDrc20TxsParams,
): Promise<TxWithFeeEstimation[] | undefined> => {
  const {
    txWallet,
    address,
    receiverAddress,
    tick,
    amt,
    transferInscriptionUtxos,
    isClearRequired,
    feePerVByte,
    estimateFeesOnly,
  } = buildSendDrc20TxsParams;

  if (!txWallet) {
    console.warn("buildSendDrc20s - no wallet found");
    throw new Error("No wallet found");
  }

  if (!address) {
    console.warn("buildSendDrc20s - no address given");
    throw new Error("No no address given");
  }

  // array to store all txs that we will broadcast at the end
  let txs = [];

  // 3. clear txs: first send all transfer inscriptions to oneself
  if (isClearRequired && transferInscriptionUtxos?.length > 0) {
    console.log("buildSendDrc20s - clearing...");
    const batchSize = 500;
    for (let i = 0; i < transferInscriptionUtxos.length; i += batchSize) {
      const batchInscriptionUtxos = transferInscriptionUtxos.slice(
        i,
        i + batchSize,
      );

      // build transaction
      const tx = new Transaction();
      batchInscriptionUtxos.forEach((utxo) => {
        // input and output amount has to be same for inscriptions
        tx.from(utxo);
        tx.to(address, 100000);
      });

      // fund & sign
      const { tx: fundedTx } = fundWallet(
        txWallet,
        tx,
        LOW_NETWORK_FEE_RATE,
        false,
      );
      // if (!estimateFeesOnly) {
      //   txWallet.updateUtxos({ tx: fundedTx });
      // }
      txs.push(fundedTx);
    }
  }

  // 4. build inscription
  const drc20 = {
    p: "drc-20",
    op: "transfer",
    tick: `${tick.toLowerCase()}`,
    amt: amt.toString(),
  };
  const parsedDogeDrc20Tx = JSON.stringify(drc20);
  const hexData = Buffer.from(parsedDogeDrc20Tx).toString("hex");
  const contentType = "text/plain;charset=utf-8";
  const data = Buffer.from(hexData, "hex");

  // 5. create 3 inscription txs: commit, reveal & send
  const inscribeTxs = createDrc20Txs(
    txWallet,
    contentType,
    data,
    feePerVByte,
    // check if true or false???
    true,
    estimateFeesOnly,
    receiverAddress,
  );

  txs = txs.concat(inscribeTxs).map((tx) => ({
    tx,
    txFeeInSats: getTotalTransactionFeeInSats(tx, feePerVByte),
  }));

  return txs;
};

export { buildSendDrc20Txs };
