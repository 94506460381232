import {
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import {
  DRC20_LISTINGS_PAGINATION_LIMIT,
  ONE_DOGE_IN_SHIBES,
  PAGINATION_LIMIT,
} from "@/constants";
import { useCurrency, useDogePrice, useRefreshKey } from "@/contextHooks";
import { marketplaceApiV2 } from "@/lib/fetch";
import { NumberFormatType, formatNumber, formatPrice } from "@/lib/numbers";
import * as Sentry from "@sentry/react";
import {
  Currency,
  DRC20Sale,
  DRC20Token,
  Drc20Data,
  Sorting,
  TimeFrames,
} from "@/types";
import { WatchlistItem } from "@/types/watchlist.ts";
import {
  calculateTokenValueInDoge,
  calculateTokenValueInUSD,
  ensureUniqueTableData,
  handleError,
  mapHoursToRange,
} from "@/utility";

import {
  Drc20WatchlistContext,
  WatchlistProvider,
} from "./Drc20WatchListContext.ts";
import { fetchDrc20Tokens } from "./helpers/fetchDrc20Tokens";
import { filterTokenByVolumeRule } from "@/utility/filterTokenByVolumeRule.ts";

enum MoverKey {
  ChangePercent24h = "changePercent24h",
  ChangePercent7d = "changePercent7d",
  ChangePercent30d = "changePercent30d",
  ChangePercent = "changePercent",
}

type Drc20DataWithMoverKey = Drc20Data & { moverKey: MoverKey };

type Drc20SalesList = {
  activities: Array<DRC20Sale>;
  total: number;
};

type Drc20List = {
  list: Array<Drc20Data>;
  total: number;
  DOGEprice: number;
};

export type TopBase = {
  title: string;
  value: string;
};

export type TopMover = TopBase & {
  id: string;
  percentage: string;
  percentageTimeframe: string;
  priceInUSD: string;
  priceInDOGE: string;
};

export type TopSales = TopBase & {
  id: string;
  sales: string;
  numSales?: number;
  timeframe?: string;
  priceInUSD: string;
  priceInDOGE: string;
};

export type RecentSales = TopBase & {
  id: string;
  sales: string;
  timeframe?: string;
  priceInUSD: string;
  priceInDOGE: string;
  createdAt: string;
  inscriptionId: string;
};

interface Drc20TokenListContextType {
  // table data
  isLoadingTableData: boolean;
  isLoadingMoreTableData: boolean;
  hasMoreTableData: boolean;
  tableData: DRC20Token[];
  tableOffset: number;
  debouncedSetTableOffset: (offset: number) => void;
  loadTableData: (params?: {
    history: TimeFrames;
    sortParam: Sorting;
    currency: Currency;
    limit: number;
  }) => void;

  // top movers and recent sales
  isMoversLoading: boolean;
  topMovers: TopMover[];
  topSales: TopSales[];
  recentSales: RecentSales[];
}

type Drc20TokenListProviderProps = {
  children: ReactNode;
};

const Drc20TokenListContext = createContext<Drc20TokenListContextType>({
  // table data
  isLoadingTableData: false,
  isLoadingMoreTableData: false,
  hasMoreTableData: true,
  tableData: [],
  tableOffset: 0,
  debouncedSetTableOffset: () => {},
  loadTableData: () => {},

  // top movers and recent sales
  isMoversLoading: false,
  topMovers: [],
  topSales: [],
  recentSales: [],
});

const processTrendingList = (
  list: Drc20Data[],
  dogePrice: number,
  currency: Currency,
) => {
  return list.map((item) => {
    const { tick: id, floorPrice, sales7d, sales30d, sales24h } = item;

    const formattedId = id.toUpperCase();
    const priceInUSD = calculateTokenValueInUSD(1, floorPrice || 0, dogePrice);
    const priceInDOGE = calculateTokenValueInDoge(1, floorPrice || 0);

    const sales24hFormatted = formatNumber({
      value: sales24h,
      type: NumberFormatType.Large_Number,
    });

    const sales7dFormatted = formatNumber({
      value: sales7d,
      type: NumberFormatType.Large_Number,
    });

    const sales30dFormatted = formatNumber({
      value: sales30d,
      type: NumberFormatType.Large_Number,
    });

    const salesFormatted =
      sales24h > 0
        ? sales24hFormatted
        : sales7d > 0
          ? sales7dFormatted
          : sales30dFormatted;
    const sales = sales24h > 0 ? sales24h : sales7d > 0 ? sales7d : sales30d;
    const timeframe = sales24h > 0 ? "24h" : sales7d > 0 ? "7D" : "30D";

    return {
      sales: `${salesFormatted} SALE${sales === 1 ? "" : "S"}`,
      numSales: sales,
      id: formattedId,
      title: formattedId,
      timeframe,
      value:
        currency === Currency.DOGE
          ? `${Currency.DOGE}${priceInDOGE}`
          : `${Currency.USD}${priceInUSD}`,
      priceInUSD: `${Currency.USD}${priceInUSD}`,
      priceInDOGE: `${Currency.DOGE}${priceInDOGE}`,
    };
  });
};

const processMoversList = (
  list: Drc20DataWithMoverKey[],
  dogePrice: number,
  currency: Currency,
) => {
  return list.map((item) => {
    const { tick: id, floorPrice, moverKey } = item;

    const formattedId = id.toUpperCase();
    const priceInUSD = calculateTokenValueInUSD(1, floorPrice || 0, dogePrice);
    const priceInDOGE = calculateTokenValueInDoge(1, floorPrice || 0);
    const changePercentFormatted = formatNumber({
      value: item[moverKey] || 0,
      type: NumberFormatType.Percentage,
    });
    const percentageTimeframe =
      moverKey === MoverKey.ChangePercent24h
        ? ""
        : moverKey === MoverKey.ChangePercent7d
          ? "7D"
          : moverKey === MoverKey.ChangePercent30d
            ? "30D"
            : "ALL";

    return {
      id: formattedId,
      title: formattedId,
      percentage: changePercentFormatted,
      percentageTimeframe,
      priceInUSD: `${Currency.USD}${priceInUSD}`,
      priceInDOGE: `${Currency.DOGE}${priceInDOGE}`,
      value:
        currency === Currency.DOGE
          ? `${Currency.DOGE}${priceInDOGE}`
          : `${Currency.USD}${priceInUSD}`,
    };
  });
};

const mapTokenToTopMover = (
  data: DRC20Token,
  dogePrice: number,
  currency?: Currency,
): WatchlistItem => {
  const { id, change, price, sales, currentSupply, rank } = data;

  const formattedId = id.toUpperCase();
  // To format the price with small number is needed to calculate the mcaps correctly, as formatting
  // with price gives a string with html in it.
  const priceInUSD = parseFloat(
    calculateTokenValueInUSD(
      1,
      price,
      dogePrice,
      NumberFormatType.Small_Number,
    ),
  );
  const priceInUSDFormatted = formatPrice(priceInUSD);

  const priceInDOGE = parseFloat(
    calculateTokenValueInDoge(1, price, NumberFormatType.Small_Number),
  );
  const priceInDOGEFormatted = formatPrice(priceInDOGE);

  const percentage = formatNumber({
    value: change,
    type: NumberFormatType.Percentage,
  });

  const mcapInUSD =
    currentSupply && currentSupply > 0
      ? `$${formatPrice(currentSupply * priceInUSD)}`
      : "";

  const mcapInDogeUnformatted =
    currentSupply && currentSupply > 0
      ? currentSupply * (price / ONE_DOGE_IN_SHIBES)
      : 0;

  const totalSales = formatNumber({
    value: sales,
    type: NumberFormatType.Large_Number,
  });

  return {
    mcapInUSD,
    mcapInDogeUnformatted,
    rank,
    sales: totalSales,
    id: id,
    title: formattedId,
    percentage: percentage,
    priceInUSD: `${Currency.USD}${priceInUSDFormatted}`,
    priceInDOGE: `${Currency.DOGE}${priceInDOGEFormatted}`,
    value:
      currency === Currency.DOGE
        ? `${Currency.DOGE}${priceInDOGEFormatted}`
        : `${Currency.USD}${priceInUSDFormatted}`,
  };
};

const processSalesList = (
  list: DRC20Sale[],
  dogePrice: number,
  currency: Currency,
) => {
  // If the list is empty, return an empty array
  if (!list.length) return [];

  // Sort by the most recent sale via createdAt (string date)
  const sortedList = list.sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
  );

  return sortedList.map((item) => {
    const { tick: id, price, amount, createdAt, inscriptionId } = item;

    const formattedId = id.toUpperCase();
    const formattedValueInDOGE = calculateTokenValueInDoge(amount, price);
    const formattedValueInUSD = calculateTokenValueInUSD(
      amount,
      price,
      dogePrice,
    );

    const formattedAmount = formatNumber({
      value: amount,
      type: NumberFormatType.Large_Number,
    });

    return {
      createdAt,
      inscriptionId,
      id: formattedId,
      title: formattedId,
      sales: `${formattedAmount} ${formattedId}`,
      timeframe: `${mapHoursToRange(createdAt)}`,
      value:
        currency === Currency.DOGE
          ? `${Currency.DOGE}${formattedValueInDOGE}`
          : `${Currency.USD}${formattedValueInUSD}`,
      priceInUSD: `${Currency.USD}${formattedValueInUSD}`,
      priceInDOGE: `${Currency.DOGE}${formattedValueInDOGE}`,
    };
  });
};

const Drc20TokenListProvider = ({ children }: Drc20TokenListProviderProps) => {
  const { currency } = useCurrency();
  const { dogePrice } = useDogePrice();
  const hasMoversLoadedInitially = useRef(false);

  const [isLoadingTableData, setIsLoadingTableData] = useState<boolean>(false);
  const [isLoadingMoreTableData, setIsLoadingMoreTableData] =
    useState<boolean>(false);
  const [tableData, setTableData] = useState<DRC20Token[]>([]);
  const [tableOffset, setTableOffset] = useState<number>(0);
  const [hasMoreTableData, setHasMoreTableData] = useState<boolean>(true);

  const [isMoversLoading, setIsMoversLoading] = useState<boolean>(false);
  // this is the state for the data we fetch, this data gets mapped/processed into topSales, topMovers, recentSales
  const [topMoversList, setTopMoversList] = useState<Drc20DataWithMoverKey[]>(
    [],
  );
  const [recentSalesList, setRecentSalesList] = useState<DRC20Sale[]>([]);
  const [trendingList, setTrendingList] = useState<Drc20Data[]>([]);
  const [topSales, setTopSales] = useState<TopSales[]>([]); // The highest 7 day number of sales, in descending order
  const [topMovers, setTopMovers] = useState<TopMover[]>([]); // The highest gain from the past 24 hours, in descending order
  const [recentSales, setRecentSales] = useState<RecentSales[]>([]); // The most recent drc20 sale on the platform, in chronological order (most recent on the far left).

  const { refreshKey } = useRefreshKey();

  const loadTableData = useCallback(
    async (params = {}) => {
      const { ...fetchParams } = {
        // default params
        history: TimeFrames["7d"],
        sortParam: Sorting.volume30d,
        currency: Currency.USD,
        limit: DRC20_LISTINGS_PAGINATION_LIMIT,
        // passed params
        ...params,
      };

      if (!hasMoreTableData) {
        return;
      }

      try {
        setIsLoadingMoreTableData(true);
        const newData = await fetchDrc20Tokens({
          offset: tableOffset,
          ...fetchParams,
        });
        setHasMoreTableData(newData.length === DRC20_LISTINGS_PAGINATION_LIMIT);
        setTableData(ensureUniqueTableData(newData, "ticker"));

        // @todo: check why we could need that...
        // setTableData((prevTableData) => {
        // if (
        //   dogePrice === FALLBACK_DOGE_PRICE &&
        //   dogePriceAwaits.current < 2
        // ) {
        //   dogePriceAwaits.current += 1;
        //   return [];
        // }

        // const data = [...prevTableData, ...newData].sort(
        //   (a, b) => a.rank - b.rank,
        // );
        //
        // if (!data.find((item) => item.rank === 1)) {
        //   loadTableData({ ...params, offset: 0 });
        //   return prevTableData;
        // }

        // return data;
        // });
      } catch (e: Error | unknown) {
        handleError(e);
      } finally {
        setIsLoadingMoreTableData(false);
        setIsLoadingTableData(false);
      }
    },

    [hasMoreTableData, tableOffset],
  );

  // todo: move to a hook
  const fetchActivityData = useCallback(
    async (
      maxRetries = 3,
      cachebreaker: boolean = false,
      sortParam: Sorting,
      offset: number,
      limit: number,
    ) => {
      let attempt = 0;
      while (attempt < maxRetries) {
        try {
          const response = await marketplaceApiV2(cachebreaker).get<Drc20List>(
            "/drc20/list/activity",
            {
              params: {
                offset,
                limit,
                sortOrder: "desc",
                sortParam,
                action: "sale",
              },
            },
          );
          return response.data?.list || [];
        } catch (e) {
          attempt++;
          if (attempt >= maxRetries) {
            Sentry.captureException(e);
            return [];
          }
        }
      }
      return [];
    },
    [],
  );

  // todo: once https://app.clickup.com/t/86b1de22h is implemented, this whole function can be replaced by one api call
  const getTopChangePercentItems = useCallback(
    async (onlyPositive?: boolean) => {
      // we want to display 20 items
      const targetAmount = 20;

      const limit = 25; // this is a good number to fetch at once
      let offset = 0;
      const seenTicks = new Set<string>();
      const top24h: Drc20DataWithMoverKey[] = [];
      const top7d: Drc20DataWithMoverKey[] = [];
      const top30d: Drc20DataWithMoverKey[] = [];
      const topOverall: Drc20DataWithMoverKey[] = [];

      while (
        top24h.length + top7d.length + top30d.length + topOverall.length <
        targetAmount
      ) {
        if (offset == limit * 3) {
          break;
        }

        const data = await fetchActivityData(
          3,
          false,
          Sorting.top,
          offset,
          limit,
        );
        if (data.length === 0) {
          break; // Break if no data is fetched
        }

        const hasPositiveChange = (changePercent: number | undefined) =>
          changePercent !== undefined &&
          (onlyPositive ? changePercent > 0 : true);

        for (const item of data) {
          if (!seenTicks.has(item.tick)) {
            const filterTick24h = filterTokenByVolumeRule(item.volume24h);
            const filterTick7d = filterTokenByVolumeRule(item.volume7d);
            const filterTick30d = filterTokenByVolumeRule(item.volume30d);
            const filterTickOverall = filterTokenByVolumeRule(item.volume);

            if (hasPositiveChange(item.changePercent24h) && !filterTick24h) {
              top24h.push({ ...item, moverKey: MoverKey.ChangePercent24h });
            } else if (
              hasPositiveChange(item.changePercent7d) &&
              !filterTick7d
            ) {
              top7d.push({ ...item, moverKey: MoverKey.ChangePercent7d });
            } else if (
              hasPositiveChange(item.changePercent30d) &&
              !filterTick30d
            ) {
              top30d.push({ ...item, moverKey: MoverKey.ChangePercent30d });
            } else if (
              hasPositiveChange(item.changePercent) &&
              !filterTickOverall
            ) {
              topOverall.push({ ...item, moverKey: MoverKey.ChangePercent });
            }

            seenTicks.add(item.tick);
          }
        }

        if (
          top24h.length + top7d.length + top30d.length + topOverall.length >=
          targetAmount
        ) {
          console.log("getTopChangePercentItems -> top24h", top24h.length);
          console.log("getTopChangePercentItems -> top7d", top7d.length);
          console.log("getTopChangePercentItems -> top30d", top30d.length);
          console.log(
            "getTopChangePercentItems -> topOverall",
            topOverall.length,
          );
          break;
        }

        if (data.length < limit) {
          break;
        }

        offset += limit;
      }

      // Sorting logic
      const sortedTop24h = [...top24h].sort(
        (a, b) => (b.changePercent24h || 0) - (a.changePercent24h || 0),
      );
      const sortedTop7d = [...top7d].sort(
        (a, b) => (b.changePercent7d || 0) - (a.changePercent7d || 0),
      );
      const sortedTop30d = [...top30d].sort(
        (a, b) => (b.changePercent30d || 0) - (a.changePercent30d || 0),
      );
      const sortedTopOverall = [...topOverall].sort(
        (a, b) => (b.changePercent || 0) - (a.changePercent || 0),
      );

      const combinedSorted = [
        ...sortedTop24h,
        ...sortedTop7d,
        ...sortedTop30d,
        ...sortedTopOverall,
      ];

      return [...combinedSorted].slice(0, limit);
    },
    [fetchActivityData],
  );

  // todo: move to a hook
  const fetchSalesData = useCallback(
    async (history: string, cachebreaker: boolean = false) => {
      try {
        const response = await marketplaceApiV2(
          cachebreaker,
        ).get<Drc20SalesList>("offer/drc20/activity", {
          params: {
            offset: 0,
            limit: PAGINATION_LIMIT,
            history,
            sortOrder: "desc",
            sortParam: "recent",
            action: "sale",
          },
        });
        return response.data.activities || [];
      } catch (e) {
        Sentry.captureException(e);
        return [];
      }
    },
    [],
  );

  const fetchPerformersData = useCallback(
    async (cachebreaker: boolean = false) => {
      try {
        setIsMoversLoading(true);
        const [activitiesTop, activitiesSales30d, recentSales] =
          await Promise.all([
            getTopChangePercentItems(true),
            fetchActivityData(
              3,
              cachebreaker,
              Sorting.sales30d,
              0,
              PAGINATION_LIMIT,
            ),
            fetchSalesData("30d", false),
          ]);
        setTopMoversList(activitiesTop);
        // filter to only show items with at least one sale in the last 7 days or 24 hours
        // https://github.com/the-doge-labs/marketplace-pwa/issues/376
        setTrendingList(
          activitiesSales30d.filter(
            (item) =>
              Number(item.sales7d) > 0 ||
              Number(item.sales24h) > 0 ||
              Number(item.sales30d) > 0,
          ),
        );
        setRecentSalesList(recentSales);
      } catch (e: Error | unknown) {
        handleError(e);
      } finally {
        setIsMoversLoading(false);
      }
    },
    [fetchSalesData, fetchActivityData, getTopChangePercentItems],
  );

  // fetch data initially or whenever offset changes -> endless scroll
  useEffect(() => {
    const fetchData = async () => {
      await loadTableData({ cachebreaker: false });
    };

    fetchData();
  }, [tableOffset, loadTableData]);

  // fetch movers data initially -> should we refetch on an interval?
  useEffect(() => {
    const fetchData = async () => {
      if (!hasMoversLoadedInitially.current) {
        await fetchPerformersData(false);
        hasMoversLoadedInitially.current = true;
      }
    };

    fetchData();
  }, [fetchPerformersData]);

  // remap movers data whenever the listActivities, listRecentSales, dogePrice or currency change
  useEffect(() => {
    if (topMoversList.length > 0) {
      const mappedTwentyFourHours = processMoversList(
        topMoversList,
        dogePrice,
        currency,
      );
      setTopMovers(mappedTwentyFourHours);
    }

    if (trendingList.length > 0) {
      const mappedToDays = processTrendingList(
        trendingList,
        dogePrice,
        currency,
      );

      mappedToDays.sort((a, b) => {
        // Sort by timeframe first: "24H" > "7D" > "30D"
        const timeframes = ["24H", "7D", "30D"];

        const indexA = timeframes.indexOf(a.timeframe);
        const indexB = timeframes.indexOf(b.timeframe);

        if (indexA !== indexB) {
          return indexA - indexB; // Sort by timeframe order (lower index comes first)
        }

        // If timeframes are the same, sort by sales within that timeframe
        return b.numSales - a.numSales; // Descending order by sales
      });

      setTopSales(mappedToDays);
    }

    if (recentSalesList.length > 0) {
      const mappedRecentSales = processSalesList(
        recentSalesList,
        dogePrice,
        currency,
      );

      setRecentSales(mappedRecentSales);
    }
  }, [recentSalesList, topMoversList, trendingList, currency, dogePrice]);

  useEffect(() => {
    if (refreshKey > 0) {
      setTableData([]);
      setTableOffset(0);
      loadTableData({ offset: 0, cachebreaker: false });
      // also loads sales and activity data
      fetchPerformersData(false);
    }
  }, [refreshKey]);

  return (
    <Drc20TokenListContext.Provider
      value={{
        isLoadingTableData,
        isLoadingMoreTableData: !isLoadingTableData && isLoadingMoreTableData,
        hasMoreTableData,
        tableData,
        tableOffset,
        debouncedSetTableOffset: setTableOffset, //@todo rename to setTableOffset
        loadTableData,
        isMoversLoading,
        topMovers,
        topSales,
        recentSales,
      }}
    >
      <WatchlistProvider
        watchlistName="watchlist-drc20"
        fetchTokens={fetchDrc20Tokens}
        mapTokenToItem={mapTokenToTopMover}
        watchlistKey="id"
        sortParam="-fdv"
        Context={Drc20WatchlistContext}
      >
        {children}
      </WatchlistProvider>
    </Drc20TokenListContext.Provider>
  );
};

export { Drc20TokenListProvider, Drc20TokenListContext };
