import { AnimatePresence } from "framer-motion";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { FeeType } from "@/context/wallet/calculateNetworkFees.ts";
import { useCurrency, useDogePrice, useTxWallet } from "@/contextHooks";
import { InscriptionType } from "@/types";
import { calculateItemBuyFees, processOffers } from "@/utility";

import {
  DEFAULT_ERROR,
  ErrorView,
  ModalNavigation,
  Sheet,
  SuccessView,
} from "../base";
import { BuyTransactionStep } from "./constants";
import { BuyView } from "./views";
import { ModalBuyProps } from "@/types/buy.ts";
import { ALPHA_DISCOUNT_DUNES } from "@/constants.ts";

export const ModalBuyDune: React.FC<ModalBuyProps> = ({
  onClose,
  offers,
  isVisible,
  refetchListings,
  removeFromListings,
  ...props
}) => {
  const { currency } = useCurrency();
  const { dogePrice } = useDogePrice();
  const { buyItem, calculateNetworkFees } = useTxWallet();

  // Local State
  const [localIsVisible, setLocalIsVisible] = useState<boolean>(isVisible);
  const [txFeeInSats, setTxFeeInSats] = useState(0);
  const [error, setError] = useState(DEFAULT_ERROR);
  const [transactionStep, setTransactionStep] = useState(
    BuyTransactionStep.BUY,
  );

  useEffect(() => {
    setLocalIsVisible(isVisible);
  }, [isVisible]);

  // Handle buy error
  const handleBuyError = useCallback((title: string, description: string) => {
    setError({ title, description });
    setTransactionStep(BuyTransactionStep.ERROR);
    setLocalIsVisible(true);
  }, []);

  // Build the transaction and set the buyerTx and sellerPsdtHex
  useEffect(() => {
    if (
      !offers?.length ||
      calculateNetworkFees.isLoading ||
      calculateNetworkFees.isError ||
      calculateNetworkFees.isSuccess
    )
      return;

    const offerIds = offers.map((offer) => offer.offerId);
    calculateNetworkFees.execute(FeeType.BUYDUNES, {
      offerIds: offerIds,
    });
  }, [offers]);

  // Close the modal
  const handleClose = useCallback(() => {
    if (buyItem.isLoading) return;

    // reset values
    setError(DEFAULT_ERROR);
    setTransactionStep(BuyTransactionStep.BUY);
    buyItem.reset();

    setLocalIsVisible(false);
    onClose?.();
  }, [buyItem, onClose]);

  useEffect(() => {
    if (calculateNetworkFees.isError && calculateNetworkFees.error) {
      // @todo: this needs to go into a custom hook for error handling calculateNetworkFees

      //ensure the selected listings are updated after an error
      if (calculateNetworkFees.error instanceof Map) {
        for (const offerId of calculateNetworkFees.error.keys()) {
          removeFromListings(offerId);
        }
      }

      handleBuyError(
        "Error",
        calculateNetworkFees.error instanceof Map
          ? Array.from(calculateNetworkFees.error.entries())
              .map(([key, value]) => `${key}: ${value}`)
              .join(", ")
          : (calculateNetworkFees.error as string) ||
              "An error occurred while calculating your fees.",
      );
    }
  }, [
    calculateNetworkFees.error,
    calculateNetworkFees.isError,
    handleBuyError,
    removeFromListings,
  ]);

  useEffect(() => {
    if (calculateNetworkFees.isSuccess && calculateNetworkFees.totalFeeInSats) {
      setTxFeeInSats(calculateNetworkFees.totalFeeInSats);
    }
  }, [calculateNetworkFees.isSuccess, calculateNetworkFees.totalFeeInSats]);

  // Handle buy success
  const handleBuySuccess = useCallback(() => {
    setTransactionStep(BuyTransactionStep.SUCCESS);
    // Ensure the list is updated after a successful buy
    setTimeout(() => refetchListings(true, true), 3000);
  }, [refetchListings]);

  // Handle buy transaction
  const handleBuy = useCallback(async () => {
    if (offers?.length) {
      const offerIds = offers.map((offer) => offer.offerId);
      await buyItem.execute(offerIds, InscriptionType.DUNE);
    }
  }, [offers, buyItem]);

  // On success, show the success view
  useEffect(() => {
    if (buyItem.isSuccess) {
      handleBuySuccess();
    }
  }, [handleBuySuccess, buyItem.isSuccess]);

  // On error, show the error view
  useEffect(() => {
    if ((buyItem.isError && buyItem.error) || buyItem.errors.length > 0) {
      let message = "An error occurred while processing your request.";
      if (buyItem.errors.length > 0) {
        message = `${message}, ${buyItem.errors.join(",")}`;
      }

      if (buyItem.error) {
        message = `${message}, ${buyItem.error as string}`;
      }

      handleBuyError("Error", message);
    }
  }, [handleBuyError, buyItem.isError, buyItem.error, buyItem.errors]);

  // Displayed fees
  const fees = useMemo(() => {
    return calculateItemBuyFees({
      offers,
      txFeeInSats,
      dicountAlpha: ALPHA_DISCOUNT_DUNES,
    });
  }, [offers, txFeeInSats]);

  // Displayed values
  const { pay, receive, tick } = useMemo(() => {
    const { pay, receive, tick } = processOffers({
      offers,
      dogePrice,
      currency,
    });

    return {
      pay: { value: pay.value, valueInUSD: pay.valueInUSD },
      receive: {
        value: receive.value,
        valueInUSD: receive.valueInUSD,
        valueDetails: receive.valueDetails,
      },
      tick,
    };
  }, [offers, dogePrice, currency]);

  return (
    <Sheet
      withHeader={false}
      isVisible={localIsVisible}
      classNameContent="flex flex-col text-text-primary h-full md:min-h-2/3 md:max-h-5/6 md:aspect-auto rounded-none pt-safe-offset-4 pb-safe-offset-4"
      onClose={handleClose}
      {...props}
    >
      <ModalNavigation
        title="Review Order"
        iconBack="close"
        onBack={handleClose}
      />
      <AnimatePresence>
        <div className="relative flex flex-1 flex-col">
          {transactionStep === BuyTransactionStep.BUY &&
            fees.network &&
            fees.taker && (
              <BuyView
                pay={pay}
                tick={tick}
                receive={receive}
                handleBuy={handleBuy}
                loading={
                  (buyItem.isLoading as boolean) ||
                  (calculateNetworkFees.isLoading as boolean)
                }
                fees={{
                  network: fees.network,
                  taker: fees.taker,
                  takerDiscountAlpha: fees.takerDiscountAlpha,
                  total: fees.total,
                  totalSellingPriceWithoutFees:
                    fees.totalSellingPriceWithoutFees,
                }}
              />
            )}
          {transactionStep === BuyTransactionStep.SUCCESS && (
            <SuccessView handleContinue={handleClose} />
          )}
          {transactionStep === BuyTransactionStep.ERROR && (
            <ErrorView
              handleContinue={handleClose}
              title={error.title}
              description={error.description}
            />
          )}
        </div>
      </AnimatePresence>
    </Sheet>
  );
};
