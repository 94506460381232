import { useContext } from "react";

import { WalletManagementContext } from "@/context/WalletManagementProvider";

export const useWalletManagement = () => {
  const walletManagementContext = useContext(WalletManagementContext);

  if (!walletManagementContext) {
    throw new Error("useWallet must be used within a WalletManagementProvider");
  }

  return walletManagementContext;
};
