import { useCallback, useState } from "react";

import { ACCOUNT_OVERALL_VOLUME_RANGE, ONE_DOGE_IN_SHIBES } from "@/constants";
import { useDogePrice } from "@/contextHooks";
import { marketplaceApiV2 } from "@/lib/fetch";
import {
  AllDunesData,
  ChangeRange,
  CollectionData,
  Currency,
  Drc20Data,
  Drc20OverviewDetail,
  Drc20OverviewResponseData,
  RequestParams,
} from "@/types";
import { Drc20TickData } from "@/types/drc20List";
import { getValueForCurrency, handleError } from "@/utility";
import { filterTokenByMarketCapRule } from "@/utility/filterTokenByMarketCapRule.ts";
import { filterTokenByVolumeRule } from "@/utility/filterTokenByVolumeRule.ts";
import isString from "lodash/isString";

const fetchDrc20s = async (
  address: string,
  params?: RequestParams,
  cachebreaker?: boolean,
): Promise<{ drc20: Drc20OverviewResponseData[] }> => {
  // @todo: check why the cachebreaker is necessary and invalidation is too slow
  // for example to invalidate the cache after sending a drc20
  // for now bruteforce the cachebreaker
  const response = await marketplaceApiV2(cachebreaker, {
    ...params,
    address,
  }).get(`/drc20/balance`);
  return { drc20: response.data.balances };
};

export const fetchDrc20Ticks = async (
  ticks: string[],
): Promise<Drc20TickData[]> => {
  if (ticks.length === 0) {
    return [];
  }

  const response = await marketplaceApiV2(false).get<Drc20TickData[]>(
    `/drc20s/data?ticks=${ticks.join(",")}`,
  );
  return response?.data;
};

const calculateMarketCap = (
  data: Drc20Data | CollectionData | AllDunesData,
): number => {
  let currentSupply: number;
  if ("supply" in data) {
    // CollectionData
    currentSupply = data.supply;
  } else {
    // Drc20TickData (number) | AllDunesData (string)
    currentSupply = isString(data.currentSupply)
      ? parseFloat(data.currentSupply)
      : data.currentSupply;
  }
  return currentSupply * data.floorPrice;
};

// hook to fetch any Drc20 tokens for an address and each token's details
const useFetchDrc20s = (
  address?: string,
): {
  getDrc20s: () => Promise<void>;
  drc20s: Drc20OverviewDetail[];
  loading: boolean;
} => {
  const { dogePrice } = useDogePrice();
  const [loading, setLoading] = useState(false);
  const [drc20s, setDrc20s] = useState<Drc20OverviewDetail[]>([]);

  const getDrc20s = useCallback(async () => {
    if (!address) {
      setDrc20s([]);
      return;
    }

    if (!dogePrice) {
      return;
    }

    setLoading(true);

    let result = [] as Drc20OverviewDetail[];

    try {
      // if necessary, we could fetch additonal data about drc20 token in parallel like in useFetchDrc20.ts
      // we need the cachebreaker here to invalidate the cache after sending a drc20
      const data = await fetchDrc20s(address, { show_utxos: false }, false);
      const drc20s = data.drc20;

      const ticks = drc20s.map((drc20) => drc20.tick);
      const ticksDetails = await fetchDrc20Ticks(ticks);

      const fullOverviewPromises = drc20s.map(async (drc20) => {
        const {
          tick,
          available,
          transferable,
          receivingPendingAmount,
          sendingPendingAmount,
        } = drc20;
        let amount = Number(available) + Number(transferable);
        amount = amount < 0 ? 0 : amount;

        try {
          let floorPrice = 0;
          let changePercent = 0;
          const currentTickData = ticksDetails.find(
            (tickData) => tickData.tick == tick,
          );
          if (currentTickData) {
            floorPrice = currentTickData.floorPrice;
            changePercent = currentTickData.twentyFourHourChangePercent;
          }

          const priceData = {
            floorPrice: {
              doge: getValueForCurrency(
                currentTickData?.floorPrice ?? 0,
                Currency.DOGE,
                dogePrice,
              ),
              usd: getValueForCurrency(
                currentTickData?.floorPrice ?? 0,
                Currency.USD,
                dogePrice,
              ),
            },
            twentyFourHourChangePercent:
              currentTickData?.twentyFourHourChangePercent,
            weeklyChangePercent: currentTickData?.weeklyChangePercent,
            weeklyVolume: {
              doge: getValueForCurrency(
                currentTickData?.weeklyVolume ?? 0,
                Currency.DOGE,
                dogePrice,
              ),
              usd: getValueForCurrency(
                currentTickData?.weeklyVolume ?? 0,
                Currency.USD,
                dogePrice,
              ),
            },
            volume: {
              doge: getValueForCurrency(
                currentTickData?.volume ?? 0,
                Currency.DOGE,
                dogePrice,
              ),
              usd: getValueForCurrency(
                currentTickData?.volume ?? 0,
                Currency.USD,
                dogePrice,
              ),
            },
          };

          const floorPriceDoge = floorPrice / ONE_DOGE_IN_SHIBES;
          const floorPriceUsd = floorPriceDoge * dogePrice;

          const volumeRangeForMarketCapDecision =
            ACCOUNT_OVERALL_VOLUME_RANGE.toString() ==
            ChangeRange.SEVEN_DAYS.toString()
              ? currentTickData?.weeklyVolume || 0
              : currentTickData?.volume || 0;

          const setValueToZeroByMarketCapRule = filterTokenByMarketCapRule(
            currentTickData?.marketCap || 0,
            volumeRangeForMarketCapDecision,
            currentTickData?.tick || "",
          );

          // if weekly volume is used, it needs to be adjusted in the backend, too at:
          const setValueToZeroByVolumeRule = filterTokenByVolumeRule(
            volumeRangeForMarketCapDecision,
          );

          const valueDollar =
            setValueToZeroByMarketCapRule || setValueToZeroByVolumeRule
              ? 0
              : floorPriceUsd * amount;
          const valueDoge =
            setValueToZeroByMarketCapRule || setValueToZeroByVolumeRule
              ? 0
              : floorPriceDoge * amount;

          // Calculate previous value using the changePercent
          const previousDollar = valueDollar / (1 + changePercent / 100);
          const previousDoge = valueDoge / (1 + changePercent / 100);

          const changeDollar = valueDollar - previousDollar;
          const changeDoge = valueDoge - previousDoge;

          return {
            tick,
            amount,
            sendingPendingAmount,
            receivingPendingAmount,
            valueDollar,
            valueDoge,
            changeDollar,
            changeDoge,
            priceData,
          };
        } catch (e: Error | unknown) {
          handleError(e);
          return null;
        }
      });

      const fullOverview = (await Promise.all(fullOverviewPromises)).filter(
        Boolean,
      );

      // filtering out nulls
      result = fullOverview.filter(
        (item) => item !== null,
      ) as Drc20OverviewDetail[];
    } catch (e: Error | unknown) {
      handleError(e);
    } finally {
      setDrc20s(result);
      setLoading(false);
    }
  }, [address, dogePrice]);

  return { getDrc20s, drc20s, loading };
};

export type { Drc20OverviewDetail, RequestParams, Drc20TickData };
export { useFetchDrc20s, fetchDrc20s, calculateMarketCap };
