import "./App.css";
import { Toaster } from "@/components";
import { useLockOrientation, useNotifications } from "@/hooks";

import { AppRouter } from "./AppRouter";
import {
  DoginalCollectionListProvider,
  BalanceProvider,
  CollectiblePendingItemsProvider,
  CollectiblesDetailsProvider,
  ContentProvider,
  CurrencyProvider,
  DogePriceProvider,
  Drc20TokenListProvider,
  DunesTokenListProvider,
  LoginPromptProvider,
  RefreshKeyProvider,
  TxWalletProvider,
  UIProvider,
  WalletManagementProvider,
  SecurityPreferenceProvider,
} from "@/context";
import { TooltipProvider } from "@/components/ui/tooltip";

function App() {
  useLockOrientation();
  useNotifications();

  return (
    <>
      <div className="App bg-background-primary">
        <UIProvider>
          <TooltipProvider>
            <WalletManagementProvider>
              <SecurityPreferenceProvider>
                <ContentProvider>
                  <CurrencyProvider>
                    <LoginPromptProvider>
                      <DogePriceProvider>
                        <BalanceProvider>
                          <TxWalletProvider>
                            <RefreshKeyProvider>
                              <Drc20TokenListProvider>
                                <DunesTokenListProvider>
                                  <DoginalCollectionListProvider>
                                    <CollectiblesDetailsProvider>
                                      <CollectiblePendingItemsProvider>
                                        <AppRouter />
                                      </CollectiblePendingItemsProvider>
                                    </CollectiblesDetailsProvider>
                                  </DoginalCollectionListProvider>
                                </DunesTokenListProvider>
                              </Drc20TokenListProvider>
                            </RefreshKeyProvider>
                          </TxWalletProvider>
                        </BalanceProvider>
                      </DogePriceProvider>
                    </LoginPromptProvider>
                  </CurrencyProvider>
                </ContentProvider>
              </SecurityPreferenceProvider>
            </WalletManagementProvider>
          </TooltipProvider>
        </UIProvider>
      </div>
      <Toaster />
    </>
  );
}

export default App;
