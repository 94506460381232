import React, { useMemo } from "react";

import { TableContentOwners, TableOwners } from "@/components";
import { useCollectiblesDetails } from "@/contextHooks/useCollectiblesDetails.tsx";
import { CollectibleHolder } from "@/types/holders.ts";

interface TabCollectionOwnersProps {
  collectionName: string | undefined;
}

export const TabCollectionOwners: React.FC<TabCollectionOwnersProps> = ({
  collectionName,
}) => {
  const { collectibleHolders } = useCollectiblesDetails();

  const dataTokenHolders = useMemo(() => {
    return collectibleHolders.topHolders.map((holder: CollectibleHolder) => {
      return {
        rank: holder.rank,
        address: holder.address,
        amount: holder.available,
        share: holder.percentage,
      };
    });
  }, [collectibleHolders.topHolders]);

  return (
    <div className="mx-auto flex max-w-2xl flex-1 flex-col p-4">
      <div className="flex flex-1 flex-col space-y-4">
        <div className="text-center text-md font-bold text-text-primary">{`Top 100 ${collectionName ?? ""} Owners`}</div>
        <div className="flex flex-col rounded-lg bg-background-primary/50 p-2 md:p-4 2xl:bg-background-secondary">
          <TableOwners
            data={dataTokenHolders}
            columns={TableContentOwners}
            loading={collectibleHolders.isTopHoldersLoading}
          />
        </div>
      </div>
    </div>
  );
};
