import { useContext, useEffect, useRef } from "react";

import { UIContext } from "@/context";

// Save the header height to the context; used for the main content padding
export const useNavigationHeaderHeight = () => {
  const headerRef = useRef<HTMLDivElement>(null);
  const { setNavigationHeaderHeight } = useContext(UIContext);

  useEffect(() => {
    if (headerRef.current) {
      setNavigationHeaderHeight(headerRef.current.offsetHeight);
    }
  }, [setNavigationHeaderHeight]);

  return headerRef;
};
