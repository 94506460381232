import { motion } from "framer-motion";

import { ImageWithFallback } from "@/components/imageWithFallback";
import { WONKY_ORD_URL } from "@/constants";
import { CollectionActivity } from "@/types";
import { getTimeDifference } from "@/utility";
import { ColumnDef } from "@tanstack/react-table";

import {
  ActivityTypeCell,
  ChangeCell,
  FromToCell,
  HeaderCell,
  NumberCell,
  TextCell,
} from "../components";
import { ChangeCellType } from "../components/type";

export const TableContentActivityCollection: ColumnDef<CollectionActivity>[] = [
  {
    accessorKey: "createdAt",
    header: ({ column }) => {
      return (
        <HeaderCell
          title="Time"
          aligned="left"
          className="ml-4 mr-2 w-8"
          column={column}
          withSorting
        />
      );
    },
    cell: ({ row }) => {
      const { createdAt } = row.original;
      return (
        <TextCell
          value={getTimeDifference(createdAt)}
          className="ml-4 mr-2 w-8"
        />
      );
    },
  },
  {
    accessorKey: "item",
    header: () => (
      <HeaderCell
        title="Item"
        aligned="left"
        className="w-32 lg:w-64"
        withSorting={false}
      />
    ),
    cell: ({ row }) => {
      const {
        inscriptionNumber,
        inscriptionId,
        doginalName,
        imageURI,
        onNavigateToListing,
      } = row.original;

      const onClickListing = () => onNavigateToListing();

      const onClick = (event: React.MouseEvent) => {
        event.stopPropagation();
        window.open(
          `${WONKY_ORD_URL}shibescription/${inscriptionId}`,
          "_blank",
        );
      };

      return (
        <motion.div
          className="flex w-32 cursor-pointer flex-row items-center space-x-2 lg:w-64 lg:space-x-4"
          onClick={onClickListing}
          whileHover={{ scale: 1.05 }}
          transition={{ duration: 0.1 }}
        >
          <ImageWithFallback
            image={imageURI}
            className="h-7 w-7 rounded-md lg:h-14 lg:w-14"
          />
          <div className="flex h-full flex-1 flex-col justify-center -space-y-1 lg:space-y-1">
            <span className="w-24 truncate text-sm font-medium uppercase text-text-primary lg:w-48 lg:text-md">
              {doginalName}
            </span>
            <span
              className="text-2xs text-text-tertiary hover:text-text-primary lg:text-xs"
              onClick={onClick}
            >
              {inscriptionNumber}
            </span>
          </div>
        </motion.div>
      );
    },
  },
  {
    accessorKey: "type",
    header: ({ column }) => {
      return (
        <HeaderCell
          title="Action"
          aligned="left"
          className="w-4"
          withSorting
          column={column}
        />
      );
    },
    cell: ({ row }) => {
      const { type } = row.original;
      return <ActivityTypeCell type={type} className="w-12" />;
    },
  },
  {
    accessorKey: "price",
    header: ({ column }) => {
      return (
        <HeaderCell
          title="Price"
          aligned="right"
          column={column}
          withSorting
          className="w-24"
        />
      );
    },
    cell: ({ row }) => {
      const { priceForCurrency } = row.original;
      return <NumberCell value={priceForCurrency} className="w-24" />;
    },
  },
  {
    accessorKey: "floorDifference",
    header: ({ column }) => {
      return (
        <HeaderCell
          title="Floor Diff."
          aligned="right"
          column={column}
          withSorting
          className="w-24"
        />
      );
    },
    cell: ({ row }) => {
      const { floorDifference } = row.original;
      return (
        <ChangeCell
          change={floorDifference}
          type={ChangeCellType.FloorDifference}
          className="w-24"
        />
      );
    },
  },
  {
    accessorKey: "fromTo",
    header: () => (
      <HeaderCell
        title="From/To"
        aligned="left"
        withSorting={false}
        className="w-auto"
      />
    ),
    cell: ({ row }) => {
      const { from, to } = row.original;
      return <FromToCell from={from} to={to} className="w-fit" />;
    },
  },
];
