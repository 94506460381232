import { AnimatePresence, motion } from "framer-motion";

import { cn } from "@/lib/utils";
import { useToggle } from "@uidotdev/usehooks";
import { FeeType } from "@/hooks";
import { ImageWithFallback } from "@/components";
import { DOGE_LOGO } from "@/constants.ts";

interface FeeListItemProps {
  label: string | React.ReactNode;
  value: string;
  onClick: () => void;
  className?: string;
}

export const FeeListItem: React.FC<FeeListItemProps> = ({
  label,
  value,
  onClick,
  className,
}: FeeListItemProps) => {
  return (
    <div
      className="flex h-10 flex-row items-center justify-between"
      onClick={onClick}
    >
      <div className="flex flex-row items-center space-x-1">
        <span className={cn("text-sm text-text-primary/30", className)}>
          {label}
        </span>
        <span className="material-symbols-rounded text-xs text-text-tertiary">
          info
        </span>
      </div>
      <span
        className={cn("text-right text-sm text-text-primary/30", className)}
        dangerouslySetInnerHTML={{ __html: value }}
      />
    </div>
  );
};

interface ExpandableFeeListProps {
  fees: FeeListItemProps[];
  totalFees?: string;
  showTotalFeesHeaderImage?: boolean;
  expandableHead?: {
    withExpandableHeaderImage: boolean;
    header: string;
    value: string;
    className?: string;
    valueInUSD?: string;
    onClick?: () => void;
    imageSize?: string;
  };
  potentialProceeds?: {
    inUSD: string;
    inDoge: string;
  };
}

export const ExpandableFeeList: React.FC<ExpandableFeeListProps> = ({
  fees,
  totalFees,
  expandableHead,
  showTotalFeesHeaderImage,
  potentialProceeds,
}) => {
  const [expanded, toggleExpanded] = useToggle(false);

  return (
    <motion.div
      layout="position"
      transition={{ duration: 0.1 }}
      className="flex flex-1 flex-col space-y-4"
    >
      <div className="flex flex-col space-y-4 rounded-lg bg-background-secondary p-4">
        {totalFees ||
          (expandableHead && (
            <div className="flex flex-row items-center space-x-4">
              {showTotalFeesHeaderImage ||
                (expandableHead && expandableHead.withExpandableHeaderImage && (
                  <ImageWithFallback
                    image={DOGE_LOGO}
                    className={cn(
                      "rounded-full",
                      expandableHead?.imageSize
                        ? expandableHead.imageSize
                        : "h-8 w-8",
                    )}
                  />
                ))}
              <div className="flex flex-1 flex-row space-x-2">
                <div className="flex flex-1 flex-col space-y-1">
                  <div className="flex flex-1 flex-row items-center">
                    <span
                      className={cn(
                        "text-sm text-text-primary",
                        expandableHead ? expandableHead.className : "",
                      )}
                      onClick={
                        expandableHead && expandableHead?.onClick
                          ? expandableHead?.onClick
                          : () => {}
                      }
                    >
                      {expandableHead ? expandableHead.header : FeeType.TOTAL}
                    </span>
                    <span
                      className={cn(
                        "material-symbols-rounded text-xs text-text-tertiary",
                        expandableHead ? expandableHead.className : "",
                        { "rotate-180": expanded },
                      )}
                      onClick={() => toggleExpanded()}
                    >
                      expand_more
                    </span>
                  </div>
                  <div className="flex flex-1 flex-row justify-between">
                    <span
                      className="text-md uppercase text-text-primary"
                      dangerouslySetInnerHTML={{
                        __html: `${expandableHead ? expandableHead.value : totalFees}`,
                      }}
                    />
                  </div>
                </div>
                {expandableHead && expandableHead.valueInUSD && (
                  <div className="flex flex-1 flex-col justify-center">
                    <div className="flex flex-1 flex-row items-end">
                      <span
                        className={cn(
                          "w-full text-right text-sm uppercase leading-tight text-text-tertiary" +
                            " md:text-md",
                          expandableHead ? expandableHead.className : "",
                        )}
                        dangerouslySetInnerHTML={{
                          __html: `${expandableHead ? expandableHead.valueInUSD : "0"}`,
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}

        {potentialProceeds && (
          <div className="flex flex-row items-center space-x-4">
            <ImageWithFallback
              image={DOGE_LOGO}
              className="h-8 w-8 rounded-full"
            />
            <div className="flex flex-1 flex-row space-x-2">
              <div className="flex flex-1 flex-col space-y-1">
                <div className="flex flex-1 flex-row items-center">
                  <span className="text-xs uppercase text-text-tertiary">
                    Potential&nbsp;Proceeds
                  </span>
                  <span
                    className={cn(
                      "material-symbols-rounded text-xs text-text-tertiary",
                      { "rotate-180": expanded },
                    )}
                    onClick={() => toggleExpanded()}
                  >
                    expand_more
                  </span>
                </div>
                <div className="flex flex-1 flex-row justify-between">
                  <span
                    className="text-md uppercase text-text-primary"
                    dangerouslySetInnerHTML={{
                      __html: `${potentialProceeds.inDoge}`,
                    }}
                  />
                </div>
              </div>
              <div className="flex flex-1 flex-col justify-center">
                <div className="flex flex-1 flex-row items-end">
                  <span
                    className="w-full text-right text-sm uppercase leading-tight text-text-tertiary md:text-md"
                    dangerouslySetInnerHTML={{
                      __html: `${potentialProceeds.inUSD}`,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {expanded && (
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ delay: 0.1, duration: 0.1 }}
            className="mt-0 rounded-lg border border-border-primary bg-background-secondary/50 px-4 py-2"
          >
            {fees.map((fee, index) => (
              <FeeListItem
                key={index}
                label={fee.label}
                value={fee.value}
                onClick={fee.onClick}
              />
            ))}
          </motion.div>
        </AnimatePresence>
      )}
    </motion.div>
  );
};
