import { ReactNode, createContext, useState } from "react";

import { useFetchDoginalsOfCollection } from "@/hooks";
import { useFetchCollectionActivities } from "@/hooks/datafetching/useFetchCollectionActivities.ts";
import { useFetchTopCollectibleHolders } from "@/hooks/datafetching/useFetchTopCollectibleHolders.ts";
import {
  ActivitySortParam,
  CollectibleHoldersReturn,
  FetchCollectionActivitiesReturn,
  FetchDoginalListingsReturn,
  SortOrder,
  SortParamListingsCollection,
} from "@/types";

type CollectiblesDetailsContextType = {
  // table data
  collectibleListings: FetchDoginalListingsReturn;
  collectibleActivities: FetchCollectionActivitiesReturn;
  collectibleHolders: CollectibleHoldersReturn;
  setCollectionSymbol: (collectionSymbol?: string) => void;
  setAddress: (address?: string) => void;
};

type CollectibleDetailsProviderProps = {
  children: ReactNode;
};

const CollectiblesDetailsContext =
  createContext<CollectiblesDetailsContextType>({
    collectibleListings: {
      data: [],
      total: 0,
      isLoading: false,
      hasMoreData: false,
      isLoadingMoreData: false,
      setSort: () => {},
      setFilters: () => {},
      setOffset: () => {},
      offset: 0,
      reset: () => {},
      reset_for_symbol: () => {},
      refetch: async () => {},
      sort: {
        sortParam: SortParamListingsCollection.CreatedAt,
        sortOrder: SortOrder.Descending,
      },
      filters: {
        offset: 0,
        limit: 0,
        seller: undefined,
        collectionSymbol: undefined,
        listed: true,
        priceMin: "0",
        priceMax: undefined,
        metadata: [],
        name: undefined,
      },
      floorPrice: null,
    },
    collectibleActivities: {
      data: [],
      total: 0,
      isLoading: false,
      hasMoreData: false,
      isLoadingMoreData: false,
      setSort: () => {},
      setFilters: () => {},
      setOffset: () => {},
      offset: 0,
      reset: () => {},
      sort: {
        sortParam: ActivitySortParam.Recent,
        sortOrder: SortOrder.Descending,
      },
      filters: {
        offset: 0,
        limit: 0,
        collectionSymbol: undefined,
        type: undefined,
        address: undefined,
      },
    },
    collectibleHolders: {
      topHolders: [],
      isTopHoldersLoading: false,
      fetchTop100Holders: () => {},
    },
    setCollectionSymbol: () => {},
    setAddress: () => {},
  });

const CollectiblesDetailsProvider = ({
  children,
}: CollectibleDetailsProviderProps) => {
  const [collectionSymbol, setCollectionSymbol] = useState<string | undefined>(
    undefined,
  );
  const [address, setAddress] = useState<string | undefined>(undefined);

  const collectibleActivities = useFetchCollectionActivities(collectionSymbol);
  const collectibleListings = useFetchDoginalsOfCollection(
    collectionSymbol,
    address,
  );
  const collectibleHolders = useFetchTopCollectibleHolders(collectionSymbol);

  return (
    <CollectiblesDetailsContext.Provider
      value={{
        collectibleListings,
        collectibleActivities,
        collectibleHolders,
        setCollectionSymbol,
        setAddress,
      }}
    >
      {children}
    </CollectiblesDetailsContext.Provider>
  );
};

export { CollectiblesDetailsProvider, CollectiblesDetailsContext };
