import { useCallback, useState } from "react";

import { LISTING_MATCHING_FEE } from "@/constants";
import { useToggle } from "@uidotdev/usehooks";

export enum FeeType {
  TOTAL = "Total Fees",
  NETWORK = "Network Fee",
  MATCHING = "Matching Fee",
  SERVICE = "Service Fee",
  ALPHA_DISCOUNT = "Alpha Phase Discount",
}

interface InfoContent {
  title: string;
  content: string | string[];
}

export const useInfoModal = () => {
  const [info, setInfo] = useState<InfoContent>({ title: "", content: "" });
  const [infoVisible, toggleInfoVisible] = useToggle(false);

  const handleOpenInfo = useCallback(
    (title: string, content: string | string[]) => {
      setInfo({ title, content });
      toggleInfoVisible();
    },
    [toggleInfoVisible],
  );

  const handleCloseInfo = useCallback(() => {
    setInfo({ title: "", content: "" });
    toggleInfoVisible();
  }, [toggleInfoVisible]);

  const handleOpenInfoFee = useCallback(
    (feeType: FeeType) => {
      switch (feeType) {
        case FeeType.NETWORK:
          handleOpenInfo(
            FeeType.NETWORK,
            "The Network Fee is the cost of processing the transaction on the blockchain.",
          );
          break;
        case FeeType.MATCHING:
          handleOpenInfo(
            FeeType.MATCHING,
            `The Matching Fee (${LISTING_MATCHING_FEE}%) is calculated as a percentage of the total amount of the transaction.`,
          );
          break;
        case FeeType.SERVICE:
          handleOpenInfo(
            FeeType.SERVICE,
            "The Service Fee covers the cost of creating the transfer inscription required by the DRC-20 protocol.",
          );
          break;
        case FeeType.ALPHA_DISCOUNT:
          handleOpenInfo(
            FeeType.ALPHA_DISCOUNT,
            "The Alpha Phase discount (50%) is calculated based on the Matching Fee.",
          );
          break;
        case FeeType.TOTAL:
          handleOpenInfo(
            FeeType.TOTAL,
            "The Total Fees are the sum of the Network Fee, Matching Fee, and Service Fee minus the Alpha Phase" +
              " discount.",
          );
          break;
      }
    },
    [handleOpenInfo],
  );

  return {
    info,
    infoVisible,
    FeeType,
    setInfo,
    handleOpenInfo,
    handleCloseInfo,
    handleOpenInfoFee,
  };
};
