import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  AppScreen,
  Button,
  DetailCard,
  ImageWithFallback,
  Skeleton,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  TokenDetailsCardSkeleton,
} from "@/components";
import {
  useCurrency,
  useDogePrice,
  useDoginalCollectionList,
  useDoginalWatchlist,
  useTxWallet,
} from "@/contextHooks";
import { useCurrentAccount } from "@/hooks";
import { cn } from "@/lib/utils";

import { getFormattedCollectionDetails } from "./helper";
import {
  TabCollectionActivity,
  TabCollectionDetails,
  TabCollectionListings,
  TabCollectionOwners,
} from "./tabs";
import { CollectionDetailProps } from "./types";
import { useOnboardingModalContext } from "@/contextHooks/useOnboardingModal.ts";
import { ModalInstall } from "@/modals";

export const Collection: React.FC = () => {
  const navigate = useNavigate();
  const { currency } = useCurrency();
  const { collectionSymbol } = useParams();
  const { dogePrice } = useDogePrice();
  const { address } = useCurrentAccount();
  const { login } = useTxWallet();

  const {
    isLoadingCurrentCollectionData,
    getCurrentCollectionData,
    currentCollectionData,
  } = useDoginalCollectionList();

  const [isOnWatchList, setIsOnWatchList] = useState<boolean>(false);
  const [openModalInstall, setOpenModalInstall] = useState<boolean>(false);
  const { isInstallModalOpen, hideInstallModal } = useOnboardingModalContext();

  const {
    addToWatchlist,
    removeFromWatchlist,
    getIsOnWatchlist,
    isWatchlistLoading,
  } = useDoginalWatchlist();

  // For disabling the action buttons when the collection data is not available
  const isActionDisabled = useMemo(() => {
    return (
      collectionSymbol !== undefined &&
      currentCollectionData !== undefined &&
      (address !== undefined || isInstallModalOpen)
    );
  }, [collectionSymbol, currentCollectionData, address, isInstallModalOpen]);

  const collectionDetails: CollectionDetailProps[] | undefined = useMemo(() => {
    if (!currentCollectionData) return;
    return getFormattedCollectionDetails(
      currentCollectionData,
      dogePrice,
      currency,
    );
  }, [currentCollectionData, currency, dogePrice]);

  const toggleWatchlist = useCallback(
    async (symbol?: string) => {
      if (!symbol) return;
      if (openModalInstall) return;

      // open the install modal (by setting this internal state variable) if the user clicks on add to watchlist and is not
      // on the right device or has not installed the app
      if (isInstallModalOpen) {
        setOpenModalInstall(true);
        return; // do not add to watchlist if the user has not installed the app
      }

      const isLoggedIn = await login();
      if (!isLoggedIn) return;

      if (getIsOnWatchlist(symbol)) {
        removeFromWatchlist(symbol);
        setIsOnWatchList(false);
      } else {
        await addToWatchlist(symbol);
        setIsOnWatchList(true);
      }
    },
    [
      addToWatchlist,
      getIsOnWatchlist,
      isInstallModalOpen,
      login,
      openModalInstall,
      removeFromWatchlist,
    ],
  );

  // update the internal state of the install modal variable  when the install modal is closed
  useEffect(() => {
    if (!isInstallModalOpen) {
      setOpenModalInstall(false);
    }
  }, [isInstallModalOpen]);

  const getCurrentCollectionDataCalled = useRef<boolean>(false);
  useEffect(() => {
    if (
      !collectionSymbol ||
      isLoadingCurrentCollectionData ||
      getCurrentCollectionDataCalled.current
    )
      return;
    getCurrentCollectionData(collectionSymbol);
    getCurrentCollectionDataCalled.current = true;
  }, [
    collectionSymbol,
    isLoadingCurrentCollectionData,
    getCurrentCollectionData,
  ]);

  useEffect(() => {
    if (collectionSymbol && !isOnWatchList && !isWatchlistLoading) {
      getIsOnWatchlist(collectionSymbol) && setIsOnWatchList(true);
    }
  }, [collectionSymbol, getIsOnWatchlist, isOnWatchList, isWatchlistLoading]);

  return (
    <>
      <AppScreen>
        <div className="flex h-full w-full flex-1 flex-col pt-2">
          {/* Screen Header */}
          <div className="flex flex-row items-center justify-between px-4 py-2">
            <div className="flex flex-1 flex-row justify-start space-x-1 lg:space-x-4">
              <Button
                variant="ghost"
                size="icon"
                shape="circle"
                className="h-8 w-8"
                onClick={() => navigate(-1)}
              >
                <span className="material-symbols-rounded text-lg">
                  arrow_back_ios
                </span>
              </Button>
            </div>

            <div className="flex flex-1 flex-row items-center justify-center space-x-2 lg:space-x-4">
              <ImageWithFallback
                image={
                  currentCollectionData ? currentCollectionData?.imageURI : null
                }
                className="h-8 w-8 rounded-md lg:h-10 lg:w-10"
              />
              {isLoadingCurrentCollectionData ? (
                <Skeleton className="h-6 w-24 text-lg lg:h-8 lg:w-64" />
              ) : (
                <span className="max-w-44 truncate text-center text-lg font-bold uppercase text-text-primary lg:max-w-96 lg:text-2xl">
                  {currentCollectionData?.name}
                </span>
              )}
              <div className="h-8 w-8 bg-transparent" />
            </div>

            <div className="flex flex-1 flex-row justify-end space-x-1 lg:space-x-4">
              <Button
                variant="ghost"
                size="icon"
                shape="circle"
                className="h-8 w-8 hover:bg-background-secondary lg:h-10 lg:w-10"
                onClick={() =>
                  navigate(`/account/collectible/${collectionSymbol}`)
                }
                disabled={!isActionDisabled}
              >
                <span className="material-symbols-rounded text-xl text-text-highlight hover:text-text-primary lg:text-2xl">
                  sell
                </span>
              </Button>
              <Button
                variant="ghost"
                size="icon"
                shape="circle"
                className="h-8 w-8 hover:bg-background-secondary lg:h-10 lg:w-10 "
                onClick={() => toggleWatchlist(collectionSymbol)}
              >
                <span
                  className={cn(
                    "material-symbols-rounded text-2xl hover:text-text-primary lg:text-3xl",
                    collectionSymbol && isOnWatchList
                      ? "text-text-highlight"
                      : "text-text-tertiary",
                  )}
                >
                  star
                </span>
              </Button>
            </div>
          </div>
          {/** Collection Details */}
          <div className="flex w-full flex-row space-x-2 overflow-x-auto p-4 xl:px-5">
            {!collectionDetails?.length ? (
              <TokenDetailsCardSkeleton />
            ) : (
              collectionDetails?.map((detail) => (
                <DetailCard key={detail.label} {...detail} />
              ))
            )}
          </div>
          {/** Tabs */}
          <div
            className={cn(
              "flex h-full flex-1 flex-col bg-background-secondary",
              "lg:w-full lg:items-center 2xl:bg-transparent",
            )}
          >
            <Tabs defaultValue="listings" className="w-full">
              <TabsList
                className={cn(
                  "mt-2 grid grid-cols-4 px-4",
                  "w-full max-w-2xl bg-transparent md:mx-auto md:mt-8 md:px-0",
                )}
              >
                <TabsTrigger
                  value="listings"
                  className="w-full bg-transparent px-0"
                >
                  Listings
                </TabsTrigger>
                <TabsTrigger
                  value="activity"
                  className="w-full bg-transparent px-0"
                >
                  Activity
                </TabsTrigger>
                <TabsTrigger
                  value="details"
                  className="w-full bg-transparent px-0"
                >
                  Details
                </TabsTrigger>
                <TabsTrigger
                  value="owners"
                  className="w-full bg-transparent px-0"
                >
                  Owners
                </TabsTrigger>
              </TabsList>

              <TabsContent value="listings">
                <TabCollectionListings collectionSymbol={collectionSymbol} />
              </TabsContent>

              <TabsContent value="activity">
                <TabCollectionActivity collectionSymbol={collectionSymbol} />
              </TabsContent>

              <TabsContent value="details">
                <TabCollectionDetails
                  collectionDetails={currentCollectionData}
                />
              </TabsContent>

              <TabsContent value="owners">
                <TabCollectionOwners
                  collectionName={currentCollectionData?.name}
                />
              </TabsContent>
            </Tabs>
          </div>
        </div>
      </AppScreen>

      <ModalInstall
        isVisible={openModalInstall}
        onClose={() => {
          hideInstallModal();
          navigate("/");
        }}
      />
    </>
  );
};
