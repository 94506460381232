import { AnimatePresence, motion } from "framer-motion";
import React from "react";

import { Spinner } from "@/components/spinner";

interface LoadingIndicatorProps {
  isVisible: boolean;
}

export const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({
  isVisible,
}) => {
  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          className="flex h-24 flex-col items-center justify-start"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <Spinner size={24} />
        </motion.div>
      )}
    </AnimatePresence>
  );
};
