// In-memory storage strategy using Map
const memoryStorage = new Map<string, string>();

// Abstract persist function that handles both localStorage and memoryStorage
const persistStorage = (
  name: string,
  obj: object,
  useLocalStorage: boolean,
) => {
  const serializedData = JSON.stringify(obj);

  if (useLocalStorage) {
    localStorage.setItem(name, serializedData);
  } else {
    memoryStorage.set(name, serializedData);
  }
};

// Abstract retrieval function that handles both localStorage and memoryStorage
const retrieveStorage = (
  name: string,
  useLocalStorage: boolean,
): string | null => {
  return useLocalStorage
    ? localStorage.getItem(name)
    : memoryStorage.get(name) || null;
};

export interface CreatePersistStoreParams<T> {
  name: string;
  template?: T;
  fromStorage?: boolean;
  useLocalStorage?: boolean;
}

const createPersistStore = async <T extends object>({
  name,
  template = Object.create(null),
  fromStorage = true,
  useLocalStorage = false, // Default to Map instead of localStorage
}: CreatePersistStoreParams<T>): Promise<T> => {
  let tpl = template;

  if (fromStorage) {
    const rawStorageCache = retrieveStorage(name, useLocalStorage);
    let storageCache = null;
    try {
      storageCache = rawStorageCache ? JSON.parse(rawStorageCache) : null;
    } catch (e) {
      console.error(e);
    }
    tpl = storageCache || template;
    if (!storageCache) {
      persistStorage(name, tpl, useLocalStorage);
    }
  }

  const createProxy = <A extends T>(obj: A): A =>
    new Proxy(obj, {
      set(target: A, prop: string | symbol, value: any) {
        // Casting prop to keyof A to avoid TypeScript error
        target[prop as keyof A] = value;

        persistStorage(name, target, useLocalStorage);

        return true;
      },

      deleteProperty(target: A, prop: string | symbol) {
        if (Reflect.has(target, prop)) {
          Reflect.deleteProperty(target, prop);

          persistStorage(name, target, useLocalStorage);
        }

        return true;
      },
    });

  return createProxy(tpl);
};

export default createPersistStore;
