import { useCallback, useState } from "react";

import { useDogePrice } from "@/contextHooks";
import { sdoggsApiV2, wonkyOrdApi } from "@/lib/fetch";
import {
  AccountDunesData,
  AllDunesData,
  RankingDataResponse,
  UseSingleDunesHookReturn,
} from "@/types/inscription.ts";
import {
  calculateTokenValueInDoge,
  calculateTokenValueInUSD,
  handleError,
} from "@/utility";

import { fetchDunes, fetchDunesTicks, useCurrentAccount } from "..";
import { DuneDetails, DunesList } from "@/types";
import { FEATURE_ACTIVATION_DUNES } from "@/constants";
import { formatBigValueExact } from "@/lib/numbers";

// hook to fetch Dunes token data, tickdata, dogeprice
// if there is an address and this address has the token, fetch address related data and merge them all in one

export const useFetchDune = (): UseSingleDunesHookReturn => {
  const { dogePrice } = useDogePrice();
  const { address } = useCurrentAccount();
  const [loading, setLoading] = useState(false);
  const [loadingAccountData, setLoadingAccountData] = useState(false);
  const [accountData, setAccountData] = useState<
    AccountDunesData | undefined
  >();
  const [tokenData, setTokenData] = useState<AllDunesData | undefined>();

  const getDuneData = useCallback(
    async (tick?: string) => {
      if (FEATURE_ACTIVATION_DUNES !== "true") {
        return;
      }

      if (!tick) {
        setTokenData(undefined);
        return;
      }

      if (!dogePrice) {
        return;
      }

      try {
        setLoading(true);

        // fetch general token data
        // then fetch additional data about the token e.g. limitPerMint, mintingTransactions, deployer, deploymentShibescription,ranking etc.
        const [generalTickDatas, rankingDataRes, activityData] =
          await Promise.all([
            fetchDunesTicks([tick]),
            sdoggsApiV2(false).get(
              `dunes/list?offset=0&limit=1&filterByTick=${tick.toUpperCase()}`,
            ),
            sdoggsApiV2(false).get<DunesList>("/dunes/list/activity", {
              params: { filterByTick: tick, offset: 0, limit: 10 },
            }),
          ]);

        const activityItem = activityData.data.list[0];
        if (activityItem && typeof activityItem.holders !== "number") {
          activityItem.holders = (activityItem.holders as any).length;
        }

        const rankingData: RankingDataResponse = rankingDataRes.data.list[0];
        const generalTickData = generalTickDatas[0];
        const {
          limitPerMint,
          minted,
          mintingTransactions,
          added,
          deployer,
          deploymentShibescription,
          trustLevel,
          rank,
        } = rankingData;

        setTokenData({
          ...generalTickData,
          ...activityItem,
          limitPerMint,
          minted,
          mintingTransactions,
          added,
          deployer,
          deploymentShibescription,
          trustLevel,
          rank,
        });
      } catch (e: Error | unknown) {
        handleError(e);
        // we are throwing again to deal with error in UI further up the dependency chain
        console.log("Error in useFetchDune", e);
        throw new Error("Failed to fetch Dunes details");
      } finally {
        setLoading(false);
      }
    },
    [dogePrice],
  );

  const getDuneAccountData = useCallback(
    async (tick?: string, loadUtxos?: boolean, cachebreaker?: boolean) => {
      if (!tick) {
        setTokenData(undefined);
        setAccountData(undefined);
        return;
      }

      if (!address || !tokenData || !dogePrice) return;

      try {
        setLoadingAccountData(true);

        const show_utxos = loadUtxos || true;
        const cacheBreaker = cachebreaker === true;
        const data = await fetchDunes(
          address,
          { tick, show_utxos, show_all: true },
          cacheBreaker,
        );
        const userTokenData = data.dunes[0];

        const duneDetailsResponse = await wonkyOrdApi().get<DuneDetails>(
          `/dune/${tick.toUpperCase()}?json=true`,
        );

        // if there is user data, fetch it
        if (userTokenData) {
          const divisibility = userTokenData.divisibility;
          const amount = formatBigValueExact(
            userTokenData.total_balance,
            divisibility,
          );

          const floorPrice = tokenData.floorPrice;
          const walletTokenValueInDoge = calculateTokenValueInDoge(
            amount,
            floorPrice,
          );
          const walletTokenValueInUSD = calculateTokenValueInUSD(
            amount,
            floorPrice,
            dogePrice,
          );

          const parsedUtxos = userTokenData?.balances.map((balance) => {
            return {
              txid: balance.txid,
              vout: balance.vout,
              satoshis: balance.shibes,
              script: balance.script,
              dunes: {
                dune: tick,
                id: duneDetailsResponse.data.id,
                divisibility,
                balance: formatBigValueExact(balance.balance, divisibility),
              },
            };
          });

          setAccountData({
            tick,
            divisibility,
            utxos: parsedUtxos,
            availableBalance: amount,
            walletTokenValueInDoge,
            walletTokenValueInUSD,
            address,
            receivingPendingAmount: userTokenData.pending.receiving,
            sendingPendingAmount: userTokenData.pending.sending,
          });
        }
      } catch (e: Error | unknown) {
        handleError(e);
        // we are throwing again to deal with error in UI further up the dependency chain
        throw new Error("Failed to fetch Dunes Account Data");
      } finally {
        setLoadingAccountData(false);
      }
    },
    [address, dogePrice, tokenData],
  );

  return {
    getDuneData,
    getDuneAccountData,
    accountDunesData: accountData,
    tokenData,
    loading: loading || loadingAccountData,
  };
};
