import React, { useCallback, useState } from "react";

import { CheckboxField, Input, useToast } from "@/components";
import { useWalletManagement } from "@/contextHooks";
import { handleError } from "@/utility";
import { useToggle } from "@uidotdev/usehooks";

import { Alert } from "../base";

const CONFIRMATION_TEXT = "RESET-APP";

interface ModalResetAppProps {
  visible: boolean;
  onClose: () => void;
}

export const ModalResetApp: React.FC<ModalResetAppProps> = ({
  visible,
  onClose,
}) => {
  const { toast } = useToast();
  const { deleteWallet } = useWalletManagement() || {};

  const [value, setValue] = useState("");
  const [isConfirmed, toggleIsConfirmed] = useToggle(false);

  const handleResetApp = useCallback(() => {
    try {
      deleteWallet?.();
      toast({
        title: "App Reset",
        description: "The app has been reset.",
        variant: "default",
      });
    } catch (e: Error | unknown) {
      const message = handleError(e);
      toast({
        title: "Error Resetting App",
        description: message,
        variant: "error",
      });
    } finally {
      onClose();
    }
  }, [toast, deleteWallet, onClose]);

  const handleValueChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value);
    },
    [],
  );
  return (
    <Alert
      title="Reset App"
      message="Are you sure you want to reset the app? This will delete all your data."
      isVisible={visible}
      onClose={onClose}
      actionPrimary={{
        label: "Reset",
        variant: "destructive",
        onClick: handleResetApp,
        disabled: !isConfirmed || value !== CONFIRMATION_TEXT,
      }}
      actionSecondary={{
        label: "Cancel",
        variant: "ghost",
        onClick: onClose,
      }}
    >
      <div className="flex flex-col space-y-2">
        <span className="ml-2 text-2xs text-text-tertiary">
          {`Enter "${CONFIRMATION_TEXT}" to confirm.`}
        </span>
        <Input
          placeholder={CONFIRMATION_TEXT}
          className="border-0.5 border-border-secondary bg-background-primary"
          onChange={handleValueChange}
          value={value}
        />
      </div>
      <CheckboxField
        id="reset-app"
        text="I have backed up my recovery phrase."
        value={isConfirmed}
        onToggleChange={toggleIsConfirmed}
        className="border-0.5 border-border-secondary bg-background-primary"
      />
    </Alert>
  );
};
