import { useCallback, useState } from "react";

import {
  buildListDrc20Txs,
  createDrc20Offer,
  getSellerPsdtHex,
} from "@/context/wallet/helpers/listItems.ts";
import { signPsbt } from "@/context/wallet/lib/transaction.ts";
import {
  TxWallet,
  TxWithFeeEstimation,
  WalletContextHandleReturn,
} from "@/context/wallet/types.ts";
import { Utxo } from "@/types";
import { handleError } from "@/utility";

export type UseListDrc20Params = {
  txWallet: TxWallet | undefined;
  address: string | undefined;
};

export type ListDrc20Params = {
  priceInShibs: number;
  tick: string;
  amt: number;
  transferInscriptionUtxos: Utxo[];
  feePerVByte: number;
};

export interface ListDrc20Return extends WalletContextHandleReturn {
  execute: (listDrc20Params: ListDrc20Params) => Promise<void>;
  txHashes: string[];
}

export const useListDrc20 = (
  useListParams: UseListDrc20Params,
): ListDrc20Return => {
  const [isLoading, setIsListingDrc20] = useState<boolean>(false);
  const [isError, setIsErrorDrc20List] = useState<boolean>(false);
  const [isSuccess, setIsSuccessDrc20List] = useState<boolean>(false);
  const [error, setError] = useState<null | string>(null);
  const [txHashes, setTxHashes] = useState<any[]>([]);

  const { txWallet, address } = useListParams;

  const buildListDrc20sTx = useCallback(
    async (
      buildListDrc20Params: ListDrc20Params,
    ): Promise<TxWithFeeEstimation[] | undefined> => {
      const estimateFeesOnly = false;
      const buildSendDrc20TxsParams = {
        txWallet,
        address,
        estimateFeesOnly,
        ...buildListDrc20Params,
      };

      return buildListDrc20Txs(buildSendDrc20TxsParams);
    },
    [address, txWallet],
  );

  const listDrc20 = useCallback(
    async (listDrc20Params: ListDrc20Params): Promise<void> => {
      setIsListingDrc20(true);
      setIsErrorDrc20List(false);
      setIsSuccessDrc20List(false);

      try {
        const { priceInShibs } = listDrc20Params;

        if (!txWallet) {
          throw new Error("No wallet found");
        }

        const txs = await buildListDrc20sTx(listDrc20Params);
        if (!txs) {
          throw new Error("No transactions found");
        }

        // Broadcast all to the backend in one go
        const preparedTxHexs: string[] = [];
        const preparedTxHashes: string[] = [];
        for (const [index, { tx }] of txs.entries()) {
          if (!tx.hash) {
            throw new Error(
              `Failed to prepare txs for createDrc20Offer at index ${index}`,
            );
          }
          preparedTxHashes.push(tx.hash);
          preparedTxHexs.push(tx.toString("hex"));
        }
        setTxHashes(preparedTxHashes);

        // broadcast all txs and get the psdt
        const psdtHex = await getSellerPsdtHex({
          sellerAddress: txWallet.address,
          inscriptionPrice: priceInShibs,
          transactionHexs: preparedTxHexs,
          transactionOutput: 0,
          discount: false,
          type: "drc20",
        });

        // Sign psdt
        const signedPsdtHex = signPsbt(psdtHex, txWallet, {
          isHex: true,
          autoFinalized: true,
        }) as string;

        // Create offer
        const response = await createDrc20Offer(signedPsdtHex);
        console.log("listDrc20 - Offer created", { response });

        setIsSuccessDrc20List(true);
      } catch (e: Error | unknown) {
        setIsErrorDrc20List(true);
        const message = handleError(e);
        setError(message);
      } finally {
        setIsListingDrc20(false);
      }
    },
    [txWallet, buildListDrc20sTx],
  );

  const reset = useCallback(() => {
    setIsErrorDrc20List(false);
    setIsSuccessDrc20List(false);
    setError(null);
    setTxHashes([]);
  }, []);

  return {
    isLoading,
    isError,
    isSuccess,
    execute: listDrc20,
    txHashes,
    reset,
    error,
  };
};
