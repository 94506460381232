import React from "react";

import {
  Button,
  InputPassword,
  InputPin,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/components";

import { AnimatedContent } from "../../base";

interface PasswordCreationViewProps {
  onContinueWithPassword: () => void;
  onContinueWithPin: () => void;

  onPinsChange: (
    type: "pin" | "repeatPin",
    index: number,
    value: string,
  ) => void;

  pins: { pin: string[]; repeatPin: string[] };
  pinsMatch: boolean;
  pinsSet: boolean;

  onPasswordsChange: (
    type: "password" | "repeatPassword",
    value: string,
  ) => void;

  passwords: { password: string; repeatPassword: string };
  allRulesMet: boolean;
  passwordsMatch: boolean;
  passwordsSet: boolean;

  initialSecurityPreference: "pin" | "password" | undefined;
  onSwitchSecurityPreference: (value: "pin" | "password") => void;
}

export const PasswordCreationView: React.FC<PasswordCreationViewProps> = ({
  onPasswordsChange,
  onPinsChange,
  onContinueWithPassword,
  onContinueWithPin,
  onSwitchSecurityPreference,
  pins,
  passwords,
  allRulesMet,
  passwordsMatch,
  passwordsSet,
  pinsMatch,
  pinsSet,
  initialSecurityPreference,
}) => {
  return (
    <AnimatedContent id="onboarding_create_1">
      <div className="flex-1.5 flex flex-col justify-center space-y-2 p-2">
        <h2 className="text-center text-xl font-medium">Secure Wallet</h2>
        <p className="text-center text-xs">
          Secure your wallet with a password or PIN.
        </p>
      </div>
      <Tabs
        defaultValue={initialSecurityPreference}
        className="flex flex-1 flex-col"
      >
        <TabsList className="mx-8 grid grid-cols-2 bg-background-primary">
          <TabsTrigger
            value="pin"
            className="bg-background-primary"
            onClick={() => onSwitchSecurityPreference("pin")}
          >
            PIN
          </TabsTrigger>
          <TabsTrigger
            value="password"
            className="bg-background-primary"
            onClick={() => onSwitchSecurityPreference("password")}
          >
            Password
          </TabsTrigger>
        </TabsList>
        <TabsContent value="pin" className="h-full w-full">
          <div className="flex h-full w-full flex-1 flex-col">
            <div className="flex flex-1 flex-col justify-center space-y-8">
              <div className="flex flex-col items-center space-y-2">
                <div className="space-y-2">
                  <span className="ml-2 text-sm text-white">
                    Enter 6-Digit PIN
                  </span>
                  <InputPin
                    pin={pins.pin}
                    onChange={(index, value) =>
                      onPinsChange("pin", index, value)
                    }
                  />
                </div>
              </div>
              <div className="flex flex-col items-center space-y-2">
                <div className="space-y-2">
                  <span className="text-sm text-white">Repeat 6-Digit PIN</span>
                  <InputPin
                    pin={pins.repeatPin}
                    onChange={(index, value) =>
                      onPinsChange("repeatPin", index, value)
                    }
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col space-y-1">
              <span className="text-center text-2xs text-red-500">
                {pinsMatch ? "\u00A0" : "Pins do not match."}
              </span>
              <Button
                size="large"
                variant="default"
                onClick={onContinueWithPin}
                disabled={!pinsMatch || !pinsSet}
              >
                <span>Continue</span>
              </Button>
            </div>
          </div>
        </TabsContent>
        <TabsContent value="password" className="h-full w-full">
          <div className="flex h-full w-full flex-1 flex-col">
            <div className="flex flex-1 flex-col justify-center space-y-4">
              <InputPassword
                placeholder="Password"
                onChange={(event) =>
                  onPasswordsChange("password", event.target.value)
                }
                password={passwords.password}
                withCriteria
              />
              <InputPassword
                placeholder="Repeat Password"
                onChange={(event) =>
                  onPasswordsChange("repeatPassword", event.target.value)
                }
                password={passwords.repeatPassword}
              />
              <span className="-mt-2 ml-2 text-2xs text-red-500">
                {passwordsMatch ? "\u00A0" : "Passwords do not match."}
              </span>
            </div>

            <Button
              size="large"
              variant="default"
              onClick={onContinueWithPassword}
              disabled={!allRulesMet || !passwordsMatch || !passwordsSet}
            >
              <span>Continue</span>
            </Button>
          </div>
        </TabsContent>
      </Tabs>
    </AnimatedContent>
  );
};
