import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Button, ListItem, useToast } from "@/components";
import { AVERAGE_NETWORK_FEE_RATE, ONE_DOGE_IN_SHIBES } from "@/constants";
import { broadcastAll } from "@/context/wallet/lib/transaction.ts";
import { useBalance } from "@/contextHooks";
import { UnsafeBigUtxo, useUnblockUtxo } from "@/hooks";
import { shortenAddress } from "@/lib/address";
import { NumberFormatType, formatNumber } from "@/lib/numbers";
import { Currency } from "@/types";
import { PreparedTx } from "@/types/transaction";
import { handleError } from "@/utility";

import { Alert, Sheet, SheetProps } from "../base";

interface ModalUTXOProps extends SheetProps {}

export const ModalUTXO: React.FC<ModalUTXOProps> = ({
  isVisible = false,
  onClose,
}) => {
  const { getBigUnsafeUtxos, unsafeBigUtxos, unblockUtxo } = useUnblockUtxo();
  const { refetchDogeBalance } = useBalance();
  const { toast } = useToast();

  const [selectedUtxos, setSelectedUtxos] = useState<UnsafeBigUtxo[]>([]);
  const [transactions, setTransactions] = useState<PreparedTx[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isAlertVisible, setIsAlertVisible] = useState(false);

  const onAddTransaction = useCallback((tx: PreparedTx) => {
    setTransactions((prev) => [...prev, tx]);
  }, []);

  const onRemoveTransaction = useCallback((tx: PreparedTx) => {
    setTransactions((prev) => prev.filter((item) => item !== tx));
  }, []);

  //@todo: this should be moved to the wallet context
  const createUnblockingTransaction = useCallback(
    async (utxo: UnsafeBigUtxo, prev_txs: PreparedTx[]) => {
      const tx = await unblockUtxo({
        utxo,
        feeRate: AVERAGE_NETWORK_FEE_RATE,
        prev_txs,
      });
      return tx;
    },
    [unblockUtxo],
  );

  // Select and unselect an UTXO
  const onSelectUtxo = useCallback(
    async (utxo: UnsafeBigUtxo) => {
      setSelectedUtxos((prev) => {
        if (prev.includes(utxo)) {
          const txToRemove = transactions.find(
            (tx) => tx.tx.inputs[0].prevTxId.toString("hex") === utxo.txId,
          );

          if (txToRemove) onRemoveTransaction(txToRemove);
          return prev.filter((item) => item !== utxo);
        }
        return [...prev, utxo];
      });

      if (!selectedUtxos.includes(utxo)) {
        setIsLoading(true);
        try {
          const newTx = await createUnblockingTransaction(utxo, transactions);
          onAddTransaction(newTx);
        } catch (e: Error | unknown) {
          const message = handleError(e);
          toast({
            title: "Error",
            description: message,
          });
        } finally {
          setIsLoading(false);
        }
      }
    },
    [
      selectedUtxos,
      transactions,
      createUnblockingTransaction,
      onAddTransaction,
      onRemoveTransaction,
      toast,
    ],
  );

  // Reset the selected UTXOs
  const onResetUtxos = useCallback(() => {
    setSelectedUtxos([]);
    setTransactions([]);
  }, []);

  // Close the modal and reset the UTXOs
  const handleOnClose = useCallback(() => {
    onResetUtxos();
    setIsAlertVisible(false);
    onClose?.();
  }, [onClose, onResetUtxos]);

  // The list of displayed UTXOs
  const listItems = useMemo(() => {
    return unsafeBigUtxos.map((item) => ({
      label: shortenAddress(item.txId),
      selected: selectedUtxos.includes(item),
      onClick: () => onSelectUtxo(item),
    }));
  }, [unsafeBigUtxos, onSelectUtxo, selectedUtxos]);

  // Transaction fee calculation
  const transactionFee = useMemo(() => {
    const totalInSats = transactions.reduce(
      (acc, tx) => acc + tx.txFeeInSats,
      0,
    );
    return formatNumber({
      value: totalInSats / ONE_DOGE_IN_SHIBES,
      type: NumberFormatType.Price,
    });
  }, [transactions]);

  // Unblocking UTXOs
  //@todo: this should be moved to the wallet context
  const onConfirm = useCallback(async () => {
    try {
      setIsLoading(true);
      const txs = transactions.map((tx) => tx.tx);
      const txHashes = await broadcastAll(txs, {
        type: "doge",
      });

      if (!txHashes) {
        throw new Error("Failed to broadcast transaction");
      }

      const descriptions = txHashes.map((txHash, index) => {
        return `UTXO ${shortenAddress(txs[index]?.txId)} unblocked - txHash: ${txHash}`;
      });

      await getBigUnsafeUtxos();
      await refetchDogeBalance();
      toast({
        title: "Success",
        description: descriptions.join("\n"),
      });
      handleOnClose();
    } catch (e: Error | unknown) {
      const message = handleError(e);
      toast({
        title: "Error",
        description: message,
      });
    } finally {
      setIsLoading(false);
    }
  }, [
    getBigUnsafeUtxos,
    refetchDogeBalance,
    toast,
    handleOnClose,
    transactions,
  ]);

  useEffect(() => {
    console.log("call getBigUnsafeUtxos");
    getBigUnsafeUtxos();
  }, [getBigUnsafeUtxos]);

  // const createBlockingUtxosForTesting = useCallback(async () => {
  //   if (!address || !pw) throw new Error("No address or password found");
  //   const privKey = await showAccountPrivateKey?.(address, pw);
  //
  //   // get a drc20 utxo
  //   const res = await fetchDrc20s(address, { t: new Date().getTime() });
  //   const drc20Utxos = res.drc20[0].utxos;
  //   const drc20Utxo = drc20Utxos?.[1];
  //   if (!drc20Utxo) throw new Error("Ping Jules we ran out of DRC20 UTXOs");
  //   const {
  //     txid: drc20TxId,
  //     shibes: drc20Shibes,
  //     vout: drc20Vout,
  //     script: drc20Script,
  //   } = drc20Utxo;
  //
  //   // get a doge utxo
  //   const balance = await fetchDogeBalance(address, {
  //     t: new Date().getTime(),
  //   });
  //   const dogeUtxos = balance.utxos;
  //   const dogeUtxo = dogeUtxos?.[1];
  //   if (!dogeUtxo) throw new Error("Ping Jules we ran out of DOGE UTXOs");
  //   const {
  //     txid: dogeTxId,
  //     shibes: dogeShibes,
  //     vout: dogeVout,
  //     script: dogeScript,
  //   } = dogeUtxo;
  //
  //   const tx = new Transaction();
  //   tx.from(
  //     [
  //       // drc20
  //       {
  //         txid: drc20TxId,
  //         vout: drc20Vout,
  //         script: drc20Script,
  //         satoshis: drc20Shibes,
  //       },
  //       // doge
  //       {
  //         txid: dogeTxId,
  //         vout: dogeVout,
  //         script: dogeScript,
  //         satoshis: dogeShibes,
  //       },
  //     ],
  //     [privKey],
  //   );
  //
  //   console.log({ drc20Utxo, dogeUtxo });
  //
  //   tx.change(address);
  //   tx.sign(privKey);
  //   const txHash = await broadcast(tx);
  //   console.log({ txHash });
  // }, [address, pw, showAccountPrivateKey]);

  return (
    <>
      <Sheet
        isVisible={isVisible}
        onClose={handleOnClose}
        title="Unblock UTXOs"
        classNameContent="flex flex-col flex-1"
      >
        <div className="flex flex-1 flex-col space-y-4 p-4 pb-safe-or-4">
          <div className="lg:show-scrollbar h-full max-h-[60vh] flex-col space-y-4 overflow-y-auto md:max-h-[70vh]">
            {unsafeBigUtxos?.length > 0 ? (
              <div className="flex flex-col space-y-2 rounded-xl bg-background-secondary p-2">
                {listItems.map((item, index) => (
                  <ListItem
                    key={index}
                    selectable
                    selected={item.selected}
                    label={item.label}
                    withChevron={false}
                    onClick={item.onClick}
                  />
                ))}
              </div>
            ) : null}
          </div>
          {/* <Button size="large" onClick={createBlockingUtxosForTesting}>
            Create Block UTXOs (Testing)
          </Button> */}
          <Button
            size="large"
            disabled={selectedUtxos.length === 0}
            onClick={() => setIsAlertVisible(true)}
          >
            Unblock Selected
          </Button>
        </div>
      </Sheet>

      <Alert
        title={"Unblock UTXOs"}
        message="Are you sure you want to unblock the selected UTXOs?"
        isVisible={isAlertVisible}
        onClose={() => setIsAlertVisible(false)}
        actionPrimary={{
          label: "Unblock",
          variant: "inverse",
          onClick: onConfirm,
          loading: isLoading,
        }}
        actionSecondary={{
          label: "Cancel",
          variant: "ghost",
          loading: isLoading,
          onClick: () => setIsAlertVisible(false),
        }}
      >
        <span className="text-center text-xs text-white">
          {`Network Fees: ${Currency.DOGE} `}
          <span dangerouslySetInnerHTML={{ __html: transactionFee }} />
        </span>
      </Alert>
    </>
  );
};
