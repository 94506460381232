import { useContext } from "react";
import { DoginalWatchlistContext } from "@/context/DoginalWatchListContext.ts";

export const useDoginalWatchlist = () => {
  const context = useContext(DoginalWatchlistContext);

  if (!context) {
    throw new Error(
      "useDoginalWatchlist must be used within a WatchlistProvider",
    );
  }

  return context;
};
