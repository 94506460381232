import React from "react";

import { cn } from "@/lib/utils";

import { Button } from "../ui/button";

interface ButtonConfig {
  icon: string;
  onClick: () => void;
  className?: string;
  isActive?: boolean;
}

interface ButtonBarProps {
  buttons: ButtonConfig[];
  className?: string;
}

export const ButtonBar: React.FC<ButtonBarProps> = ({ buttons, className }) => {
  return (
    <div
      className={cn(
        "flex flex-row space-x-2 overflow-visible rounded-full bg-background-tertiary px-4 shadow-md",
        "mx-auto w-full max-w-2xl",
        className,
      )}
    >
      {buttons.map((button, index) => (
        <Button
          key={index}
          variant="ghost"
          onClick={button.onClick}
          className={cn(
            "flex-1 hover:text-text-highlight",
            button.className,
            button.isActive && "text-text-highlight",
          )}
        >
          <span className="material-symbols-rounded text-lg">
            {button.icon}
          </span>
        </Button>
      ))}
    </div>
  );
};
