import { useContext } from "react";

import { LoginPromptContext } from "@/context/LoginPromptProvider";

export const useLoginPrompt = () => {
  const loginPromptContext = useContext(LoginPromptContext);

  if (!loginPromptContext) {
    throw new Error("useLoginPrompt must be used within a LoginPromptProvider");
  }

  return loginPromptContext;
};
