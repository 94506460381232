import { useContext } from "react";

import { CurrencyContext } from "@/context/CurrencySwitchProvider";

export const useCurrency = () => {
  const currencyContext = useContext(CurrencyContext);

  if (!currencyContext) {
    throw new Error("useCurrency must be used within a CurrencyProvider");
  }

  return currencyContext;
};
