import { useEffect } from "react";
import { useScrollLock } from "usehooks-ts";

export const useLock = (isVisible: boolean) => {
  const { lock, unlock } = useScrollLock({ autoLock: false });

  useEffect(() => {
    if (isVisible) {
      lock();
    } else {
      unlock();
    }
  }, [isVisible, lock, unlock]);
};
