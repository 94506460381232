import React from "react";

import { Button } from "@/components";

import { AnimatedContent } from "./animatedContent";

interface SuccessViewProps {
  handleOpenTransactions?: () => void;
  handleContinue: () => void;
  title?: string;
  info?: string;
}

export const SuccessView: React.FC<SuccessViewProps> = ({
  handleContinue,
  handleOpenTransactions,
  title = "Transaction Submitted!",
  info,
}: SuccessViewProps) => (
  <AnimatedContent id="success">
    <div className="flex flex-1 flex-col items-center justify-center space-y-10">
      <span className="relative flex h-16 w-16">
        <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-green-600 opacity-25" />
        <span className="relative inline-flex h-full w-full items-center justify-center rounded-full bg-green-600">
          <span className="material-symbols-rounded text-4xl text-text-primary">
            check
          </span>
        </span>
      </span>

      <div className="flex flex-col justify-center space-y-0">
        <h2 className="text-center text-xl font-medium">{title}</h2>
        {handleOpenTransactions && (
          <Button
            size="small"
            variant="ghost"
            onClick={handleOpenTransactions}
            className="text-text-tertiary"
          >
            View Transactions
          </Button>
        )}
        {info !== undefined && (
          <p className="ite text-center text-sm font-medium">{info}</p>
        )}
      </div>
    </div>
    <Button size="large" variant="default" onClick={handleContinue}>
      Close
    </Button>
  </AnimatedContent>
);
