import { wonkyOrdApi } from "@/lib/fetch";

import { handleError } from "./handleError";

export const getTxConfirmations = async (txId: string) => {
  try {
    const response = await wonkyOrdApi(true).get(`/tx/${txId}?json=true`, {
      // we don't want to wait for this for long
      timeout: 1000,
    });

    return response?.data?.confirmations || 0;
  } catch (e: Error | unknown) {
    handleError(e);
    return 0;
  }
};
